import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      principalCourses: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      }
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCoursesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getPrincipalCourses = search => Get(
      `/principal_courses?query=${ search }`,
      this.getPrincipalCoursesSuccess,
      this.getPrincipalCoursesError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getPrincipalCoursesSuccess = payload => this.setState({ principalCourses: payload.data })
    getPrincipalCoursesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          principalCourses={ this.state.principalCourses }
          onLoadPrincipalCourses={ this.state.loading }
          onChangeCoursesHOC={ this.onChangeCoursesHOC }
          getPrincipalCourses={ this.getPrincipalCourses }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  })( WithHOC )
}

export default HOC