import React, { useEffect, useState } from 'react'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { GradeOptions, MuetGrade } from '../../../asset'

import PrincipalHOC from '../../../actions/principal'
 
const defaultValue = {
  grade: '',
  is_current_repeat_course: false,
  principal_course_id: null
}

const FormContent = ({
  open,
  student_id,
  principalCourses,
  onLoadCourse,
  onLoadPrincipalCourses,
  
  onClose,
  onClickSubmit,
  getPrincipalCourses
}) => {

  const [ courseData, onChangeCourseData ] = useState( defaultValue )
  const [ gradeOptions, onChangeGradeOptions ] = useState( GradeOptions )

  const onChangeCourseField = ( key, val ) => {
    let tmp = _.cloneDeep( courseData )
    tmp[ key ] = val
    return onChangeCourseData( tmp )
  }

  useEffect(() => {
    open && getPrincipalCourses()

    if ( !open ){
      onChangeCourseData( defaultValue )
    }
  }, [open])

  //handle grade options over here.

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create Course` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            <Col lg={ 6 }>
              <FormGroup>
                <Label>
                  Principal Course
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type={ 'select' }
                  value={ courseData.principal_course_id }
                  onChange={ e => {
                    if ( e.target.value !== '4') {
                      onChangeGradeOptions( GradeOptions )
                      onChangeCourseField( 'principal_course_id', e.target.value )
                    } else {
                      onChangeGradeOptions( MuetGrade )
                      onChangeCourseData({
                        ... courseData,
                        principal_course_id: e.target.value,
                        grade: ''
                      })
                    }
                  }}
                >
                  <option></option>
                  { principalCourses?.[0] && principalCourses.map( data => (
                    <option key={ data.id } value= { data.id }>{ data.name }</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={ 6 }>
              <FormGroup>
                <Label>
                  Grade
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type={ 'select' }
                  value={ courseData.grade }
                  onChange={ e => onChangeCourseField( 'grade', e.target.value )}
                >
                  <option></option>
                  { gradeOptions.map( grade => (
                    <option key={ grade } value= { grade }>{ grade }</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col lg={ 12 }>
              <FormGroup check>
                <Label check>
                  <Input 
                    checked={ courseData.is_current_repeat_course } 
                    type="checkbox" 
                    onChange={ e => onChangeCourseField( 'is_current_repeat_course', e.target.checked )}
                  />
                  Placement Form Check
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        { ( onLoadCourse || onLoadPrincipalCourses ) && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ 
            ( !courseData.principal_course_id || !courseData.grade ) 
              ? 'secondary' 
              : 'primary' 
          }
          disabled={ !courseData.principal_course_id || !courseData.grade }
          onClick={() => {
            onClickSubmit({
              ... courseData,
              principal_course_id: +courseData.principal_course_id,
              student_id
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PrincipalHOC( FormContent )