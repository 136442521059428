import React from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Collapse, Row, Col, 
  Card, CardBody,
  Button, 
  UncontrolledTooltip
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import StudentAcceptedVacanciesHOC from './actions/acceptedVacancies'
import { useEffect } from 'react'
  
const StudentAcceptedVacancies = ({
  studentAcceptedVacancies,
  data,
  searchParams,
  selectedVisitVacancy,
  showSearchForms,
  onLoadStudentAcceptedVacancies,

  onSelectAcceptedVacancies,
  getStudentAcceptedVacancies,
  onChangeStudentAcceptedVacanciesHOC
}) => {

  useEffect(( )=> {
    const { current_selected_session_id } = data.SessionReducer

    getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
  }, [])

  return (
    <>
      <Button 
        color="warning"
        className={ 'btn-icon ml-auto d-flex mb-2' }
        onClick={ () => {
          onChangeStudentAcceptedVacanciesHOC( 'showSearchForms', !showSearchForms )
        }}
      >
        <>
          <i className="pe-7s-search btn-icon-wrapper"> </i>
          <span>Search</span>
        </>
      </Button>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Collapse
          isOpen={ showSearchForms }
          onExiting={ () => onChangeStudentAcceptedVacanciesHOC( 'showSearchForms', false )}
        >
          <SearchForm
            searchParams={ searchParams }
            onChangeHOCState={ onChangeStudentAcceptedVacanciesHOC }
            getListAPI={ getStudentAcceptedVacancies }
          />
        </Collapse>
        <Row>
          <Col lg={ 12 }>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    studentAcceptedVacancies.data.length < 1? 3: 
                    studentAcceptedVacancies.data.length < 10? studentAcceptedVacancies.data.length:
                    10
                  }
                  data={ studentAcceptedVacancies.data }
                  columns={[
                    {
                      Header: 'Student Name',
                      accessor: 'student_name'
                    },
                    {
                      Header: 'Company',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="AssignStudentVacancies"
                              className="mr-2"
                              disabled={ selectedVisitVacancy?.id === row.original.id }
                              color={ 
                                selectedVisitVacancy?.id === row.original.id 
                                  ? 'secondary'
                                  : 'success' 
                              }
                              onClick={() => onSelectAcceptedVacancies( row.original )}
                            >
                              { selectedVisitVacancy?.id === row.original.id ? 'Assigned' : 'Assign' }
                            </Button>
                            <UncontrolledTooltip target="AssignStudentVacancies" placement="left">Assign accepted vacancy for lectuter visit schedule.</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={ false } />
                <Pagination 
                  metadata={ studentAcceptedVacancies.meta } 
                  onChangePage={ pg => {
                    const { search } = data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    getStudentAcceptedVacancies( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
      { onLoadStudentAcceptedVacancies && <LoadingOverlay />}
    </> 
  )
}

export default compose(
  StudentAcceptedVacanciesHOC
)(StudentAcceptedVacancies)