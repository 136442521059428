import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Button, UncontrolledTooltip, Collapse,
  ModalHeader, Modal
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'

import EditForm from './Edit/index'
import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'  
import Pagination from 'components/Pagination'

import JobScopeHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class JobScope extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getJobScopes( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getJobScopes( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }
  }

  renderNonStudentContent = () => {
    const { is_update } = this.props.currentPermission

    return (
      <>
        <Collapse
          isOpen={ this.props.showSearchForms }
          onExiting={ () => this.props.onChangeJobScopeHOC( 'showSearchForms', false )}
        >
          <SearchForm
            searchParams={ this.props.searchParams }
            onChangeHOCState={ this.props.onChangeJobScopeHOC }
            getListAPI={ val => {
              let tempJSON = JSON.parse( atob( val ))
              tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 's.session_id' )

              this.props.getJobScopes( btoa( JSON.stringify( tempJSON )) )
            }}
          />
        </Collapse>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    this.props.jobScope.data.length < 1? 3: 
                    this.props.jobScope.data.length < 10? this.props.jobScope.data.length:
                    10
                  }
                  data={ this.props.jobScope.data }
                  columns={[
                    {
                      Header: 'Student Name',
                      accessor: 'student_name'
                    },
                    {
                      Header: 'Student Email',
                      accessor: 'student_email'
                    },
                    {
                      Header: 'Company',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'Approved ?',
                      Cell: cell =>(
                        <>
                          {
                            cell.original.jkli_approval_status === "Approved"
                              ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                              : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
                          }
                        </>
                      )
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="DownloadJobScope"
                              className="mr-2 btn-icon btn-icon-only"
                              color="warning"
                              onClick={() => this.props.getStudentJobScopeForm_pdf(row.original.id, row.original.student_name) }
                            >
                              <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="DownloadJobScope" placement="left">Download this job scope</UncontrolledTooltip>
                            <Button
                              id="UpdateJobScope"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_update ? "primary" : 'secondary' }
                              disabled={ !is_update }
                              onClick={() => {
                                this.props.getSelectedJobScope( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="UpdateJobScope" placement="left">Update the information of this job scope</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={false} />
                <Pagination 
                  metadata={ this.props.jobScope.meta } 
                  onChangePage={ pg => {
                    const { search } = this.props.data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    this.props.getJobScopes( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal size='xl' isOpen={ this.props.showUpdateJobScopesModal }>
          <ModalHeader toggle={() => this.props.onChangeJobScopeHOC( 'showUpdateJobScopesModal', false )}>
            { `Edit Job Scope` }
          </ModalHeader>
          <EditForm
            selectedJobScope={ this.props.selectedJobScope }
            onLoadJobScope={ this.props.onLoadJobScope }
            updateJobScope={ this.props.updateJobScope }
            currentPermission={ this.props.currentPermission }
          />
        </Modal>
      </>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Job Scope"
          subheading="Listings of all the job scope present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            ... this.props.data.ProfileReducer.current_role_id !== 13  ? [{
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeJobScopeHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }] : []
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent() }
          { this.props.data.ProfileReducer.current_role_id === 13 && 
            this.props.jobScope.data.length > 0 && ( 
            <>
              {
                _.keys( this.props.selectedJobScope ).length > 0 && (
                  <Card>
                    <EditForm
                      selectedJobScope={ this.props.selectedJobScope }
                      updateJobScope={ this.props.updateJobScope }
                      onLoadJobScope={ this.props.onLoadJobScope }
                    />
                  </Card>
                )
              }
              {
                _.keys( this.props.selectedJobScope ).length < 1 && (
                  <Card>
                    <CardHeader>No Job Scope is available. </CardHeader>
                  </Card>
                )
              }
            </>
          )}
        </ReactCSSTransitionGroup>
        { this.props.onLoadJobScope && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  JobScopeHOC
)(JobScope)