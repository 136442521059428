import React, { useEffect, useState } from 'react'
import { 
  Row, Col,
  FormGroup, Label, Input,
  Card, CardHeader,CardBody
} from 'reactstrap'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'

const FieldData = [
  { label: 'Created At', value: 'created_at', type: 'text', editable: false },
  { label: 'Insurance Form URL', value: 'doc_url', type: 'url', editable: false },
  { label: 'Clerk Checked', value: 'is_clerk_approved', type: 'boolean', editable: false },
]

const InsuranceForm = props => {

  const { student_insurance_form, student_insurance_form_id } = props.selectedAcceptedVacancies
  const { is_update } = props
  const [ formContent, setFormContent ] = useState({
    created_at: "",
    doc_url: "",
    is_clerk_approved: false,
  })

  useEffect( () => {
    if( student_insurance_form )
      setFormContent({ ...student_insurance_form })
  }, [ props.selectedAcceptedVacancies ])

  return(
    <Card className="mt-2 mb-3">
      <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
        Form Details
      </CardHeader>
      <CardBody>
        <Row>
          {
            student_insurance_form_id && FieldData.map( data => (
              <Col lg={ 12 }>
                <FormGroup>
                  <Label>{ data.label }</Label>
                  {
                    data.type === 'boolean' && (
                      formContent[data.value]
                        ? <CheckmarkOutline cssClasses={ 'text-success d-block' }/>
                        : <CloseOutline cssClasses={ 'text-danger d-block' }/>
                    )
                  }
                  {
                    data.type === 'url' && (
                      <div>
                        {
                          formContent[data.value]
                          ? <a className='student-vacancy__detail-subtitle' href={ formContent[data.value] } target="_blank">{ formContent[data.value] }</a>
                          : <span className='student-vacancy__detail-subtitle'>N/A</span>
                        }
                      </div>
                    )
                  }
                  {
                    data.type !== 'boolean' && data.type !== 'url' && (
                      <Input
                        type={ data.type }
                        value={ formContent[data.value] || 'N/A' }
                        disabled={ true }
                      />
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          {
            !student_insurance_form_id && (
              <span className='student-vacancy__detail-subtitle pl-3'>No Insurance Form</span>
            )
          }
        </Row>
      </CardBody>
    </Card>
  )
}

export default InsuranceForm;