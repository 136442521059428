import React, { useState, useRef } from 'react'

import { 
  ModalBody,
  CardFooter,
  Button,
  FormGroup,
  Label, 
  Card, CardHeader, CardBody
} from 'reactstrap'
import _ from 'lodash'

import { CloseOutline } from 'react-ionicons'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const Edit = ({
  data,
  session,
  onLoadForm,

  uploadEvaluationGuideline
}) => {
  const pond = useRef(null)
  
  const [ evaluationGuidelineFile, onChangeFile ] = useState( null )
  const { current_selected_session_id } = data.SessionReducer

  const onDropFile = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangeFile( fileItems[ 0 ].file )
    }
  }

  return (
    <>
      <ModalBody>
        <Card>
          <CardHeader style={{ fontSize: '1rem' }}>Evaluation Doc</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>Uploaded Evaluation Form Guideline</Label>
              {
                session.student_evaluation_form_guidelines_url && (
                  <div>
                    <strong 
                      style={{ cursor: 'pointer', textDecoration: 'underline', wordBreak: 'break-all' }}
                      className={ 'd-block'}
                      onClick={ () => window.open( session.student_evaluation_form_guidelines_url )}
                    >
                      { session.student_evaluation_form_guidelines_url }
                    </strong>
                  </div>
                )
              }
              {
                !session.student_evaluation_form_guidelines_url && (
                  <p>
                    <strong>No doc is uploaded</strong>
                    <CloseOutline cssClasses={ 'text-danger ml-1' }/>
                  </p>
                )
              }
              <hr />
            </FormGroup>
            <FormGroup>
              <Label>Upload Document</Label>
              <FilePond
                ref={ pond }
                allowMultiple={ false }
                fullWidth
                acceptedFileTypes={['application/pdf']}
                maxFiles={ 1 }
                onremovefile={ () => onChangeFile( null )}
                onupdatefiles={ onDropFile }
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button 
              color={ !evaluationGuidelineFile ? 'secondary': 'primary' }
              className="mr-2"
              disabled={ !evaluationGuidelineFile }
              onClick={() => uploadEvaluationGuideline(
                current_selected_session_id,
                evaluationGuidelineFile,
                () => {
                  pond.current.removeFiles()
                  onChangeFile( null )
                }
              )
            }>
              Upload
            </Button>
          </CardFooter>
        </Card>
        { onLoadForm && <LoadingOverlay/> }
      </ModalBody>
    </>
  )
}

export default Edit