import React, { useCallback, useEffect, useState } from 'react'
import Moment from 'moment'

import { 
  Row,
  Col,
  ModalBody,
  Button,
  Form,
  FormGroup, Label, Input, FormText,
  Card, CardBody, CardHeader, CardFooter
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import DatePicker from 'react-datepicker'
import JobScopeCard from './JobScopeForm'
import { FieldData, defaultValue, CheckboxFields } from './assets'
import { mobileChecker } from 'utils/mobileChecker'
import JobScopeHOC from './actions/jobsscope'

const EditReportDuty = ({
  data,
  onLoadForm,
  onLoadJobScope,
  selectedAcceptedList,
  selectedForm,
  selectedJobScope,

  onClickSubmit,
  getJobScopes
}) => {

  const [ formData, onChangeReportData ] = useState( defaultValue )

  useEffect(() => {
    selectedForm && onChangeReportData( selectedForm )
    selectedForm.is_change_job_scope && getJobScopes()
  }, [ selectedForm ])

  const onChangeField = ( key, val ) => {
    let temp = _.cloneDeep( formData )
    temp[ key ] = val

    onChangeReportData( temp )
  }

  const debounceMobile = useCallback(_.debounce(( fullData, context, val ) => {
    onChangeReportData({
      ... fullData,
      [context]: mobileChecker( val )
    })
  }, 500), [])

  return (
    <>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          {
            FieldData.map( card => (
              <Card className={ 'mb-3' }>
                <CardHeader style={{ fontSize: '1rem' }}> { card.card_name }</CardHeader>
                <CardBody>
                  <Row>
                    {
                      card.fields.map( item => (
                        <Col lg={ item.col || 12 }>
                          <FormGroup>  
                            <Label>{ item.label }</Label>
                            {
                              item.type === 'date' && (
                                <>
                                  <DatePicker
                                    // minDate={ new Date()}
                                    className={ 'w-100' }
                                    showYearDropdown={ true }
                                    dateFormat="dd-MM-yyyy"
                                    selected={ formData[ item.value ] }
                                    onChange={ val => onChangeField( item.value, val )}
                                  />
                                </>
                              )
                            }
                            {
                              item.type !== 'date' && (
                                <Input
                                  type={ item.type }
                                  disabled={ !card.editable }
                                  value={ 
                                    card.editable 
                                      ? formData[ item.value ]
                                      : selectedAcceptedList?.[ card.card_obj ]?.[ item.value ]??''
                                  }
                                  onChange={ e => {
                                    Promise.all([
                                      onChangeField( item.value, e.target.value )
                                    ]).then(() => {
                                      [ 'hr_officer_mobile_phone', 'hr_officer_phone' ].indexOf( item.value ) > -1 && debounceMobile( formData, item.value, e.target.value )
                                    })
                                  }}
                                />
                              )
                            }
                          </FormGroup>
                        </Col>
                      ))
                    }
                    {
                      card.editable && (
                        <>
                          {
                            formData.is_have_allowance && (
                              <Col lg={ 12 }>
                                <FormGroup>
                                  <Label>Allowance Amount</Label>
                                  <Input 
                                    value={ formData.allowance_amount } 
                                    type="number" 
                                    onChange={ e => onChangeField( 'allowance_amount', parseFloat(e.target.value) )}
                                  />
                                </FormGroup>
                              </Col>
                            )
                          }
                          {
                            CheckboxFields.map ( field => (
                              <Col lg={ 12 }>
                                <FormGroup className='mb-2' check> 
                                  <Label check>
                                    <Input 
                                      checked={ formData[ field.value ] } 
                                      type="checkbox"
                                      disabled={ 
                                        field.value === 'is_reviewed' && 
                                        [ 1, 11, 12, 14 ].indexOf( data.ProfileReducer.current_role_id ) < 0
                                      }
                                      onChange={ e => {
                                        onChangeField( field.value, e.target.checked )
                                        
                                        field.value === 'is_change_job_scope' && e.target.checked && getJobScopes(selectedAcceptedList?.student_job_scope_form_id)
                                      }}
                                    />
                                    { field.label }
                                  </Label>
                                  {
                                    field.subLabel && (
                                      <FormText style={{ fontWeight: 500 }}>
                                        { field.subLabel }
                                      </FormText>
                                    )
                                  }
                                </FormGroup>
                              </Col>
                            ))
                          }
                        </>
                      )
                    }
                  </Row>
                </CardBody>
                {
                  card.editable && (
                    <CardFooter>
                      <Button 
                        color="primary"
                        onClick={() => onClickSubmit({
                          ... formData, 
                          report_duty_date: Moment( formData.report_duty_date ).format(),
                          external_supervisor_id: +formData.external_supervisor_id 
                        })}>
                        Submit
                      </Button>
                    </CardFooter>
                  )
                }
              </Card>
            ))
          }
          {
            formData.is_change_job_scope && _.keys( selectedJobScope ).length > 0 && (
              <>
                <JobScopeCard 
                  selectedJobScope={ selectedJobScope }
                />
              </>
            )
          }
        </Form>
       {( onLoadForm || onLoadJobScope ) && <LoadingOverlay/> }
      </ModalBody>
    </>
  )
}

export default JobScopeHOC( EditReportDuty );