import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      secModules: [],
    }

    onChangeSecModulesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSecModules = () => Get(
    `/sec_modules?query=${ btoa(`{"page":0, "per_page": 1000}`) }`,
      this.getSecModulesSuccess,
      this.getSecModulesError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getSecModulesSuccess = payload => this.setState({ secModules: payload.data })
    getSecModulesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          secModules={ this.state.secModules }
          onLoadSecModules={ this.state.loading }
          
          getSecModules={ this.getSecModules }
          onChangeSecModulesHOC={ this.onChangeSecModulesHOC }/>
      )
    }
  }
  return WithHOC
}

export default HOC