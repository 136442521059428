import React from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const Confirmation = ({
  open,
  title,
  loading,
  message,

  onClose,
  onClickConfirm
  }) => {
  
    return (
      <Modal
        isOpen={ open }
        size={ 'xl' }
        toggle={() => onClose() }
      >
        <ModalHeader toggle={() =>onClose()}>{ title }</ModalHeader>
        <ModalBody>
          <Label>{ message }</Label>
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary"
            className="mr-2"
            onClick={() => onClickConfirm()}
          >
            Confirm
          </Button>
          <Button 
            color="danger"
            onClick={() => onClose()}
          >
            Dismiss
          </Button>
        </ModalFooter>
        { loading && <LoadingOverlay/> }
      </Modal>
    )
  }
  
  export default Confirmation