import React, { Component } from "react";
import { toast } from "react-toastify";

import { Post } from "utils/axios";
import { DefaultFormValue } from "../assets";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			showTNCModal: true,

			registrationData: DefaultFormValue,
		};

		onChangeRegistrationHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });
		requestError = (error) => toast.error(error);
		requestSuccess = (success) => toast.success(success);

		submitRegistration = (dataToSubmit) => {
			Post(
				`/companies`,
				dataToSubmit,
				this.submitRegistrationSuccess,
				this.submitRegistrationError,
				this.load
			);
		};
		submitRegistrationSuccess = () => {
			this.requestSuccess("New company has been registered successfully.");
			this.setState({
				registrationData: DefaultFormValue,
			});
		};
		submitRegistrationError = (error) => this.requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					registrationData={this.state.registrationData}
					showTNCModal={this.state.showTNCModal}
					onLoadRegistration={this.state.loading}
					onChangeRegistrationHOC={this.onChangeRegistrationHOC}
					submitRegistration={this.submitRegistration}
				/>
			);
		};
	}
	return WithHOC;
};

export default HOC;
