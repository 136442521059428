import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      students: [],
    }

    onChangeStudentsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    //getters
    getStudents = search => Get(
      `/students?query=${ search }`,
      this.getStudentsSuccess,
      this.getStudentsError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getStudentsSuccess = payload => {
      let tempData = []

      payload.data?.[0] && payload.data.map(({ student, user }) => {
        tempData.push({
          id: student.id,
          name: user.name
        })
      })
      this.setState({ students: tempData })
    }
    getStudentsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          students={ this.state.students }

          getStudents={ this.getStudents }
          onChangeStudentsHOC={ this.onChangeStudentsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC