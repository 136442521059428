import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import IndustryCatForm from './Form'

import IndustryCatHOC from './actions'

class IndustryCat extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getIndustryCat( btoa(`{"page":1}`) )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <PageTitle
          heading="Industry Category"
          subheading="Listings of all the industry categories present in the system."
          icon="pe-7s-tools icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeIndustryCatHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... ( is_create ? [{
              color: 'primary',
              onClick: () => {
                this.props.onChangeIndustryCatHOC( 'showCreateIndustryCatModal', true )
              },
              content: 'Create'
            }] : [] )
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeIndustryCatHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeIndustryCatHOC }
              getListAPI={ this.props.getIndustryCat }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.industryCat.data.length < 1? 3: 
                      this.props.industryCat.data.length < 10? this.props.industryCat.data.length:
                      10
                    }
                    data={ this.props.industryCat.data }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateIndustryCat"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.getSelectedIndustryCat(row.original.id, 'edit')
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateIndustryCat" placement="left">Update the information of this industry category</UncontrolledTooltip>
                              <Button
                                id="DeleteIndustryCat"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? "danger" : 'secondary' }
                                disabled={ !is_delete }
                                onClick={() => {
                                  this.props.onChangeIndustryCatHOC( 'showDeleteIndustryCatModal', true )
                                  this.props.onChangeIndustryCatHOC( 'selectedDeleteIndustryCatId', row.original.id )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteIndustryCat" placement="left">Delete this industry category</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.industryCat.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getIndustryCat( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <IndustryCatForm
            {...this.props}
            mode={ 'Create' }
            open={ this.props.showCreateIndustryCatModal }
            onClose={ () => this.props.onChangeIndustryCatHOC( 'showCreateIndustryCatModal', false )}
            onClickSubmit={ this.props.createIndustryCat }
          />
          <IndustryCatForm
            {...this.props}
            mode={ 'Edit' }
            selectedFormData={ this.props.selectedIndustryCat }
            open={ this.props.showUpdateIndustryCatModal }
            onClose={ () => this.props.onChangeIndustryCatHOC( 'showUpdateIndustryCatModal', false )}
            onClickSubmit={ this.props.updateIndustryCat }
          />
          <ConfirmationModal
            open={ this.props.showDeleteIndustryCatModal }
            title={ `Delete Industry Category` }
            loading={ this.props.onLoadIndustryCat }
            message={ `Are you sure you want to delete this Industry Category? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeIndustryCatHOC( 'showDeleteIndustryCatModal', false )}
            onClickConfirm={ () => this.props.deleteIndustryCat( this.props.selectedDeleteIndustryCatId )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadIndustryCat && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  IndustryCatHOC
)(IndustryCat)