import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'
import { searchParams } from '../asset'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      dashboardMetricData: {
        total_students: 0,
        total_approved_companies: 0,
        total_not_approved_companies: 0,
        total_vacancies: 0,
        total_accepted_vacancies: 0,
      },
      dashboardMetric: [],

      users: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedUser: { 
        role_ids: [], 
        rolesInfo: [] 
      },
      studentAcceptedVacancies: {
        offer_letter_count: 0,
        placement_form_count: 0,
        report_duty_count: 0,
        insurance_form_count: 0,
        job_scope_count: 0,
        visit_count: 0,
        external_eval_count: 0,
        internal_eval_count: 0,
        final_report_count: 0,
        total_student: 0,
      },

      jobScope: {
        jobScopeApproved: 0
      },

      showUpdateUsersModal: false,

      currentUserTab: null
    }

    onChangeHOC = ( key, val ) => this.setState({ [key]: val })
    onChangeUsersHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeDashboardHOC = ( key, val ) => this.setState({ [key]: val })

    getDashboardMetric = sessionId => Get(
      `/sessions/metrics/${ sessionId }`,
      this.getDashboardMetricSuccess,
      this.getDashboardMetricError,
      this.load
    )
    getDashboardMetricSuccess = payload => this.setState({  
      dashboardMetricData: {
        ... payload,
        total_companies: payload.total_approved_companies + payload.total_not_approved_companies
      }
    })
    getDashboardMetricError = error => this.requestError( error )

    //getters
    getUsers = ( search, role_id ) => {
      this.props.storeLastView({ search })
      Get(
        `/users?query=${ search }${ this.state.currentUserTab ? `&filter_role_id=[${ this.state.currentUserTab }]` : '' }`,
        this.getUsersSuccess,
        this.getUsersError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getUsersSuccess = payload => {
      const { data = [], meta } = payload
      const { roles } = this.props.data.DictionaryReducer

      let tempData = _.map( data, item => {
        let tempRole = _.map( item.role_ids, role_id => {
          let temp = _.find( roles, { id: role_id })  

          return {
            id: role_id,
            name: temp?.name??''
          }
        })

        return ({
          ... item,
          rolesInfo: tempRole
        })
      })
      this.setState({ users: {
        data: tempData,
        meta: meta
      }})
    }
    getUsersError = error => this.requestError( error )

    getSelectedUser = id => {
      Get(
        `/users/${ id }`,
        this.getSelectedUserSuccess,
        this.getSelectedUserError,
        this.load
      )
    }
    getSelectedUserSuccess = payload => {
      let temp = _.cloneDeep( payload )
      const { roles } = this.props.data.DictionaryReducer

      let tempRole = _.map( payload.role_ids, role_id => {
        let temp = _.find( roles, { id: role_id })  

        return {
          id: role_id,
          name: temp?.name??''
        }
      })

      this.setState({ 
        selectedUser: {
          ... temp,
          rolesInfo: tempRole
        }, 
        showUpdateUsersModal: true 
      })
    }
    getSelectedUserError = error => this.requestError ( error )

    updateUser = dataToSubmit => Put(
      `/users/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateUserSucces,
      this.updateUserError,
      this.load
    )
    updateUserSucces = payload => {
      this.getSelectedUser( payload.id )
      const { search } = this.props.data.LastViewReducer.lastView

      this.getUsers( search )
      this.requestSuccess( 'User was updated successfully.' )
    }
    updateUserError = error => this.requestError( error )

    getStudentAcceptedVacancies = search => {
      this.props.storeLastView({ search })
      Get(
        `/student_vacancy_accepted_lists?query=${ search }`,
        this.getStudentAcceptedVacanciesSuccess,
        this.getStudentAcceptedVacanciesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentAcceptedVacanciesSuccess = payload => {
      let offer_letter_count = 0
      let placement_form_count = 0
      let report_duty_count = 0
      let insurance_form_count = 0
      let job_scope_count = 0
      let visit_count = 0
      let external_eval_count = 0
      let internal_eval_count = 0
      let final_report_count = 0
      let total_student = 0
      payload.data && payload.data.map( item => {
        if (item.offer_letter_urls != null) {
          offer_letter_count += 1
        }
        if (item.placement_form_id != null) {
          placement_form_count += 1
        }
        if (item.student_report_duty_form_id != null) {
          report_duty_count += 1
        }
        if (item.student_insurance_form_id != null) {
          insurance_form_count += 1
        }
        if (item.student_job_scope_form_id != null) {
          job_scope_count += 1
        }
        if (item.visit_schedule_id != null) {
          visit_count += 1
        }
        if (item.external_evaluation_form_id != null) {
          external_eval_count += 1
        }
        if (item.internal_evaluation_form_id != null) {
          internal_eval_count += 1
        }
        if (item.student_vacancy_accepted_list.final_report_url != "") {
          final_report_count += 1
        }
        total_student += 1
      })

      this.setState({ studentAcceptedVacancies: {
        offer_letter_count,
        placement_form_count,
        report_duty_count,
        insurance_form_count,
        job_scope_count,
        visit_count,
        external_eval_count,
        internal_eval_count,
        final_report_count,
        total_student
      }})
    }
    getStudentAcceptedVacanciesError = error => this.requestError( error )
    
    getJobScopes = search => {
      this.props.storeLastView({ search })

      Get(
        `/student_job_scope_forms?query=${ search }`,
        this.getJobScopesSuccess,
        this.getJobScopesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getJobScopesSuccess = payload => {
      let jobScopeApproved = 0
      payload.data && payload.data.map( item => {
        if (item.student_job_scope_form.jkli_approval_status === "Approved") {
          jobScopeApproved += 1
        }
      })
      this.setState({ jobScope: {
        jobScopeApproved
      }})
    }
    getJobScopesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadDashboard={ this.state.loading }
          dashboardMetric={ this.state.dashboardMetric }
          dashboardMetricData={ this.state.dashboardMetricData }
          
          onChangeDashboardHOC={ this.onChangeDashboardHOC }
          getDashboardMetric={ this.getDashboardMetric }

          users={ this.state.users }
          studentAcceptedVacancies={ this.state.studentAcceptedVacancies }
          jobScope={ this.state.jobScope }
          onLoadUser={ this.state.loading }
          currentUserTab={ this.state.currentUserTab }
          selectedUser={ this.state.selectedUser }
          showUpdateUsersModal={ this.state.showUpdateUsersModal }

          getUsers={ this.getUsers }
          getSelectedUser={ this.getSelectedUser }
          getStudentAcceptedVacancies={ this.getStudentAcceptedVacancies }
          getJobScopes={ this.getJobScopes }
          updateUser={ this.updateUser }
          onChangeUsersHOC={ this.onChangeUsersHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC