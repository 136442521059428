import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      studentVacancies:[]
    }

    onChangeStudentVacanciesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentVacancies = search => {
      Get(
        `/student_vacancies?query=${ search }`,
        this.getStudentVacanciesSuccess,
        this.getStudentVacanciesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentVacanciesSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_user, student, student_vacancy, student_vacancy_status }) => {
        tempData.push({
          disableStatusField: student_vacancy.student_vacancy_status_id === 6,
          student_vacancy_id: student_vacancy.id,
          student_id: student_vacancy.student_id,
          session_id: student.session_id,
          student_name: student_user.name,
          student_vacancy_status_id: student_vacancy.student_vacancy_status_id,
          name: student_vacancy_status.name,
        })
      })
      this.setState({ studentVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getStudentVacanciesError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          studentVacancies={ this.state.studentVacancies }
          onLoadStudentVacancies={ this.state.loading }

          getStudentVacancies={ this.getStudentVacancies }
          onChangeStudentVacanciesHOC={ this.onChangeStudentVacanciesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC