
export const DefaultFormValue = {
  name: '',
  phone: '',
  password: '',
  password_confirm: '',
  
  registration_number: '',
  address: '',
  description: '',
  state: '',
  country: '',
  zipcode: '',
  fax_number: '',
  industry_category_id: null,
  website: '',
  contact_person_name: '',
  contact_person_designation_id: null,
  contact_person_email: '',
  is_read_agreement: true
}

export const RegistrationDetails = ({ designations, industryCat, countries, states }) => [
  {
    cardName: 'Company Details',
    fields: [
      { 
        label: 'Co. Name',
        subLabel: 'Name of the company',
        value: 'name',
        required: true
      },
      { 
        label: 'Phone',
        subLabel: 'e.g +6041234567',
        value: 'phone',
        col: 6,
        type: 'text',
        required: true
      },
      { 
        label: 'Fax',
        subLabel: 'e.g +604-1234567 / 4568',
        value: 'fax_number',
        col: 6,
        type: 'text',
        required: true
      },
      { 
        label: "Password",
        type: 'password',
        value: 'password',
        col: 6,
        required: true
      },
      { 
        label: "Confirm Password",
        type: 'password',
        value: 'password_confirm',
        col: 6,
        required: true
      },
      { 
        label: "Company's Website",
        subLabel: 'Official portal / website',
        value: 'website',
        required: false
      },
      { 
        label: 'Co. Registration Number',
        subLabel: 'Add a valid registration number',
        value: 'registration_number',
        required: true
      },
      { 
        label: 'Category',
        subLabel: 'Type of industry',
        value: 'industry_category_id',
        type: 'select',
        options: industryCat,
        required: true
      },
      { 
        label: 'Country',
        subLabel: 'eg. Malaysia / China',
        value: 'country',
        type: 'select',
        col: 4,
        options: countries,
        required: true
      },
      { 
        label: 'State',
        subLabel: 'eg. Pulau Pinang / Kedah',
        value: 'state',
        type: 'select',
        col: 4,
        options: states,
        required: true
      },
      { 
        label: 'Postal / Zip Code',
        subLabel: 'Postal Code',
        value: 'zipcode',
        col: 4,
        required: true
      },
      { 
        label: 'Street Address',
        subLabel: 'Company of full address',
        value: 'address',
        required: true
      },      
      { 
        label: "Company Background",
        type: 'textarea',
        value: 'description',
        required: false,
        subLabel: 'Max Length: 500 words'
      }
    ]
  },
  {
    cardName: 'Contact Person ( Management Division )',
    fields: [
      { 
        label: "Contact Person's Designation",
        subLabel: '1st person to contact',
        value: 'contact_person_designation_id',
        type: 'select',
        options: designations,
        col: 3,
        required: true
      },
      { 
        label: "Contact Person's Name",
        value: 'contact_person_name',
        col: 9,
        required: true
      },
      { 
        label: "Email Address",
        subLabel: '1st person to contact',
        value: 'contact_person_email',
        required: true
      }
    ]
  }
]