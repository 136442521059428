import { STORE_LAST_VIEW } from 'actions/type'

const initialState = {
  search: ''
}

export default ( state = initialState, actions ) => {

  switch ( actions.type ) {
    case STORE_LAST_VIEW:
      return ({
        ...state,
        lastView: actions.payload
      })
  
    default: return state
  }
}
