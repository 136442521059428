import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Company Name', 
    value: 'companies.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Job Title', 
    value: 'company_vacancies.job_title',
    type: 'text',
    param: ''
  },
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentVacancies: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },

      showFullStudentVacanciesModal: false,
      showDeleteStudentVacanciesModal: false,
      showUpdateStudentVacanciesModal: false,
      showSearchForms: false,

      searchParams: searchParams,
      showApplyConfirmation: false,
      selectedDeleteStudentVacanciesId: null,
      selectedUpdateStudentVacancies: null,

      searchQuery: ''
    }

    onChangeStudentVacanciesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentVacancies = search => {
      this.setState({ searchQuery: search })
      Get(
        `/student_vacancies?query=${ search }`,
        this.getStudentVacanciesSuccess,
        this.getStudentVacanciesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentVacanciesSuccess = payload => {
      let tempData = []
      payload.data && payload.data.map(({ student_user, student_vacancy, student_vacancy_status, company, company_vacancy }) => {
        tempData.push({
          ... student_vacancy,
          allowChange: student_vacancy.student_vacancy_status_id !== 5,
          disableStatusField: student_vacancy.student_vacancy_status_id === 6,
          job_title: company_vacancy.job_title,
          company_name: company?.name??'Test',
          student_name: student_user.name,
          student_vacancy_status: student_vacancy_status.name,
          student_confirmation: student_vacancy.student_confirmation
        })
      })

      this.setState({ studentVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getStudentVacanciesError = error => this.requestError( error )
    
    applyStudentVacancies = dataToSubmit => Post(
      `/student_vacancies`,
      dataToSubmit,
      this.applyStudentVacanciesSuccess,
      this.applyStudentVacanciesError,
      this.load
    )
    applyStudentVacanciesSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getCompanyVacancies( search )
      this.getStudentVacancies(btoa(`{"page":1, "per_page: 5 "}`))
      this.setState({ showApplyConfirmation: false })
      this.requestSuccess( 'Vacancies has been applied successfully.' )
    }
    applyStudentVacanciesError = error => this.requestError( error )

    deleteStudentVacancies = id => Delete(
      `/student_vacancies/${ id }`,
      this.deleteStudentVacanciesSuccess,
      this.deleteStudentVacanciesError,
      this.load
    )
    deleteStudentVacanciesSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getCompanyVacancies( search )
      this.getStudentVacancies( this.state.searchQuery )
      this.setState({ showDeleteStudentVacanciesModal: false })
      this.requestSuccess( 'Vacancy application was deleted successfully.' )
    }
    deleteStudentVacanciesError = error => this.requestError( error )

    updateStudentVacancy = ( dataToSubmit ) => Put(
      `/student_vacancies/${ dataToSubmit.student_vacancy_id }`,
      dataToSubmit,
      this.updateStudentVacancySuccess,
      this.updateStudentVacancyError,
      this.load
    )
    updateStudentVacancySuccess = () => {
      this.getStudentVacancies( this.state.searchQuery )
      this.setState({ showUpdateStudentVacanciesModal: false })
      this.requestSuccess( 'Student vacancy offer accepted successfully.')
    }
    updateStudentVacancyError = error => this.requestError( error )

    onChangeAppliedStudentStatus = ( val, index ) => {
      let temp = _.cloneDeep( this.state.studentVacancies )
      temp.data[ index ].student_vacancy_status_id = val

      this.setState({ studentVacancies: temp })
    }
    
    onChangeStudentConfirmation = ( val, index ) => {
      let temp = _.cloneDeep( this.state.studentVacancies )
      temp.data[ index ].student_confirmation = val

      this.setState({ studentVacancies: temp })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          studentVacancies={ this.state.studentVacancies }
          onLoadStudentVacancies={ this.state.loading }
          searchParams={ this.state.searchParams }
          showSearchForms={ this.state.showSearchForms }
          showFullStudentVacanciesModal={ this.state.showFullStudentVacanciesModal }
          showApplyConfirmation={ this.state.showApplyConfirmation }
          selectedDeleteStudentVacanciesId={ this.state.selectedDeleteStudentVacanciesId }
          selectedUpdateStudentVacancies={ this.state.selectedUpdateStudentVacancies }
          showDeleteStudentVacanciesModal={ this.state.showDeleteStudentVacanciesModal }
          showUpdateStudentVacanciesModal={ this.state.showUpdateStudentVacanciesModal }

          deleteStudentVacancies={ this.deleteStudentVacancies }
          updateStudentVacancy={ this.updateStudentVacancy }
          getStudentVacancies={ this.getStudentVacancies }
          applyStudentVacancies={ this.applyStudentVacancies }
          onChangeAppliedStudentStatus={ this.onChangeAppliedStudentStatus }
          onChangeStudentConfirmation={ this.onChangeStudentConfirmation }
          onChangeStudentVacanciesHOC={ this.onChangeStudentVacanciesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC