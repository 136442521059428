import React, { Component, useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, Card, CardBody,
  NavItem, Nav 
} from 'reactstrap'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination'
import DetailsModal from './Details'

import NotificationsHOC from './action'

class Notifications extends Component {

  componentDidMount = () => {
    this.props.getNotifications( btoa(`{"page": 1}`) )
  }

  render = () => {
    const { 
      notifications 
    } = this.props
    return (
      <>
        <PageTitle
          heading="Notifications"
          subheading="Listings of all the notifications present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <Nav vertical>
                  {
                    notifications.meta.total_count < 1 && (
                      <NavItem className="text-center">
                        <h6 className={ 'fw-600' }>No notification is available.</h6>
                      </NavItem>
                    )
                  }
                  {
                    notifications.meta.total_count > 0 && (
                      <>
                        {
                          notifications.data?.[0] && notifications.data.map(( data, index ) => (
                            <NavItem 
                              key={ `notification-${ index }`}
                              className="card nav-item-btn position-relative mb-2"
                              style={{ cursor: 'pointer' }}
                              onClick={ () => {
                                this.props.onChangeNotificationHOC( 'selectedNotification', data )
                                this.props.onChangeNotificationHOC( 'showDetailsModal', true )
                                
                                !data.is_read && this.props.setNotificationOnRead( data.id )
                              }}
                            >
                              <span>
                                { `${ data.content.slice( 0, 150 ) } ${ data.content.length > 150 ? '...' : '' }`}
                              </span>
                              {
                                !data.is_read && (
                                  <div 
                                    className="badge badge-dot badge-dot-lg badge-warning position-absolute"
                                    style={{ top: '40%', right: 10, backgroundColor: '#545cd8' }}
                                  >
                                    Unread
                                  </div>
                                )
                              }
                            </NavItem>
                          ))
                        }
                      </>
                    )
                  }
                  </Nav>
                  <Pagination 
                    metadata={ notifications.meta } 
                    onChangePage={ pg => this.props.getNotifications( btoa(`{"page": ${ pg }}`))} 
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        { this.props.onLoadNotification && <LoadingOverlay />}
        <DetailsModal
          open={ this.props.showDetailsModal }
          content={ this.props.selectedNotification?.content??'' }
          onClose={ () => this.props.onChangeNotificationHOC( 'showDetailsModal', false )}
        />
      </>
    )
  }
}

export default NotificationsHOC (Notifications)