import React, { useCallback } from 'react'
import { Input } from 'reactstrap'
import _ from 'lodash'

import { Fragment } from 'react'

const GradeOption = [ 
  {label: 'A', val: 4.00 }, 
  {label: 'A-', val: 3.67 }, 
  {label: 'B+', val: 3.33 }, 
  {label: 'B', val: 3.00 }, 
  {label: 'B-', val: 2.67 }, 
  {label: 'C+', val: 2.33 }, 
  {label: 'C', val: 2.00 }, 
  {label: 'C-', val: 1.67 }, 
  {label: 'D+', val: 1.33 }, 
  {label: 'D', val: 1.00 }, 
  {label: 'D-', val: 0.67 }, 
  {label: 'F', val: 0.00 }  
]

export default function InternalEvaluation ({
  tempJSON, 
  disabled,
  onChangeJSON
}) {

  let onChangeGrade = useCallback(( tableIndex, subTableIndex, rowIndex, val ) => {
    let temp = _.cloneDeep( tempJSON )
    let tempSubTotal = 0
    let tempGrandTotal = 0

    let { val: gradeMark } = _.find( GradeOption, { label: val })

    let tempSelectedRow = _.cloneDeep( temp.data[ tableIndex ].data[ subTableIndex ].children[rowIndex] )

    // update field mark and grade value
    temp.data[ tableIndex ].data[ subTableIndex ].children[rowIndex].grade = val
    temp.data[ tableIndex ].data[ subTableIndex ].children[rowIndex].marks = gradeMark * tempSelectedRow.process

    // update card total and total after weightage
    temp.data[ tableIndex ].data.map( item => {
      item.children.map( child => {
        tempSubTotal += child.marks
      })
    })
    temp.data[ tableIndex ].total = tempSubTotal
    temp.data[ tableIndex ].total_after_weightage = tempSubTotal * temp.data[ tableIndex ].process / 100

    // update final total mark
    temp.data.map( table => {
      tempGrandTotal += table.total_after_weightage
    })
    temp.total= tempGrandTotal
    
    onChangeJSON( temp )
  }, [tempJSON])

  return (
    <>
       <table className={ 'table mt-3 border border' }>
          <thead>
            <tr>
              <th>Evaluation Criteria</th>
              <th>Grade by USM Supervisor</th>
              <th>Marks</th>
            </tr>
          </thead>
          <tbody>
            {
              tempJSON.data.map(( table, tableIndex ) => (
                <Fragment key={ tableIndex }>
                  <tr className={ 'bg-secondary text-white' }>
                    <td style={{ fontWeight: 600, fontSize: '0.85rem' }} colSpan={ 3 }>{ table.name }</td>
                  </tr>
                  {
                    table.data.map(( subtable, subTableIndex ) => (
                      <>
                        <tr style={{ backgroundColor: 'rgba( 0, 0, 0, 0.1 )'}}>
                          <td style={{ fontSize: '0.9rem', fontWeight: 600 }} colSpan={ 3 }>{ subtable.name }</td>
                        </tr>
                        {
                          subtable.children.map(( row, rowIndex ) => (
                            <tr>
                              <td>{ row.name }</td>
                              <td>
                                <Input
                                  type={ 'select' }
                                  value={ row.grade }
                                  disabled={ disabled }
                                  onChange={ e => onChangeGrade( tableIndex, subTableIndex, rowIndex, e.target.value )}
                                >
                                 {
                                    GradeOption.map( item => (
                                      <option key={ item.val } value={ item.label }>{ item.label }</option>
                                    ))
                                  }
                                </Input>
                              </td>
                              <td>
                                <Input
                                  type={ 'number' }
                                  value={ row.marks }
                                  disabled={ true }
                                />
                              </td>
                            </tr>
                          ))
                        }
                      </>
                    ))
                  }
                  <tr>
                    <td style={{ fontWeight: 600, textAlign: 'end', fontSize: '0.85rem' }} colSpan={ 2 }>
                      { `Total` }
                    </td>
                    <td style={{ fontWeight: 600, textAlign: 'end' }}>{ table.total.toFixed( 2 ) }</td>
                  </tr>
                  <tr className={ 'bg-secondary text-white' }>
                    <td style={{ fontWeight: 600, textAlign: 'end', fontSize: '0.85rem' }} colSpan={ 2 }>
                      { `Total after weightage ( Total x ${ table.process } )/100`}
                    </td>
                    <td style={{ fontWeight: 600, textAlign: 'end' }}>{ table.total_after_weightage.toFixed( 2 ) }</td>
                  </tr>
                </Fragment>
              ))
            }
          </tbody>
          <tfoot>
            <tr className={ 'bg-secondary text-white' }>
              <td style={{ fontWeight: 600, textAlign: 'end', fontSize: '0.9rem' }} colSpan={ 2 }>
                { `Final Total Marks`}
              </td>
              <td style={{ fontWeight: 600, fontSize: '1.3rem', textAlign: 'end' }}>{ tempJSON.total.toFixed( 2 ) }</td>
            </tr>
          </tfoot>
        </table>
    </>
  )
}