import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Name', 
    value: 'companies.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Email', 
    value: 'companies.contact_person_email',
    type: 'text',
    param: ''
  },
  { 
    label: 'Phone', 
    value: 'companies.phone',
    type: 'text',
    param: ''
  },
  { 
    label: 'Status',
    value: 'companies.status',
    type: 'select',
    param: '',
    valueType: 'string',
    exact: true,
    options: [
      { id: 'Active', name: 'Active' },
      { id: 'Inactive', name: 'Inactive' },
    ]
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companies: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedCompany: null,
      selectedDeleteCompanyId: null,

      showCreateCompanyModal: false,
      showUpdateCompanyModal: false,
      showDeleteCompanyModal: false,
      showSearchForms: false,

      searchParams: searchParams
    }

    onChangeCompanyHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    //getters
    getCompany = search => {
      this.props.storeLastView({ search })

      Get(
        `/companies?query=${ search }`,
        this.getCompanySuccess,
        this.getCompanyError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getCompanySuccess = payload => {
      let tempData = []
      payload.data.map(({ company }) => tempData.push({ ... company }))
      
      this.setState({ companies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getCompanyError = error => this.requestError( error )

    getSelectedCompany = id => {
      Get(
        `/companies/${ id }`,
        this.getSelectedCompanySuccess,
        this.getSelectedCompanyError,
        this.load
      )
    }
    getSelectedCompanySuccess = payload => this.setState({ 
      selectedCompany: {
        users: payload.users,
        company: {
          ... payload.company,
          country: payload.company.country,
          state: payload.company.state,
          industry_category_id: payload.industry_category.id,
          contact_person_designation_id: payload.contact_person_designation.id
        }
      }, 
      showUpdateCompanyModal: true 
    })
    getSelectedCompanyError = error => this.requestError ( error )

    createCompany = dataToSubmit => Post(
      `/companies`,
      dataToSubmit,
      this.createCompanySuccess,
      this.createCompanyError,
      this.load
    )
    createCompanySuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getCompany( search )
      this.setState({ showCreateCompanyModal: false })
      this.requestSuccess( 'Company has been created successfully.' )
    }
    createCompanyError = error => this.requestError( error )

    updateCompany = dataToSubmit => Put(
      `/companies/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateCompanySucces,
      this.updateCompanyError,
      this.load
    )
    updateCompanySucces = payload => {
      this.getSelectedCompany( payload.company.id )
      const { search } = this.props.data.LastViewReducer.lastView

      this.getCompany( search )
      this.requestSuccess( 'Company info was updated successfully.' )
    }
    updateCompanyError = error => this.requestError( error )

    deleteCompany = id => Delete( 
      `/companies/${ id }`,
      this.deleteCompanySuccess,
      this.deleteCompanyError,
      this.load
    )
    deleteCompanySuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getCompany( search )
      this.requestSuccess( 'Company was deleted successfully.' )
    }
    deleteCompanyError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          companies={ this.state.companies }
          onLoadCompany={ this.state.loading }
          searchParams={ this.state.searchParams }
          selectedCompany={ this.state.selectedCompany }
          selectedDeleteCompanyId={ this.state.selectedDeleteCompanyId }
          showSearchForms={ this.state.showSearchForms }
          showCreateCompanyModal={ this.state.showCreateCompanyModal }
          showUpdateCompanyModal={ this.state.showUpdateCompanyModal }
          showDeleteCompanyModal={ this.state.showDeleteCompanyModal }

          getCompany={ this.getCompany }
          getSelectedCompany={ this.getSelectedCompany }
          createCompany={ this.createCompany }
          updateCompany={ this.updateCompany }
          deleteCompany={ this.deleteCompany }
          onChangeCompanyHOC={ this.onChangeCompanyHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC