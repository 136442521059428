import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { Post, Get } from "utils/axios";
import { DefaultFormValue } from "./assets";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			companies: [],

			companyVacancyData: DefaultFormValue,
		};

		onChangeVacancyHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });
		requestError = (error) => toast.error(error);
		requestSuccess = (success) => toast.success(success);

		getCompany = (search) => {
			Get(
				`/companies?query=${search}`,
				this.getCompanySuccess,
				this.getCompanyError,
				this.load,
				this.props.data.ProfileReducer.current_role_id
			);
		};
		getCompanySuccess = (payload) => {
			let tempData = [];
			payload.data.map(({ company }) => tempData.push({ ...company }));

			this.setState({ companies: tempData });
		};
		getCompanyError = (error) => this.requestError(error);

		createCompanyVacancies = (dataToSubmit) =>
			Post(
				`/company_vacancies`,
				dataToSubmit,
				this.createCompanyVacanciesSuccess,
				this.createCompanyVacanciesError,
				this.load
			);
		createCompanyVacanciesSuccess = () => {
			this.setState({ companyVacancyData: DefaultFormValue });
			this.requestSuccess("Company Vacancies has been created successfully.");
		};
		createCompanyVacanciesError = (error) => this.requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					onLoadVacancy={this.state.loading}
					getCompany={this.getCompany}
					onChangeVacancyHOC={this.onChangeVacancyHOC}
					createCompanyVacancies={this.createCompanyVacancies}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
