import LoadingOverlay from 'components/Indicator/LoadingOverlay';
import _ from 'lodash';
import { useState } from 'react';
import {
  Button,
  CardBody, CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  Row
} from 'reactstrap';

export const defaultValue = {
  name: true,
  email: true,
  phone: true,
  address: true,
  cgpa: true,
  ic: true,
  matric_number: true,
  specialization: true,
  session_id: true,
  at_cgpa: true,
  academic_status: true,
  accumulated_credits: true,
  is_accepted_vacancy: true,
  company_name: true,
  company_address: true,
  company_description: true,
  job_scope: true,
  job_title: true,
  operating_systems: true,
  programming_languages: true,
  vacancy_number: true,
  student_status: true
}

export const DefaultFieldData = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'phone' },
  { label: 'Address', value: 'address' },
  { label: 'CGPA', value: 'cgpa' },
  { label: 'AT CGPA', value: 'at_cgpa' },
  { label: 'Academic Status', value: 'academic_status' },
  { label: 'Acumulated Credits', value: 'accumulated_credits' },
  { label: 'NRIC', value: 'ic' },
  { label: 'Matric No.', value: 'matric_number' },
  { label: 'Session Id', value: 'session_id' },
  { label: 'Specialization', value: 'specialization' },
  { label: 'Is Accepted Vacancy', value: 'is_accepted_vacancy' },
  { label: 'Company Name', value: 'company_name' },
  { label: 'Company Address', value: 'company_address' },
  { label: 'Company Description', value: 'company_description' },
  { label: 'Job Scope', value: 'job_scope' },
  { label: 'Job Title', value: 'job_title' },
  { label: 'Operating Systems', value: 'operating_systems' },
  { label: 'Programming Languages', value: 'programming_languages' },
  { label: 'Vacancy Number', value: 'vacancy_number' },
  { label: 'Student Status', value: 'student_status' },
]

const FormContent = ({
  data,
  exportStudentCSV,
  onLoadUser
}) => {

  const [ csvData, onChangeCSVData ] = useState( defaultValue )
  const [ generateData, onChangeGenerateData ] = useState([])

  const onChangeCSVField = ( key, val ) => {
    let tmp = _.cloneDeep( csvData )
    tmp[ key ] = val

    return onChangeCSVData( tmp )
  }

  const onClickSubmit = () => {
    onChangeGenerateData([])
    for (const key in csvData) {
      if (!csvData[key]) {
        onChangeGenerateData(prev => [... prev, key])
      }
    }
    if (!_.isEmpty(generateData)) {
      generateData.map(item => {
        data.forEach(stud => {
          delete stud[item] 
        })
      })
    }
  }

  return (
    <>
      <ModalBody>
        <CardBody>
          <Form> 
            <Row>
              <Col>
                <h6>Example Output</h6>
                <ul style={{ marginLeft: '2rem' }}>
                  <li>Please make sure to check the example output first before downloading CSV.</li>
                  <li>If example output is empty, please go to students tab first before trying again.</li>
                </ul>
                <pre>{ JSON.stringify(data[0], null, '\t') }</pre>
              </Col>
            </Row>
            <hr />
            <Row>
              {
                DefaultFieldData.map( field => (
                  <Col lg={ 6 }>
                    <FormGroup check>
                      <Label check>
                        <Input 
                          checked={ csvData[ field.value ] } 
                          type="checkbox" 
                          disabled={ false }
                          onClick={ () => onChangeCSVField(field.value, !csvData[ field.value ])}
                        />
                        { field.label }
                      </Label>
                    </FormGroup>
                  </Col>
                ))
              }
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          {/* <Button 
            color="primary"
            className="mr-2"
            onClick={ onClickSubmit }
          >
            Generate CSV
          </Button> */}
          {/* <CSVLink data={ data } filename={"StudentLists.csv"}>
            <Button
              id="DownloadStudent"
              className="btn-icon"
              color="warning">
                <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                Download CSV
            </Button>
          </CSVLink> */}
          <Button
            className="btn-icon"
            onClick={() => {
              console.log(csvData)
              exportStudentCSV(csvData)
            }}
            color="warning">
              <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
              Download CSV
          </Button>
        </CardFooter>
        { onLoadUser && <LoadingOverlay/> }
      </ModalBody>
    </>
  )
}

export default FormContent;