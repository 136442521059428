import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import _ from 'lodash'
import { PulseLoader } from 'react-spinners';

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SessionTypeahead from 'components/Typeahead/session'
import SesssionHOC from 'actions/hoc/session'

// const AnnouncementType = [ 'General', 'Session Based' ]
const AnnouncementType = [ 'Session Based' ]

const defaultValue = {
  title: '',
  description: '',
  type: null,
  session_id: null
}

const fieldData = [
  { label: 'Title', value: 'title', type: 'text', required: true },
  { label: 'Description', value: 'description', type: 'textarea', required: true },
  { label: 'Type ', value: 'type', type: 'select', required: true },
]

const FormContent = ({
  mode,
  open,
  onClose,
  onLoadAnnouncement,
  selectedFormData,
  onClickSubmit,

  sessions,
  onChangeSessionsHOC,
  onLoadSession,
  getSessions
}) => {

  const [ announcementData, onChangeAnnouncementData ] = useState( defaultValue )

  useEffect(() => {
    if ( open && mode === 'Edit' ){
      onChangeAnnouncementData( selectedFormData )
    } 
    if ( !open ){
      onChangeSessionsHOC( 'sessions', [] )
      onChangeAnnouncementData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    if ( mode === 'Edit' && selectedFormData ){
      onChangeAnnouncementData( selectedFormData )

      selectedFormData?.session_id && getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ selectedFormData.session_id } }}`))
    }
    
  }, [ selectedFormData ])

  let disableSubmit = useMemo(() => {
    if ( announcementData.type === 'Session Based' ){
      return _.values( announcementData ).some( item => !item )
    }
    
    return _.keys( announcementData ).some( key => key!== 'session_id' && !announcementData[key] )
  }, [announcementData])

  const onChangeAnnouncementField = ( key, val ) => {
    let tmp = _.cloneDeep( announcementData )
    tmp[ key ] = val
    return onChangeAnnouncementData( tmp )
  }

  const onChangeTypeaheadSearch = useCallback( _.debounce(
    val => {
      getSessions( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sessions.name": {"$like": "%${ val }%" } }}`))
    }, 600 ), []
  );

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Announcement`}</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          {
            fieldData.map( field => (
              <FormGroup>
                <Label>
                  { `${ field.label } ` }
                  { field.required && <span className="text-danger">*</span> }
                </Label>
                {
                  field.type !== 'select' && (
                    <Input
                      type={ field.type }
                      value={ announcementData[ field.value ] }
                      onChange={ e => {
                        onChangeAnnouncementField( field.value, e.target.value )
                      }} 
                    />
                  )
                }
                {
                  field.type === 'select' && (
                    <Input
                      type={ 'select' }
                      value={ announcementData[ field.value ] }
                      onChange={ e => {
                        onChangeAnnouncementData({
                          ... announcementData,
                          [field.value]: e.target.value,
                          session_id: e.target.value === 'Session Based'
                            ? announcementData.session_id 
                            : null
                        })
                      }}>
                      <option></option>
                      { AnnouncementType.map( data => (
                        <option key={ data } value= { data }>{ data }</option>
                      ))}
                    </Input>
                  )
                }
              </FormGroup>
            ))
          }
          {
            announcementData.type === 'Session Based' && (
              <>
                <Label>
                  { `Session` }
                  <span className="text-danger mr-2">*</span>
                  <PulseLoader  
                    sizeUnit={"px"}
                    size={ 10 }
                    color={ '#16232c' }
                    loading={ onLoadSession } 
                  />
                </Label>
                <SessionTypeahead
                  options={ sessions }
                  onSelect={ val => val?.[0]?.id && onChangeAnnouncementField( 'session_id', val[0].id )}
                  selectedValue={ 
                    announcementData?.session_id && _.find( sessions, { id: announcementData.session_id })
                      ? [ _.find( sessions, { id: announcementData.session_id })] 
                      : [] 
                  }
                  onSearch={ val => {
                    onChangeAnnouncementField( 'session_id', null )
                    val.length > 0
                      ? onChangeTypeaheadSearch( val )
                      : onChangeSessionsHOC( 'sessions', [] )
                  }}
                  filterBy={ 'name' }
                  disableEdit={ onLoadSession }
                  helpText={ 'Please enter session name to search.' }
                />
              </>
            )
          }
        </Form>
        { onLoadAnnouncement && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          color={ disableSubmit ? "secondary" : 'primary' } 
          disabled={ disableSubmit }
          onClick={() => onClickSubmit({
            ... ( mode === 'Edit' && {
              id: selectedFormData.id 
            }),
            title: announcementData.title,
            description: announcementData.description,
            type: announcementData.type,
            ... ( announcementData.type === 'Session Based' && {
              session_id: announcementData.session_id 
            })
          })}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SesssionHOC( FormContent )