import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'

import { storeLastView } from 'actions/lastView'

const searchParams = [
  { 
    label: 'Title', 
    value: 'title',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      announcements: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedAnnouncement: {},

      searchParams: searchParams,
      selectedDeleteAnnouncementId: null,
      showCreateAnnouncementsModal: false,
      showDeleteAnnouncementModal: false,
      showUpdateAnnouncementsModal: false,
      showSearchForms: false
    }

    onChangeAnnouncementsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAnnouncements = search => {
      this.props.storeLastView({ search })

      Get(
        `/announcements?query=${ search }`,
        this.getAnnouncementsSuccess,
        this.getAnnouncementsError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getAnnouncementsSuccess = payload => this.setState({ announcements: payload })
    getAnnouncementsError = error => this.requestError( error )

    getSelectedAnnouncement = ( id, context ) => {
      Get(
        `/announcements/${ id }`,
        payload=> this.getSelectedAnnouncementSuccess( payload, context ),
        this.getSelectedAnnouncementError,
        this.load
      )
    }
    getSelectedAnnouncementSuccess = ( payload, context ) => this.setState({ 
      selectedAnnouncement: payload,
      showUpdateAnnouncementsModal: context === 'edit' 
    })
    getSelectedAnnouncementError = error => this.requestError ( error )

    createAnnouncement = dataToSubmit => Post(
      `/announcements`,
      dataToSubmit,
      this.createAnnouncementSuccess,
      this.createAnnouncementError,
      this.load
    )
    createAnnouncementSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getAnnouncements( search )
      this.setState({ showCreateAnnouncementsModal: false })
      this.requestSuccess( 'Announcements has been created successfully.' )
    }
    createAnnouncementError = error => this.requestError( error )

    deleteAnnouncement = id => Delete( 
      `/announcements/${ id }`,
      this.deleteAnnouncementSuccess,
      this.deleteError,
      this.load
    )
    deleteAnnouncementSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getAnnouncements( search )
      this.setState({ showDeleteAnnouncementModal: false })
      this.requestSuccess( 'Announcement has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateAnnouncement = dataToSubmit => Put(
      `/announcements/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateAnnouncementSucces,
      this.updateAnnouncementError,
      this.load
    )
    updateAnnouncementSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getAnnouncements( search )
      this.setState({ showUpdateAnnouncementsModal: false })
      this.getSelectedAnnouncement( payload.id )
      this.requestSuccess( 'Announcement was updated successfully.' )
    }
    updateAnnouncementError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          announcements={ this.state.announcements }
          onLoadAnnouncement={ this.state.loading }
          selectedAnnouncement={ this.state.selectedAnnouncement }
          showSearchForms={ this.state.showSearchForms }
          selectedDeleteAnnouncementId={ this.state.selectedDeleteAnnouncementId }
          showCreateAnnouncementsModal={ this.state.showCreateAnnouncementsModal }
          showDeleteAnnouncementModal={ this.state.showDeleteAnnouncementModal }
          showUpdateAnnouncementsModal={ this.state.showUpdateAnnouncementsModal }

          getAnnouncements={ this.getAnnouncements }
          getSelectedAnnouncement={ this.getSelectedAnnouncement }
          createAnnouncement={ this.createAnnouncement }
          deleteAnnouncement={ this.deleteAnnouncement }
          updateAnnouncement={ this.updateAnnouncement }
          onChangeAnnouncementsHOC={ this.onChangeAnnouncementsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC