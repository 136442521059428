import React, { useEffect, useState } from 'react'

import { 
  Button,
  Form,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardFooter,
  FormText
} from 'reactstrap'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'

import CompanyHOC from '../actions/company'
import { useMemo, useCallback } from 'react'
import { mobileChecker } from 'utils/mobileChecker'

const defaultValue = {
  name: '',
  email: '',
  phone: '',
  password: '',
  password_confirm: ''
}

const DefaultFieldData = [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true }
]

const FormContent = ({
  selectedRole,
  open,
  companies,
  onLoadCompany,

  getCompany,
  createUser
}) => {

  const [ userData, onChangeUserData ] = useState( defaultValue )

  useEffect(() => {
    !open && onChangeUserData( defaultValue )
  }, [open ])

  useEffect(() => {
    onChangeUserData( defaultValue )

    if ( [ 14, 17, 19 ].indexOf( selectedRole ) > -1 ){
      getCompany()
      onChangeUserData({
        ... userData,
        company_id: null
      })
    }
  }, [selectedRole])

  const onChangeUserField = useCallback(( key, val ) => {
    let tmp = _.cloneDeep( userData )
    tmp[ key ] = val

    return onChangeUserData( tmp )
  }, [userData])

  const debounceMobile = useCallback(_.debounce(( fullData, val ) => {
    onChangeUserData({
      ... fullData,
      phone: mobileChecker( val )
    })
  }, 500), [])

  const disableSubmit = useMemo(() => {
    return _.values( userData ).some( item => !item )
  }, [ userData ])
  
  return (
    <>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}> 
          <Row>
            {
              [ 14, 17, 19 ].indexOf( selectedRole ) > -1 && (
                <Col xs={ 12 }>
                  <FormGroup>
                    <Label>Company</Label>
                    <Input
                      type="select"
                      value={ userData.company_id } 
                      onChange={ e => onChangeUserField( 'company_id', parseInt( e.target.value ) )}
                    >
                      <option value={ '' }></option>
                      {
                        companies.map( data => (
                          <option value={ data.id }>{ data.name }</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </Col>
              )
            }
            {
              DefaultFieldData.map( field => (
                <Col md={ field.col || 12 } xs={ 12 }>
                  <FormGroup>
                    <Label>
                      { `${ field.label } ` }
                      { field.required && <span className="text-danger">*</span> }
                    </Label>
                    <Input
                      type={ field.type }
                      value={ userData[ field.value ] }
                      onChange={ e => {
                        Promise.all([
                          onChangeUserField( field.value, e.target.value )
                        ]).then(() => {
                          field.value === 'phone' && debounceMobile( userData, e.target.value )
                        })
                      }} />
                    { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button 
          disabled={ disableSubmit || !selectedRole }
          color={ ( disableSubmit || !selectedRole ) ? 'secondary' : 'primary' }
          className="mr-2"
          onClick={() => createUser({
            ... userData,
            role_id: +selectedRole
          })}
        >
          Create
        </Button>
      </CardFooter>
      { ( onLoadCompany ) && <LoadingOverlay />}
    </>
  )
}

export default CompanyHOC( FormContent );