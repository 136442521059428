import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import { Post, Get } from "utils/axios";
import { DefaultFormValue } from "../assets";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			companies: [],

			companyUser: DefaultFormValue,
		};

		onChangeUserHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });
		requestError = (error) => toast.error(error);
		requestSuccess = (success) => toast.success(success);

		getCompany = (search) => {
			Get(
				`/companies?query=${search}`,
				this.getCompanySuccess,
				this.getCompanyError,
				this.load,
				this.props.data.ProfileReducer.current_role_id
			);
		};
		getCompanySuccess = (payload) => {
			let tempData = [];
			payload.data.map(({ company }) => tempData.push({ ...company }));

			this.setState({ companies: tempData });
		};
		getCompanyError = (error) => this.requestError(error);

		createCompanyUser = (dataToSubmit) =>
			Post(
				`/users`,
				{
					...dataToSubmit,
				},
				this.createCompanyUserSuccess,
				this.createCompanyUserError,
				this.load
			);
		createCompanyUserSuccess = () => {
			this.setState({ companyUser: DefaultFormValue });
			this.requestSuccess("User info was created successfully.");
		};
		createCompanyUserError = (error) => this.requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					companyUser={this.state.companyUser}
					onLoadCompanyUser={this.state.loading}
					getCompany={this.getCompany}
					onChangeUserHOC={this.onChangeUserHOC}
					createCompanyUser={this.createCompanyUser}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
