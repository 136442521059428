export const searchParams = [
  { 
    label: 'Name', 
    value: 'u.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Email', 
    value: 'u.email',
    type: 'text',
    param: ''
  },
  { 
    label: 'Phone', 
    value: 'u.phone',
    type: 'text',
    param: ''
  }
]

export const FormDefaultValue = {
  name: '',
  email: '',
  phone: '',
  password: '',
  password_confirm: '',
  address: '',
  cgpa: '',
  ic: '',
  matric_number: '',
  specialization: '',
  session_id: '',
  at_cgpa: '',
  academic_status: '',
  accumulated_credits: null,
  remark: '',
  is_accepted_vacancy: false
}

const AcademicStatusLib = [ 
  {
    value: 'Active',
    name: 'Active'
  }, 
  {
    value: 'P1',
    name: 'P1'
  }, 
  {
    value: 'P2',
    name: 'P2'
  }, 
  {
    value: 'P3',
    name: 'P3'
  } 
]

const specializationOptions = [ 
  {
    id: 'Major: Intelligent.Computing',
    name: 'Major: Intelligent.Computing'
  }, 
  {
    id: 'Major: Computing Infrastructure',
    name: 'Major: Computing Infrastructure'
  }, 
  {
    id: 'Major: SE',
    name: 'Major: SE'
  }
]

export const GradeOptions = [ 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'F', 'N/A']

export const MuetGrade = [ '1', '2', '3', '4', '5', '6' ]

export const DefaultFieldData = [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true },
  { label: 'Address', value: 'address', type: 'textarea', required: false },
  { label: 'CGPA', value: 'cgpa', type: 'number', col: 6, required: true },
  { label: 'AT CGPA', value: 'at_cgpa', type: 'number', col: 6, required: true },
  { label: 'Academic Status', value: 'academic_status', type: 'select', options: AcademicStatusLib, col: 6, required: true },
  { label: 'Acumulated Credits', value: 'accumulated_credits', type: 'number', col: 6, required: false },
  { label: 'NRIC', value: 'ic', type: 'text', required: true },
  { label: 'Matric No.', value: 'matric_number', type: 'text', required: true },
  { label: 'Session Id', value: 'session_id', type: 'typeahead', required: true },
  { label: 'Specialization', value: 'specialization', type: 'select', options: specializationOptions, required: false },
  { label: 'Remark', value: 'remark', type: 'textarea', required: false },
]