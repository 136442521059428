import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedAcceptedList: null
    }

    onChangeStudentHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAcceptedList = search => {
      Get(
        `/student_vacancy_accepted_lists?query=${ search }`,
        this.getAcceptedListSuccess,
        this.getAcceptedListError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getAcceptedListSuccess = ({ data}) => {
      this.setState({ 
        selectedAcceptedList: data
          ? {
            student_user: {
              ... this.props.data.ProfileReducer.profile.user
            },
            company: {
              company_name: data[0]?.company_vacancy?.company?.name?? 'N/A',
              external_sv_name: data[0]?.external_supervisor?.name ?? 'N/A',
            }
          }
          : null 
      })
    }
    getAcceptedListError = error => this.requestError ( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          selectedAcceptedList={ this.state.selectedAcceptedList }
          onLoadAcceptedList={ this.state.loading }

          getAcceptedList={ this.getAcceptedList }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC;