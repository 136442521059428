import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
 
const defaultValue = {
  name: '',
  user_id: null,
  start_date: null,
  end_date: null,
  hide_session: null
}

const fieldData = [
  { label: 'Session Name', value: 'name', required: true, type: 'text' },
  { label: 'Person In Charge', value: 'user_id', required: true, type: 'select' },
  { label: 'Start Date', value: 'start_date', required: true, type: 'date' },
  { label: 'End Date', value: 'end_date', required: true, type: 'date' },
  { 
    label: 'Hide Session',
    value: 'hide_session',
    type: 'select',
    options: [
      { id: 'true', name: 'Hide' },
      { id: 'false', name: 'Show' },
    ],
    required: true,
  },
]

const FormContent = ({
  mode,
  open,
  internalUsers = [],
  onLoadSession,
  
  onClose,
  selectedFormData,
  onClickSubmit
}) => {
  const [ sessionData, onChangeSessionData ] = useState( defaultValue )

  useEffect(() => {
    if ( open && mode === 'Edit' ){
      onChangeSessionData( selectedFormData )
    } 
    if ( !open ){
      onChangeSessionData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    mode === 'Edit' && onChangeSessionData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeSessionField = ( key, val ) => {
    let tmp = _.cloneDeep( sessionData )
    tmp[ key ] = val
    return onChangeSessionData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Session` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
          {
            fieldData.map( field => (
              <Col lg={ 6 }>
                <FormGroup>
                  <Label>
                    { `${ field.label } ` }
                    { field.required && <span className="text-danger">*</span> }
                  </Label>
                  {
                    field.type === 'text' && (
                      <Input
                        type={ field.type }
                        value={ sessionData[ field.value ] }
                        onChange={ e => onChangeSessionField( field.value, e.target.value )}/>
                    )
                  }
                  {
                    field.type === 'select' && (
                      <Input
                        type={ field.type }
                        value={ sessionData[ field.value ] }
                        onChange={ e => onChangeSessionField( field.value, e.target.value )}
                      >
                        <option value=""></option>
                        {
                          field.value === "user_id" ? 
                            internalUsers.map( item => (
                              <option value={ item.id }>{ item.name }</option>
                            )) :
                            field.options.map( item => (
                              <option value={ item.id }>{ item.name }</option>
                            ))
                        }
                      </Input>
                    )
                  }
                  {
                    field.type === 'date' && (
                      <DatePicker
                        className={ 'w-100' }
                        showTimeSelect
                        dateFormat="dd-MM-yyyy h:mm aa"
                        selected={ sessionData[ field.value ] }
                        onChange={ val => {
                          onChangeSessionField( field.value, val )
                        }}/>
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          {
            mode === 'Edit' && (
              <Col lg={ 6 }>
                <FormGroup>
                  <Label>{ 'Session ID' }</Label>
                  <Input
                    type="text"
                    value={ selectedFormData.id }
                    disabled={ true } />
                </FormGroup>
              </Col>
            )
          }
          </Row>
        </Form>
        { onLoadSession && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color={
            _.values( sessionData ).some( item => !item ) && mode === 'Create' 
              ? 'secondary'
              : "primary" 
          }
          className="mr-2"
          disabled={ _.values( sessionData ).some( item => !item ) && mode === 'Create' }
          onClick={() => {
            onClickSubmit({
              ... sessionData,
              user_id: +sessionData.user_id,
              start_date: Moment( sessionData.start_date ).format(),
              end_date: Moment( sessionData.end_date ).format(),
              hide_session: sessionData.hide_session === 'true' ? true : false
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent