import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Put, Post, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      jobTask: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedJobTask: {},

      jobTaskQuery: '',
      showDeleteTaskModal: false,
      showCreateTaskModal: false,
      showUpdateTaskModal: false
    }

    onChangeJobTaskHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getTasks = search => {
      this.setState({ jobTaskQuery: search }, () => {
        Get(
          `/student_job_scope_form_tasks?query=${ search }`,
          this.getTasksSuccess,
          this.getTasksError,
          this.load,
          this.props.data.ProfileReducer.current_role_id 
        )
      })
    }
    getTasksSuccess = payload => {
      let tempData = []
      payload?.data?.[0] && payload.data.map(({ student_job_scope_form_task }, index ) => {
        tempData.push({
          count: index + 1,
          id: student_job_scope_form_task.id,
          department_sv: student_job_scope_form_task.department_sv,
          description: `${ student_job_scope_form_task.description.slice( 0, 80 ) }${ student_job_scope_form_task.description.length > 80 ? '...' : ''}`,
        })
      })
      this.setState({ jobTask: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getTasksError = error => this.requestError( error )

    getSelectedJobTask = id => {
      Get(
        `/student_job_scope_form_tasks/${ id }`,
        this.getSelectedJobTaskSuccess,
        this.getSelectedJobTaskError,
        this.load
      )
    }
    getSelectedJobTaskSuccess = payload => this.setState({   
      selectedJobTask: { ... payload.student_job_scope_form_task }, 
      showUpdateTaskModal: true
    })
    getSelectedJobTaskError = error => this.requestError ( error )

    updateJobTask = dataToSubmit => Put(
      `/student_job_scope_form_tasks/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateJobTaskSucces,
      this.updateJobTaskError,
      this.load
    )
    updateJobTaskSucces = payload => {
      this.getTasks( this.state.jobTaskQuery )
      this.getSelectedJobTask( payload.student_job_scope_form_task.id )
      this.requestSuccess( 'Job Task was updated successfully.' )
    }
    updateJobTaskError = error => this.requestError( error )

    createJobTask = dataToSubmit => Post(
      `/student_job_scope_form_tasks`,
      dataToSubmit,
      this.createJobTaskSuccess,
      this.createJobTaskError,
      this.load
    )
    createJobTaskSuccess = () => {
      this.setState({ showCreateTaskModal: false })
      this.getTasks( this.state.jobTaskQuery )
      this.requestSuccess( 'Job Task was created successfully.' )
    }
    createJobTaskError = error => this.requestError( error )

    deleteJobTask = id => Delete(
      `/student_job_scope_form_tasks/${ id }`,
      this.deleteJobTaskSuccess,
      this.deleteJobTaskError,
      this.load
    )
    deleteJobTaskSuccess = () => {
      this.getTasks( this.state.jobTaskQuery )
      this.setState({ showDeleteTaskModal: false })
      this.requestSuccess( 'Job Task was deleted successfully.' )
    }
    deleteJobTaskError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          jobTask={ this.state.jobTask }
          onLoadJobTask={ this.state.loading }
          selectedJobTask={ this.state.selectedJobTask }
          showUpdateTaskModal={ this.state.showUpdateTaskModal }
          showCreateTaskModal={ this.state.showCreateTaskModal }
          showDeleteTaskModal={ this.state.showDeleteTaskModal }
          
          getTasks={ this.getTasks }
          getSelectedJobTask={ this.getSelectedJobTask }
          updateJobTask={ this.updateJobTask }
          createJobTask={ this.createJobTask }
          deleteJobTask={ this.deleteJobTask }
          onChangeJobTaskHOC={ this.onChangeJobTaskHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC