import Axios from 'axios'
import { GET_DICTIONARY_ROLE, GET_DESIGNATION } from './type'

import getDomainURL from 'utils/api'
import { getItem } from 'utils/tokenStore'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'


export const getDictionaryRole = () => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'USM_IMS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/roles?query=${ btoa(`{"page":1, "per_page": 1000}`) }`
  ).then( response => {
    dispatch( getDictionaryRoleSuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getDictionaryDesignation = () => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'USM_IMS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/designations?query=${ btoa(`{"page":1, "per_page": 1000}`) }`
  ).then( response => {
    dispatch( getDictionaryDesignationsuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getDictionaryRoleSuccess = payload => ({
  type: GET_DICTIONARY_ROLE,
  payload
})


export const getDictionaryDesignationsuccess = payload => ({
  type: GET_DESIGNATION,
  payload
})