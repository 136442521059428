import React, { useEffect, useState, useCallback } from 'react'

import { 
  Button,
  Form,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardFooter,
  FormText
} from 'reactstrap'
import _ from 'lodash'
import { useMemo } from 'react'
import { GrView, GrFormViewHide } from 'react-icons/gr'

const defaultValue = {
  password: '',
  password_confirm: ''
}

const DefaultFieldData = [
  { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true }
]

const FormContent = ({
  selectedUser,
  updateUser
}) => {

  const [ userData, onChangeUserData ] = useState( defaultValue )
  const [ showPassword, onChangeShowPassword ] = useState(false)

  useEffect(() => {
    selectedUser && onChangeUserData({
      email: selectedUser.email,
      password: '',
      password_confirm: ''
    })
  }, [selectedUser])

  const onChangeUserField = useCallback(( key, val ) => {
    let tmp = _.cloneDeep( userData )
    tmp[ key ] = val

    return onChangeUserData( tmp )
  }, [userData])

  const disableSubmit = useMemo(() => {
    const areAllFieldsFilled = DefaultFieldData.every(({ value, required }) => {
      return required ? !!userData[value] : true;
    });
  
    return !areAllFieldsFilled;
  }, [userData]);
  
  return (
    <>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}> 
          <Row>
            {
              DefaultFieldData.map( field => (
                <Col md={ field.col || 12 } xs={ 12 }>
                  <FormGroup>
                    <Label>
                      <div style={{ display: 'flex' }}>
                        {`${field.label} `}
                        <div
                          style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => onChangeShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <GrView style={{ width: '100%', height: '100%' }} />
                          ) : (
                            <GrFormViewHide style={{ width: '100%', height: '100%' }} />
                          )}
                        </div>
                      </div>
                    </Label>
                    <Input
                      type={ showPassword ? 'text' : 'password' }
                      disabled={ field.type !== "password"}
                      value={ userData[ field.value ] }
                      onChange={ e => { onChangeUserField( field.value, e.target.value ) }} />
                    { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button 
          className="mr-2"
          color={ 'primary' }
          disabled={ disableSubmit }
          onClick={() => {
            let temp = _.cloneDeep( userData )
            if ( !temp.password && !temp.password_confirm ){
              delete temp.password
              delete temp.password_confirm
            }
            updateUser( temp )
          }}
        >
          Update Password
        </Button>
      </CardFooter>
    </>
  )
}

export default FormContent;