import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companyUsers: []
    }

    onChangeUserHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAllCompanyUsers = search => Get(
      `/companies/users?query=${ search }`,
      this.getAllCompanyUsersSuccess,
      this.getAllCompanyUsersError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getAllCompanyUsersSuccess = payload => this.setState({ companyUsers: payload.data })
    getAllCompanyUsersError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadUsers={ this.state.loading }
          companyUsers={ this.state.companyUsers }

          getAllCompanyUsers={ this.getAllCompanyUsers }
          onChangeUserHOC={ this.onChangeUserHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC