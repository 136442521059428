import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  CardHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormText,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import _ from 'lodash'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { mobileChecker } from 'utils/mobileChecker'

import RegisterHOC from './actions/index'
import DictionaryHOC from './actions/dictionary'

const RegistrationDetails = ({ designations, industryCat, countries, states }) => [
  {
    cardName: 'Company Details',
    fields: [
      { 
        label: 'Co. Name',
        subLabel: 'Name of the company',
        value: 'name',
        required: true
      },
      { 
        label: 'Phone',
        subLabel: 'e.g +6041234567',
        value: 'phone',
        col: 6,
        type: 'text',
        required: true
      },
      { 
        label: 'Fax',
        subLabel: 'e.g +604-1234567 / 4568',
        value: 'fax_number',
        col: 6,
        type: 'text',
        required: true
      },
      { 
        label: "Password",
        type: 'password',
        value: 'password',
        col: 6,
        required: true
      },
      { 
        label: "Confirm Password",
        type: 'password',
        value: 'password_confirm',
        col: 6,
        required: true
      },
      { 
        label: "Company's Website",
        subLabel: 'Official portal / website',
        value: 'website',
        required: false
      },
      { 
        label: 'Co. Registration Number',
        subLabel: 'Add a valid registration number',
        value: 'registration_number',
        required: true
      },
      { 
        label: 'Category',
        subLabel: 'Type of industry',
        value: 'industry_category_id',
        type: 'select',
        options: industryCat,
        required: true
      },
      { 
        label: 'Country',
        subLabel: 'eg. Malaysia / China',
        value: 'country',
        type: 'select',
        col: 4,
        options: countries,
        required: true
      },
      { 
        label: 'State',
        subLabel: 'eg. Pulau Pinang / Kedah',
        value: 'state',
        type: 'select',
        col: 4,
        options: states,
        required: true
      },
      { 
        label: 'Postal / Zip Code',
        subLabel: 'Postal Code',
        value: 'zipcode',
        col: 4,
        required: true
      },
      { 
        label: 'Street Address',
        subLabel: 'Company of full address',
        value: 'address',
        required: true
      },      
      { 
        label: "Company Background",
        type: 'textarea',
        value: 'description',
        required: false,
        subLabel: 'Max Length: 500 words'
      }
    ]
  },
  {
    cardName: 'Contact Person ( Management Division )',
    fields: [
      { 
        label: "Contact Person's Designation",
        subLabel: '1st person to contact',
        value: 'contact_person_designation_id',
        type: 'select',
        options: designations,
        col: 3,
        required: true
      },
      { 
        label: "Contact Person's Name",
        value: 'contact_person_name',
        col: 9,
        required: true
      },
      { 
        label: "Email Address",
        subLabel: '1st person to contact',
        value: 'contact_person_email',
        required: true
      }
    ]
  }
]

class Registration extends Component {

  componentDidMount = () => {
    this.props.getDesignations()
    this.props.getIndustryCat()
    this.props.getCountry()

    this.debounceMobile= _.debounce(( context, val ) => {
      this.onChangeField( context, mobileChecker( val ))
    }, 500 )
  }

  onChangeField = ( context, val, callback ) => {
    let temp = _.cloneDeep( this.props.registrationData )
    temp[context] = val
    
    callback && callback()
    this.props.onChangeRegistrationHOC( 'registrationData', temp )
  }

  renderTNCContent = () => {
    const { 
      tncChecked, 
      showTNCModal, 
      onChangeRegistrationHOC 
    } = this.props
    return (
      <Modal
        isOpen={ showTNCModal }
        contentClassName={ 'h-100' }
        style={{ maxWidth: '100vw', margin: 0, maxHeight: '100vh', overflow: 'hidden' }}
      >
        <ModalHeader>Industry-Internship Application Form</ModalHeader>
        <ModalBody className={ 'p-0' }>
          <iframe 
            src={ 'https://ims-dev-api.cs.usm.my/static/Legal-Internship-Scope-CompanyRequirements.pdf' } frameBorder="0"
            style={{ width: '100%', height: '100%' }}
          />
        </ModalBody>
        <ModalFooter style={{ display: 'block' }}>
          <FormGroup check>
            <Label check> 
              <Input 
                checked={ tncChecked } 
                type="checkbox" 
                onChange={ e => onChangeRegistrationHOC( 'tncChecked', e.target.checked )}/>
              I have read and and accept the Terms and Condition above.
            </Label>
          </FormGroup>
          <div className="d-flex mt-2" style={{ marginLeft: -2 }}>
            <Button
              color="danger"
              className="mr-2"
              onClick={() => this.props.history.push( '/login' )}>
              Back
            </Button>
            <Button
              color={ tncChecked ? "primary" : 'secondary' } 
              disabled={ !tncChecked }
              onClick={() => onChangeRegistrationHOC( 'showTNCModal', false )}>
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }

  renderFormContent = () => {
    const { registrationData } = this.props
    let disabledSubmit = () => {
      let tempFields = []
      _.map( RegistrationDetails({}), 'fields' ).map( item => tempFields.push( ... item ))
  
      return tempFields.some( item => item.required && !registrationData[ item.value ])
    }

    return (
      <div className="app-main">
        <div className="app-main__outer">
          <div className="app-main__inner">
            <PageTitle
              heading="Industry-Internship Application Form"
              subheading="Fill up this form to apply for Industry-Internship Network in School of Computer Sciences, USM"
              icon="pe-7s-id icon-gradient bg-happy-itmeo"
              buttons={[
                {
                  className: 'btn-icon',
                  color: 'secondary',
                  onClick: () => this.props.history.push( '/login' ),
                  content: <>
                    <i class="btn-icon-wrapper pe-7s-angle-left-circle"></i>
                    <span>Back to login</span>
                  </>
                }
              ]}/>
            {
              RegistrationDetails( this.props ).map(( card, index ) => (
                <Fragment key={ index }>
                  <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <Card className="main-card mb-3">
                      <CardHeader style={{ fontSize: '1rem' }}>
                        { card.cardName }
                      </CardHeader>
                      <CardBody>
                        <Row>
                          {
                            card.fields.map( item => (
                              <Col md={ item.col || 12 } xs={ 12 }>
                                <FormGroup>
                                  { 
                                    item.label && (
                                      <Label>
                                        { `${ item.label } ` }
                                        { item.required && <span className="text-danger">*</span> }
                                      </Label>
                                  )}
                                  {
                                    item.type === 'select' && (
                                      <Input
                                        type={ item.type }
                                        value={ registrationData[ item.value ] }
                                        onChange={ e => {
                                          this.onChangeField( item.value, e.target.value )
                                          item.value === 'country' && this.props.getCoutryState( e.target.value )
                                        }}>
                                        <option value=""></option>
                                        { 
                                          item.options.map( option => (
                                            <option value={ option.id }>{ option.name }</option>
                                          ))
                                        }
                                      </Input>
                                    )
                                  }
                                  {
                                    item.type === 'textarea' && (
                                      <Input
                                        type={ 'textarea' }
                                        maxlength={ 500 }
                                        value={ registrationData[ item.value ] }
                                        rows={ 4 }
                                        onChange={ e => this.onChangeField( item.value, e.target.value )}
                                      />
                                    )
                                  }
                                  {
                                    [ 'textarea', 'select' ].indexOf( item.type ) < 0 && (
                                      <Input
                                        type={ item.type }
                                        value={ registrationData[ item.value ] }
                                        onChange={ e => this.onChangeField( item.value, e.target.value, () => {
                                          [ 'fax_number', 'phone' ].indexOf( item.value ) > -1 && this.debounceMobile( item.value, e.target.value )
                                        })}/>
                                    )
                                  }
                                  { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                                </FormGroup>
                              </Col>
                            ))
                          }
                        </Row>
                      </CardBody>
                    </Card>
                  </ReactCSSTransitionGroup>
                </Fragment>
              ))
            }
            <Button 
              color={ disabledSubmit() ? 'secondary' : 'primary' }
              className="mb-2"
              disabled={ disabledSubmit() }
              onClick={() => this.props.submitRegistration({
                ... registrationData,
                status: 'Inactive',
                contact_person_designation_id: +registrationData.contact_person_designation_id,
                industry_category_id: +registrationData.industry_category_id,
                country: +registrationData.country,
                state: +registrationData.state,
              })}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const { onLoadRegistration, onLoadDictionary } = this.props

    return (
      <>
        { this.renderTNCContent() }
        { this.renderFormContent() }
        {( onLoadRegistration || onLoadDictionary )&& <LoadingOverlay/> }
      </>
    )
  }
}

export default compose( RegisterHOC, DictionaryHOC )( Registration );