import React, { useEffect, useState } from 'react'
import { 
  Row, Col,
  FormGroup, Label, Input,
  Card, CardHeader,CardBody,
  Button, UncontrolledTooltip
} from 'reactstrap'

const FieldData = [
  { label: 'Created At', value: 'created_at', type: 'text', editable: false },
  { label: 'Job Scope URL', value: 'url', type: 'url', editable: false },
  { label: 'JKLI Comment', value: 'jkli_comment', type: 'textarea', editable: false },
  { label: 'Status', value: 'jkli_approval_status', type: 'text', editable: false },
]

const JobScopeForm = props => {

  const { student_job_scope_form, student_job_scope_form_id, student_name } = props.selectedAcceptedVacancies
  const { is_update } = props
  const [ formContent, setFormContent ] = useState({
    created_at: "",
    url: "",
    jkli_comment: "",
    jkli_approval_status: "",
  })

  useEffect( () => {
    if( student_job_scope_form )
      setFormContent({ 
        ...student_job_scope_form, 
        url: `https://ims-dev-api.cs.usm.my/api/v1/student_job_scope_forms/pdf/${ student_job_scope_form_id }` 
      })
  }, [ props.selectedAcceptedVacancies ])

  return(
    <Card className="mt-2 mb-3">
      <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
        Form Details
      </CardHeader>
      <CardBody>
        <Row>
          {
            student_job_scope_form_id && FieldData.map( data => (
              <Col lg={ 12 }>
                <FormGroup>
                  <Label>{ data.label }</Label>
                  {
                    data.type === 'url' && (
                      <div>
                        {
                          formContent[data.value]
                          ? 
                            <div>
                              <Button
                                id="DownloadJobScope"
                                className="mr-2 btn-icon btn-icon-only"
                                color="warning"
                                onClick={() => props.getStudentJobScopeForm_pdf(student_job_scope_form_id, student_name) }
                              >
                                <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DownloadJobScope" placement="right">Download this job scope</UncontrolledTooltip>
                            </div>
                          : <span className='student-vacancy__detail-subtitle'>N/A</span>
                        }
                      </div>
                    )
                  }
                  {
                    data.type !== 'url' && (
                      <Input
                        type={ data.type }
                        value={ formContent[data.value] || 'N/A' }
                        disabled={ true }
                      />
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          {
            !student_job_scope_form_id && (
              <span className='student-vacancy__detail-subtitle pl-3'>No Job Scope Form</span>
            )
          }
        </Row>
      </CardBody>
    </Card>
  )
}

export default JobScopeForm;