import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Name', 
    value: 'name',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      courses: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedCourse: {
        name: '',
        session_id: null
      },
      newCourse: {
        name: '',
        session_id: null
      },
      showCreateCourse: false,
      showUpdateCourse: false,
      toRemoveID: '',
      showConfirmDeleteModal: false,
      searchParams: searchParams,
      showSearchForms: false
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCoursesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getCourses = search => {
      this.props.storeLastView({ search })
      Get(
        `/principal_courses?query=${ search }`,
        this.getCoursesSuccess,
        this.getCoursesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getCoursesSuccess = payload => this.setState({ courses: payload })
    getCoursesError = error => this.requestError( error )

    getSelectedCourse = id => Get(
      `/principal_courses/${ id }`,
      this.getSelectedCourseSuccess,
      this.getSelectedCourseError,
      this.load
    )
    getSelectedCourseSuccess = payload => this.setState({ selectedCourse: payload, showUpdateCourse: true })
    getSelectedCourseError = error => this.requestError( error )

    createCourse = data => Post(
      `/principal_courses`,
      data,
      this.createCourseSuccess,
      this.createCourseError,
      this.load
    )
    createCourseSuccess = payload => {
      this.requestSuccess( 'Principal course is created successfully.' )
      this.setState({
        showCreateCourse: false,
        newCourse: {
          name: ''
        }
      })
      const { search } = this.props.data.LastViewReducer.lastView
      this.getCourses( search )
    }
    createCourseError = error => this.requestError( error )

    updateCourse = data => Put(
      `/principal_courses/${ data.id }`,
      data,
      this.updateCourseSuccess,
      this.updateCourseError,
      this.load
    )
    updateCourseSuccess = payload => {
      this.requestSuccess( 'Principal course is updated successfully.' )
      this.setState({
        showUpdateCourse: false,
        selectedCourse: {
          name: ''
        }
      })
      const { search } = this.props.data.LastViewReducer.lastView
      this.getCourses( search )
    }
    updateCourseError = error => this.requestError( error )

    deleteCourse = id => Delete(
      `/principal_courses/${ id }`,
      this.deleteCourseSuccess,
      this.deleteCourseError,
      this.load
    )
    deleteCourseSuccess = () => {
      this.requestSuccess( 'Principal course is removed successfully.' )
      this.setState({
        showConfirmDeleteModal: false,
        toRemoveID: ''
      })
      const { search } = this.props.data.LastViewReducer.lastView
      this.getCourses( search )
    }
    deleteCourseError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          searchParams={ this.state.searchParams }
          courses={ this.state.courses }
          selectedCourse={ this.state.selectedCourse }
          newCourse={ this.state.newCourse }
          showCreateCourse={ this.state.showCreateCourse }
          showUpdateCourse={ this.state.showUpdateCourse }
          onLoadCourses={ this.state.loading }
          toRemoveID={ this.state.toRemoveID }
          showSearchForms={ this.state.showSearchForms }
          showConfirmDeleteModal={ this.state.showConfirmDeleteModal }
          onChangeCoursesHOC={ this.onChangeCoursesHOC }
          getCourses={ this.getCourses }
          getSelectedCourse={ this.getSelectedCourse }
          createCourse={ this.createCourse }
          updateCourse={ this.updateCourse }
          deleteCourse={ this.deleteCourse } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeLastView
  })( WithHOC )
}

export default HOC