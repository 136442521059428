import React, { useMemo, Fragment } from 'react'
import _ from 'lodash'
import { GoClock } from 'react-icons/go'
import { 
  DropdownToggle, DropdownMenu,
  Nav, NavItem,
  UncontrolledButtonDropdown 
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { setSessionID } from 'actions/session'

const SwitchSessions = ({
data,
setSessionID,
current_role_id
}) => {
    const {
			current_selected_session_id,
			sessions
    } = data.SessionReducer

		const selectedSession = useMemo(() => {
			if ( current_selected_session_id ){
        let temp = _.find( sessions, item => {
          return current_selected_session_id === item.session.id
        })
  
        if ( temp ){
          return temp.session
        } 
  
        return null
      } else {
        return {
          name: 'All'
        }
      }
		}, [ current_selected_session_id ])
    return (

    <UncontrolledButtonDropdown disabled={ current_role_id === 13 }>
      <DropdownToggle color="link" className={ 'd-flex align-items-center pl-0' }>
        <div 
          className="icon-wrapper bg-secondary icon-wrapper-alt text-white" 
          style={{ width: 'initial', height: 'initial', padding: '8px 10px', overflow: 'initial', borderRadius: '1rem' }}
        >
          <GoClock style={{ fontSize: 18, color: 'white', marginRight: 7 }}/>
          <span>{ selectedSession?.name??'N/A' }</span>
        </div>
        <FontAwesomeIcon className="ml-2 opacity-8" icon={ faAngleDown } />
      </DropdownToggle>
      <DropdownMenu 
        right 
        className="dropdown-menu-xl rm-pointers" 
        style={{
            maxHeight: '240px',
            overflowY: 'auto',
            overflowX: 'hidden'
        }}>
          <div className="dropdown-menu-header mb-0">
              <div className="dropdown-menu-header-inner" style={{ padding: '0.8rem 0.5rem' }}>
                  <div className="menu-header-content text-dark">
                      <h5 className="menu-header-title">
                        { `Switch Sessions` }
                      </h5>
                  </div>
              </div>
          </div>
          <Nav vertical>
            {/* <NavItem className="nav-item-divider text-align"/>
              <NavItem 
                className={ `nav-item-btn text-center` }
                style={{ cursor: 'pointer' }}
                onClick={ () => setSessionID( null )}
              >
                All
                {
                  !current_selected_session_id && (
                    <div 
                      className="rounded-circle bg-secondary ml-3" 
                      style={{ width: 10, height: 10, display: 'inline-block' }}
                    />
                  )
                }
              </NavItem> */}
          {
            sessions.map(({ session: sessionItem  }) => (
              (
                <Fragment key={ sessionItem.id }>
                  <NavItem className="nav-item-divider text-align"/>
                  <NavItem 
                    className={ `nav-item-btn text-center` }
                    style={{ cursor: 'pointer' }}
                    onClick={ () => setSessionID( sessionItem.id )}
                  >
                   { sessionItem.name || 'N/A' }
                   {
                     current_selected_session_id === sessionItem.id && (
                       <div 
                        className="rounded-circle bg-secondary ml-3" 
                        style={{ width: 10, height: 10, display: 'inline-block' }}
                      />
                     )
                   }
                  </NavItem>
                </Fragment>
              )
            ))
          }
        </Nav>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}


const mapStateToProps = state => ({ data: state })
export default connect (
  mapStateToProps, 
  { setSessionID }
)(SwitchSessions)