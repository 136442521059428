import React, { useEffect, useState } from 'react'

import { 
  Card, CardHeader, CardBody,
  Modal, ModalHeader, ModalBody,
  Row, Col,
  FormGroup, Input, Label, Form,
} from 'reactstrap'
import _ from 'lodash'

import { defaultValue, cardData } from './assets'

const Details = ({
  data,
  open,
  selectedData,

  onClose
}) => {

  const [ companyVacancyData, onChangeCompanyVacancyData ] = useState( defaultValue )

  useEffect(() => {
    if ( open ){
      onChangeCompanyVacancyData({ ... selectedData })
    }
    !open && onChangeCompanyVacancyData( defaultValue )
  }, [open])
  
  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Company Vacancies Details` }</ModalHeader>
      <ModalBody>
        {
          cardData.map(( card, cardIndex ) => (
            <Card className='mb-3' key={ cardIndex }>
              <CardHeader>
                { card.label }
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    card.fields.map( field => (
                      <Col key={ field.value } md={ field.col || 6 } xs={ 12 }>
                        <FormGroup >
                          <Label>{ field.label }</Label>
                          {
                            field.type === 'img' && (
                              <>
                                {
                                  companyVacancyData.poster_url && (
                                    <img 
                                      src={ companyVacancyData.poster_url } alt="company_poster"
                                      style={{ height: 200, display: 'block' }}
                                    /> 
                                  )
                                }
                                {
                                  !companyVacancyData.poster_url && (
                                    <strong className={ 'd-block' }>No poster is available.</strong>
                                  )
                                }
                              </>
                            )
                          }
                          {
                            field.type === 'select' && (
                              <Input
                                type={ 'select' }
                                disabled={ true }
                                value={ companyVacancyData[field.value] }
                              >
                                <option value=""></option>
                                {
                                  data.DictionaryReducer.designations.map( item => (
                                    <option key={ item.id } value={ item.id }>{ item.name }</option>
                                  ))
                                }
                              </Input>
                            )
                          }
                          { 
                            [ 'select', 'img' ].indexOf( field.type ) < 0 && (
                              <Input
                                type={ field?.type??'text' }
                                rows={ 5 }
                                disabled={ true }
                                value={ companyVacancyData[field.value] }
                              />
                            )
                          }
                        </FormGroup>
                      </Col>
                    ))
                  }
                </Row>
              </CardBody>
            </Card>
          ))
        }
      </ModalBody>
    </Modal>
  )
}

export default Details;