import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
 
const defaultValue = {
  sec_module_id: null,
  role_id: null,
  is_create: false,
  is_delete: false,
  is_read: false,
  is_update: false
}

const permissionFields = [
  {
    label: 'Create',
    value: 'is_create'
  },
  {
    label: 'Delete',
    value: 'is_delete'
  },
  {
    label: 'Read',
    value: 'is_read'
  },
  {
    label: 'Update',
    value: 'is_update'
  }
]

const FormContent = ({
  data,
  open,
  secModules,
  onLoadSecModulesRole,
  
  onClose,
  selectedFormData,
  onClickSubmit
}) => {

  const [ SecModulesRolesData, onChangeSecModulesRolesData ] = useState( defaultValue )

  useEffect(() => {
    if ( open ){
      onChangeSecModulesRolesData( selectedFormData )
    } else {
      onChangeSecModulesRolesData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    onChangeSecModulesRolesData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeSecModulesRolesField = ( key, val ) => {
    let tmp = _.cloneDeep( SecModulesRolesData )
    tmp[ key ] = val
    return onChangeSecModulesRolesData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{`Edit sec modules roles`}</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>
              Role
              <span className="text-danger"> *</span>
            </Label>
            <Input
              type={ 'select' }
              value={ SecModulesRolesData.role_id }
              onChange={ e => {
                onChangeSecModulesRolesField( 'role_id', parseInt( e.target.value ) )
              }}>
              <option value=""></option>
              {
                data.DictionaryReducer.roles.map( item => (
                  <option value={ item.id }>{ item.name }</option>
                ))
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>
              Sec Modules
              <span className="text-danger"> *</span>
            </Label>
            <Input
              type={ 'select' }
              value={ SecModulesRolesData.sec_module_id }
              onChange={ e => {
                onChangeSecModulesRolesField( 'sec_module_id', parseInt( e.target.value ) )
              }}>
              <option value=""></option>
              {
                secModules.map( item => (
                  <option value={ item.id }>{ item.name }</option>
                ))
              }
            </Input>
          </FormGroup>
          <Label className={ 'd-block' }>Permission</Label>
          {
            permissionFields.map( field => (
              <FormGroup check inline>
                <Label check>
                  <Input 
                    type="checkbox" checked={ SecModulesRolesData[ field.value ]} 
                    onClick={ () => onChangeSecModulesRolesField( field.value, !SecModulesRolesData[ field.value ]) 
                  }/>
                  { field.label }
                </Label>
              </FormGroup>
            ))
          }
        </Form>
        { onLoadSecModulesRole && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          color={ 
            ( !SecModulesRolesData.role_id || !SecModulesRolesData.sec_module_id  )
              ? 'secondary' 
              : 'primary' 
          }
          disabled={ !SecModulesRolesData.role_id || !SecModulesRolesData.sec_module_id }
          onClick={() => onClickSubmit( SecModulesRolesData )}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( FormContent )