import React, { useState, useCallback } from 'react'
import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  Form,
  FormGroup,
  Label
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { useEffect } from 'react'
import UserTypeahead from 'components/Typeahead/user'
import { PulseLoader } from 'react-spinners'

const FormContent = ({
  open,
  data,
  students = [],
  advisorOptions = [],
  onLoadAdvisor,
  onLoadStudent,
  onLoadUserAdvisor,

  onClose,
  getStudents,
  getSelectedStudent,
  getAdvisorUsers,
  onClickSubmit,
  onChangeUserHOC,
  selectedFormData
}) => {

  const [ selectedStudent, onChangeSelectedStudent ] = useState( null )
  const [ selectedAdvisor, onChangeAdvisor ] = useState( null )

  useEffect(() => {
    if ( selectedFormData ) {
      const {  
        academic_advisor_id,
        student_id
      } = selectedFormData
      
      onChangeAdvisor( academic_advisor_id )
      onChangeSelectedStudent( student_id )
    }
  }, [selectedFormData])

  useEffect(() => {
    if ( !open ){
      onChangeSelectedStudent( null )
      onChangeAdvisor( null )
      onChangeUserHOC( 'students', [] )
      onChangeUserHOC( 'advisorOptions', [] )
    } 
    
    if ( open && selectedFormData ){
      const {  
        academic_advisor_id,
        student_id
      } = selectedFormData
      
      student_id && getSelectedStudent( student_id )
      academic_advisor_id && getAdvisorUsers( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "u.id": ${ academic_advisor_id } }}`))
    }
  }, [open, selectedFormData ])

  const onChangeStudentTypeahead = useCallback( _.debounce(
    val => {
    getStudents( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "$and":[ { "users.name": {"$like": "%${ val }%" } }, { "students.session_id": ${ data.SessionReducer.current_selected_session_id } } ]  }}`))
    }, 600 ), []
  );

  const onChangeAdvisorTypeahead = useCallback( _.debounce(
    val => {
      getAdvisorUsers( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "u.name": {"$like": "%${ val }%" } }}`))
    }, 600 ), []
  );

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Assign Academic Advisor` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>
              { `Student ` }
              <span className="text-danger mr-2">*</span>
              <PulseLoader  
                sizeUnit={"px"}
                size={ 10 }
                color={ '#16232c' }
                loading={ onLoadStudent } 
              />
            </Label>
            <UserTypeahead
              options={ students }
              onSelect={ val => val?.[0]?.id && onChangeSelectedStudent( val[0].id )}
              selectedValue={ 
                selectedStudent && _.find( students, { id: selectedStudent })
                  ? [ _.find( students, { id: selectedStudent })] 
                  : [] 
              }
              onSearch={ val => {
                onChangeSelectedStudent( null )
                val.length > 0
                  ? onChangeStudentTypeahead( val )
                  : onChangeUserHOC( 'students', [] )
              }}
              disableEdit={ onLoadStudent }
              helpText={ 'Please enter student name to search.' }
            />
          </FormGroup>
          <FormGroup>
            <Label>
              { `Advisor ` }
              <span className="text-danger mr-2">*</span>
              <PulseLoader  
                sizeUnit={"px"}
                size={ 10 }
                color={ '#16232c' }
                loading={ onLoadUserAdvisor  } 
              />
            </Label>
            <UserTypeahead
              options={ advisorOptions }
              onSelect={ val => val?.[0]?.id && onChangeAdvisor( val[0].id )}
              selectedValue={ 
                selectedAdvisor && _.find( advisorOptions, { id: selectedAdvisor })
                  ? [ _.find( advisorOptions, { id: selectedAdvisor })] 
                  : [] 
              }
              onSearch={ val => {
                onChangeAdvisor( null )
                val.length > 0
                  ? onChangeAdvisorTypeahead( val )
                  : onChangeUserHOC( 'advisorOptions', [] )
              }}
              disableEdit={ onLoadUserAdvisor }
              helpText={ 'Please enter advisor name to search.' }
            />
          </FormGroup>
        </Form>
        { onLoadAdvisor && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ selectedStudent ? "primary" : 'secondary'}
          className="mr-2"
          disabled={ !selectedStudent }
          onClick={() => onClickSubmit(
            selectedFormData.id,
            {
              student_id: +selectedStudent,
              academic_advisor_id: +selectedAdvisor
            }
          )}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent