import React, { useEffect, useState, useCallback } from 'react'
import { 
  Row, Col,
  Form, FormGroup, Label, Input, Button,
  Card, CardHeader,CardBody, CardFooter
} from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'

function Approval ({
  selectedJobScope,
  updateJobScope,
  currentPermission,
  data
}) {
  const [ jkliData, onChangeJKLIData ] = useState({
    jkli_comment: '',
    jkli_approval_status: ''
  })

  useEffect(() => { 
    onChangeJKLIData({
      jkli_comment: selectedJobScope.jkli_comment,
      jkli_approval_status: selectedJobScope.jkli_approval_status
    })
  }, [ selectedJobScope ])

  const onChangeJKLIField = useCallback(( context, val ) => {
    onChangeJKLIData({
      ... jkliData,
      [context]: val
    })
  }, [jkliData])

  return (
    <>
      <Card className="mt-2 mb-3">
        <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
          JKLI Approval
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={ 6 }>
                <FormGroup>
                  <Label>Approval Status</Label>
                  <Input
                    type={ 'select' }
                    disabled={ data.ProfileReducer.current_role_id === 13 }
                    value={ jkliData.jkli_approval_status }
                    onChange={ e => onChangeJKLIField( 'jkli_approval_status', e.target.value )}
                  >
                    <option value=""></option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={ 12 }>
                <FormGroup>
                  <Label>Comments</Label>
                  <Input
                    type={ 'textarea' }
                    disabled={ data.ProfileReducer.current_role_id === 13 }
                    value={ jkliData.jkli_comment }
                    rows={ 5 }
                    onChange={ e => onChangeJKLIField( 'jkli_comment', e.target.value )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        {
          data.ProfileReducer.current_role_id !== 13 && (
            <CardFooter>
              <Button 
                color="primary"
                disabled={!jkliData.jkli_approval_status }
                onClick={() => updateJobScope({
                  id: selectedJobScope.id,
                  ... jkliData
                })}>
                Update
              </Button>
            </CardFooter>
          )
        }
      </Card> 
    </>
  )
}

const mapStateToProps = (state) => ({ data: state })

export default connect( mapStateToProps )( Approval )
