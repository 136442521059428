import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import StudentVacancyHOC from 'actions/hoc/studentVacancyStatus'
import StudentVacanciesHOC from './actions/studentVacancies'

class StudentVacancy extends Component {

  componentDidMount = () => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( current_selected_session_id ) {
      this.props.getStudentVacancies( btoa(`{ "per_page": 1000, "filter": { "students.session_id": ${ current_selected_session_id } }}`) )
    }
  }

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getStudentVacancyStatus( btoa(`{"page":1}`) )
    }
    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id && current_selected_session_id ) {
      this.props.getStudentVacancies( btoa(`{ "per_page": 1000, "filter": { "students.session_id": ${ current_selected_session_id } }}`) )
    }
  }

  render = () => {
    function countStatus (arr, status) {
      const tempArr = arr?.filter(function (el) {
        return el.name == status;
      });
      return tempArr?.length;
    }

    return (
      <>
        <PageTitle
          heading="Student Vacancy Status" 
          subheading="Listings of all the student vacancy status present in the system."
          icon="pe-7s-study icon-gradient bg-happy-itmeo"
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.studentVacancyStatus.data.length < 1? 3: 
                      this.props.studentVacancyStatus.data.length < 10? this.props.studentVacancyStatus.data.length:
                      10
                    }
                    data={ this.props.studentVacancyStatus.data }
                    columns={[
                      {
                        Header: 'Status',
                        accessor: 'name'
                      },
                      {
                        Header: 'Number of Students',
                        Cell: (row) => {
                          return (
                            <>
                              { 
                                <strong>
                                  { countStatus(this.props.studentVacancies.data, row.original.name) }
                                </strong>
                              }
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        { this.props.onLoadVacancyStatus && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  StudentVacancyHOC,
  StudentVacanciesHOC
)(StudentVacancy)