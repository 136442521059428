export const CompanyFieldData = ({ designations, industryCat, countries, states }) => [
  {
    cardName: 'Company Details',
    fields: [
      { 
        label: 'Company Name',
        subLabel: `Company's name`,
        value: 'name',
        col: 12,
        type: 'text',
        required: true
      },
      { 
        label: 'Company Phone',
        subLabel: `Company's phone contact`,
        value: 'phone',
        col: 12,
        type: 'text',
        required: true
      },
      { 
        label: 'Fax',
        subLabel: 'e.g +604-1234567 / 4568',
        value: 'fax_number',
        col: 12,
        type: 'text',
        required: false
      },
      { 
        label: "Company's Website",
        subLabel: 'Official portal / website',
        value: 'website',
        required: false
      },
      { 
        label: 'Co. Registration Number',
        subLabel: 'Add a valid registration number',
        value: 'registration_number',
        required: true
      },
      { 
        label: 'Category',
        subLabel: 'Type of industry',
        value: 'industry_category_id',
        type: 'select',
        options: industryCat,
        required: true
      },
      { 
        label: 'Country',
        subLabel: 'eg. Malaysia / China',
        value: 'country',
        type: 'select',
        col: 6,
        options: countries,
        required: true
      },
      { 
        label: 'State',
        subLabel: 'eg. Pulau Pinang / Kedah',
        value: 'state',
        type: 'select',
        col: 6,
        options: states,
        required: true
      },
      { 
        label: 'Postal / Zip Code',
        subLabel: 'Postal Code',
        value: 'zipcode',
        col: 6,
        required: true
      },
      { 
        label: 'Street Address',
        subLabel: 'Company of full address',
        value: 'address',
        required: true
      },      
      { 
        label: "Company Background",
        type: 'textarea',
        value: 'description',
        required: false,
        subLabel: 'Max Length: 500 words'
      }
      // {
      //   label: "Offer Jobs",
      //   type: 'textarea',
      //   value: 'offer_jobs',
      //   required: false,
      //   subLabel: 'Max Length: 300 words'
      // }
    ]
  },
  {
    cardName: 'Contact Person ( Management Division )',
    fields: [
      { 
        label: "Contact Person's Designation",
        subLabel: '1st person to contact',
        value: 'contact_person_designation_id',
        type: 'select',
        options: designations,
        col: 4,
        required: true
      },
      { 
        label: "Contact Person's Name",
        value: 'contact_person_name',
        col: 8,
        required: true
      },
      { 
        label: "Email Address",
        subLabel: '1st person to contact',
        value: 'contact_person_email',
        required: true
      }
    ]
  }
]

export const defaultEditFormValue = {
  users: [],
  company: {
    registration_number: '',
    address: '',
    description: '',
    state: '',
    status: 'Inactive',
    country: '',
    zipcode: '',
    fax_number: '',
    industry_category_id: '',
    website: '',
    offer_jobs: '',
    contact_person_name: '',
    contact_person_designation_id: 1,
    contact_person_email: '',
    is_read_agreement: true
  }
}