import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'
import Cookies from 'js-cookie'
import getDomainURL from 'utils/api'
import Axios from 'axios'
import FileSaver from 'file-saver'
import { Get, Put } from 'utils/axios'

const searchParams = [
  { 
    label: 'Student name', 
    value: 'su.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Company name', 
    value: 'c.name',
    type: 'text',
    param: ''
  },
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      dutyForms: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedForm: null,
      searchParams: searchParams,

      showUpdateFormModal: false,
      showSearchForms: false
    }

    onChangeFormHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getForm = search => {
      this.props.storeLastView({ search })

      Get(
        `/student_report_duty_forms?query=${ search }`,
        this.getFormSuccess,
        this.getFormError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getFormSuccess = payload => {
      let tempData = []
      
      payload.data.map(({
        company,
        student_user, 
        student_report_duty_form, 
        student_vacancy_accepted_list,
        ... item 
      }) => {
        tempData.push({
          ... item,
          student_name: student_user.name,
          student_email: student_user.email,
          company_name: company.name,
          ...student_report_duty_form,
        })
      })
      if ( this.props.data.ProfileReducer.current_role_id === 13 && payload.data?.[0] ){
        this.getSelectedForm( payload.data?.[0]?.student_report_duty_form?.id )
      }

      this.setState({ dutyForms: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getFormError = error => this.requestError( error )

    getSelectedForm = id => {
      Get(
        `/student_report_duty_forms/${ id }`,
        this.getSelectedFormSuccess,
        this.getSelectedFormError,
        this.load
      )
    }
    getSelectedFormSuccess = ({ student_report_duty_form }) => {
      this.setState({   
        selectedForm: {
          id: student_report_duty_form.id,
          is_reviewed: student_report_duty_form.is_reviewed,
          hr_officer_name: student_report_duty_form.hr_officer_name,
          hr_officer_email: student_report_duty_form.hr_officer_email,
          hr_officer_phone: student_report_duty_form.hr_officer_phone,
          hr_officer_mobile_phone: student_report_duty_form.hr_officer_mobile_phone,
          report_duty_date: student_report_duty_form.report_duty_date ? new Date( student_report_duty_form.report_duty_date ) : new Date(),
          allowance_amount: student_report_duty_form.allowance_amount,
          is_have_allowance: student_report_duty_form.is_have_allowance,
          is_change_job_scope: student_report_duty_form.is_change_job_scope,
        }, 
        showUpdateFormModal: true
      })
    }
    getSelectedFormError = error => this.requestError ( error )

    deleteError = error => this.requestError( error )

    updateForm = dataToSubmit => Put(
      `/student_report_duty_forms/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateFormSucces,
      this.updateFormError,
      this.load
    )
    updateFormSucces = ({ student_report_duty_form }) => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getForm( search )
      this.getSelectedForm( student_report_duty_form.id )
      this.requestSuccess( 'Form was updated successfully.' )
    }
    updateFormError = error => this.requestError( error )

    getStudentReportDuty_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/student_report_duty_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_ReportDuty.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          dutyForms={ this.state.dutyForms }
          onLoadForm={ this.state.loading }
          selectedForm={ this.state.selectedForm }
          showUpdateFormModal={ this.state.showUpdateFormModal }
          showSearchForms={ this.state.showSearchForms }
          
          getForm={ this.getForm }
          getSelectedForm={ this.getSelectedForm }
          updateForm={ this.updateForm }
          getStudentReportDuty_pdf={ this.getStudentReportDuty_pdf }
          onChangeFormHOC={ this.onChangeFormHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC