import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'
import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      weeklyLogs: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedWeeklyLog: null,
      searchParams: [],

      showCreateWeeklyLogModal: false,
      showDeleteWeeklyLogModal: false,
      showUpdateWeeklyLogModal: false,
      showSearchForms: false
    }

    onChangeWeeklyLogHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getWeeklyLog = search => {
      this.props.storeLastView({ search })

      Get(
        `/weekly_logs?query=${ search }`,
        this.getWeeklyLogSuccess,
        this.getWeeklyLogError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getWeeklyLogSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map( item => {
        tempData.push({
          ... item,
          ... item.weekly_log,
          student_name: item.student_user.name,
          date: Moment.utc( item.weekly_log.date ).format( 'DD-MM-yyyy' ),
        })
      })
      this.setState({ weeklyLogs: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getWeeklyLogError = error => this.requestError( error )

    getSelectedWeeklyLog = id => {
      Get(
        `/weekly_logs/${ id }`,
        this.getSelectedWeeklyLogSuccess,
        this.getSelectedWeeklyLogError,
        this.load
      )
    }
    getSelectedWeeklyLogSuccess = ({ 
      weekly_log, 
      company_name,
      external_supervisor,
      ... payload 
    }) => {
      let tempDate = new Date( weekly_log.date )  
      var userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedWeeklyLog: {
          ... payload,
          company: {
            company_name,
            external_sv_name: external_supervisor.name
          },
          weekly_log: {
            ... weekly_log,
            date: new Date(tempDate.getTime() + userTimezoneOffset ),
          }
        }, 
        showUpdateWeeklyLogModal: true 
      })
    }
    getSelectedWeeklyLogError = error => this.requestError ( error )

    createWeeklyLog = dataToSubmit => Post(
      `/weekly_logs`,
      dataToSubmit,
      this.createWeeklyLogSuccess,
      this.createWeeklyLogError,
      this.load
    )
    createWeeklyLogSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getWeeklyLog( search )
      this.setState({ showCreateWeeklyLogModal: false })
      this.requestSuccess( 'Weekly Log has been created successfully.' )
    }
    createWeeklyLogError = error => this.requestError( error )

    deleteWeeklyLog = id => Delete( 
      `/weekly_logs/${ id }`,
      this.deleteWeeklyLogSuccess,
      this.deleteError,
      this.load
    )
    deleteWeeklyLogSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getWeeklyLog( search )
      this.setState({ showDeleteWeeklyLogModal: false })
      this.requestSuccess( 'Weekly Log has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateWeeklyLog = dataToSubmit => Put(
      `/weekly_logs/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateWeeklyLogSucces,
      this.updateWeeklyLogError,
      this.load
    )
    updateWeeklyLogSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getWeeklyLog( search )
      this.getSelectedWeeklyLog( payload.weekly_log.id )
      this.requestSuccess( 'Weekly Log was updated successfully.' )
    }
    updateWeeklyLogError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          weeklyLogs={ this.state.weeklyLogs }
          onLoadWeeklyLog={ this.state.loading }
          selectedWeeklyLog={ this.state.selectedWeeklyLog }
          showCreateWeeklyLogModal={ this.state.showCreateWeeklyLogModal }
          showDeleteWeeklyLogModal={ this.state.showDeleteWeeklyLogModal }
          showUpdateWeeklyLogModal={ this.state.showUpdateWeeklyLogModal }
          showSearchForms={ this.state.showSearchForms }
          
          getWeeklyLog={ this.getWeeklyLog }
          getSelectedWeeklyLog={ this.getSelectedWeeklyLog }
          createWeeklyLog={ this.createWeeklyLog }
          deleteWeeklyLog={ this.deleteWeeklyLog }
          updateWeeklyLog={ this.updateWeeklyLog }
          onChangeWeeklyLogHOC={ this.onChangeWeeklyLogHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC