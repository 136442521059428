import Moment from 'moment'

export const queryHandler = ( searchParams, page, per_page ) => {
  let tmpFilter = ''
  searchParams.map( item => {
    if ( 
        item.param || 
        typeof( item.param ) === 'boolean'
      ){
      tmpFilter += `"${ item.value }": ${ paramHandler( item ) },`
    }
  })
  return btoa( 
    `{ "page": ${ page } ${ per_page ? `,"per_page": ${ per_page }` : '' } ${ tmpFilter.length > 0 ? `, "filter":{ ${ tmpFilter.slice(0, -1) } }` : '' }}` 
  )
}

const paramHandler = item => {
  if ( item.type === 'date' ){
    return `{"${ item.searchMode || '$gt' }": "${ Moment( item.param ).format( `YYYY-MM-DDTHH:mm:ss` ) }Z"}`
  } 
  if ( typeof( item.param ) === 'number' || typeof( item.param ) === 'boolean' ){
    return item.param
  }

  if ( item.exact ){
    return `{"$like": "${ item.param }" }`
  }

  return `{"$like": "%${ item.param }%" }`
}