import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'

import { Get } from 'utils/axios'

const searchParams = [
  { 
    label: 'Student name', 
    value: 'su.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Company name', 
    value: 'c.name',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedJobScope: {},
      searchParams: searchParams,

      showSearchForms: false
    }

    onChangeJobScopeHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getJobScopes = id => {
      Get(
        `/student_job_scope_forms/${ id }`,
        this.getJobScopesSuccess,
        this.getJobScopesError,
        this.load,
      )
    }
    getJobScopesSuccess = payload => {
      let tempData = []
      tempData.push({
        ... payload.student_job_scope_form,
        company_name: payload.company.name,
        student_name: payload.student_user.name,
        student_email: payload.student_user.email,
        student_matric: payload.student.matric_number
      })
      this.setState({ selectedJobScope: tempData[0] })
    }
    getJobScopesError = error => this.requestError( error )


    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          jobScope={ this.state.jobScope }
          onLoadJobScope={ this.state.loading }
          selectedJobScope={ this.state.selectedJobScope }
          showSearchForms={ this.state.showSearchForms }
          
          getJobScopes={ this.getJobScopes }
          getSelectedJobScope={ this.getSelectedJobScope }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC