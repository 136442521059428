import React, { useEffect, useState } from 'react'
import { 
  Button,
  Row, Col,
  Card, CardBody, CardHeader, 
  Modal, ModalBody, ModalHeader
} from 'reactstrap'
import { compose } from 'redux'
import { BiRefresh } from 'react-icons/bi'

import { clearItem } from 'utils/tokenStore';
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import EditStudent from './EditStudent'
import PrerequisiteContent from './Prerequisite'
import CangredCard from './EditStudent/Cangred'

import StudentHOC from './actions/index'
import AdvisorHOC from './actions/advisor'
import AdvisorCreateUpdateHOC from '../../../containers/Dashboards/AssignAcademicAdvisor/actions/index'

const tabOptions = [
  { id: 1, label: 'Student Details' },
  { id: 2, label: 'Prerequisite Courses' }
]

const ValidInternModal = ({
  data,
  history,
  onLoadStudent,
  selectedStudent,
  academicAdvisorsList,

  getProfile,
  submitCangred,
  getSelectedStudent,
  getAdvisorList,
  updateStudent,
  updateAdvisor,
  createAdvisor
}) => {
  const [ activeTab, onChangeActiveTab ] = useState( 1 )
  
  useEffect(() => {
    getProfile()
    getSelectedStudent( data.ProfileReducer?.profile?.student?.id )
    getAdvisorList( btoa(`{ "per_page": 1000}`) )
  }, [])

  return (
    <>
      <Modal
        isOpen={ true }
        contentClassName={ 'h-100' }
        style={{ maxWidth: '100vw', margin: 0, maxHeight: '100vh', overflow: 'hidden' }}
      >
        <ModalHeader>
          <div className="d-flex">
            <Button 
              color={ 'secondary' }
              className={ 'btn-icon d-flex ml-auto' }
              onClick={() => { 
                history.push( '/login' )
                clearItem( 'USM_IMS_TOKEN' )
              }}
            >
              <i class="btn-icon-wrapper pe-7s-angle-left-circle"></i>
              <span>Back to login</span>
            </Button>
          </div>
        </ModalHeader>
        <ModalBody style={{ overflow: 'auto' }}> 
          <Row>
            <Col md={ 6 }>
              <Card className={ 'mb-3' }>
              <CardHeader style={{ fontSize: '1rem' }}>
                Notice
                <BiRefresh style={{ 
                  cursor: 'pointer',
                  fontSize: 20,
                  marginLeft: 10
                }}
                onClick={ () => getProfile()}  
              />
              </CardHeader>
              <CardBody>
                <strong style={{ fontSize: 15, textDecoration: 'underline' }} className={ 'mb-3 d-block' }>Status: <i>Pending</i></strong>
                <p className="mb-1">While waiting for your Academic Advisor's approval, you may: </p>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Update your <strong>Personal information</strong>.</li>
                  <li>Upload your <strong>Cangred</strong> file.</li>
                  <li>Update your <strong>Prerequisite Courses Result</strong>.</li>
                </ul>
              </CardBody>
            </Card>
            </Col>
            <Col md={ 6 }>
              <Card className={ 'mb-3' }>
                <CardHeader style={{ fontSize: '1rem' }}>
                  Approval Comment
                </CardHeader>
                <CardBody>
                  { data.ProfileReducer.profile.student.ineligible_intern_comment }
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="d-flex mb-3 flex-wrap">
            {
              tabOptions.map( tab => (
                <button
                  className={ `btn mr-1 btn-wide ${ activeTab === tab.id ? 'btn-primary' : 'btn-outline-primary' }` }
                  onClick={ () => onChangeActiveTab( tab.id )}
                >
                  { tab.label }
                </button>
              ))
            }
          </div>
          {
            activeTab === 1 && (
              <>
                <EditStudent
                  selectedFormData={ selectedStudent }
                  academicAdvisorsTotalList={ academicAdvisorsList.data }
                  onClickSubmit={ updateStudent }
                  onClickCreate={ createAdvisor }  
                  onClickUpdate={ updateAdvisor }
                />
                <CangredCard
                  submitCangred={ submitCangred }
                  selectedStudent={ selectedStudent }
                  cangred_pdf_url={ data.ProfileReducer?.profile?.student?.cangred_pdf_url }
                />
              </>
            )
          }
          {
            activeTab === 2 && (
              <PrerequisiteContent 
                selectedStudent={ selectedStudent }
              />
            )
          }
        </ModalBody>
        {( onLoadStudent )&& <LoadingOverlay />}
      </Modal>
    </>
  )
}

export default compose(
  StudentHOC,
  AdvisorHOC,
  AdvisorCreateUpdateHOC
)( ValidInternModal )