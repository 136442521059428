import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'
import Cookies from 'js-cookie'
import Moment from 'moment'
import getDomainURL from 'utils/api'
import Axios from 'axios'
import FileSaver from 'file-saver'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showStudentCVContent: false,
      selectedStudentCV: {},
      studentCV: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
    }

    onChangeStudentCVHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentCV = search => {
      Get(
        `/student_cvs?query=${ search }`,
        this.getStudentCVSuccess,
        this.getStudentCVError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentCVSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_cv, user }) => {
        tempData.push({
          ... student_cv,
          student_name: user.name,
          student_email: user.email,
          student_mobile_contact: user.phone,
          created_at: Moment.utc( student_cv.created_at ).format( 'DD-MM-yyyy' ),
        })
      })
      
      if ( this.props.data.ProfileReducer.current_role_id === 13 && payload.data?.[0] ){
        this.getSelectedStudentCV( payload.data?.[0]?.student_cv?.id )
      }

      this.setState({ studentCV: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getStudentCVError = error => this.requestError( error )

    getSelectedStudentCV = id => {
      Get(
        `/student_cvs/${ id }`,
        this.getSelectedStudentCVSuccess,
        this.getSelectedStudentCVError,
        this.load
      )
    }
    getSelectedStudentCVSuccess = payload => {
      this.setState({   
        selectedStudentCV: {
          ... payload,
          student_cv: {
            ... payload.student_cv,
          }
        },
      })
      return this.setState({ showStudentCVContent: true })
    }
    getSelectedStudentCVError = error => this.requestError ( error )

    getStudentLI = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/students/li_cert_letter/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_LI_Certificate.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadStudentCV={ this.state.loading }
          studentCV={ this.state.studentCV }
          selectedStudentCV={ this.state.selectedStudentCV }
          showStudentCVContent={ this.state.showStudentCVContent }
          
          getSelectedStudentCV={ this.getSelectedStudentCV }
          getStudentCV={ this.getStudentCV }
          getStudentLI={ this.getStudentLI }
          onChangeStudentCVHOC={ this.onChangeStudentCVHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC