import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import { storeLastView } from 'actions/lastView'
import { getProfile } from 'actions/profile'

const searchParams = roles => [
  { 
    label: 'Roles', 
    value: 'role_id',
    type: 'select',
    param: '',
    options: roles
  }, 
  {
    label: 'Sec Modules',
    value: 'sec_module_id',
    type: 'select',
    param: '',
    options: []
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      SecModulesRoles: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedSecModulesRole: {},
      searchParams: searchParams( 
        this.props.data.DictionaryReducer.roles 
      ),

      showUpdateSecModulesRolesModal: false,
      showSearchForms: false
    }

    onChangeSecModulesRolesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSecModulesRoles = search => {
      this.props.storeLastView({ search })

      Get(
        `/sec_module_roles?query=${ search }`,
        this.getSecModulesRolesSuccess,
        this.getSecModulesRolesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getSecModulesRolesSuccess = payload => this.setState({ SecModulesRoles: payload })
    getSecModulesRolesError = error => this.requestError( error )

    getSelectedSecModulesRole = ( id, context ) => {
      Get(
        `/sec_module_roles/${ id }`,
        payload => this.getSelectedSecModulesRolesuccess( payload, context ),
        this.getSelectedSecModulesRoleError,
        this.load
      )
    }
    getSelectedSecModulesRolesuccess = ( payload, context ) => {
      this.setState({   
        selectedSecModulesRole: payload, 
        showUpdateSecModulesRolesModal: context === 'edit' 
      })
    }
    getSelectedSecModulesRoleError = error => this.requestError ( error )

    updateSecModulesRole = dataToSubmit => Put(
      `/sec_module_roles/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSecModulesRolesucces,
      this.updateSecModulesRoleError,
      this.load
    )
    updateSecModulesRolesucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      this.setState({ showUpdateSecModulesRolesModal: false })
      this.getSecModulesRoles( search )
      
      if ( payload.role_id === this.props.data.ProfileReducer.current_role_id ){
        this.props.getProfile()
        this.props.history.push( '/dashboard/statistics' )
      }
      
      this.getSelectedSecModulesRole( payload.id )
      this.requestSuccess( 'Sec Module Role was updated successfully.' )
    }
    updateSecModulesRoleError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          SecModulesRoles={ this.state.SecModulesRoles }
          onLoadSecModulesRole={ this.state.loading }
          selectedSecModulesRole={ this.state.selectedSecModulesRole }
          showSearchForms={ this.state.showSearchForms }
          showUpdateSecModulesRolesModal={ this.state.showUpdateSecModulesRolesModal }

          getSecModulesRoles={ this.getSecModulesRoles }
          getSelectedSecModulesRole={ this.getSelectedSecModulesRole }
          updateSecModulesRole={ this.updateSecModulesRole }
          onChangeSecModulesRolesHOC={ this.onChangeSecModulesRolesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView,
    getProfile
  } )( WithHOC )
}

export default HOC