import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      sessions: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeSessionsHOC = ( key, val ) => this.setState({ [key]: val })

    getSessions = search => Get(
      `/sessions?query=${ search }`,
      this.getSessionsSuccess,
      this.getSessionsError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getSessionsSuccess = payload => {
      let tempData = []
      payload.data.map(({ session })=> {
        let tempStart = Moment.utc( session.start_date ).format( 'DD-MM-yyyy h:mm a' )
        let tempEnd = Moment.utc( session.end_date ).format( 'DD-MM-yyyy h:mm a' )

        tempData.push({
          ... session,
          name: `${ session?.name??'N/A' } (${ tempStart } - ${ tempEnd })`
        })
      })
      this.setState({ sessions: tempData })
    }
    getSessionsError = error => this.requestError( error )


    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          sessions={ this.state.sessions }
          onLoadSession={ this.state.loading }
          
          getSessions={ this.getSessions }
          onChangeSessionsHOC={ this.onChangeSessionsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC