import { GET_PROFILE, SET_CURRENT_ROLE } from '../actions/type'

const initialState = {
  profile: {
    sec_modules_roles: [],
    user: {
      email: '',
      name: '',
      phone : '',
      role_id: 1
    }
  }
}


export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_PROFILE: 
      return {
        ...state,
        profile: action.payload
      }
    case SET_CURRENT_ROLE: {
      return {
        ... state,
        current_role_id: action.payload
      }
    }
    default: return state
  }
}