import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'
import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'
import { getSessions } from 'actions/session'

const searchParams = [
  { 
    label: 'Session name', 
    value: 'sessions.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Start Date', 
    value: 'sessions.start_date',
    type: 'date',
    param: '',
    searchMode: '$gt',
    col: 6
  },
  { 
    label: 'End Date', 
    value: 'sessions.end_date',
    type: 'date',
    searchMode: '$lt',
    param: '',
    col: 6
  }
]
const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      sessions: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedSession: {},
      searchParams: searchParams,

      selectedDeleteSessionId: null,
      showCreateSessionsModal: false,
      showDeleteSessionModal: false,
      showUpdateSessionsModal: false,
      showSearchForms: false
    }

    onChangeSessionsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSessions = search => {
      this.props.storeLastView({ search })

      Get(
        `/sessions?query=${ search }`,
        this.getSessionsSuccess,
        this.getSessionsError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getSessionsSuccess = payload => {
      let tempData = []
      payload?.data?.[0] && payload.data.map(({ session, user }) => {
        tempData.push({
          ... session,
          start_date: Moment.utc( session.start_date ).format( 'DD-MM-yyyy h:mm a' ),
          end_date: Moment.utc( session.end_date ).format( 'DD-MM-yyyy h:mm a' ),
          person_in_charge: user.name
        })
      })
      this.setState({ sessions: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getSessionsError = error => this.requestError( error )

    getSelectedSession = ( id, context ) => {
      Get(
        `/sessions/${ id }`,
        payload => this.getSelectedSessionSuccess( payload, context ),
        this.getSelectedSessionError,
        this.load
      )
    }
    getSelectedSessionSuccess = ( payload, context ) => {
      let tempStartDate = new Date( payload.start_date )  
      let tempEndDate = new Date( payload.end_date )
      var userTimezoneOffset = tempStartDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedSession: {
          ... payload,
          start_date: new Date(tempStartDate.getTime() + userTimezoneOffset ),
          end_date: new Date(tempEndDate.getTime() + userTimezoneOffset ),
        }, 
        showUpdateSessionsModal: context === 'edit' 
      })
    }
    getSelectedSessionError = error => this.requestError ( error )

    createSession = dataToSubmit => Post(
      `/sessions`,
      dataToSubmit,
      this.createSessionSuccess,
      this.createSessionError,
      this.load
    )
    createSessionSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getSessions( search )
      this.props.getSessions(btoa(`{"page":0, "per_page": 1000}`))
      this.setState({ showCreateSessionsModal: false })
      this.requestSuccess( 'Sessions has been created successfully.' )
    }
    createSessionError = error => this.requestError( error )

    deleteSession = id => Delete( 
      `/sessions/${ id }`,
      this.deleteSessionSuccess,
      this.deleteError,
      this.load
    )
    deleteSessionSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getSessions( search )
      this.props.getSessions(btoa(`{"page":0, "per_page": 1000}`))
      this.setState({ showDeleteSessionModal: false })
      this.requestSuccess( 'Session has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateSession = dataToSubmit => Put(
      `/sessions/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSessionSucces,
      this.updateSessionError,
      this.load
    )
    updateSessionSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getSessions( search )
      this.props.getSessions(btoa(`{"page":0, "per_page": 1000}`))
      this.setState({ showUpdateSessionsModal: false })
      this.getSelectedSession( payload.id )
      this.requestSuccess( 'Session was updated successfully.' )
    }
    updateSessionError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          sessions={ this.state.sessions }
          onLoadSession={ this.state.loading }
          selectedSession={ this.state.selectedSession }
          selectedDeleteSessionId={ this.state.selectedDeleteSessionId }
          showCreateSessionsModal={ this.state.showCreateSessionsModal }
          showDeleteSessionModal={ this.state.showDeleteSessionModal }
          showUpdateSessionsModal={ this.state.showUpdateSessionsModal }
          showSearchForms={ this.state.showSearchForms }
          
          getSessions={ this.getSessions }
          getSelectedSession={ this.getSelectedSession }
          createSession={ this.createSession }
          deleteSession={ this.deleteSession }
          updateSession={ this.updateSession }
          onChangeSessionsHOC={ this.onChangeSessionsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView,
    getSessions
  } )( WithHOC )
}

export default HOC