import React, { useState } from 'react'

import {
  DropdownToggle, DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
  Modal, ModalHeader, ModalBody
} from 'reactstrap'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clearItem } from 'utils/tokenStore';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { setNotificationRead } from 'actions/notifications'
import NotificationContent from './Notification'
import SwitchRolesContent from './SwitchRoles'
import SwitchSessions from './SwitchSessions'
import { setCurrentRole } from 'actions/profile'

import avatar1 from 'assets/Images/av1.png'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import UserHOC from './actions'
import UserForm from './UserForm';

const Details = ({
  open,
  content,
  onClose,
}) => {
  return (
    <Modal
      isOpen={ open }
      size={ 'md' }>
      <ModalHeader toggle={() => onClose()}>{ `Notification Details` }</ModalHeader>
      <ModalBody>
        <span>{content}</span>
      </ModalBody>
    </Modal>
  )
}

const Userbox = ({
  data,
  history,
  onLoadForgotPassword,
  onChangeUsersHOC,
  getSelectedUser,
  selectedUser,
  updateUser,

  setNotificationRead,
  showUpdateUsersModal
}) => {
  const [ showDetails, onToggleDetail ] = useState( false )
  const [ selectedNotification, onChangeSelected ] = useState( false )
  const { current_role_id } = data.ProfileReducer
  
  const {
    notification
  } = data.NotificationReducer
  const { 
    email,
    name
  } = data.ProfileReducer.profile.user

  return (
    <>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <NotificationContent
                history={ history }
                notification={ notification }
                setNotificationRead={ setNotificationRead }
                onToggleDetail={ onToggleDetail }
                onChangeSelected={ onChangeSelected }
              />
              <SwitchRolesContent 
                history={ history }
              />
              <SwitchSessions
                current_role_id={ current_role_id }
              />
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img width={ 42 } className="rounded-circle" src={ avatar1 } alt="" />
                  <FontAwesomeIcon className="ml-2 opacity-8" icon={ faAngleDown } />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg p-0">
                  <div className="dropdown-menu-header" style={{ marginBottom: 0 }}>
                    <div className="dropdown-menu-header-inner bg-white">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img width={42} className="rounded-circle" src={ avatar1 } alt="" />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading text-dark">{ `${ name || 'Username' }` }</div>
                              <span className="badge badge-success">{ data.ProfileReducer.profile.role }</span>
                              <div className="widget-subheading text-dark opacity-8">{ email || 'Email' }</div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button 
                                className="btn-pill bg-danger text-light btn-shadow btn-shine" 
                                color="focus" 
                                onClick = { () => {
                                  clearItem( 'USM_IMS_TOKEN' )
                                  history.push( '/login' )
                                }}>
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <Button 
                            className="btn-pill bg-dark text-light btn-shadow btn-shine" 
                            color="focus" 
                            onClick={ () => {
                              getSelectedUser( data.ProfileReducer.profile.user.id )
                            }}>
                            Change Password
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
        {
          onLoadForgotPassword && <LoadingOverlay />
        }        
      </div>
      <Details
        open={ showDetails }
        content={ selectedNotification?.content??'' }
        onClose={ () => onToggleDetail( false )}
      />
      <Modal isOpen={ showUpdateUsersModal } size={ 'lg' }>
        <ModalHeader toggle={ () => onChangeUsersHOC( 'showUpdateUsersModal', false )}>
          Change Your Password
        </ModalHeader>
        <UserForm 
          selectedUser={ selectedUser }
          updateUser={ updateUser }
        />
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({ data:state })
export default compose (
  connect( mapStateToProps, {
    setNotificationRead,
    setCurrentRole
  }),
  UserHOC
)( Userbox )