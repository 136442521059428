import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import LogBookDateTimeForm from './Form'

import LogBookDateTimeHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class LogBookDateTime extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getLogBookDateTime( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'session_id' ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getLogBookDateTime( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'session_id' ))) )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    return (
      <>
        <PageTitle
          heading="Logbook Datelines"
          subheading="Listings of all the logbook datelines present in the system. Please ensure that only one dateline exists for one session."
          icon="pe-7s-tools icon-gradient bg-happy-itmeo"
          buttons={[
            ... (
              is_create ? [{
                color: 'primary',
                onClick: () => {
                  this.props.onChangeLogBookDateTimeHOC( 'showCreateLogBookDateTimeModal', true )
                },
                content: 'Create'
              }] : []
            )
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.logBookDateTime.data.length < 1? 3: 
                      this.props.logBookDateTime.data.length < 10? this.props.logBookDateTime.data.length:
                      10
                    }
                    data={ this.props.logBookDateTime.data }
                    columns={[
                      {
                        Header: 'Start Date',
                        accessor: 'start_date'
                      },
                      {
                        Header: 'End Date',
                        accessor: 'end_date'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                disabled={ !is_update }
                                id="UpdateLogBookDateTime"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? 'primary' : 'secondary' }
                                onClick={() => {
                                  this.props.getSelectedLogBookDateTime(row.original.id, 'edit')
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateLogBookDateTime" placement="left">Update the information of this industry category</UncontrolledTooltip>
                              <Button
                                id="DeleteLogBookDateTime"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? 'danger' : 'secondary' }
                                disabled={ !is_delete }
                                onClick={() => {
                                  this.props.onChangeLogBookDateTimeHOC( 'showDeleteLogBookDateTimeModal', true )
                                  this.props.onChangeLogBookDateTimeHOC( 'selectedDeleteLogBookDateTimeId', row.original.id )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteLogBookDateTime" placement="left">Delete this industry category</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.logBookDateTime.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getLogBookDateTime( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <LogBookDateTimeForm
            {...this.props}
            mode={ 'Create' }
            open={ this.props.showCreateLogBookDateTimeModal }
            onClose={ () => this.props.onChangeLogBookDateTimeHOC( 'showCreateLogBookDateTimeModal', false )}
            onClickSubmit={ this.props.createLogBookDateTime }
          />
          <LogBookDateTimeForm
            {...this.props}
            mode={ 'Edit' }
            selectedFormData={ this.props.selectedLogBookDateTime }
            open={ this.props.showUpdateLogBookDateTimeModal }
            onClose={ () => this.props.onChangeLogBookDateTimeHOC( 'showUpdateLogBookDateTimeModal', false )}
            onClickSubmit={ this.props.updateLogBookDateTime }
          />
          <ConfirmationModal
            open={ this.props.showDeleteLogBookDateTimeModal }
            title={ `Delete Logbook Datelines` }
            loading={ this.props.onLoadLogBookDateTime }
            message={ `Are you sure you want to delete this Logbook Datelines? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeLogBookDateTimeHOC( 'showDeleteLogBookDateTimeModal', false )}
            onClickConfirm={ () => this.props.deleteLogBookDateTime( this.props.selectedDeleteLogBookDateTimeId )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadLogBookDateTime && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  LogBookDateTimeHOC
)(LogBookDateTime)