import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { 
  Row,
  Col,
  Card, CardHeader, CardBody, Button,
  FormGroup,
  Input,
  Label,
  FormText
} from 'reactstrap'
import _ from 'lodash'

import ConfirmationModal from 'components/Modal/confirmation'

import { mobileChecker } from 'utils/mobileChecker'

import { CompanyFieldData, defaultEditFormValue } from '../assets'

const ApprovalOptions = [ 'Active', 'Inactive' ]

const BGHandler = val => {
  if ( val === 'Active' ) {
    return 'bg-success'
  }

  if ( val === 'Inactive' ) {
    return 'bg-danger'
  }

  return 'bg-secondary'
}

const CompanyCard = props => {
  const {
    data,
    selectedCompany,
    getCountryState,

    updateCompany
  } = props
  
  const [ statusConfirmation, setStatusConfirmation ] = useState( false )
  const [ companyData, onChangeCompanyData ] = useState( defaultEditFormValue )
  
  useEffect(() => {
    selectedCompany && onChangeCompanyData( selectedCompany )
  }, [selectedCompany])
  
  let disableApproval = useMemo(() => {
    return selectedCompany.company.status === 'Active'
  }, [ selectedCompany])
  
  let disabledSubmit = useMemo(() => {
    let tempFields = []
    _.map( CompanyFieldData({}), 'fields' ).map( item => tempFields.push( ... item ))
    return tempFields.some( item => item.required && !companyData.company[ item.value ])
  }, [companyData])

  const onChangeCompanyField = ( key, val ) => {
    let tmp = _.cloneDeep( companyData )
    tmp[ 'company' ][ key ] = val

    return onChangeCompanyData( tmp )
  }
  
  const debounceMobile = useCallback( _.debounce(({ users, company }, context, val ) => {
    let tempCompany = {
      ... company,
      [context]: mobileChecker( val )
    }
    onChangeCompanyData({
      company: tempCompany,
      users
    })
  }, 500), [])

  return (
    <>
      {
        CompanyFieldData({
          ... props,
          designations: data.DictionaryReducer.designations
        }).map(( card, cardIndex ) => (
          <>
            <Card className="main-card mb-3">
              <CardHeader style={{ fontSize: '1rem' }}>
                { card.cardName }
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    cardIndex === 0 && (
                      <Col md={ 6 } >
                        <FormGroup className={ `p-2 rounded ${ BGHandler( companyData.company.status )}` }>
                          <Label className='text-white'>Approval Status</Label>
                          <Input 
                            disabled={ 
                              [ 1, 11, 12 ].indexOf( data.ProfileReducer.current_role_id ) < 0 
                              // || disableApproval 
                            }
                            type={ 'select' }
                            value={ companyData.company[ 'status' ] }
                            onChange={ e => {
                              e.target.value === 'Active' 
                                ? setStatusConfirmation( true )
                                : onChangeCompanyField( 'status', 'Inactive' )
                            }}
                          >
                            <option value=""></option>
                            { 
                              ApprovalOptions.map( option => (
                                <option key={ option } value={ option }>{ option }</option>
                              ))
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    )
                  }
                  {
                    card.fields.map( item => (
                      <Col lg={ item.col || 12 } xs={ 12 }>
                        <FormGroup>
                          { 
                            item.label && (
                              <Label>
                                { `${ item.label } ` }
                                { item.required && <span className="text-danger">*</span> }
                              </Label>
                          )}
                          {
                            item.type === 'textarea' && (
                              <Input
                                type={ 'textarea' }
                                maxlength={ 500 }
                                value={ companyData.company[ item.value ] }
                                rows={ 4 }
                                onChange={ e => onChangeCompanyField( item.value, e.target.value )}
                              />
                            )
                          }
                          {
                            item.type === 'select' && (
                              <Input
                                type={ item.type }
                                value={ companyData.company[ item.value ] }
                                onChange={ e => {
                                  onChangeCompanyField( item.value, e.target.value )
                                  item.value === 'country' && getCountryState( e.target.value )
                                }}>
                                <option value=""></option>
                                { 
                                  item.options.map( option => (
                                    <option key={ option.id } value={ option.id }>{ option.name }</option>
                                  ))
                                }
                              </Input>
                            )
                          }
                          {
                              ['textarea', 'select' ].indexOf( item.type ) < 0 && (
                              <Input
                                type={ item.type }
                                value={ companyData.company[ item.value ] }
                                onChange={ e => {
                                  Promise.all([
                                    onChangeCompanyField( item.value, e.target.value )
                                  ]).then(() => {
                                    [ 'fax_number', 'phone' ].indexOf( item.value ) > -1 && debounceMobile( companyData, item.value, e.target.value )
                                  })
                                }}
                              />
                            )
                          }
                          { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                        </FormGroup>
                      </Col>
                    ))
                  }
                </Row>
              </CardBody>
            </Card>
          </>
        ))
      }    
      <Button 
        color={ !disabledSubmit ? "primary" : 'secondary'}
        className="mt-2"
        disabled={ disabledSubmit }
        onClick={() => updateCompany({ 
          ... companyData.company,
          country: +companyData.company.country,
          state: +companyData.company.state,
          contact_person_designation_id: +companyData.company.contact_person_designation_id,
          industry_category_id: +companyData.company.industry_category_id
        })}
      >
        Update
      </Button>
    <ConfirmationModal
      open={ statusConfirmation }
      title={ `Company Approval Status` }
      message={ `Are you sure you want to set this company's status to 'Active'? (Once saved, it cannot be revert.)` }
      onClose={ () => setStatusConfirmation( false )}
      onClickConfirm={ () => {
        onChangeCompanyField( 'status', 'Active' )
        setStatusConfirmation( false )
      }}/>
    </>
  )
}

export default CompanyCard;