import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'
import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Start Date', 
    value: 'start_date',
    type: 'date',
    param: '',
    searchMode: '$gt',
    col: 6
  },
  { 
    label: 'End Date', 
    value: 'end_date',
    type: 'date',
    searchMode: '$lt',
    param: '',
    col: 6
  }
]
const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      visitSchedule: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedVisitSchedule: {},
      searchParams: searchParams,

      showCreateVisitSchedulesModal: false,
      showDeleteVisitScheduleModal: false,
      showUpdateVisitSchedulesModal: false,
      showSearchForms: false
    }

    onChangeVisitSchedulesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getVisitSchedules = search => {
      this.props.storeLastView({ search })

      Get(
        `/visit_schedules?query=${ search }`,
        this.getVisitSchedulesSuccess,
        this.getVisitSchedulesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getVisitSchedulesSuccess = payload => {
      let tempData = []
      payload.data.map(({ visit_schedule, company, user }) => {
        tempData.push({
          ... visit_schedule,
          company_name: company.name,
          user_name: user.name,
          date: Moment.utc( visit_schedule.date ).format( 'DD-MM-yyyy' ),
          start_time: Moment.utc( visit_schedule.start_time ).format( 'h:mm a' ),
          end_time: Moment.utc( visit_schedule.end_time ).format( 'h:mm a' ),
        })
      })
      if ( this.props.data.ProfileReducer.current_role_id === 13 && tempData.length > 0 ){
        this.getSelectedVisitSchedule( tempData[0].id )
      } 

      this.setState({ visitSchedule: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getVisitSchedulesError = error => this.requestError( error )

    getSelectedVisitSchedule = id => {
      Get(
        `/visit_schedules/${ id }`,
        this.getSelectedVisitScheduleSuccess,
        this.getSelectedVisitScheduleError,
        this.load
      )
    }
    getSelectedVisitScheduleSuccess = ({ visit_schedule, company, user }) => {
      let tempStartTime = new Date( visit_schedule.start_time )  
      let tempEndTime = new Date( visit_schedule.end_time )
      let tempDate = new Date( visit_schedule.date )

      var userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedVisitSchedule: {
          ... visit_schedule,
          date: new Date(tempDate.getTime() + userTimezoneOffset ),
          start_time: new Date(tempStartTime.getTime() + userTimezoneOffset ),
          end_time: new Date(tempEndTime.getTime() + userTimezoneOffset ),
          acceptedList: {
            id: visit_schedule.student_vacancy_accepted_list_id,
            student_name: user.name,
            company_name: company.name, 
            session_id: visit_schedule.session_id,
            user_id: visit_schedule.user_id,
            company_id: visit_schedule.company_id
          }
        }, 
        showUpdateVisitSchedulesModal: true
      })
    }
    getSelectedVisitScheduleError = error => this.requestError ( error )

    createVisitSchedule = dataToSubmit => Post(
      `/visit_schedules`,
      dataToSubmit,
      this.createVisitScheduleSuccess,
      this.createVisitScheduleError,
      this.load
    )
    createVisitScheduleSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getVisitSchedules( search )
      this.setState({ showCreateVisitSchedulesModal: false })
      this.requestSuccess( 'Visit Schedules has been created successfully.' )
    }
    createVisitScheduleError = error => this.requestError( error )

    deleteVisitSchedule = id => Delete( 
      `/visit_schedules/${ id }`,
      this.deleteVisitScheduleSuccess,
      this.deleteError,
      this.load
    )
    deleteVisitScheduleSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getVisitSchedules( search )
      this.setState({ showDeleteVisitScheduleModal: false })
      this.requestSuccess( 'VisitSchedule has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateVisitSchedule = dataToSubmit => Put(
      `/visit_schedules/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateVisitScheduleSucces,
      this.updateVisitScheduleError,
      this.load
    )
    updateVisitScheduleSucces = ({ visit_schedule }) => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getVisitSchedules( search )
      this.getSelectedVisitSchedule( visit_schedule.id )
      this.requestSuccess( 'VisitSchedule was updated successfully.' )
    }
    updateVisitScheduleError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          visitSchedule={ this.state.visitSchedule }
          onLoadVisitSchedule={ this.state.loading }
          selectedVisitSchedule={ this.state.selectedVisitSchedule }
          showCreateVisitSchedulesModal={ this.state.showCreateVisitSchedulesModal }
          showDeleteVisitScheduleModal={ this.state.showDeleteVisitScheduleModal }
          showUpdateVisitSchedulesModal={ this.state.showUpdateVisitSchedulesModal }
          showSearchForms={ this.state.showSearchForms }
          
          getVisitSchedules={ this.getVisitSchedules }
          getSelectedVisitSchedule={ this.getSelectedVisitSchedule }
          createVisitSchedule={ this.createVisitSchedule }
          deleteVisitSchedule={ this.deleteVisitSchedule }
          updateVisitSchedule={ this.updateVisitSchedule }
          onChangeVisitSchedulesHOC={ this.onChangeVisitSchedulesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC