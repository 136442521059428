import _ from 'lodash'

export default function sessionQueryHandler( json, sessionId, key ){
	if ( !sessionId ) {
		return json
	} else {
		let tempJSON = _.cloneDeep( json )

		if ( tempJSON.filter ){
			tempJSON.filter[ key ] = sessionId
		} else {
			tempJSON.filter = {
				[key]: sessionId
			}
		}

		return tempJSON;
	}
}