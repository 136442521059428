import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedLecturer: {},

      showImportLecturersModal: false,
    }

    onChangeLecturersHOC = ( key, val ) => this.setState({ [key]: val })
    
    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSelectedLecturer = id => {
      Get(
        `/lecturers/${ id }`,
        this.getSelectedLecturerSuccess,
        this.getSelectedLecturerError,
        this.load
      )
    }
    getSelectedLecturerSuccess = ({ lecturer, user }) => {
      const { roles } = this.props.data.DictionaryReducer
      let tempMerge = _.merge( user, lecturer )
      let tempRole = _.map( user.role_ids, role_id => {
        let temp = _.find( roles, { id: role_id })  

        return {
          id: role_id,
          name: temp?.name??''
        }
      })

      this.props.onChangeUsersHOC( 'showUpdateUsersModal', true )
      this.props.onChangeUsersHOC( 'selectedUser', {
        ... tempMerge,
        rolesInfo: tempRole
      })
    }
    getSelectedLecturerError = error => this.requestError ( error )

    importLecturers = dataToSubmit => Post(
      `/lecturers/import`,
      {
        "data": dataToSubmit
      },
      this.importLecturersSuccess,
      this.importLecturersError,
      this.load
    )
    importLecturersSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.setState({ showImportLecturersModal: false })
      this.props.getUsers( search )
      this.requestSuccess( 'User has been created successfully.' )
    }
    importLecturersError = error => this.requestError( error )

    createLecturer = dataToSubmit => Post(
      `/lecturers`,
      dataToSubmit,
      this.createLecturerSuccess,
      this.createLecturerError,
      this.load
    )
    createLecturerSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getUsers( search )
      this.props.onChangeUsersHOC( 'showCreateUsersModal', false )
      this.requestSuccess( 'User has been created successfully.' )
    }
    createLecturerError = error => this.requestError( error )

    deleteLecturer = id => Delete( 
      `/lecturers/${ id }`,
      this.deleteLecturerSuccess,
      this.deleteError,
      this.load
    )
    deleteLecturerSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getUsers( search )
      this.props.onChangeUsersHOC( 'showDeleteUserModal', false )
      this.requestSuccess( 'User has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateLecturer = dataToSubmit => Put(
      `/lecturers/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateLecturerSuccess,
      this.updateLecturerError,
      this.load
    )
    updateLecturerSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getUsers( search )
      this.getSelectedLecturer( payload.lecturer.id )
      this.requestSuccess( 'User was updated successfully.' )
    }
    updateLecturerError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadLecturer={ this.state.loading }
          selectedLecturer={ this.state.selectedLecturer }
          showImportLecturersModal={ this.state.showImportLecturersModal }

          getSelectedLecturer={ this.getSelectedLecturer }
          createLecturer={ this.createLecturer }
          deleteLecturer={ this.deleteLecturer }
          updateLecturer={ this.updateLecturer }
          importLecturers={ this.importLecturers }
          onChangeLecturersHOC={ this.onChangeLecturersHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC