import React, { useCallback } from "react";
import {
	Card,
	CardBody,
	CardFooter,
	Form,
	Col,
	Row,
	Label,
	FormGroup,
	Button,
	Input,
} from "reactstrap";
import SessionTypeahead from "components/Typeahead/session";
import _ from "lodash";
import { StatusOptions } from "./assets";
import HOC from "./action";

function VacancyStudentForm(props) {
	const {
		getStudents,
		getVacancies,
		applyStudentVacancies,
		onLoadVacancy,
		vacancies,
		students,
		vacancyForm,
		onChangeVacancyHOC,
	} = props;

	const onChangeField = (key, val) => {
		let temp = _.cloneDeep(vacancyForm);
		temp[key] = val;

		onChangeVacancyHOC("vacancyForm", temp);
	};

	const onChangeTypeaheadSearch = useCallback(
		_.debounce((val) => {
			getVacancies(
				btoa(
					`{"page":0,"filter":{"$or":[{"company_vacancies.job_title":{"$like":"%${ val }%"}},{"companies.name":{"$like":"%${val}%"}}]}}`
				)
			);
		}, 600),
		[]
	);

	const onChangeTypeaheadStudentSearch = useCallback(
		_.debounce((val) => {
			getStudents(btoa(`{"page":0,"filter":{"users.name":{"$like":"%${val}%"}}}`));
		}, 600),
		[]
	);

	return (
		<>
			<Card>
				<CardBody>
					<Form onSubmit={(e) => e.preventDefault()}>
						<Row>
							<Col md={6} xs={12}>
								<FormGroup>
									<Label>Vacancies</Label>
									<SessionTypeahead
										options={vacancies}
										onSelect={(val) =>
											val?.[0]?.id &&
											onChangeField("company_vacancy_id", val[0].id)
										}
										selectedValue={
											vacancyForm?.company_vacancy_id &&
											_.find(vacancies, {
												id: vacancyForm.company_vacancy_id,
											})
												? [
														_.find(vacancies, {
															id: vacancyForm.company_vacancy_id,
														}),
												  ]
												: []
										}
										onSearch={(val) => {
											onChangeField("company_vacancy_id", null);
											val.length > 0
												? onChangeTypeaheadSearch(val)
												: onChangeVacancyHOC("vacancies", []);
										}}
										filterBy={"name"}
										disableEdit={onLoadVacancy}
										helpText={"Please enter job title to search."}
									/>
								</FormGroup>
							</Col>
							<Col md={6} xs={12}>
								<FormGroup>
									<Label>Students</Label>
									<SessionTypeahead
										options={students}
										onSelect={(val) =>
											val?.[0]?.id && onChangeField("student_id", val[0].id)
										}
										selectedValue={
											vacancyForm?.student_id &&
											_.find(students, {
												id: vacancyForm.student_id,
											})
												? [
														_.find(students, {
															id: vacancyForm.student_id,
														}),
												  ]
												: []
										}
										onSearch={(val) => {
											onChangeField("student_id", null);
											val.length > 0
												? onChangeTypeaheadStudentSearch(val)
												: onChangeVacancyHOC("students", []);
										}}
										filterBy={"name"}
										disableEdit={onLoadVacancy}
										helpText={"Please enter name to search."}
									/>
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<Label>Status</Label>
									<Input
										type="select"
										value={vacancyForm.student_vacancy_status_id}
										onChange={(e) => {
											onChangeField(
												"student_vacancy_status_id",
												+e.target.value
											)
										}}>
										{StatusOptions.map((option) => (
											<option value={option.id}>{option.name}</option>
										))}
									</Input>
								</FormGroup>
							</Col>
						</Row>
					</Form>
				</CardBody>
				<CardFooter>
					<Button
						color={"primary"}
						onClick={() =>
							applyStudentVacancies({
								...vacancyForm,
							})
						}>
						Submit
					</Button>
				</CardFooter>
			</Card>
		</>
	);
}

export default HOC(VacancyStudentForm);
