export const MainNav = [
  {
    sec_module_id: 30,
    icon: 'pe-7s-graph3',
    label: 'Statistics',
    to: "#/dashboard/statistics"
  },
  { 
    icon: 'pe-7s-settings',
    label: 'Configurations',
    content: [
      {
        sec_module_id: 1,
        icon: "pe-7s-display2",
        label: "Sessions",
        to: "#/dashboard/sessions"
      },
      {
        sec_module_id: 11,
        icon: 'pe-7s-date',
        label: 'Logbook Datelines',
        to: "#/dashboard/logbook_datelines"
      },  
      {
        sec_module_id: 2,
        icon: 'pe-7s-speaker',
        label: 'Announcement',
        to: "#/dashboard/announcement"
      },
      {
        sec_module_id: 3,
        icon: 'pe-7s-users',
        label: 'User Management',
        to: "#/dashboard/users"
      },
      {
        sec_module_id: 14,
        icon: 'pe-7s-culture',
        label: 'Company Management',
        to: "#/dashboard/company"
      },
      {
        sec_module_id: 4,
        icon: 'pe-7s-user',
        label: 'Role',
        to: "#/dashboard/roles"
      },
      {
        sec_module_id: 5,
        icon: 'pe-7s-id',
        label: 'Designation',
        to: "#/dashboard/designation"
      },
      {
        sec_module_id: 6,
        icon: 'pe-7s-tools',
        label: 'Industry Categories',
        to: "#/dashboard/industry_categories"
      },
      {
        sec_module_id: 7,
        icon: 'pe-7s-box2',
        label: 'Sec Modules',
        to: "#/dashboard/sec_modules"
      },
      {
        sec_module_id: 15,
        icon: 'pe-7s-edit',
        label: 'Sec Modules Roles',
        to: "#/dashboard/sec_modules_roles"
      },
      {
        sec_module_id: 17,
        icon: 'pe-7s-study',
        label: 'Student Vacancies Status',
        to: "#/dashboard/students_vacancies_status"
      },
      {
        sec_module_id: 20,
        icon: 'pe-7s-notebook',
        label: 'Principal Courses Management',
        to: "#/dashboard/principal-courses-management"
      },
      {
        sec_module_id: 35,
        icon: 'pe-7s-user',
        label: 'Assign Academic Advisor',
        to: "#/dashboard/assign-academic-advisor"
      }
    ]
  },
  {
    sec_module_id: 16,
    icon: "pe-7s-culture",
    label: "Company Vacancies",
    to: "#/dashboard/company-vacancies"
  },
  {
    sec_module_id: 27,
    icon: 'pe-7s-note2',
    label: 'Student CV',
    to: "#/dashboard/student-cv"
  },
  {
    sec_module_id: 38,
    icon: 'pe-7s-note2',
    label: 'Offer Letters',
    to: "#/dashboard/offer-letters"
  },
  {
    sec_module_id: 19,
    icon: 'pe-7s-study',
    label: 'Vacancies',
    to: "#/dashboard/student_vacancies"
  },
  {
    sec_module_id: 29,
    icon: 'pe-7s-tools',
    label: 'Internship Management' ,
    to: "#/dashboard/internship-management"
  },
  {
    sec_module_id: 18,
    icon: 'pe-7s-note2',
    label: 'Placement Forms',
    to: "#/dashboard/placement-forms"
  },
  // {
  //   sec_module_id: 24,
  //   icon: 'pe-7s-note2',
  //   label: 'Internship Duty Report',
  //   to: "#/dashboard/internship-duty-report"
  // },
  {
    sec_module_id: 32,
    icon: 'pe-7s-note2',
    label: 'Report Duty Form',
    to: "#/dashboard/report-duty-form"
  },
  {
    sec_module_id: 25,
    icon: 'pe-7s-date',
    label: 'Lecturer Visit Schedule',
    to: "#/dashboard/visit-schedule"
  },
  {
    sec_module_id: 31,
    icon: 'pe-7s-note2',
    label: 'Job Scope Form',
    to: "#/dashboard/job-scope"
  },
  {
    sec_module_id: 33,
    icon: 'pe-7s-note2',
    label: 'Evaluation Form',
    to: "#/dashboard/evaluation-form"
  },
  {
    sec_module_id: 37,
    icon: 'pe-7s-news-paper',
    label: 'Insurance Form', 
    to: '#/dashboard/insurance'
  },
  {
    sec_module_id: 23,
    icon: 'pe-7s-note2',
    label: 'Weekly Logs',
    to: "#/dashboard/weekly-logs"
  },
  {
    sec_module_id: 34,
    icon: 'pe-7s-news-paper',
    label: 'Result',
    to: "#/dashboard/result"
  },
  {
    sec_module_id: 39,
    icon: 'pe-7s-news-paper',
    label: 'Admin Form',
    to: "#/dashboard/admin-form"
  }
];