
import { 
  GET_NOTIFICATION
 } from 'actions/type'

const initialState = {
  notification: {
    data: [],
    meta: {
      Meta: {
        page: 1,
        page_count: 0,
        per_page: 1,
        total_count: 0,
      },
      unread_count: 0,
    }

  },
}

export default ( state = initialState, actions ) => {

  switch ( actions.type ) {
    case GET_NOTIFICATION:
      return ({
        ...state,
        notification: actions.payload
      })
  
    default: return state
  }
}
