import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Put } from 'utils/axios'
import { getNotification } from 'actions/notifications'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      notifications: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      showDetailsModal: false,
      selectedNotification: {}
    }

    onChangeNotificationHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getNotifications = ( search ) => Get(
      `/notifications?query=${ search }`,
      this.getNotificationsSuccess,
      this.getNotificationsError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getNotificationsSuccess = payload => {
      let tempData = []
      payload?.data?.[0] && payload.data.map( data => {
        tempData.push({
          ... data,
          content: data.content.length > 150
            ? data.content.slice( 0, 150 )
            : data.content,
          ... ( data.content.length > 150 && {
            remainingContent: data.content.slice( 150 )
          })
        })
      })
      this.setState({ 
        notifications: { 
          data: tempData, 
          meta: payload.meta.Meta, 
          undreadCount: payload.meta.unread_count 
        } 
      })
    }
    getNotificationsError = error => this.requestError( error )

    setNotificationOnRead = id => {
      Put(
        `/notifications/${ id }`,
        { "is_read": true },
        this.setNotificationOnReadSuccess,
        this.setNotificationOnReadError,
        this.load
      )
    }
    setNotificationOnReadSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView
      const { current_role_id } = this.props.data.ProfileReducer
      
      this.getNotifications( search )
      this.props.getNotification( btoa(`{ "page": 1, "filter": { "is_read": true }}`), false, current_role_id )
    }
    setNotificationOnReadError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          notifications={ this.state.notifications }
          selectedNotification={ this.state.selectedNotification }
          onLoadNotification={ this.state.loading }
          showDetailsModal={ this.state.showDetailsModal }

          getNotifications={ this.getNotifications }
          setNotificationOnRead={ this.setNotificationOnRead }
          onChangeNotificationHOC={ this.onChangeNotificationHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, { 
    getNotification
  })( WithHOC )
}

export default HOC