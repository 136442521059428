import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'

import { Get, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Student name', 
    value: 'su.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Company name', 
    value: 'c.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Is Checked', 
    value: 'idr.is_checked',
    type: 'select',
    param: '',
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      dutyReports: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedReport: null,
      searchParams: searchParams,


      showDeleteReportModal: false,
      showUpdateReportModal: false,
      showSearchForms: false
    }

    onChangeReportHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getReport = search => {
      this.props.storeLastView({ search })

      Get(
        `/internship_duty_reports?query=${ search }`,
        this.getReportSuccess,
        this.getReportError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getReportSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({
        company,
        student_user, 
        internship_duty_report, 
        external_supervisor_user,
        ... item 
      }) => {
        tempData.push({
          ... item,
          student_name: student_user.name,
          student_email: student_user.email,
          company_name: company.name,
          external_sv: external_supervisor_user?.name??'-',
          ...internship_duty_report,
        })
      })

      if ( this.props.data.ProfileReducer.current_role_id === 13 && payload.data?.[0] ){
        this.getSelectedReport( payload.data?.[0]?.internship_duty_report?.id )
      }

      this.setState({ dutyReports: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getReportError = error => this.requestError( error )

    getSelectedReport = id => {
      Get(
        `/internship_duty_reports/${ id }`,
        this.getSelectedReportSuccess,
        this.getSelectedReportError,
        this.load
      )
    }
    getSelectedReportSuccess = ({ internship_duty_report, student, student_user, ...payload }) => {
      let tempDate = new Date( internship_duty_report.report_duty_date )  
      var userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedReport: {
          ... payload,
          student: { ... student, ... student_user },
          internship_duty_report: {
            id: internship_duty_report.id,
            external_supervisor_id : internship_duty_report.external_supervisor_id,
            external_sv_remark: internship_duty_report.external_sv_remark,
            have_intern_allowance: internship_duty_report.have_intern_allowance,
            is_checked: internship_duty_report.is_checked,
            report_duty_date: new Date(tempDate.getTime() + userTimezoneOffset ),
            supervisor_contact: internship_duty_report.supervisor_contact,
            supervisor_fax: internship_duty_report.supervisor_fax,
            supervisor_mobile: internship_duty_report.supervisor_mobile
          }
        }, 
        showUpdateReportModal: true
      })
    }
    getSelectedReportError = error => this.requestError ( error )

    deleteReport = id => Delete( 
      `/internship_duty_reports/${ id }`,
      this.deleteReportSuccess,
      this.deleteError,
      this.load
    )
    deleteReportSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getReport( search )
      this.setState({ showDeleteReportModal: false })
      this.requestSuccess( 'Internship Duty Report has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateReport = dataToSubmit => Put(
      `/internship_duty_reports/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateReportSucces,
      this.updateReportError,
      this.load
    )
    updateReportSucces = ({ internship_duty_report }) => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getReport( search )
      this.getSelectedReport( internship_duty_report.id )
      this.requestSuccess( 'Report was updated successfully.' )
    }
    updateReportError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          dutyReports={ this.state.dutyReports }
          onLoadReport={ this.state.loading }
          selectedReport={ this.state.selectedReport }
          showDeleteReportModal={ this.state.showDeleteReportModal }
          showUpdateReportModal={ this.state.showUpdateReportModal }
          showSearchForms={ this.state.showSearchForms }
          
          getReport={ this.getReport }
          getSelectedReport={ this.getSelectedReport }
          deleteReport={ this.deleteReport }
          updateReport={ this.updateReport }
          onChangeReportHOC={ this.onChangeReportHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC