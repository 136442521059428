import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedRole: {
        name :''
      },
      selectedSecModules: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeDetailsHOC = ( key, val ) => this.setState({ [key]: val })

    getRoleSecModules = role => {
      this.setState({ selectedRole: role })
      Get(
        `/sec_module_roles?query=${ btoa(`{"filter": {"role_id":${ role.id }}, "per_page": 1000 }`)}`,
        this.getRoleSecModulesSuccess,
        this.getRoleSecModulesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getRoleSecModulesSuccess = payload => this.setState({ 
      selectedSecModules: payload.data,
      showRolesDetails: true
    })
    getRoleSecModulesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadDetails={ this.state.loading }
          selectedSecModules={ this.state.selectedSecModules }
          selectedRole={ this.state.selectedRole }
          showRolesDetails={ this.state.showRolesDetails }

          onChangeDetailsHOC={ this.onChangeDetailsHOC }
          getRoleSecModules={ this.getRoleSecModules }
          onChangesHOC={ this.onChangesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC