import React, { useEffect, useState } from 'react'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form, FormText, FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { SkillsDefaultValue, SkillsFieldData } from '../../assets'

const FormContent = ({
  open,
  onLoadSkill,
  
  onClose,
  selectedFormData,
  onClickSubmit
}) => {

  const [ skillFormData, onChangeSkillData ] = useState( SkillsDefaultValue )

  useEffect(() => { 
    if ( !open ){
      onChangeSkillData( SkillsDefaultValue )
    }
  }, [open])

  useEffect(() => {
    onChangeSkillData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeSkillField = ( key, val ) => {
    let tmp = _.cloneDeep( skillFormData )
    tmp[ key ] = val
    return onChangeSkillData( tmp )
  }
  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Edit Skill` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
          {
            SkillsFieldData.map( item => (
              <Col key={ item.value } lg={ item.col || 12 }>
                <FormGroup>
                  { 
                    item.label && (
                      <Label>
                        { `${ item.label } ` }
                        { item.required && <span className="text-danger">*</span> }
                      </Label>
                  )}
                  {
                    item.type === 'select' && (
                      <Input
                        type={ item.type }
                        value={ skillFormData[ item.value ] }
                        onChange={ e =>  onChangeSkillField( item.value, e.target.value )}
                        >
                        <option value=""></option>
                        { 
                          item.options.map( option => (
                            <option key={ option.name } value={ option.name }>{ option.name }</option>
                          ))
                        }
                      </Input>
                    )
                  }
                  {
                    item.type !== 'select' && (
                      <Input
                        type={ item.type }
                        value={ skillFormData[ item.value ] }
                        onChange={ e => onChangeSkillField( item.value, e.target.value )}/>
                    )
                  }
                  { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                </FormGroup>
              </Col>
            ))
          }
          </Row>
        </Form>
        { onLoadSkill && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ 
            (SkillsFieldData.some( item => item.required && !skillFormData[ item.value ])) 
              ? 'secondary' 
              : 'primary' 
          }
          className="mr-2"
          disabled={ SkillsFieldData.some( item => item.required && !skillFormData[ item.value ])}
          onClick={() => {
            onClickSubmit({
              ... skillFormData
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent