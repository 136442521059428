import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import getDomainURL from 'utils/api'
import Axios from 'axios'
import FileSaver from 'file-saver'
import { Get, Put } from 'utils/axios'
import { storeLastView }  from 'actions/lastView'

const searchParams = [
  { 
    label: 'Student Name', 
    value: 'su.name',
    type: 'text',
    param: '' 
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      placementForms: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedPlacementFormPlacementForm: null,

      showUpdatePlacementModal: false,
      showSearchForms: false,
      searchParams: searchParams
    }

    onChangePlacementHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getPlacementForm = ( search ) => {
      this.props.storeLastView({ search })

      Get(
        `/placement_forms?query=${ search }`,
        this.getPlacementFormSuccess,
        this.getPlacementFormError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getPlacementFormSuccess = payload => {
      let tempData =  []
      payload?.data?.[0] && payload.data.map( item => {
        tempData.push({
          ... item.student_user,
          id: item.placement_form.id
        })
      })
      if ( this.props.data.ProfileReducer.current_role_id === 13 && payload.data.length > 0 ){
        this.getSelectedPlacementForm( tempData[0].id )
      } 
      this.setState({ placementForms: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getPlacementFormError = error => this.requestError( error )

    getSelectedPlacementForm = id => Get(
      `/placement_forms/${ id }`,
      this.getSelectedPlacementFormSuccess,
      this.getSelectedPlacementFormError,
      this.load
    )

    getSelectedPlacementFormSuccess = payload => this.setState({ selectedPlacementForm: {
      ... payload.placement_form,
      id: payload.placement_form.id,

      company_address: payload.company.address,
      company_name: payload.company.name,
      company_vacancy_scope: payload.company_vacancy.job_scope,

      contact_person_designation_id: payload.placement_form.contact_person_designation_id,
      contact_person_name: payload.placement_form.contact_person_name,
      contact_person_email_address: payload.placement_form.contact_person_email_address,
      contact_person_mobile: payload.placement_form.contact_person_mobile,
      contact_person_phone_number: payload.placement_form.contact_person_phone_number,
      
      student_name: payload.student_user.name,
      student_phone: payload.student_user.phone,
      student_email: payload.student_user.email,
      student_matric: payload.student.matric_number,
      student_specialization: payload.student.specialization, 
      student_nric: payload.student.ic,
    }, showUpdatePlacementModal: true })
    getSelectedPlacementFormError = error => this.requestError ( error )

    updatePlacementForm = dataToSubmit => Put(
      `/placement_forms/${ dataToSubmit.id }`,
      dataToSubmit,
      () => this.updatePlacementFormSucces( dataToSubmit.id ),
      this.updatePlacementFormError,
      this.load
    )
    updatePlacementFormSucces = id => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getPlacementForm( search )
      this.getSelectedPlacementForm( id )
      this.requestSuccess( 'Placement form updated successfully.' )
    }
    updatePlacementFormError = error => this.requestError( error )

    getStudentPlacementForm_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/placement_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_PlacementForm.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          placementForms={ this.state.placementForms }
          onLoadPlacement={ this.state.loading }
          showSearchForms={ this.state.showSearchForms }
          selectedPlacementForm={ this.state.selectedPlacementForm }
          showUpdatePlacementModal={ this.state.showUpdatePlacementModal }
          getPlacementForm={ this.getPlacementForm }
          updatePlacementForm={ this.updatePlacementForm }
          getSelectedPlacementForm={ this.getSelectedPlacementForm }
          getStudentPlacementForm_pdf={ this.getStudentPlacementForm_pdf }
          onChangePlacementHOC={ this.onChangePlacementHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeLastView
  })( WithHOC )
}

export default HOC