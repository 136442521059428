export const fieldData = [
  { label: 'Job Title', value: 'job_title', type: '', required: true },
  { label: 'No. of positions available', value: 'vacancy_number', type: 'number', required: false },
  { label: 'Job Scope', value: 'job_scope', type: 'textarea', col: 12, required: true },
  { label: 'Preferred skills (Programming Languages)', value: 'programming_languages', type: 'text', required: false },
  { label: 'Preferred skills (Operating Systems)', value: 'operating_systems', type: 'text', required: false },
  { label: 'Valid Until', value: 'valid_until_date', type: 'date', required: false },
]

export const defaultCreateValue = {
  company_id: '',
  company_state: '',
  job_title: '',
  vacancy_number: null,
  job_scope: '',
  programming_languages: '',
  operating_systems: '',
  valid_until_date: null
}