import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import { storeLastView } from 'actions/lastView'

const searchParams = [
  { 
    label: 'Name', 
    value: 'name',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      designations: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedDesignation: {},
      searchParams: searchParams,

      selectedDeleteDesignationId: null,
      showCreateDesignationsModal: false,
      showDeleteDesignationModal: false,
      showUpdateDesignationsModal: false,
      showSearchForms: false
    }

    onChangeDesignationsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getDesignations = search => {
      this.props.storeLastView({ search })

      Get(
        `/designations?query=${ search }`,
        this.getDesignationsSuccess,
        this.getDesignationsError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getDesignationsSuccess = payload => this.setState({ designations: payload })
    getDesignationsError = error => this.requestError( error )

    getSelectedDesignation = ( id, context ) => {
      Get(
        `/designations/${ id }`,
        payload => this.getSelectedDesignationSuccess( payload, context ),
        this.getSelectedDesignationError,
        this.load
      )
    }
    getSelectedDesignationSuccess = ( payload, context ) => {
      this.setState({   
        selectedDesignation: payload, 
        showUpdateDesignationsModal: context === 'edit' 
      })
    }
    getSelectedDesignationError = error => this.requestError ( error )

    createDesignation = dataToSubmit => Post(
      `/designations`,
      dataToSubmit,
      this.createDesignationSuccess,
      this.createDesignationError,
      this.load
    )
    createDesignationSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      
      this.getDesignations( search )
      this.setState({ showCreateDesignationsModal: false })
      this.requestSuccess( 'Designations has been created successfully.' )
    }
    createDesignationError = error => this.requestError( error )

    deleteDesignation = id => Delete( 
      `/designations/${ id }`,
      this.deleteDesignationSuccess,
      this.deleteError,
      this.load
    )
    deleteDesignationSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getDesignations( search )
      this.setState({ showDeleteDesignationModal: false })
      this.requestSuccess( 'Designation has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateDesignation = dataToSubmit => Put(
      `/designations/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateDesignationSucces,
      this.updateDesignationError,
      this.load
    )
    updateDesignationSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.setState({ showUpdateDesignationsModal: false })
      this.getDesignations( search )
      this.getSelectedDesignation( payload.id )
      this.requestSuccess( 'Designation was updated successfully.' )
    }
    updateDesignationError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          showSearchForms={ this.state.showSearchForms }
          searchParams={ this.state.searchParams }
          designations={ this.state.designations }
          onLoadDesignation={ this.state.loading }
          selectedDesignation={ this.state.selectedDesignation }
          selectedDeleteDesignationId={ this.state.selectedDeleteDesignationId }
          showCreateDesignationsModal={ this.state.showCreateDesignationsModal }
          showDeleteDesignationModal={ this.state.showDeleteDesignationModal }
          showUpdateDesignationsModal={ this.state.showUpdateDesignationsModal }

          getDesignations={ this.getDesignations }
          getSelectedDesignation={ this.getSelectedDesignation }
          createDesignation={ this.createDesignation }
          deleteDesignation={ this.deleteDesignation }
          updateDesignation={ this.updateDesignation }
          onChangeDesignationsHOC={ this.onChangeDesignationsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC