import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import { 
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormText,
  Card, CardHeader, CardBody, CardFooter
} from 'reactstrap'
import  AdvisorHOC from '../actions/advisor'

import { FormDefaultValue, DefaultFieldData } from '../assets'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { mobileChecker } from 'utils/mobileChecker'

const FormContent = ({
  sessions,
  selectedFormData,
  onLoadAdvisor,
  academicAdvisors,
  academicAdvisorsTotalList,

  getAdvisor,
  onClickSubmit,
  onClickCreate,
  onClickUpdate
}) => {

  const [ studentData, onChangeStudentData ] = useState( FormDefaultValue )
  const academicAdvisorsExist = academicAdvisorsTotalList?.find(o => o.studentName === studentData.name)

  useEffect(() => {
    getAdvisor( btoa(`{"page":1, "per_page": 1000}`) )
  }, [])

  useEffect(() => {
    selectedFormData && onChangeStudentData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeStudentField = ( key, val ) => {
    let tmp = _.cloneDeep( studentData )
    tmp[ key ] = val
    return onChangeStudentData( tmp )
  }

  const debounceMobile = useCallback(_.debounce(( fullData, context, val ) => {
    onChangeStudentData({
      ... fullData,
      [context]: mobileChecker( val )
    })
  }, 500), [])

  return (
    <>
      <Card>
        <CardHeader style={{ fontSize: '1rem' }}>
          Student Details
        </CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault()}> 
            <Row>
              {
                DefaultFieldData({ sessions, academicAdvisors }).map( field => (
                  <Col md={ field.col || 12 } xs={ 12 }>
                    <FormGroup>
                      <Label>
                        { `${ field.label } ` }
                        { field.required && <span className="text-danger">*</span> }
                      </Label>
                      {
                        field.type === 'select' && (
                          <Input
                            type={ 'select' }
                            value={ studentData[ field.value ] }
                            onChange={ e => onChangeStudentField( field.value, field.valueType === 'int' ?  +e.target.value : e.target.value )}
                          >
                            <option value={ null }></option>
                            { 
                              field.options?.[0] && field.options.map( option => (
                                <option key={ option.id } value={ option.id }>{ option.name }</option>
                              ))
                            }
                          </Input>
                        )
                      }
                      {
                        field.type !== 'select' && (
                          <Input
                            type={ field.type }
                            step={ ['cgpa', "at-cgpa"].indexOf( field.value ) > -1 ? 0.01 : 1 }
                            value={ studentData[ field.value ] }
                            onChange={ e => {
                              Promise.all([
                                onChangeStudentField( field.value, e.target.value )
                              ]).then(() => {
                                field.value === 'phone' && debounceMobile( studentData, field.value, e.target.value )
                              })
                            }} />
                        )
                      }
                      { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                    </FormGroup>
                  </Col>
                ))
              }
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Button 
            color="primary"
            className="mr-2 mt-3"
            onClick={() => {
              onClickSubmit({ 
                ... studentData,
                session_id: parseInt( studentData.session_id ),
                accumulated_credits: parseInt( studentData.accumulated_credits ),
                at_cgpa: parseFloat( studentData.at_cgpa ),
                cgpa: parseFloat( studentData.cgpa )
              });
              (_.isEmpty(academicAdvisorsExist)) ?
                onClickCreate(
                  {
                    student_id: studentData.id,
                    academic_advisor_id: studentData.academic_advisor_id
                  }
                ) :
                onClickUpdate(
                  academicAdvisorsExist.id,
                  {
                    student_id: studentData.id,
                    academic_advisor_id: studentData.academic_advisor_id
                  }
                )
            }}>
            Update
          </Button>
        </CardFooter>
      </Card>
      { onLoadAdvisor && <LoadingOverlay />}
    </>
  )
}

export default AdvisorHOC( FormContent );