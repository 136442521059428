import React, { useEffect, useState } from 'react'

import { 
 Modal, 
 ModalHeader,
 ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormText
} from 'reactstrap'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'

const defaultValue = {
  name: '',
  email: '',
  phone: '',
  password: '',
  password_confirm: '',
  role_name: ''
}

const DefaultFieldData = [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true },
  { label: 'Role Name', value: 'role_name', type: 'text', required: true }
]

const FormContent = ({
  mode,
  open,
  onLoadLecturer,
  onClose,
  selectedFormData,
  onClickSubmit
}) => {

  const [ lecturerData, onChangeLecturerData ] = useState( defaultValue )
  
  useEffect(() => {
    !open && onChangeLecturerData( defaultValue )
  }, [open])

  useEffect(() => {
    selectedFormData && onChangeLecturerData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeLecturerField = ( key, val ) => {
    let tmp = _.cloneDeep( lecturerData )
    tmp[ key ] = val

    return onChangeLecturerData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Lecturer` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}> 
          <Row>
            {
              DefaultFieldData.map( field => (
                <Col md={ field.col || 12 } xs={ 12 }>
                  <FormGroup>
                    <Label>
                      { `${ field.label } ` }
                      { field.required && <span className="text-danger">*</span> }
                    </Label>
                    <Input
                      type={ field.type }
                      value={ lecturerData[ field.value ] }
                      onChange={ e => {
                        onChangeLecturerField( field.value, e.target.value )
                      }} />
                    { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
        { onLoadLecturer && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          className="mr-2"
          onClick={() => {
            onClickSubmit( lecturerData )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent;