import React, { useEffect, useState, useRef, useMemo } from 'react'

import { 
  ModalBody,
  CardFooter,
  Button,
  FormGroup,
  Label, Input, Form,
  Card, CardHeader, CardBody
} from 'reactstrap'
import _ from 'lodash'

import { CloseOutline } from 'react-ionicons'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import ConfirmationModal from 'components/Modal/confirmation'

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const Edit = ({
  data,
  onLoadInsurance,
  selectedInsurance,

  updateInsurance,
  uploadInsuranceDoc
}) => {
  const pond = useRef(null)
  
  const [ insuranceFile, onChangeFile ] = useState( null )
  const [ showApprovalConfirmation, onToggleConfirmation ] = useState( false )
  const [ comment, onChangeComment ] = useState(selectedInsurance?.comment)

  let disableCheckbox = useMemo(() => {
    return selectedInsurance?.is_clerk_approved || [ 1, 11, 12 ].indexOf( data.ProfileReducer.current_role_id ) < 0
  }, [selectedInsurance])

  let disableInput = useMemo(() => {
    return [ 1, 11, 12 ].indexOf( data.ProfileReducer.current_role_id ) < 0
  }, [selectedInsurance])

  const onDropFile = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangeFile( fileItems[ 0 ].file )
    }
  }

  const getStudentId = () => {
    if (_.isEmpty(selectedInsurance)) {
      return data.ProfileReducer.profile?.student?.id;
    }
    return selectedInsurance?.student_id;
  }

  return (
    <>
      <ModalBody>
        <Card>
          <CardHeader style={{ fontSize: '1rem' }}>Insurance Doc</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>Approval Comment</Label> 
              <Input
                type={ 'textarea' }
                disabled={ disableInput }
                value={ (_.isEmpty(comment) ? selectedInsurance?.comment : comment) }
                onChange={ e => onChangeComment( e.target.value )}
              />
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input 
                  checked={ selectedInsurance?.is_clerk_approved } 
                  disabled={ disableCheckbox }
                  type="checkbox"
                  onClick={ () => onToggleConfirmation( true )}
                />
                Insurance Doc has been approved.
              </Label>
            </FormGroup>
            {
              !disableInput && (
                <Button 
                  color={ 'primary' }
                  className="mt-3"
                  disabled={ disableInput }
                  onClick={() => 
                    updateInsurance({
                      id: selectedInsurance?.id,
                      is_clerk_approved: selectedInsurance.is_clerk_approved,
                      comment: comment
                    })
                }>
                  Update
                </Button>
              )
            }
            <hr />
            <FormGroup>
              <Label>Uploaded Insurance Doc</Label>
              {
                selectedInsurance?.doc_url && (
                  <div>
                    <strong 
                      style={{ cursor: 'pointer', textDecoration: 'underline', wordBreak: 'break-all' }}
                      className={ 'd-block'}
                      onClick={ () => window.open( selectedInsurance?.doc_url )}
                    >
                      { selectedInsurance?.doc_url }
                    </strong>
                  </div>
                )
              }
              {
                !selectedInsurance?.doc_url && (
                  <p>
                    <strong>No doc is uploaded</strong>
                    <CloseOutline cssClasses={ 'text-danger ml-1' }/>
                  </p>
                )
              }
              <hr />
            </FormGroup>
            <FormGroup>
              <Label>Upload Document</Label>
              <FilePond
                ref={ pond }
                allowMultiple={ false }
                fullWidth
                acceptedFileTypes={['application/pdf']}
                maxFiles={ 1 }
                onremovefile={ () => onChangeFile( null )}
                onupdatefiles={ onDropFile }
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button 
              color={ !insuranceFile ? 'secondary': 'primary' }
              className="mr-2"
              disabled={ !insuranceFile }
              onClick={() => uploadInsuranceDoc(
                getStudentId(),
                insuranceFile,
                () => {
                  pond.current.removeFiles()
                  onChangeFile( null )
                }
              )
            }>
              Upload
            </Button>
          </CardFooter>
        </Card>
        { onLoadInsurance && <LoadingOverlay/> }
      </ModalBody>
      <ConfirmationModal
        loading={ onLoadInsurance }
        open={ showApprovalConfirmation }
        title={ `Approve Insurance` }
        message={ `Are you sure you want to approve this insurance? (This process can't be undone)` }
        onClose={ () => onToggleConfirmation( false )}
        onClickConfirm={ () => {
          Promise.all([
            updateInsurance({
              id: selectedInsurance?.id,
              is_clerk_approved: true 
            })
          ]).then(() => {
            onToggleConfirmation( false )
          })
        }}
      />
    </>
  )
}

export default Edit