import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'
import Cookies from 'js-cookie'
import getDomainURL from 'utils/api'
import Axios from 'axios'
import FileSaver from 'file-saver'
import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Student name', 
    value: 'su.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Company name', 
    value: 'c.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Type', 
    value: 'ief.type',
    type: 'select',
    param: '',
    valueType: 'string',
    options: [
      { id: 'Internal', name: 'Internal' },
      { id: 'External', name: 'External' },
    ]
  },
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      evaluation: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      sessions: [],
      selectedEvaluation: null,
      searchParams: searchParams,

      showCreateEvaluationsModal: false,
      showDeleteEvaluationModal: false,
      showUpdateEvaluationsModal: false,
      showUploadEvaluationGuideline: false,
      showSearchForms: false
    }

    onChangeEvaluationsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getEvaluations = search => {
      this.props.storeLastView({ search })

      Get(
        `/internship_evaluation_forms?query=${ search }`,
        this.getEvaluationsSuccess,
        this.getEvaluationsError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getEvaluationsSuccess = payload => {
      let tempData = []

      payload.data.map(({ internship_evaluation_form, company, user }) => {
        tempData.push({
          ... internship_evaluation_form,
          company_name: company.name,
          user_name: user.name
        })
      })
      this.setState({ evaluation: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getEvaluationsError = error => this.requestError( error )

    getSelectedEvaluation = id => {
      Get(
        `/internship_evaluation_forms/${ id }`,
        this.getSelectedEvaluationSuccess,
        this.getSelectedEvaluationError,
        this.load
      )
    }
    getSelectedEvaluationSuccess = ({ 
      internship_evaluation_form, 
      company, user, session, student,
      student_vacancy_accepted_list,
      student_user,
      external_supervisor,
      internal_supervisor
    }) => {

      this.setState({   
        selectedEvaluation: {
          ... internship_evaluation_form,
          internship: {
            session_id: session.id,
            student_id: student.id,
            user_id: user.id,
            company_id: company.id,
            id: student_vacancy_accepted_list.id,
            student_name: student_user.name,
            company_name: company.name,
            internal_supervisor: internal_supervisor?.name??'N/A',
            external_supervisor: external_supervisor?.name??'N/A',
          }
        }, 
        showUpdateEvaluationsModal: true
      })
    }
    getSelectedEvaluationError = error => this.requestError ( error )

    createEvaluation = dataToSubmit => Post(
      `/internship_evaluation_forms`,
      dataToSubmit,
      this.createEvaluationSuccess,
      this.createEvaluationError,
      this.load
    )
    createEvaluationSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getEvaluations( search )
      this.setState({ showCreateEvaluationsModal: false })
      this.requestSuccess( 'Evaluation Form has been created successfully.' )
    }
    createEvaluationError = error => this.requestError( error )

    deleteEvaluation = id => Delete( 
      `/internship_evaluation_forms/${ id }`,
      this.deleteEvaluationSuccess,
      this.deleteError,
      this.load
    )
    deleteEvaluationSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getEvaluations( search )
      this.setState({ showDeleteEvaluationModal: false })
      this.requestSuccess( 'Evaluation has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateEvaluation = dataToSubmit => Put(
      `/internship_evaluation_forms/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateEvaluationSucces,
      this.updateEvaluationError,
      this.load
    )
    updateEvaluationSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getEvaluations( search )
      this.setState({ showUpdateEvaluationsModal: false })
      this.requestSuccess( 'Evaluation was updated successfully.' )
    }
    updateEvaluationError = error => this.requestError( error )

    getStudentEvaluationForm_pdf = (id, name, type) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/internship_evaluation_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_${type}_EvaluationForm.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    getSessions = search => Get(
      `/sessions?query=${ search }`,
      this.getSessionsSuccess,
      this.getSessionsError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getSessionsSuccess = payload => {
      this.setState({ sessions: payload?.data[0].session })
    }
    getSessionsError = error => this.requestError( error )

    uploadEvaluationGuideline = ( id, dataToSubmit, removeFilePond ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/sessions/evaluation_form_guideline/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'evaluation_form_guideline', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
      Axios.post(url, tempForm, config ).then( payload => {
        this.load( false )
        removeFilePond()
        this.uploadEvaluationGuidelineSuccess( payload?.data?.link??'' )
      }).catch( err => {
        this.load( false )
        this.uploadEvaluationGuidelineError( err )
       })
    }
    uploadEvaluationGuidelineSuccess = link => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getSessions( search )
      this.setState( prevState => ({ 
        sessions: {
          ... prevState.sessions,
          student_evaluation_form_guidelines_url: link ? `${ link }` : ''
        },
        showUploadEvaluationGuideline: false 
      }))
      this.requestSuccess( 'Evaluation Form Guideline was uploaded successfully.' )
    }
    uploadEvaluationGuidelineError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          evaluation={ this.state.evaluation }
          onLoadEvaluation={ this.state.loading }
          selectedEvaluation={ this.state.selectedEvaluation }
          showCreateEvaluationsModal={ this.state.showCreateEvaluationsModal }
          showDeleteEvaluationModal={ this.state.showDeleteEvaluationModal }
          showUpdateEvaluationsModal={ this.state.showUpdateEvaluationsModal }
          showUploadEvaluationGuideline={ this.state.showUploadEvaluationGuideline }
          showSearchForms={ this.state.showSearchForms }
          sessions={ this.state.sessions }
          
          getSessions={ this.getSessions }
          getEvaluations={ this.getEvaluations }
          getSelectedEvaluation={ this.getSelectedEvaluation }
          createEvaluation={ this.createEvaluation }
          deleteEvaluation={ this.deleteEvaluation }
          updateEvaluation={ this.updateEvaluation }
          uploadEvaluationGuideline={ this.uploadEvaluationGuideline }
          getStudentEvaluationForm_pdf={ this.getStudentEvaluationForm_pdf }
          onChangeEvaluationsHOC={ this.onChangeEvaluationsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC