import React, { Component } from 'react'
import { compose } from 'redux'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Button, UncontrolledTooltip, Collapse,
  Modal, ModalHeader, ModalBody,
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import EditForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import ReportHOC from './actions'

class Report extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getReport( btoa(`{"page":1}`) )
    }
  }

  renderNonStudentContent = () => {
    const { is_update } = this.props.currentPermission

    return (
      <>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    this.props.dutyReports.data.length < 1? 3: 
                    this.props.dutyReports.data.length < 10? this.props.dutyReports.data.length:
                    10
                  }
                  data={ this.props.dutyReports.data }
                  columns={[
                    {
                      Header: 'Student Name',
                      accessor: 'student_name'
                    },
                    {
                      Header: 'Student Email',
                      accessor: 'student_email'
                    },
                    {
                      Header: 'Company',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'External Supervisor',
                      accessor: 'external_sv'
                    },
                    {
                      Header: 'Is Checked',
                      accessor: 'is_checked',
                      Cell: (row) => (
                        row.original.is_checked 
                          ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                          : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
                      )
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="UpdateReport"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_update ? "primary" : 'secondary' }
                              disabled={ !is_update }
                              onClick={() => {
                                this.props.getSelectedReport( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="UpdateReport" placement="left">Update the information of this internship duty report </UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={false} />
                <Pagination 
                  metadata={ this.props.dutyReports.meta } 
                  onChangePage={ pg => {
                    const { search } = this.props.data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    this.props.getReport( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
        isOpen={ this.props.showUpdateReportModal }
        size={ 'xl' }>
        <ModalHeader toggle={() => this.props.onChangeReportHOC( 'showUpdateReportModal', false )}>
          { `Edit Internship Duty Report` }
        </ModalHeader>
        <ModalBody>
          <EditForm
            selectedReport={ this.props.selectedReport }
            onLoadReport={ this.props.onLoadReport }
            onClickSubmit={ this.props.updateReport }
          />
        </ModalBody>
      </Modal>
      </>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Internship Duty Report"
          subheading="Listings of all the internship duty report present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeReportHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeReportHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeReportHOC }
              getListAPI={ this.props.getReport }
            />
          </Collapse>
          {
            this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent()
          }
          {
            this.props.data.ProfileReducer.current_role_id === 13 &&  (
              <>
                {
                  _.keys( this.props.selectedReport ).length > 0 && (
                    <EditForm
                      selectedReport={ this.props.selectedReport }
                      onLoadReport={ this.props.onLoadReport }
                      onClickSubmit={ this.props.updateReport }
                    />
                  )
                }
                {
                  _.keys( this.props.selectedReport ).length < 1 && (
                    <Card>
                      <CardHeader>No Internship Duty Report is available. </CardHeader>
                    </Card>
                  )
                }
              </>
            )
          }
          <ConfirmationModal
            loading={ this.props.onLoadReport }
            open={ this.props.showDeleteReportModal}
            title={ `Delete Internship Duty Report` }
            message={ `Are you sure you want to delete this internship duty report? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeReportHOC( 'showDeleteReportModal', false )}
            onClickConfirm={ () => this.props.deleteReport( this.props.selectedReport.id )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadReport && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  ReportHOC
)(Report)