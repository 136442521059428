import React, { useEffect, useState } from 'react'
import { CloseOutline } from 'react-ionicons'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup, Label, Input,
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import NonStudentContent from './nonStudent'

import UserHOC from '../actions/users'

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"
import { useRef } from 'react'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType )
 
const defaultValue = {
  external_supervisor_id: '',
  internal_supervisor_id: '',
  is_duty_report_check: true,
  is_external_evaluation_form_checked: true,
  is_external_final_report_checked: true,
  final_report_url: '',
  is_internal_evaluation_form_checked: true,
  is_internal_final_report_checked: true,
  is_placement_form_check: true
}

const FormContent = props => {
  const {
    open,
    data,
    selectedAcceptedVacancies,
    onLoadStudentAcceptedVacancies,
    
    onClose,
    onChangeUserHOC,
    getSelectedLecturer,
    getAllCompanyUsers,
    onClickSubmit,
    onClickUpload,
  } = props
  const pond = useRef(null)
  
  const [ formData, onChangeFormData ] = useState( defaultValue )

  const [ finalReport, onChangeFinalReport ] = useState( defaultValue )

  useEffect(() => {
    if ( open ){
      selectedAcceptedVacancies.internal_supervisor_id && getSelectedLecturer( selectedAcceptedVacancies.internal_supervisor_id )
      
      data.ProfileReducer.current_role_id !== 13  && 
      selectedAcceptedVacancies.company_id && 
      getAllCompanyUsers( btoa(`{"page":1, "per_page": 1000, "filter": { "company_id": ${ selectedAcceptedVacancies.company_id } }}`) )    
    } else {
      onChangeUserHOC( 'lecturers', [] )
    }
  }, [ open ])

  useEffect(() => {
    selectedAcceptedVacancies && onChangeFormData( selectedAcceptedVacancies )
  }, [ selectedAcceptedVacancies ])

  const onChangeAcceptedVacanciesField = ( key, val ) => {
    let tmp = _.cloneDeep( formData )
    tmp[ key ] = val
    return onChangeFormData( tmp )
  }

  const onDropFinalReport = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangeFinalReport( fileItems[ 0 ].file )
    }
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Edit Internship` }</ModalHeader>
      <ModalBody>
        {
          data.ProfileReducer.current_role_id === 13  && (
            <>
              <FormGroup>
                <Label>Final Report Url</Label>
                {
                  formData.final_report_url && (
                    <strong 
                      style={{ cursor: 'pointer', textDecoration: 'underline', wordBreak: 'break-all' }}
                      className={ 'd-block'}
                      onClick={ () => window.open( formData.final_report_url)}

                    >
                      { formData.final_report_url }
                    </strong>
                  )
                }
                {
                  !formData.final_report_url && (
                    <p>
                      <strong>No report is uploaded</strong>
                      <CloseOutline cssClasses={ 'text-danger ml-1' }/>
                    </p>
                  )
                }
              </FormGroup>
              <Row>
                <Col lg={ 6 }>
                  <FormGroup>
                    <Label>External Supervisor</Label>
                    <br />
                    <strong>{ selectedAcceptedVacancies?.external_supervisor_name??'' }</strong>
                  </FormGroup>
                </Col>
                <Col lg={ 6 }>
                  <FormGroup>
                    <Label>Internal Supervisor</Label>
                    <br />
                    <strong>{ selectedAcceptedVacancies?.internal_supervisor_name??'' }</strong>
                  </FormGroup>
                </Col>
                <Col lg={ 12 }>
                  <hr />
                </Col>
                <Col lg={ 6 }>
                  <FormGroup>
                    <Label>Upload Final Report</Label>
                    <FilePond
                      ref={ pond }
                      allowMultiple={ false }
                      fullWidth
                      acceptedFileTypes={["application/pdf"]}
                      maxFiles={ 1 }
                      onremovefile={ () => onChangeFinalReport( null )}
                      onupdatefiles={ fileItems => onDropFinalReport( fileItems ) }/>
                  </FormGroup>
                </Col>
              </Row>
            </>
          )
        }
        {
          data.ProfileReducer.current_role_id !== 13  && (
            <NonStudentContent 
              { ... props }
              formData={ formData }
              onChangeAcceptedVacanciesField={ onChangeAcceptedVacanciesField }
            />
          )
        }
      </ModalBody>
      <ModalFooter>
        {
          data.ProfileReducer.current_role_id !== 13  && (
            <Button 
              color="primary"
              onClick={() => onClickSubmit({
                ... formData,
                external_supervisor_id: parseInt( formData.external_supervisor_id ),
                internal_supervisor_id: parseInt( formData.internal_supervisor_id ),
              })}>
              Submit
            </Button>
          )
        }
        {
          data.ProfileReducer.current_role_id === 13  && (
            <Button 
              color={ finalReport ? 'primary' : 'secondary' }
              disabled={ !finalReport }
              onClick={() => onClickUpload( formData.id, finalReport, pond )}
            >
              Upload
            </Button>
          )
        }
      </ModalFooter>
      {( onLoadStudentAcceptedVacancies )&& <LoadingOverlay/> }
    </Modal>
  )
}

export default UserHOC( FormContent )