import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import AboutMeContent from 'containers/Dashboards/StudentCV/Edit/AboutMe'
import EducationContent from 'containers/Dashboards/StudentCV/Edit/Education'
import ExperienceContent from 'containers/Dashboards/StudentCV/Edit/Experiences'
import SkillsContent from 'containers/Dashboards/StudentCV/Edit/Skills'
import LanguageContent from 'containers/Dashboards/StudentCV/Edit/LanguageContent'

export default function StudentCVContent ({
  open,
  onClose,
  selectedStudentCV
}) {
  return (
    <>
      <Modal
        isOpen={ open }
        size={ 'xl' }>
        <ModalHeader toggle={() => onClose()}>{ `${ selectedStudentCV?.student_user?.name??'' }'s CV ` }</ModalHeader>
        <ModalBody>
          {
            !selectedStudentCV && (
              <div 
                style={{ minHeight: 300 }}
                className={ 'd-flex  align-items-center justify-content-center' }>
                <h5>Student's CV is not created yet.</h5>
              </div>
            )
          }
          {
            selectedStudentCV && (
              <>
                <AboutMeContent 
                  selectedStudentCV={ selectedStudentCV }
                  mode={ 'view' }
                />
                <EducationContent 
                  selectedStudentCV={ selectedStudentCV }
                  mode={ 'view' }
                />
                <ExperienceContent 
                  selectedStudentCV={ selectedStudentCV }
                  mode={ 'view' }
                />
                <SkillsContent 
                  selectedStudentCV={ selectedStudentCV }
                  mode={ 'view' }
                />
                <LanguageContent 
                  selectedStudentCV={ selectedStudentCV }
                  mode={ 'view' }
                />
              </>
            )
          }
        </ModalBody>
      </Modal>
    </>
  )
}
