import React, { Fragment } from 'react'
import { Input } from 'reactstrap'
import _ from 'lodash'

const GradeOption = [ 
  { label: 'A', val: 4.00 }, 
  { label: 'A-', val: 3.67 }, 
  { label: 'B+', val: 3.33 }, 
  { label: 'B', val: 3.00 }, 
  { label: 'B-', val: 2.67 }, 
  { label: 'C+', val: 2.33 }, 
  { label: 'C', val: 2.00 }, 
  { label: 'C-', val: 1.67 }, 
  { label: 'D+', val: 1.33 }, 
  { label: 'D', val: 1.00 }, 
  { label: 'D-', val: 0.67 }, 
  { label: 'F', val: 0.00 }  
]

export default function ExternalEvaluation ({
  tempJSON
}) {
  return (
    <>
       <table className={ 'table mt-3 border border' }>
          <thead>
            <tr>
            <th>Evaluation Criteria</th>
              <th> Grade by Industrial S/visor</th>
              <th>For internal use only</th>
            </tr>
          </thead>
          <tbody>
            {
              tempJSON.data.map(( table, tableIndex ) => (
                <Fragment key={ tableIndex }>
                  <tr style={{ backgroundColor: 'rgba( 0, 0, 0, 0.1 )'}}>
                    <td style={{ fontWeight: 600, fontSize: '0.85rem' }} colSpan={ 3 }>{ table.name }</td>
                  </tr>
                  {
                    table.children.map(( row, rowIndex ) => (
                      <>
                        <tr>
                          <td>{ row.name }</td>
                          <td>
                            <Input
                              type={ 'select' }
                              disabled={ true }
                              value={ row.grade }
                            >
                              {
                                GradeOption.map( item => (
                                  <option key={ item.val } value={ item.label }>{ item.label }</option>
                                ))
                              }
                            </Input>
                          </td>
                          <td>
                            <Input
                              type={ 'number' }
                              value={ row.marks.toFixed( 2 ) }
                              disabled={ true }
                            />
                          </td>
                        </tr>
                      </>
                    ))
                  }
                </Fragment>
              ))
            }
          </tbody>
          <tfoot>
            <tr className={ 'bg-secondary text-white' }>
              <td style={{ fontWeight: 600, textAlign: 'end', fontSize: '0.9rem' }} colSpan={ 2 }>
                { `Total (For internal use only)`}
              </td>
              <td style={{ fontWeight: 600, fontSize: '1.3rem', textAlign: 'end' }}>{ tempJSON.total.toFixed( 2 ) }</td>
            </tr>
          </tfoot>
        </table>
    </>
  )
}