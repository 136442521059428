import React, { 
  useState, useCallback, 
  useEffect, useMemo, useRef
} from 'react';
import { 
  Row, Col,
  Button,
  Form, FormGroup, Input, Label, FormText,
  Card, CardHeader, CardBody, CardFooter
} from 'reactstrap'
import { GrView, GrFormViewHide } from 'react-icons/gr'
import _ from 'lodash'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import { CloseOutline } from 'react-ionicons'
import SessionTypeahead from 'components/Typeahead/session'
import { PulseLoader } from 'react-spinners';
import { mobileChecker } from 'utils/mobileChecker'
import ConfirmationModal from 'components/Modal/confirmation'
import { FormDefaultValue, DefaultFieldData } from '../../asset'

const StudentDetailsContent = ({
  selectedFormData,
  sessions,
  onLoadSession,
  onChangeSessionsHOC,
  
  updateStudent,
  submitCangred,
  getSessions
}) => {

  const [ studentData, onChangeStudentData ] = useState( FormDefaultValue )
  const [ approvalConfirmation, setApprovalConfirmation ] = useState( false )
  const [ showPassword, onChangeShowPassword ] = useState(false)

  let disableApproval = useMemo(() => {
    return selectedFormData.is_eligible_intern
  }, [ selectedFormData ])

  const [ tempCangred, onChangeFile ] = useState( null )

  let pondRef = useRef( null )

  const onDropFile = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangeFile( fileItems[ 0 ].file )
    }
  }

  useEffect(() => {
    if ( selectedFormData ){
      let temp = _.cloneDeep( selectedFormData )

      selectedFormData?.session_id && getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ selectedFormData.session_id } }}`))
      delete temp.role_id

      onChangeStudentData( temp )
    }
  }, [ selectedFormData ])

  const onChangeTypeaheadSearch = useCallback( _.debounce(
    val => {
      getSessions( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sessions.name": {"$like": "%${ val }%" } }}`))
    }, 600 ), []
  );

  const debounceMobile = useCallback(_.debounce(( fullData, val ) => {
    onChangeStudentData({
      ... fullData,
      phone: mobileChecker( val )
    })
  }, 500), [])

  const onChangeStudentField = ( key, val ) => {
    let tmp = _.cloneDeep( studentData )
    tmp[ key ] = val
    return onChangeStudentData( tmp )
  }

  return (
    <>
      <Card>
        <CardHeader style={{ fontSize: '1rem' }}>
          Student Approval
        </CardHeader>
        <CardBody>
          <Card>
            <CardBody>
              <FormGroup>
                <Label>Cangred Url</Label>
                {
                  selectedFormData.cangred_pdf_url && (
                    <strong 
                      style={{ cursor: 'pointer', textDecoration: 'underline', wordBreak: 'break-all' }}
                      className={ 'd-block'}
                      onClick={ () => window.open( selectedFormData.cangred_pdf_url )}

                    >
                      { selectedFormData.cangred_pdf_url }
                    </strong>
                  )
                }
                {
                  !selectedFormData.cangred_pdf_url && (
                    <p>
                      <strong>No cangred is uploaded</strong>
                      <CloseOutline cssClasses={ 'text-danger ml-1' }/>
                    </p>
                  )
                }
              </FormGroup>
              <hr />
              <FormGroup>
                <FilePond
                  allowMultiple={ false }
                  fullWidth
                  ref={ pondRef }
                  acceptedFileTypes={[ "application/pdf"]}
                  maxFiles={ 1 }
                  onremovefile={ () => onChangeFile( null )}
                  onupdatefiles={ fileItem => onDropFile( fileItem ) }
                />
              </FormGroup>
            </CardBody>
            <CardFooter>
              <Button 
                color={ tempCangred ? 'primary' : 'secondary' } 
                disabled={ !tempCangred }
                className="mr-2 mt-3"
                onClick={ () => submitCangred( selectedFormData.id, tempCangred, pondRef )}>
                Submit
              </Button>
            </CardFooter>
          </Card>
          <Card className={ 'mt-3' }>
            <CardBody>
              <FormGroup check>
                <Label check>
                  <Input 
                    disabled={ disableApproval }
                    checked={ studentData.is_eligible_intern } 
                    type="checkbox" 
                    onChange={ e => {
                      e.target.checked 
                        ? setApprovalConfirmation(true )
                        : onChangeStudentField( 'is_eligible_intern', false )
                    }}
                  />
                  Eligible for Intern
                </Label>
              </FormGroup>
              <hr />
              <FormGroup>
                <Label>Egligible Comments</Label>
                <Input
                  type={ 'textarea' }
                  rows={ 6 }
                  disabled={ disableApproval }
                  value={ studentData.ineligible_intern_comment }
                  onChange={ e => onChangeStudentField( 'ineligible_intern_comment', e.target.value )}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <Card className='mt-3'>
        <CardHeader style={{ fontSize: '1rem' }}>
          Student Details
        </CardHeader>
        <CardBody>
          <Form onSubmit={ e => e.preventDefault()}> 
            <Row>
              {
                DefaultFieldData.map( field => (
                  <Col md={ field.col || 12 } xs={ 12 }>
                    <FormGroup>
                      <Label>
                        {
                          field.type === 'password' ? (
                            <div style={{ display: 'flex' }}>
                              {`${field.label} `}
                              <div
                                style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                                onClick={() => onChangeShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <GrView style={{ width: '100%', height: '100%' }} />
                                ) : (
                                  <GrFormViewHide style={{ width: '100%', height: '100%' }} />
                                )}
                              </div>
                            </div>
                          ): (
                            <div>
                              { `${ field.label } ` }
                              { field.required && <span className="text-danger mr-2">*</span> }
                              {
                                field.value === 'session_id' && (
                                  <PulseLoader  
                                    sizeUnit={"px"}
                                    size={ 10 }
                                    color={ '#16232c' }
                                    loading={ onLoadSession } 
                                  />
                                )
                              }
                            </div>
                          )
                        }
                      </Label>
                      {
                        field.type === 'select' && (
                          <Input
                            type={ 'select' }
                            value={ studentData[ field.value ] }
                            onChange={ e => onChangeStudentField( field.value, e.target.value )}
                          >
                            <option value={ null }></option>
                            { 
                              field.options?.[0] && field.options.map( option => (
                                <option key={ option.value } value={ option.value }>{ option.name }</option>
                              ))
                            }
                          </Input>
                        )
                      }
                      {
                        field.type === 'typeahead' && (
                          <SessionTypeahead
                            options={ sessions }
                            onSelect={ val => {
                              if ( val?.[0]?.id ){
                                onChangeStudentField( field.value, val[0].id )
                              }
                            }}
                            selectedValue={ 
                              studentData?.session_id && _.find( sessions, { id: studentData?.session_id })
                                ? [ _.find( sessions, { id: studentData?.session_id })] 
                                : [] 
                            }
                            onSearch={ val => {
                              onChangeStudentField( 'session_id', null )
                              val.length > 0
                                ? onChangeTypeaheadSearch( val )
                                : onChangeSessionsHOC( 'sessions', [] )
                            }}
                            filterBy={ 'name' }
                            disableEdit={ onLoadSession }
                            helpText={ 'Please enter session name to search.' }
                          />
                        )
                      }
                      {
                        [ 'select', 'typeahead' ].indexOf( field.type ) < 0 && (
                          <Input
                            type={ field.type === 'password'? (showPassword ? 'text' : 'password'): field.type }
                            step={ ['cgpa', "at-cgpa"].indexOf( field.value ) > -1 ? 0.01 : 1 }
                            value={ studentData[ field.value ] }
                            onChange={ e => {
                              Promise.all([
                                onChangeStudentField( field.value, e.target.value )
                              ]).then(() => {
                                field.value === 'phone' && debounceMobile( studentData, e.target.value )
                              })
                            }} />
                        )
                      }
                      { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                    </FormGroup>
                  </Col>
                ))
              }
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <Button 
            color="primary"
            className="mt-4"
            onClick={() => updateStudent({ 
              ... studentData,
              session_id: parseInt( studentData.session_id ),
              accumulated_credits: parseInt( studentData.accumulated_credits ),
              at_cgpa: parseFloat( studentData.at_cgpa ),
              cgpa: parseFloat( studentData.cgpa )
            })}>
            Submit
          </Button>
        </CardFooter>
      </Card>
      <ConfirmationModal
        open={ approvalConfirmation }
        title={ `Eligible Intern` }
        message={ `Are you sure you want to set this student's intern eligibility to 'Approved'? (Once saved, it cannot be revert.)` }
        onClose={ () => setApprovalConfirmation( false )}
        onClickConfirm={ () => {
          onChangeStudentField( 'is_eligible_intern', true )
          setApprovalConfirmation( false )
        }}/>
    </>
  )
}

export default StudentDetailsContent;