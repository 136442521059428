export const FormDefaultValue = {
  type: null,
  general_comments: '',
  is_student_passed: false,
  is_recommend_industrial_training_excellence: false,
  is_best_intern_awards: false,
  is_confirmed_submission: false,
}

export const FieldData = [
  { label: `Supervisor's General comments`, value: 'general_comments', type: 'textarea' },
  { label: 'Do you recommend the student a Pass ? ', value: 'is_student_passed', type: 'checkbox' },
  { label: 'Do you recommend the student for the Industrial Training Certificate of Excellence? ', value: 'is_recommend_industrial_training_excellence', type: 'checkbox' },
  { label: 'Was awarded as best intern', value: 'is_best_intern_awards', type: 'checkbox' },
  { label: 'Is Submission confirmed', value: 'is_confirmed_submission', type: 'checkbox' },
  // { label: '', value: 'Final total_marks' },
]