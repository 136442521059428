import React from 'react'
import _ from 'lodash'
import { useEffect, useState, useMemo, useCallback } from 'react'
import { 
 Modal, 
 ModalHeader,
 ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormText,
} from 'reactstrap'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { mobileChecker } from 'utils/mobileChecker'

const UserFieldData = [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true },
]

const defaultValue = {
  role_id: 14,
  name: '',
  email: '',
  phone: '',
  password: '',
  password_confirm: ''
}

const UserFormUI = ({
  open,
  context,
  selectedUser,
  onLoadUser,
  externalSV,
  selectedCompany,

  onClose,
  getExternalSV,
  onClickSave
}) => {
  
  const [ userForm, onChangeUserForm ] = useState( defaultValue )
  const [ existingUserId, onChangeUser ] = useState( null )
  const [ existingUser, onToggleExistingUser ] = useState( false )

  useEffect(() => {

    open && getExternalSV()

    if ( !open ){
      onChangeUserForm( defaultValue )
      onToggleExistingUser( false )
      onChangeUser( null )
    }

    if ( selectedUser && open ){
      let tempForm = {}
      _.keys( defaultValue ).map( key => {
        tempForm[key] = selectedUser[ key ] === undefined 
          ? ''
          : selectedUser[ key ]
      })
      onChangeUserForm({
        ... tempForm,
        id: selectedUser.id,
        company_id: selectedUser.company_id
      })
    }
  }, [open, selectedUser ])

  const onChangeUserField = ( key, val ) => {
    let tmp = _.cloneDeep( userForm )
    tmp[ key ] = val

    return onChangeUserForm( tmp )
  }

  const debounceMobile = useCallback(_.debounce(( fullData, val ) => {
    onChangeUserForm({
      ... fullData,
      phone: mobileChecker( val )
    })
  }, 500), [])

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ context } User Info` }</ModalHeader>
      <ModalBody>
        <Row>
          {/* <Col md={ 12 }>
            <FormGroup check className='mb-2'>
              <Label check className={ 'mb-1'}>
                <Input 
                  checked={ existingUser } 
                  disabled
                  type="checkbox"
                  onChange={ e => onToggleExistingUser( e.target.checked )}
                />
                Is Existing User
              </Label>
            </FormGroup>
          </Col> */}
          {
            existingUser && (
              <Col md={ 12 }>
                <FormGroup>
                  <Label>
                   External Supervisor User
                  </Label>
                  <Input
                    type={ 'select' }
                    value={ existingUserId }
                    onChange={ e => onChangeUser( e.target.value )}
                  >
                    <option></option>
                    { externalSV.map( option => (
                      <option key={ option.id } value= { option.id }>{ option.name || '' }</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            )
          }
          {
            !existingUser && (
              <>
              {
                UserFieldData.map( field => {
                  return (
                    <Col md={ field.col || 12 } xs={ 12 } key={ field.value }>
                      <FormGroup>
                        <Label>
                          { `${ field.label } ` }
                          { field.required && <span className="text-danger">*</span> }
                        </Label>
                        <Input
                          type={ field.type }
                          step={ field.value === 'cgpa' ? 0.01 : 1 }
                          value={ userForm[ field.value ] }
                          onChange={ e => {
                            Promise.all([
                              onChangeUserField( field.value, e.target.value )
                            ]).then(() => {
                              field.value === 'phone' && debounceMobile( userForm, e.target.value )
                            })
                          }} />
                        { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                      </FormGroup>
                    </Col>
                  )
                })
              }
              </>
            )
          }
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ 'primary' }
          className="mr-2"
          onClick={() => onClickSave(
            existingUser,
            existingUser 
              ? {
                company_id: selectedCompany?.company?.id,
                user_id: existingUserId
              }
              : {
                company_id: userForm.company_id,
                id: userForm.id,
                email: userForm.email,
                phone: userForm.phone,
                role_id: userForm.role_id,
                name: userForm.name,
                ... ( userForm.password?.[0] || userForm.password?.[0] )
                  ? { 
                    password: userForm.password,
                    password_confirm: userForm.password_confirm
                  }
                  : {}
              }
          )}>
          Save
        </Button>
      </ModalFooter>
      { onLoadUser && <LoadingOverlay/> }
    </Modal>
  )
}

export default UserFormUI;