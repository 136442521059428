export const cardData = [
  {
    label: 'Vacancies Details',
    fields: [
      { label: 'Vacancy Poster', value: 'poster_url', type: 'img', col: 12 },
      { label: 'Job Title', value: 'job_title' },
      { label: 'No. of positions available', value: 'vacancy_number' },
      { label: 'Valid Until', value: 'valid_until_date' },
      { label: 'Job Scope', value: 'job_scope', type: 'textarea', col: 12 },
      { label: 'Company', value: 'company_name' },
      { label: 'Company State', value: 'company_state' },
      { label: "Company's Website", value: 'website', },
      { label: 'Preferred skills (Programming Languages)', value: 'programming_languages' },
      { label: 'Preferred skills (Operating Systems)', value: 'operating_systems' },
      { label: 'Company Background', value: 'description', type: 'textarea', col: 12 },
    ]
  },
  {
    label: 'Contact Person ( Management Division )',
    fields: [
      { 
        label: "Contact Person's Designation",
        value: 'contact_person_designation_id',
        type: 'select',
        col: 4
      },
      { 
        label: "Contact Person's Name",
        value: 'contact_person_name',
        col: 8
      },
      { 
        label: "Email Address",
        value: 'contact_person_email',
      }
    ]
  }
]

export const defaultValue = {
  company_id: '',
  company_state: '',
  job_title: '',
  vacancy_number: null,
  job_scope: '',
  programming_languages: '',
  operating_systems: '',
  website: '',
  description: '',
  contact_person_designation_id: '',
  contact_person_name: '',
  contact_person_email: '',
}