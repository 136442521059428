import React, { useEffect, useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const Update = props => {
  return (
    <Modal
      isOpen={ props.showUpdateCourse }
      size={ 'xl' }>
      <ModalHeader toggle={() => props.onChangeCoursesHOC( 'showUpdateCourse', false )}>Update course</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>
              Name
              <span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              value={ props.selectedCourse.name }
              onChange={ e => {
                let tmp = _.cloneDeep( props.selectedCourse )
                tmp[ 'name' ] = e.target.value
                tmp[ 'session_id' ] = props.data.SessionReducer.current_selected_session_id
                return props.onChangeCoursesHOC( 'selectedCourse', tmp )
              }} />
          </FormGroup>
        </Form>
        { props.onLoadCourses && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => props.updateCourse( props.selectedCourse )}>Submit</Button>
      </ModalFooter>
    </Modal>
  )
}

export default Update