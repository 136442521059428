import React, { useEffect } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Button, UncontrolledTooltip, Collapse,
  ModalHeader, Modal
} from 'reactstrap'
import ReactTable from 'react-table'
import Moment from 'moment'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateOfferLetter from './Create'

import WithOfferLetter from './actions'
import getBackendURL from 'utils/backendUrl'

const OfferLetter = props => {
  useEffect(() => {
    props.getOfferLetters( props.data.ProfileReducer.profile.student.id )
  }, [])
  return (
    <>
      <PageTitle
        heading="Offer Letters"
        subheading="Listings of all the offer letters present in the system."
        icon="pe-7s-culture icon-gradient bg-happy-itmeo"
        buttons={[
          {
            color: 'success',
            className: 'btn-icon mr-2',
            onClick: () => {
              props.onChangeOfferLetterHOC( 'showCreateOfferLetter', true )
            },
            content:<>
              {/* <i className="pe-7s-search btn-icon-wrapper"> </i> */}
              <span>Upload</span>
            </>
          }
        ]}
      />
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    props.offerLetters.length < 1? 3: 
                    props.offerLetters.length < 10? props.offerLetters.length:
                    10
                  }
                  data={ props.offerLetters }
                  columns={[
                    {
                      Header: 'URL',
                      width: 500,
                      Cell:(row) => {
                        return <a onClick={() => {
                          window.open( `${ getBackendURL() }/${ row.original.url }`, "_blank" )
                        }} style={{ cursor: 'pointer' }}>{ `${ getBackendURL() }/${ row.original.url }` }</a>
                      }
                    },
                    {
                      Header: 'Created at',
                      Cell:(row) => {
                        return (
                          <span>
                            { Moment( row.original.created_at ).format( 'DD-MM-yyyy h:mm a' ) }
                          </span>
                        )
                      }
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="DeleteOfferLetter"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ 'danger' }
                              onClick={ () => {
                                props.removeOfferLetter( row.original.id )
                              }}>
                              <i className="pe-7s-close btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="DeleteOfferLetter" placement="left">Delete this offer letter</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={ false } />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
      <CreateOfferLetter { ...props } />
      { props.onLoadPlacement && <LoadingOverlay />}
    </>
  )
}

export default WithOfferLetter( OfferLetter )