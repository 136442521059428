import { GET_DICTIONARY_ROLE, GET_DESIGNATION } from 'actions/type'

const initialState = {
  roles: [],
  designations: []
}

export default ( state = initialState, action ) => {
  switch ( action.type ) {

    case GET_DICTIONARY_ROLE: return ({
      ... state,
      roles: action.payload.data
    })

    case GET_DESIGNATION: return ({
      ... state,
      designations: action.payload.data
    })

    default: return state
  }
}
