import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Name', 
    value: 'users.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Email', 
    value: 'users.email',
    type: 'text',
    param: ''
  },
  { 
    label: 'Phone', 
    value: 'users.phone',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      lecturers: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedLecturer: {},
      searchParams: searchParams,

      showImportLecturersModal: false,
      selectedDeleteLecturerId: null,
      showCreateLecturersModal: false,
      showDeleteLecturerModal: false,
      showUpdateLecturersModal: false,
      showSearchForms: false
    }

    onChangeLecturersHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    //getters
    getLecturers = search => {
      this.props.storeLastView({ search })

      Get(
        `/lecturers?query=${ search }`,
        this.getLecturersSuccess,
        this.getLecturersError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getLecturersSuccess = payload => {
      let tempData = []

      payload?.data?.[0] && payload.data.map(({ lecturer, user }) => {
        let tempMerge = _.merge( user, lecturer )
        tempData.push( tempMerge )
      })
      this.setState({ lecturers: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getLecturersError = error => this.requestError( error )

    getSelectedLecturer = id => {
      Get(
        `/lecturers/${ id }`,
        this.getSelectedLecturerSuccess,
        this.getSelectedLecturerError,
        this.load
      )
    }
    getSelectedLecturerSuccess = ({ lecturer, user }) => {
      let tempMerge = _.merge( user, lecturer )
      
      this.setState({ 
        selectedLecturer: tempMerge, 
        showUpdateLecturersModal: true 
      })
    }
    getSelectedLecturerError = error => this.requestError ( error )

    importLecturers = dataToSubmit => Post(
      `/lecturers/import`,
      {
        "data": dataToSubmit
      },
      this.importLecturersSuccess,
      this.importLecturersError,
      this.load
    )
    importLecturersSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.setState({ showImportLecturersModal: false })
      this.getLecturers( search )
    }
    importLecturersError = error => this.requestError( error )

    createLecturer = dataToSubmit => Post(
      `/lecturers`,
      dataToSubmit,
      this.createLecturerSuccess,
      this.createLecturerError,
      this.load
    )
    createLecturerSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getLecturers( search )
      this.setState({ showCreateLecturersModal: false })
      this.requestSuccess( 'Lecturers has been created successfully.' )
    }
    createLecturerError = error => this.requestError( error )

    deleteLecturer = id => Delete( 
      `/lecturers/${ id }`,
      this.deleteLecturerSuccess,
      this.deleteError,
      this.load
    )
    deleteLecturerSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getLecturers( search )
      this.setState({ showDeleteLecturerModal: false })
      this.requestSuccess( 'Lecturer has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateLecturer = dataToSubmit => Put(
      `/lecturers/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateLecturerSucces,
      this.updateLecturerError,
      this.load
    )
    updateLecturerSucces = payload => {
      this.getSelectedLecturer( payload.lecturer.id )
      const { search } = this.props.data.LastViewReducer.lastView

      this.getLecturers( search )
      this.requestSuccess( 'Lecturer was updated successfully.' )
    }
    updateLecturerError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          lecturers={ this.state.lecturers }
          onLoadLecturer={ this.state.loading }
          selectedLecturer={ this.state.selectedLecturer }
          selectedDeleteLecturerId={ this.state.selectedDeleteLecturerId }
          showSearchForms={ this.state.showSearchForms }
          showCreateLecturersModal={ this.state.showCreateLecturersModal }
          showDeleteLecturerModal={ this.state.showDeleteLecturerModal }
          showUpdateLecturersModal={ this.state.showUpdateLecturersModal }
          showImportLecturersModal={ this.state.showImportLecturersModal }

          getLecturers={ this.getLecturers }
          getSelectedLecturer={ this.getSelectedLecturer }
          createLecturer={ this.createLecturer }
          deleteLecturer={ this.deleteLecturer }
          updateLecturer={ this.updateLecturer }
          importLecturers={ this.importLecturers }
          onChangeLecturersHOC={ this.onChangeLecturersHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC