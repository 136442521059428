import React, { useEffect, useState } from 'react'
import { 
  Row,
  Col,
  Button,
  Form, FormGroup, Label, Input,
  UncontrolledTooltip,
  Card, CardHeader,CardBody,
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TaskForm from './TaskForm'
import ConfirmationModal from 'components/Modal/confirmation'
import Pagination from 'components/Pagination'

import TaskHOC from '../actions/task'

const defaultData = {
  company_name: '',
  student_name: '',
  student_matric: ''
}

const FieldData = [
  { label: 'Company Name', value: 'company_name' },
  { label: 'Student Name', value: 'student_name' },
  { label: 'Student Matric', value: 'student_matric' }
]

const FormContent = ({
  onLoadSkill,
  onLoadJobTask,
  selectedJobScope,
  showCreateTaskModal,
  showUpdateTaskModal,
  selectedJobTask,
  showDeleteTaskModal,
  jobTask,

  getTasks,
  onChangeJobTaskHOC,
  getSelectedJobTask,
  createJobTask,
  updateJobTask,
  deleteJobTask
}) => {

  const [ jobScopeData, onChangeJobScopeData ] = useState( defaultData )

  useEffect(() => { 
    getTasks( btoa(`{"page":1, "filter": { "jft.student_job_scope_form_id": ${ selectedJobScope.id } }}`) )
    onChangeJobScopeData( selectedJobScope )
  }, [])

  return (
    <Card>
      <CardHeader style={{ fontSize: '1rem' }}> { `Student Job Scope` }</CardHeader>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              FieldData.map( data => (
                <Col lg={ 12 }>
                  <FormGroup>
                    <Label>{ data.label }</Label>
                    <Input
                      type={ 'text' }
                      value={ jobScopeData[data.value] }
                      disabled={ true }
                    />
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
        <Card className={ 'mt-2'}>
          <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
            Tasks
            <Button
              className="ml-auto"
              color="primary"
              onClick={() => onChangeJobTaskHOC( 'showCreateTaskModal', true )}
            >
              Create
            </Button>
          </CardHeader>
          <CardBody>
            <ReactTable
              pageSize={ 
                jobTask.data.length < 1? 3: 
                jobTask.data.length < 10? jobTask.data.length:
                10
              }
              data={ jobTask.data }
              columns={[
                {
                  Header: 'Taks No.',
                  accessor: 'count'
                },
                {
                  Header: 'Task Description',
                  accessor: 'description'
                },
                {
                  Header: 'Dept/ Industry Supervisor',
                  accessor: 'department_sv'
                },
                {
                  Header: 'Actions',
                  Cell: (row) => {
                    return (
                      <>
                        <Button
                          id="UpdateJobScope"
                          className="mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={() => {
                            getSelectedJobTask( row.original.id )
                          }}>
                          <i className="pe-7s-pen btn-icon-wrapper"> </i>
                        </Button>
                        <UncontrolledTooltip target="UpdateJobScope" placement="left">Update job task</UncontrolledTooltip>
                        <Button
                          id="DeleteJobScope"
                          className="mr-2 btn-icon btn-icon-only"
                          color="danger"
                          onClick={() => {
                            Promise.all([
                              onChangeJobTaskHOC( 'showDeleteTaskModal', true )
                            ]).then(() => {
                              onChangeJobTaskHOC( 'selectedJobTask', row.original )
                            })
                          }}
                        >
                          <i className="pe-7s-close btn-icon-wrapper"> </i>
                        </Button>
                        <UncontrolledTooltip target="DeleteJobScope" placement="left">Delete job task</UncontrolledTooltip>
                      </>
                    )
                  }
                }
              ]}
              showPagination={false} 
            />
            <Pagination 
              metadata={ jobTask.meta } 
              onChangePage={ pg => {
                getTasks( btoa(`{"page": ${ pg }, "filter": { "student_job_scope_form_tasks.id": ${ jobScopeData.id } }}`) )
              }} />
          </CardBody>
        </Card>
        { ( onLoadSkill || onLoadJobTask ) && <LoadingOverlay/> }
        <TaskForm
          mode={ 'Create' }
          open={ showCreateTaskModal }
          onClickSubmit={ payload => createJobTask({
            ... payload,
            student_job_scope_form_id: jobScopeData.id
          })}
          onClose={ () => onChangeJobTaskHOC( 'showCreateTaskModal', false )}
        />
        <TaskForm
          mode={ 'Edit' }
          selectedJobTask={ selectedJobTask }
          open={ showUpdateTaskModal }
          onClickSubmit={ updateJobTask }
          onClose={ () => onChangeJobTaskHOC( 'showUpdateTaskModal', false )}
        />
        <ConfirmationModal
          open={ showDeleteTaskModal }
          title={ `Delete Job Task` }
          loading={ onLoadJobTask }
          message={ `Are you sure you want to delete this task? (This process can't be undone)` }
          onClose={ () => onChangeJobTaskHOC( 'showDeleteTaskModal', false )}
          onClickConfirm={ () => deleteJobTask( selectedJobTask.id )}/>
      </CardBody>
    </Card>
  )
}

export default TaskHOC( FormContent )