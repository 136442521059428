import React, { useEffect, useState, useCallback } from 'react'

import { 
 Modal, 
 ModalHeader,
 ModalBody,
  ModalFooter,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'
import Moment from 'moment'
import DatePicker from 'react-datepicker'

import { defaultCreateValue, fieldData } from './assets'
import CompanyTypeahead from 'components/Typeahead/company'
import { PulseLoader } from 'react-spinners'

const CompanyDefaultDFormValue = profile => {
  return {
    ... defaultCreateValue,
    company_id: profile.company.id,
    company_state: profile.company.state
  }
}
const CreateForm = ({
  profile,
  current_role_id,
  open,
  companies,
  current_selected_session_id,
  onLoadCompanyVacancies,
  onLoadCompany,
  
  onChangeCompanyHOC,
  getCompany,
  onClose,
  onClickSubmit
}) => {
  const [ companyVacancyData, onChangeCompanyVacancyData ] = useState( 
    current_role_id === 14
      ? CompanyDefaultDFormValue( profile )
      :  defaultCreateValue
  )
  
  useEffect(() => {
    if ( !open ){
      onChangeCompanyVacancyData( 
        current_role_id === 14
          ? CompanyDefaultDFormValue( profile )
          :  defaultCreateValue
      )
      onChangeCompanyHOC( 'companies', [] )
    }
  }, [open])

  const onChangeCompanyVacancyField = ( key, val ) => {
    let tmp = _.cloneDeep( companyVacancyData )
    tmp[ key ] = val

    return onChangeCompanyVacancyData( tmp )
  }

  const onChangeCompanyTypeahead = useCallback( _.debounce(
    val => {
      getCompany( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "companies.name": {"$like": "%${ val }%" } }}`))
    }, 600 ), []
  );

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create Company Vacancies` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            <Col md={ 6 } xs={ 12 }>
              <FormGroup >
                <Label>
                  Company
                  <span className="text-danger mr-2">*</span>
                  <PulseLoader  
                    sizeUnit={"px"}
                    size={ 10 }
                    color={ '#16232c' }
                    loading={ onLoadCompany  } 
                  />
                </Label>
                <CompanyTypeahead
                  options={ companies }
                  onSelect={ val => {
                    if ( val?.[0]?.id ){
                      let tempState = _.find( companies, { id: parseInt( val[0].id )}).state 
                      let tmp = _.cloneDeep( companyVacancyData )
                      tmp.company_id = val[0].id
                      tmp.company_state = tempState

                      return onChangeCompanyVacancyData( tmp )
                    }
                  }}
                  selectedValue={ 
                    companyVacancyData.company_id && _.find( companies, { id: companyVacancyData.company_id })
                      ? [ _.find( companies, { id: companyVacancyData.company_id })] 
                      : [] 
                  }
                  onSearch={ val => {
                    val.length > 0
                      ? onChangeCompanyTypeahead( val )
                      : onChangeCompanyHOC( 'companies', [] )
                    
                    let tmp = _.cloneDeep( companyVacancyData )
                    tmp.company_id = null
                    tmp.company_state = null

                    onChangeCompanyVacancyData( tmp )
                  }}
                  disableEdit={ onLoadCompany }
                  helpText={ 'Please enter company name to search.' }
                />
              </FormGroup>
            </Col>
            <Col md={ 6 } xs={ 12 }> 
              <FormGroup >
                <Label>Company State</Label>
                <Input
                  disabled={ true }
                  type={ 'text' }
                  value={ companyVacancyData.company_state }
                />
              </FormGroup>
            </Col>
            {
              fieldData.map( item => (
                <Col key={ item.value } md={ item.col || 6 } xs={ 12 }>
                  <FormGroup >
                    <Label>
                      { `${ item.label } ` }
                      { item.required && <span className="text-danger">*</span> }
                    </Label>
                    {
                      item.type !== 'date' && (
                        <Input
                          type={ item.type }
                          min="1"
                          rows={ 5 }
                          step="1" 
                          value={ companyVacancyData[item.value] }
                          onChange={ e => {
                            onChangeCompanyVacancyField( item.value, e.target.value )
                          }} />
                      )
                    }
                    {
                      item.type === 'date' && (
                        <DatePicker
                          className={ 'w-100' }
                          showYearDropdown={ true }
                          dateFormat="dd-MM-yyyy"
                          selected={ companyVacancyData[item.value] }
                          onChange={ val => {
                            onChangeCompanyVacancyField( item.value, val )
                        }}/>
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
        { onLoadCompanyVacancies && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ 
            _.values( companyVacancyData ).some( item => !item ) 
              ? "secondary" 
              : 'primary'
          }
          className="mr-2"
          disabled={ _.values( companyVacancyData ).some( item => !item ) }
          onClick={() => {
            onClickSubmit({ 
              job_title: companyVacancyData.job_title,
              vacancy_number: companyVacancyData.vacancy_number,
              job_scope: companyVacancyData.job_scope,
              programming_languages: companyVacancyData.programming_languages,
              operating_systems: companyVacancyData.operating_systems,
              company_id: parseInt( companyVacancyData.company_id ),
              vacancy_number: parseInt( companyVacancyData.vacancy_number ),
              session_id: current_selected_session_id,
              valid_until_date: Moment( companyVacancyData.valid_until_date ).format(),
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
} 

export default CreateForm;