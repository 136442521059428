import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Post, Delete } from 'utils/axios'
import { getProfile } from 'actions/profile'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    }

    onChangeUsersHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    createUserRole = dataToSubmit => Post(
      `/user_roles`,
      dataToSubmit,
      this.createUserRoleSuccess,
      this.createUserRoleError,
      this.load
    )
    createUserRoleSuccess = ({ user_id }) => {
      const { search } = this.props.data.LastViewReducer.lastView
      const { user } = this.props.data.ProfileReducer.profile

      user?.id === user_id && this.props.getProfile()
      this.props.getUsers( search, this.props.currentUserTab )
      this.props.getSelectedUser( this.props.selectedFormData.id )
      this.requestSuccess( 'New role has been added for the user successfully.' )
    }
    createUserRoleError = error => this.requestError( error )

    deleteUserRole = ( user_id, role_id ) => Delete( 
      `/user_roles/role/${ user_id }/${ role_id  }`,
      this.deleteUserRoleSuccess,
      this.deleteError,
      this.load
    )
    deleteUserRoleSuccess = ({ user_id }) => {
      const { search } = this.props.data.LastViewReducer.lastView
      const { user } = this.props.data.ProfileReducer.profile

      user?.id === user_id && this.props.getProfile()
      this.props.getUsers( search, this.props.currentUserTab )
      this.props.getSelectedUser( this.props.selectedFormData.id )
      this.requestSuccess( 'A role has been removed by the user successfully.' )
    }
    deleteError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadUserRole={ this.state.loading }

          createUserRole={ this.createUserRole }
          deleteUserRole={ this.deleteUserRole }
          onChangeUsersHOC={ this.onChangeUsersHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getProfile
  })( WithHOC );
}

export default HOC