import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';

import 'react-bootstrap-typeahead/css/Typeahead.css';

class CustomTypeahead extends Component {

  _renderMenuItemChildren = ( option ) => {
    return [
      <p>{`${ option.name }`}</p>
    ]
  };

  render() {
    const {
      options,
      onSelect,
      onSearch,
      selectedValue,
      disableEdit,
      className,
      helpText
    } = this.props;
    return (
      <>
        <Typeahead
          className={ className || ''}
          labelKey={ "name" }
          id={ "company" }
          isLoading={ disableEdit }
          selected={ selectedValue }
          multiple={ false }
          options={ options }
          onChange={ selected => onSelect( selected )}
          onInputChange	={ val => onSearch( val )}
          renderMenuItemChildren={ this._renderMenuItemChildren }
          filterBy={ [ 'name' ] }
          disabled={ disableEdit } 
          noMatchesMessage={ '' }
        />
        {
          helpText && (
            <small style={{ color: '#6c757d' }}>{ helpText }</small>
          )
        }
      </>
    )
  }
}

export default CustomTypeahead;