import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      courses: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },

      showCreateCourseModal: false,
      showDeleteCourseModal: false,
      selectedCourse: null,

      prerequesiteSearch: ''
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCoursesHOC = ( key, val ) => this.setState({ [ key ]: val })

    createCourse = dataToSubmit => Post(
      `/student_principal_courses`,
      dataToSubmit,
      this.createCourseSuccess,
      this.createCourseError,
      this.load
    )
    createCourseSuccess = payload => {
      this.getCourses( this.state.prerequesiteSearch )
      this.setState({ showCreateCourseModal: false })
      this.requestSuccess( 'Prerequisite courses created successfully.')
    }
    createCourseError = error => this.requestError( error )

    updateCourse = dataToSubmit => Put(
      `/student_principal_courses/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateCourseSuccess,
      this.updateCourseError,
      this.load
    )
    updateCourseSuccess = () => {
      this.getCourses( this.state.prerequesiteSearch )
      this.requestSuccess( 'Prerequisite courses updated successfully.')
    }
    updateCourseError = error => this.requestError( error )

    deleteCourse = id => Delete( 
      `/student_principal_courses/${ id }`,
      this.deleteCourseSuccess,
      this.deleteCourseError,
      this.load
    )
    deleteCourseSuccess = () => {
      this.setState({ showDeleteCourseModal: false })
      this.getCourses( this.state.prerequesiteSearch )
      this.requestSuccess( 'Prerequisite courses deleted successfully.' )
    }
    deleteCourseError = error => this.requestError( error )
    
    getCourses = search => {
      this.setState({ prerequesiteSearch: search }, () => {
        Get(
          `/student_principal_courses?query=${ search }`,
          this.getCoursesSuccess,
          this.getCoursesError,
          this.load,
          this.props.data.ProfileReducer.current_role_id
        )
      })
    }
    getCoursesSuccess = payload => {
      let tempData = []
      payload?.data?.[0] && payload.data.map(({ student_principal_course, principal_course }) => {
        tempData.push({
          ... student_principal_course,
          principal_course: principal_course.name
        })
      })
      this.setState({ courses: {
        data: tempData,
        meta: payload.meta
      } })
    }
    getCoursesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          courses={ this.state.courses }
          onLoadCourses={ this.state.loading }
          prerequesiteSearch={ this.state.prerequesiteSearch }
          selectedCourse={ this.state.selectedCourse }
          showDeleteCourseModal={ this.state.showDeleteCourseModal }
          showCreateCourseModal={ this.state.showCreateCourseModal }
          
          onChangeCoursesHOC={ this.onChangeCoursesHOC }
          getCourses={ this.getCourses }
          createCourse={ this.createCourse }
          updateCourse={ this.updateCourse }
          deleteCourse={ this.deleteCourse }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC