import React, { Component } from 'react'
import { compose } from 'redux'
import _ from 'lodash'
import { toast, ToastContainer } from 'react-toastify'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import TemplateContainerMain from 'components/Template'
import TemplateHeader from 'components/Header'
import TemplateSidemenu from 'components/Menu/Sidemenu'
import ValidInternModal from 'components/Modal/ValidIntern'

import UserManagement from './UserMngmt'
import LecturerManagement from './LecturerMngmt'
import CompanyManagement from './CompanyMngmt'
import Announcement from './Announcement'
import Sessions from './Sessions'
import Roles from './Roles'
import Designation from './Designation'
import IndustryCategory from './IndustryCat'
import SecModules from './SecModules'
import SecModulesRoles from './SecModulesRoles'
import LogBookDatelines from './LogBookDatelines'
import CompanyVacancies from './CompanyVacancies'
import StudentVacancies from './StudentVacancies'
import StudentVacanciesAccepted from './StudentVacanciesAccepted'
import StudentVacancyStatus from './StudentVacancyStatus'
import PlacementForms from './PlacementForms'
import PrincipalCoursesManagement from './PrincipalCoursesMngmt'
import Statistics from './Statistics'
import StudentCV from './StudentCV'
import VisitSchedule from './VisitSchedule'
import WeeklyLogs from './WeeklyLogs'
import ReportDutyForm from './ReportDutyForm'
import JobScope from './JobScope'
import EvaluationForm from './EvaluationForm'
import Notification from './Notification'
import Result from './Result'
import AssignAcademicAdvisor from './AssignAcademicAdvisor'
import InsuranceForm from './Insurance'
import InternshipDutyReport from './InternshipDutyReport'
import OfferLetter from './OfferLetter'
import AdminForm from './AdminForm'

import { getNotification } from 'actions/notifications'

import { setSessionID } from 'actions/session'
import { setCurrentRole, getProfile } from 'actions/profile'
import { getDictionaryRole, getDictionaryDesignation } from 'actions/dictionary'
import { getItem } from 'utils/tokenStore';
import { MainNav } from 'components/Menu/VerticalNavWrapper/dataMainNav'

const PrefixURL = '/dashboard'

class Dashboard extends Component {

  state = {
    flattenNavData: [],
    currentPermission: { 
      is_create: false,
      is_delete: false,
      is_read: false,
      is_update: false,
    }
  } 

  componentDidMount = () => {
    const { current_role_id } = this.props.data.ProfileReducer

    if( !getItem( 'USM_IMS_TOKEN' )) {
      this.props.history.push( '/login' );
      this.props.setCurrentRole( null )
    } else {
      this.props.getProfile()
      this.props.getDictionaryRole()
      this.props.getDictionaryDesignation()
    }
    
    let tempData = []
    MainNav.map( menu => {
      if ( menu.content ){
        tempData.push( ... menu.content )
      } else {
        tempData.push( menu )
      }
    })
    this.setState({ flattenNavData: tempData }, () => {
      this.onCheckPermission()
    })

    if (!_.isEmpty(current_role_id)) {
      this.props.getNotification( btoa(`{ "page": 1, "per_page": 5, "filter": { "is_read": true }}`), false, current_role_id )
    }
  }

  componentDidUpdate = ( pp, ps ) => {
    const { ajaxCallProgress, ajaxError, ajaxErrorMessage } = this.props.data.AjaxReducer
    const { current_role_id } = this.props.data.ProfileReducer
    const { roles } = this.props.data.DictionaryReducer

    if ( pp.data.AjaxReducer.ajaxCallProgress !== ajaxCallProgress ){
      !ajaxCallProgress && ajaxError && toast.error( ajaxErrorMessage )
    }

    if ( this.props.location.pathname !== pp.location.pathname && this.props.location.pathname !== '/dashboard/statistics' ){
      this.onCheckPermission()
    }

    if ( ps.currentPermission !== this.state.currentPermission ){
      [ '/dashboard', '/dashboard/statistics', '/dashboard/notifications' ].indexOf( this.props.location.pathname ) < 0 && 
      !this.state.currentPermission.is_read && 
      this.props.history.goBack()
    }

    if ( pp.data.ProfileReducer.current_role_id !== current_role_id ) {
      this.onHandleSelectedSession()
    }

    if( 
        pp.data.ProfileReducer.current_role_id && current_role_id && 
        pp.data.ProfileReducer.current_role_id !== current_role_id
      ){
        let tempRole = _.find( roles, { id: current_role_id })
        toast.info( `Currently using ${ tempRole?.name??'' } role's menu`)
    }
  }

  onHandleSelectedSession = () => {
    const { sessions = [] } = this.props.data.SessionReducer

    let tempId = null

    tempId = this.props.data.ProfileReducer.current_role_id === 13 
      ? this.props.data.ProfileReducer?.profile?.student?.session_id
      : sessions?.[ sessions.length - 1 ]?.session?.id??null

    this.props.setSessionID( tempId )
  }

  onCheckPermission = () => {
    const { current_role_id } = this.props.data.ProfileReducer
    let { sec_module_roles } = this.props.data.ProfileReducer.profile

    let tempRole = _.find( sec_module_roles, { role_id: current_role_id } )
    let tempModule = _.find( this.state.flattenNavData, { to: `#${ this.props.location.pathname }`} )
    let tempPermission = null

    if ( tempModule && tempRole ){
      const {
        sec_module_roles: currentSecModules
      } = tempRole
      let temp = _.find( currentSecModules, { sec_module_id: tempModule.sec_module_id })
      tempPermission= temp
    }
    return this.setState({ 
      currentPermission: tempPermission || { 
        is_create: false,
        is_delete: false,
        is_read: false,
        is_update: false
      }
    })
  }
  
  render = () => {
    return (
      <>
        <TemplateContainerMain>
          <TemplateHeader
            history={ this.props.history }
          />
          <div className="app-main">
            <TemplateSidemenu 
              userPermissions={ this.props.userPermissions }
              user={ this.props.user }
              userRole={ this.props.userRole } />
            <div className="app-main__outer">
              {
                this.props.data.ProfileReducer.profile.user.email === this.props.data.LoginEmailReducer.email && (
                  <div className="app-main__inner">
                    <Route 
                      path={ `${ PrefixURL }/users` } 
                      render={ props => (
                        <UserManagement { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/lecturer` } 
                      render={ props => (
                        <LecturerManagement { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/company` } 
                      render={ props => (
                        <CompanyManagement { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/sessions` } 
                      render={ props => (
                        <Sessions { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/announcement` } 
                      render={ props => (
                        <Announcement { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/roles` } 
                      render={ props => (
                        <Roles { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/designation` } 
                      render={ props => (
                        <Designation { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/industry_categories` } 
                      render={ props => (
                        <IndustryCategory { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/sec_modules` } 
                      render={ props => (
                        <SecModules { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/sec_modules_roles` } 
                      render={ props => (
                        <SecModulesRoles { ...props } { ... this.state } />
                      )}
                    />
                    <Route 
                      path={ `${ PrefixURL }/logbook_datelines` } 
                      render={ props => (
                        <LogBookDatelines { ...props } { ... this.state } />
                      )}
                    />
                    <Route 
                      path={ `${ PrefixURL }/company-vacancies` } 
                      render={ props => (
                        <CompanyVacancies { ...props } { ... this.state } />
                      )}
                    />
                    <Route 
                      path={ `${ PrefixURL }/students_vacancies_status` } 
                      render={ props => (
                        <StudentVacancyStatus { ...props } { ... this.state } />
                      )}
                    />
                    <Route
                      path={ `${ PrefixURL }/placement-forms` } 
                      render={ props => (
                        <PlacementForms { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/student_vacancies` } 
                      render={ props => (
                        <StudentVacancies { ...props } { ... this.state } />
                      )}
                    />
                    <Route
                      path={ `${ PrefixURL }/offer-letters` } 
                      render={ props => (
                        <OfferLetter { ...props } { ... this.state } />
                      )} />
                    <Route 
                      path={ `${ PrefixURL }/internship-management` } 
                      render={ props => (
                        <StudentVacanciesAccepted { ...props } { ... this.state } />
                      )}
                    />
                    <Route 
                      path={ `${ PrefixURL }/principal-courses-management` } 
                      render={ props => (
                        <PrincipalCoursesManagement { ...props } { ... this.state } />
                      )}
                    />
                    <Route 
                      path={ `${ PrefixURL }/student-cv` } 
                      render={ props => (
                        <StudentCV { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/statistics` } 
                      render={ props => (
                        <Statistics { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/visit-schedule` } 
                      render={ props => (
                        <VisitSchedule { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/weekly-logs` } 
                      render={ props => (
                        <WeeklyLogs { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/internship-duty-report` } 
                      render={ props => (
                        <InternshipDutyReport { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/report-duty-form` } 
                      render={ props => (
                        <ReportDutyForm { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/job-scope` } 
                      render={ props => (
                        <JobScope { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/notifications` } 
                      render={ props => (
                        <Notification { ...props }/>
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/evaluation-form` } 
                      render={ props => (
                        <EvaluationForm { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/result` } 
                      render={ props => (
                        <Result { ...props } { ... this.state } />
                      )}
                    />
                    <Route  
                      path={ `${ PrefixURL }/assign-academic-advisor` } 
                      render={ props => (
                        <AssignAcademicAdvisor { ...props } { ... this.state } />
                      )}
                    />
                    <Route
                      path={ `${ PrefixURL }/insurance` } 
                      render={ props => (
                        <InsuranceForm { ...props } { ... this.state } />
                      )}
                    />
                    <Route
                      path={ `${ PrefixURL }/admin-form` } 
                      render={ props => (
                        <AdminForm { ...props } { ... this.state } />
                      )}
                    />
                  </div>
                )
              }
            </div>
          </div>
          <ToastContainer position={ toast.POSITION.BOTTOM_RIGHT }/>
        </TemplateContainerMain>
        {
          this.props.data.ProfileReducer.current_role_id === 13 && 
          !this.props.data.ProfileReducer?.profile.student?.is_eligible_intern && (
            <ValidInternModal history={ this.props.history }/>
          )
        }
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default compose( 
  connect( mapStateToProps, {
    setSessionID,
    getDictionaryRole,
    getDictionaryDesignation,
    getNotification,
    setCurrentRole,
    getProfile
  }), 
  withRouter
)( Dashboard )