import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      internalSupervisorStudents: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getInternalSupervisorStudents = ( id ) => Get(
      `/internal_supervisor/students/${ id }`,
      this.getInternalSupervisorStudentsSuccess,
      this.getInternalSupervisorStudentsError,
      this.load
    )
    getInternalSupervisorStudentsSuccess = payload => this.setState({ internalSupervisorStudents: payload })
    getInternalSupervisorStudentsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          internalSupervisorStudents={ this.state.internalSupervisorStudents }
          onLoadInternalSupervisorStudents={ this.state.loading }
          getInternalSupervisorStudents={ this.getInternalSupervisorStudents } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC