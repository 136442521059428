export const ViewData = [
  {
    card_name: 'Student Info',
    card_obj: 'student',
    fields: [
      { label: 'Name', value: 'name', type: 'text' },
      { label: 'Email', value: 'email', type: 'text', col: 6 },
      { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', col: 6 },
      { label: 'Matric No.', value: 'matric_number', type: 'text' },
      { label: 'Address', value: 'address', type: 'textarea' }
    ]
  },
  {
    card_name: 'Company Info',
    card_obj: 'company',
    fields: [
      { 
        label: 'Company Name', value: 'name',type: 'text'
      },
      { 
        label: "Website", value: 'website',type: 'text'
      },
      { 
        label: 'Contact Person', value: 'contact_person_name',type: 'text'
      },
      { 
        label: 'Email', value: 'contact_person_email',col: 6,type: 'text'
      },
      { 
        label: 'Phone', value: 'phone',col: 6,type: 'text'
      },
      { 
        label: 'Fax', value: 'fax_number',type: 'text'
      }   
    ]
  }
]

export const FieldData = ({ companyUsers }) => [
  { label: 'Supervisor Contact', type: 'text', value: 'supervisor_contact', col: 12, required: true },
  { label: 'Supervisor Mobile', type: 'text', value: 'supervisor_mobile', col: 6, required: true, subLabel: 'Eg: +6012345678' },
  { label: 'Supervisor Fax', type: 'text', value: 'supervisor_fax', col: 6, required: true, subLabel: 'e.g +604-1234567 / 4568' },
  { label: 'Report Duty Date', type: 'date', value: 'report_duty_date', col: 6, required: true },
  { label: 'External Supervisor', type: 'select', value: 'external_supervisor_id', col: 12, required: true, options: companyUsers },
  { label: 'External Supervisor Remark', type: 'textarea', value: 'external_sv_remark', col: 12, required: false }
]

export const defaultValue = {
  supervisor_mobile: '',
  supervisor_contact: '',
  supervisor_fax: '',
  report_duty_date: null,
  have_intern_allowance: false,
  is_checked: false,
  external_sv_remark: '',
  external_supervisor_id: null
}