import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Get, Post, Put, Delete } from 'utils/axios' 
import { storeLastView }  from 'actions/lastView'

const searchParams = [
  { 
    label: 'Student Name', 
    value: 'su.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Academic Advisor Name', 
    value: 'aau.name',
    type: 'text',
    param: ''
  },
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showSearchForms: false,

      academicAdvisors: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedAdvisor: {},
      searchParams: searchParams,

      showCreateModal: false,
      showDeleteModal: false,
      showUpdateModal: false
    }
    

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAdvisor = ( search ) => {
      this.props.storeLastView({ search })

      Get(
        `/academic_advisor_students?query=${ search }`,
        this.getAdvisorSuccess,
        this.getAdvisorError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getAdvisorSuccess = payload => {
      let tempData = _.map( payload.data, item => {
        return ({
          id: item?.academic_advisor_student?.id??null,
          advisorName: item?.academic_advisor.name??'',
          studentName: item?.student_user?.name??''
        })
      })
      this.setState({ academicAdvisors: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getAdvisorError = error => this.requestError( error )

    getSelectedAdvisor = id => Get(
      `/academic_advisor_students/${ id }`,
      this.getSelectedAdvisorSuccess,
      this.getSelectedAdvisorError,
      this.load
    )
    getSelectedAdvisorSuccess = payload => this.setState({ 
      selectedAdvisor: payload
    })
    getSelectedAdvisorError = error => this.requestError ( error )

    createAdvisor = dataToSubmit => Post(
      `/academic_advisor_students`,
      dataToSubmit,
      this.createAdvisorSuccess,
      this.createAdvisorError,
      this.load
    )
    createAdvisorSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getAdvisor( search )
      this.setState({ showCreateModal: false })
      this.requestSuccess( `New record has been created successfully.` )
    }
    createAdvisorError = error => this.requestError( error )

    deleteAdvisor = id => Delete( 
      `/academic_advisor_students/${ id }`,
      this.deleteAdvisorSuccess,
      this.deleteAdvisorError,
      this.load
    )
    deleteAdvisorSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getAdvisor( search )
      this.setState({ showDeleteModal: false })
      this.requestSuccess( `Record has been created successfully.` )
    }
    deleteAdvisorError = error => this.requestError( error )

    updateAdvisor = ( id,dataToSubmit ) => Put(
      `/academic_advisor_students/${ id }`,
      dataToSubmit,
      this.updateAdvisorSucces,
      this.updateAdvisorError,
      this.load
    )
    updateAdvisorSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getAdvisor( search )
      this.getSelectedAdvisor( payload.id )
      this.requestSuccess( 'Record has been updated successfully.' )
    }
    updateAdvisorError = error => this.requestError( error )

    onChangeAdvisorHOC = ( key, val ) => this.setState({ [key]: val })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          showSearchForms={ this.state.showSearchForms }
          academicAdvisors={ this.state.academicAdvisors }
          selectedAdvisor={ this.state.selectedAdvisor }
          searchParams={ this.state.searchParams }
          onLoadAdvisor={ this.state.loading }
          showCreateModal={ this.state.showCreateModal }
          showDeleteModal={ this.state.showDeleteModal }
          showUpdateModal={ this.state.showUpdateModal }

          getAdvisor={ this.getAdvisor }
          getSelectedAdvisor={ this.getSelectedAdvisor }
          createAdvisor={ this.createAdvisor }
          deleteAdvisor={ this.deleteAdvisor }
          updateAdvisor={ this.updateAdvisor }
          onChangeAdvisorHOC={ this.onChangeAdvisorHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeLastView
  })( WithHOC )
}

export default HOC