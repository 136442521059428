import React, { Component } from 'react'
import { connect } from 'react-redux'

import { storeItem } from 'utils/tokenStore';
import getDomainURL from 'utils/api'
import { setCurrentRole, getProfile } from 'actions/profile' 
import { storeLoginEmail } from 'actions/email'
import { 
  getSessions,
  setSessionID
} from 'actions/session'

import Axios from 'axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showPassword: false,
      showErrorMessage: false,
      errorMessage: ''
    }

    onChangeHOC = ( val, context ) => this.setState({ [context]: val })

    onClickLogin = ({ email, password }) => {
      this.props.storeLoginEmail( email )
      this.setState({ loading: true }, () => {
        Axios.post(
          `${ getDomainURL() }/login`, {
            email,
            password,
            encode_type: 'JWT'
          }).then( response => {
            this.setState({ loading: false })
            storeItem( 'USM_IMS_TOKEN', response.data.token )
            Promise.all([
              this.props.getProfile( true ),
              this.props.getSessions(btoa(`{"page":0, "per_page": 1000, "filter":{ "sessions.hide_session": false }}`))
            ]).then(() => {
              this.props.history.push( '/dashboard/statistics' )
            })
          }).catch( error => {
            this.setState({ 
              showErrorMessage: true, loading: false, 
              errorMessage: error.response.data.message 
            })
        });
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ... this.props }
          showPassword={ this.state.showPassword }
          showErrorMessage={ this.state.showErrorMessage }
          onLoadLogin={ this.state.loading }
          errorMessage={ this.state.errorMessage }
          onChangeHOC={ this.onChangeHOC }
          onClickLogin={ this.onClickLogin }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getSessions,
    setCurrentRole,
    getProfile,
    setSessionID,
    storeLoginEmail
  })( WithHOC );
}
export default HOC