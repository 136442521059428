import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Label } from 'reactstrap'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const ImportForm = ({
  open,
  fileURL,
  context,
  onLoad,
  session_id,

  onClose,
  onSubmitFile
}) => {
  const pond = useRef(null)
  const [ importFile, onChangeFile ] = useState( null )
 
  useEffect(() => {
    onChangeFile( null )
  }, [ open ])

  const onDropImportFile = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      const reader = new FileReader()
      reader.onload = e => {
        onChangeFile( e.target.result.split(',')[1] )
      }
      reader.readAsDataURL( fileItems[ 0 ].file )
    }
  }

  return (
    <>
      <Modal
        isOpen={ open }
        size={ 'xl' }
        toggle={ () => onClose() }>
        <ModalHeader toggle={() => onClose()}>
          { `Import ${ context }` }
        </ModalHeader>
        <ModalBody>
          <Label>
            { `Drop your excel file to import ` } 
            <span style={{ textTransform: 'lowercase' }}>{ context }</span>
          </Label>
          <FilePond
            ref={ pond }
            allowMultiple={ false }
            fullWidth
            acceptedFileTypes={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
              'text/csv' 
            ]}
            maxFiles={ 1 }
            onremovefile={ () => {
              pond.current.removeFiles()
              onChangeFile( null )
            }}
            onupdatefiles={ fileItems => onDropImportFile( fileItems ) }/>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex ml-auto">
            <Button
              color={ `secondary` }
              className={ 'mr-2' }
              onClick={() => window.open( fileURL )}>
              Download Sample File
            </Button>
            <Button 
              color={ importFile ? 'primary' : 'secondary' } 
              disabled={ !importFile }
              onClick={ () => onSubmitFile( importFile, session_id )}>
              Submit
            </Button>
          </div>
        </ModalFooter>
        { onLoad && <LoadingOverlay/> }
      </Modal>
    </>
  )
}


export default ImportForm;