import React, { useEffect, useState, useRef } from 'react'

import { 
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  Col,
  Row,
  FormGroup,
  Input,
  Label,
  FormText,
} from 'reactstrap'
import _ from 'lodash'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { AboutMeFieldData } from './assets'
import DictionaryHOC from './actions/dictionary'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const DefaultValue = {
  gender: null,
  country: null,
  state: null,
  city: '',
  postal_code: '',
  additional_info: '',
  mailing_address: '',
}

const CreateForm = props => {
  const {
    open,
    data, 
    onLoadStudentCV,
    onLoadDictionary,
  
    onClose,
    onClickSubmit,
    getCountryState,
    getCountry
  } = props

  const [ createFormData, onChangeFormData ] = useState( DefaultValue )
  const [ CVPhoto, onChangePhoto ] = useState( null )
  
  useEffect(() => {
    if ( !open ){
      onChangePhoto( null )
      onChangeFormData( DefaultValue )
    }
    open && getCountry()
  }, [open])

  const onChangeCreateField = ( key, val ) => {
    let tmp = _.cloneDeep( createFormData )
    tmp[ key ] = val

    return onChangeFormData( tmp )
  }

  function onChangeCreateFile ( fileItems ) {
    if( fileItems && fileItems.length > 0 && fileItems[ 0 ].file ) {
      const reader = new FileReader()
      reader.onload = e => {
        return onChangePhoto( e.target.result )
      }
      reader.readAsDataURL( fileItems[ 0 ].file )
    } 
    return onChangePhoto( null )
  }

  const onChangeCountryField = val => {
    let tmp = _.cloneDeep( createFormData )
    tmp.country = val
    tmp.state = null

    getCountryState( val )
    onChangeFormData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create Student CV` }</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={ 12 }>
            <FormGroup>
              <Label>
                Photo
                <span className="text-danger"> *</span>
              </Label>
              <br/>
              <Label>(Please make sure the photo size does not reach 500 kb)</Label>
              <FilePond
                allowMultiple={ false }
                fullWidth
                acceptedFileTypes={['image/png']}
                maxFiles={ 1 }
                onremovefile={ () => onChangeCreateFile( null )}
                onupdatefiles={ onChangeCreateFile }/>
            </FormGroup>
          </Col>
          {
            AboutMeFieldData( props ).map( item => (
              <Col key={ item.value } lg={ item.col || 12 } xs={ 12 }>
                <FormGroup>
                  { 
                    item.label && (
                      <Label>
                        { `${ item.label } ` }
                        { item.required && <span className="text-danger">*</span> }
                      </Label>
                  )}
                  {
                    item.type === 'select' && (
                      <Input
                        type={ item.type }
                        value={ createFormData[ item.value ] }
                        onChange={ e => {
                          if ( item.value === 'country' ){
                            return onChangeCountryField( e.target.value )
                          }
                          return onChangeCreateField( item.value, e.target.value )
                        }}>
                        <option value=""></option>
                        { 
                          item.options.map( option => (
                            <option key={ option.id } value={ option.id }>{ option.name }</option>
                          ))
                        }
                      </Input>
                    )
                  }
                  {
                    item.type !== 'select' && (
                      <Input
                        type={ item.type }
                        value={ createFormData[ item.value ] }
                        onChange={ e => onChangeCreateField( item.value, e.target.value )}/>
                    )
                  }
                  { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                </FormGroup>
              </Col>
            ))
          }
        </Row>
        {( onLoadStudentCV || onLoadDictionary ) && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button 
          color={
            ( AboutMeFieldData({}).some( item => item.required && !createFormData[ item.value ]) || !CVPhoto )
              ? 'secondary'
              : 'primary'
          }
          className="mr-2"
          disabled={ AboutMeFieldData({}).some( item => item.required && !createFormData[ item.value ]) || !CVPhoto }
          onClick={() => onClickSubmit({
            ... createFormData,
            photo: CVPhoto,
            state: +createFormData.state,
            country: +createFormData.country,
            student_id: data.ProfileReducer.profile.student.id
          })}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DictionaryHOC( CreateForm );