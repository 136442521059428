import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Card, CardBody, CardHeader,
  Button, 
  UncontrolledTooltip 
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateLanguageForm from './Create'
import UpdateLanguageForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import LanguageHOC from './actions'

class Language extends Component {

  componentDidMount = () => {
    this.props.getLanguage( btoa(`{ "page": 1, "filter": { "student_cvs.id": ${ this.props.selectedStudentCV.student_cv.id } }}`) )
  }

  render = () => {
    const { mode } = this.props
    return (
      <>
        <Card className="main-card mb-3">
          <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
            Student's Language
            {
              mode === 'normal' && (
                <Button
                  className="ml-auto btn-sm"
                  color="primary"
                  onClick={() => this.props.onChangeLanguageHOC( 'showCreateLanguageModal', true )}
                >
                  Add Language
                </Button>
              )
            }
          </CardHeader>
            <CardBody>
              <ReactTable
                pageSize={ 
                  this.props.studentLanguage.data.length < 1? 3: 
                  this.props.studentLanguage.data.length < 10? this.props.studentLanguage.data.length:
                  10
                }
                data={ this.props.studentLanguage.data }
                columns={[
                  {
                    Header: 'Language Name',
                    accessor: 'name'
                  },
                  {
                    Header: 'Band/Level',
                    accessor: 'score'
                  },
                  {
                    Header: 'Spoken',
                    accessor: 'spoken'
                  },
                  {
                    Header: 'Written',
                    accessor: 'written'
                  },
                  ...( mode === 'normal' ? [{
                    Header: 'Actions',
                    Cell: (row) => {
                      return (
                        <>
                          <Button
                            id="UpdateLanguage"
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={() => {
                              this.props.getSelectedLanguage(row.original.id, 'edit' )
                            }}>
                            <i className="pe-7s-pen btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="UpdateLanguage" placement="left">Update student's language from CV.</UncontrolledTooltip>
                          <Button
                            id="DeleteLanguage"
                            className="mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={() => {
                              this.props.onChangeLanguageHOC( 'showDeleteLanguageModal', true )
                              this.props.onChangeLanguageHOC( 'selectedLanguage', row.original )
                            }}>
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="DeleteLanguage" placement="left">Delete student's language from CV.</UncontrolledTooltip>
                        </>
                      )
                    }
                  }] : [])
                ]}
                showPagination={false} />
              <Pagination 
                metadata={ this.props.studentLanguage.meta } 
                onChangePage={ pg => {
                  let tempJSON = JSON.parse( atob( this.props.languageSearchQuery ))
                  tempJSON.page = pg
                  
                  this.props.getLanguage( btoa( JSON.stringify( tempJSON )))
                }} />
            </CardBody>
          </Card>
        <CreateLanguageForm
          {...this.props}
          open={ this.props.showCreateLanguageModal }
          onClose={ () => this.props.onChangeLanguageHOC( 'showCreateLanguageModal', false )}
          onClickSubmit={ this.props.createLanguage }
        />
        <UpdateLanguageForm
          {...this.props}
          selectedFormData={ this.props.selectedLanguage }
          open={ this.props.showUpdateLanguageModal }
          onClose={ () => this.props.onChangeLanguageHOC( 'showUpdateLanguageModal', false )}
          onClickSubmit={ this.props.updateLanguage }
        />
        <ConfirmationModal
          loading={ this.props.onLoadLanguage }
          open={ this.props.showDeleteLanguageModal}
          title={ `Delete` }
          message={ 
            <p>
              Are you sure you want to delete this <b>student's language record</b> from his/her CV ? (This process can't be undone)
            </p>
          }
          onClose={ () => this.props.onChangeLanguageHOC( 'showDeleteLanguageModal', false )}
          onClickConfirm={ () => this.props.deleteLanguage( this.props.selectedLanguage.id )}/>
        { this.props.onLoadLanguage && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  LanguageHOC
)(Language)