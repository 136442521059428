import React from 'react'
import { 
  Row,
  Col,
  ModalBody,
  Form, FormGroup, Label, Input,
} from 'reactstrap'
import _ from 'lodash'

import TaskContent from './Task'
import ApprovalContent from './Approval'

const FieldData = [
  { label: 'Company Name', value: 'company_name', editable: false },
  { label: 'Student Name', value: 'student_name', editable: false },
  { label: 'Student Matric', value: 'student_matric', editable: false }
]

const FormContent = ({
  selectedJobScope,
  onLoadJobScope,
  
  updateJobScope
}) => {
  return (
    <>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              FieldData.map( data => (
                <Col lg={ 12 }>
                  <FormGroup>
                    <Label>{ data.label }</Label>
                    <Input
                      type={ 'text' }
                      value={ selectedJobScope[data.value] }
                      disabled={ true }
                    />
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
        <ApprovalContent
          selectedJobScope={ selectedJobScope }        
          updateJobScope={ updateJobScope }
        />
        <TaskContent 
          onLoadJobScope={ onLoadJobScope }
          selectedJobScope={ selectedJobScope }
        />
      </ModalBody>
    </>
  )
}

export default FormContent;