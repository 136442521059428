import Axios from 'axios'
import { getItem, clearItem } from 'utils/tokenStore';

import { 
  GET_NOTIFICATION
 } from './type'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'
import getDomainURL from 'utils/api'

export const getNotification = ( query, is_read, id ) => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'USM_IMS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/notifications?query=${ query }&is_read=${ is_read }&role_id=${ id }`
  ).then( response => {
    dispatch( getNotificationSuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    if( error.response.status === 401 ){
      clearItem( 'USM_IMS_TOKEN' )
    }
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getNotificationSuccess = payload => ({
  type: GET_NOTIFICATION,
  payload
})

export const setNotificationRead = (id, roleId) => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'USM_IMS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.put(
    `${ getDomainURL() }/notifications/${ id }`,
    { "is_read": true }
  ).then(() => {
    dispatch( getNotification( btoa(`{ "page": 1, "per_page": 5, "filter": { "is_read": true }}`), false, roleId ))
  }).catch( error => {
    if( error.response.status === 401 ){
      clearItem( 'USM_IMS_TOKEN' )
    }
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}