import React, { Component } from 'react'
import { compose } from 'redux'
import _ from 'lodash'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  Row, Col,
  Container, Progress,
  Button
} from 'reactstrap';
import CountUp from 'react-countup';

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { DashboardMetrics, MetricsForm } from './asset'
import AnnouncementContent from './Announcement'
import VisitScheduleContent from './VisitSchedule';
import SupervisorCard from './SupervisorCard'
import StudentCard from './StudentCard'
import StudentDetailsModal from './StudentDetailsModal'
import EditForm from './Edit'

import StatisticsHOC from './actions'
import WithInternalSupervisorStudent from './actions/internalSupervisorStudent' 
import WithStudents from './actions/students'
import WithExternalSupervisorStudents from './actions/externalSupervisorStudents'
import WithAcademicAdvisorStudents from './actions/academicAdvisorStudent'
import sessionQueryHandler from 'utils/sessionQueryHandler'

import './index.scss'

class Statistics extends Component {

  componentDidMount = () => { 
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ([ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0) {
      this.props.getStudentAcceptedVacancies( btoa( JSON.stringify( sessionQueryHandler( {"page":1, "per_page": 1000}, current_selected_session_id, 'students.session_id' ))) )
    }
    if( this.props.data.ProfileReducer.current_role_id === 14 ) {
      this.props.getExternalSupervisorStudents( this.props.data.ProfileReducer.profile.user.id )
    }
    if( this.props.data.ProfileReducer.current_role_id === 15 ) {
      this.props.getInternalSupervisorStudents( this.props.data.ProfileReducer.profile.user.id )
    }
    if( this.props.data.ProfileReducer.current_role_id === 18 ) {
      this.props.getAcademicAdvisorStudents()
    }
    if( this.props.data.ProfileReducer.current_role_id === 13 ) {
      this.props.getSelectedStudent( this.props.data.ProfileReducer.profile.student.id )
    }

    if ( current_selected_session_id ) {
      this.initModule()
    }
  }

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if( this.props.data.ProfileReducer.current_role_id !== pp.data.ProfileReducer.current_role_id ) {
      if ([ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0) {
        this.props.getStudentAcceptedVacancies( btoa( JSON.stringify( sessionQueryHandler( {"page":1, "per_page": 1000}, current_selected_session_id, 'students.session_id' ))) )
      }
      if( this.props.data.ProfileReducer.current_role_id === 14 ) {
        this.props.getExternalSupervisorStudents( this.props.data.ProfileReducer.profile.user.id )
      }
      if( this.props.data.ProfileReducer.current_role_id === 15 ) {
        this.props.getInternalSupervisorStudents( this.props.data.ProfileReducer.profile.user.id )
      }
      if( this.props.data.ProfileReducer.current_role_id === 18 ) {
        this.props.getAcademicAdvisorStudents()
      }
      if( this.props.data.ProfileReducer.current_role_id === 13 ) {
        this.props.getSelectedStudent( this.props.data.ProfileReducer.profile.student.id )
      }
    }
    
    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
        && current_selected_session_id ) {
      this.initModule()
    }
  }

  initModule = () => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    this.props.onChangeDashboardHOC( 
      'dashboardMetric', 
      this.props.data.ProfileReducer.current_role_id === 13
        ? [ DashboardMetrics[1] ]
        : DashboardMetrics
    )

    this.props.getDashboardMetric( current_selected_session_id )
  }

  render = () => {
    const { dashboardMetric, dashboardMetricData, studentAcceptedVacancies } = this.props
    const { current_selected_session_id } = this.props.data.SessionReducer
    let formData = {
      ...studentAcceptedVacancies
    }

    return (
      <>
        <PageTitle
          heading="Dashboard"
          icon="pe-7s-graph3 icon-gradient bg-happy-itmeo"
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>

          <Row>
            <Col md="12" lg="8">
              {
                this.props.data.ProfileReducer.current_role_id === 13 && (
                  <>
                    <VisitScheduleContent/>
                    {
                      this.props.selectedStudent.academic_advisor && <SupervisorCard data={ this.props.selectedStudent.academic_advisor } title={ 'Academic Advisor' } />
                    }
                    {
                      this.props.selectedStudent.external_supervisor && <SupervisorCard data={ this.props.selectedStudent.external_supervisor } title={ 'External Supervisor' } />
                    }
                    {
                      this.props.selectedStudent.internal_supervisor && <SupervisorCard data={ this.props.selectedStudent.internal_supervisor } title={ 'Internal Supervisor' } />
                    }
                  </>
                )
              }
              {
                this.props.data.ProfileReducer.current_role_id === 14 && (
                  <>
                    <h4>Assigned students</h4>
                    <hr />
                    {
                      this.props.externalSupervisorStudents.length > 0 
                      ? (this.props.externalSupervisorStudents.filter(
                        (item) => item.student.session_id === current_selected_session_id
                      ).length > 0 
                      ? (this.props.externalSupervisorStudents.map((item, index) => {
                          if (item.student.session_id === current_selected_session_id) {
                            return (
                              <StudentCard
                                key={`studentexternal${index}`}
                                context={`external`}
                                data={item}
                                getSelectedStudentDashboard={this.props.getSelectedStudentDashboard}
                              />
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <h6>The student associated with this account is currently in a different session.</h6>
                      )
                    ) : (
                      <h6>No student associated with this account.</h6>
                    )}
                  </>
                )
              }
              {
                this.props.data.ProfileReducer.current_role_id === 15 && (
                  <>
                    <h4>Assigned students</h4>
                    <hr />
                    {
                      this.props.internalSupervisorStudents.length > 0 
                      ? (this.props.internalSupervisorStudents.filter(
                        (item) => item.student.session_id === current_selected_session_id
                      ).length > 0 
                      ? (this.props.internalSupervisorStudents.map((item, index) => {
                          if (item.student.session_id === current_selected_session_id) {
                            return (
                              <StudentCard
                                key={`studentexternal${index}`}
                                context={`internal`}
                                data={item}
                                getSelectedStudentDashboard={this.props.getSelectedStudentDashboard}
                              />
                            );
                          } else {
                            return null;
                          }
                        })
                      ) : (
                        <h6>The student associated with this account is currently in a different session.</h6>
                      )
                    ) : (
                      <h6>No student associated with this account.</h6>
                    )}
                  </>
                )
              }
              {
                this.props.data.ProfileReducer.current_role_id === 18 && (
                  <>
                    <h4>Assigned students</h4>
                    <hr />
                    {
                      this.props.academicAdvisorStudents.data.filter(item => item.academic_advisor.id === this.props.data.ProfileReducer.profile.user.id).length < 1 && (
                        <h6>No student assigned under this account.</h6>
                      )
                    }
                    {
                      this.props.academicAdvisorStudents.data.filter(item => item.academic_advisor.id === this.props.data.ProfileReducer.profile.user.id).map(( item, index ) => {
                        return <StudentCard 
                          key={ `studentinternal${ index }` } 
                          context={ `academic` }
                          data={ item }
                          getSelectedStudentEditForm={ this.props.getSelectedStudentEditForm } />
                      })
                    }
                  </>
                )
              }
              <Row style={{ gap: 10 }}>
                {/* Total Student */}
                {
                  [ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0 && (
                    <Col xs="12" md={ 5 } lg="3" style={{ justifyContent: 'center' }}>
                      <div className="card widget-chart" style={{ maxWidth: 250, minHeight: 195 }}>
                        <div className="widget-chart-content">
                          <div className="icon-wrapper rounded">
                            <div className={ `icon-wrapper-bg bg-primary` }/>
                            <i style={{ fontSize: '2rem' }} className={ `lnr-graduation-hat text-primary` }/>
                          </div>
                          <div className="d-flex justify-content-around flex-wrap">
                            <div>
                                <div className="widget-numbers text-primary" style={{ fontSize: '2.1em' }}>
                                  <CountUp 
                                    start={0} end={ dashboardMetricData[ 'total_students' ] }
                                    useEasing={false}
                                    duration="1"
                                  />
                                </div>
                                <div
                                  className={ `opacity-10 font-weight-bold` }
                                >
                                  Total Students
                                </div>
                                {
                                  <Button 
                                    size="sm" className='mt-2 btn' color="focus"
                                    onClick={ () => this.props.history.push( '/dashboard/users' )}
                                  >
                                    View Details
                                  </Button>
                                }
                              </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                }
                {/* Dashboard Metric */}
                {
                  [ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0 && dashboardMetric.map( (item, index) => (
                    <Col key={index} xs="12" md={ 5 } lg="3">
                      <div className="card widget-content">
                          <div className="widget-content-outer">
                          <div className="icon-wrapper rounded mb-3">
                            <div className={ `icon-wrapper-bg bg-${ item.color }` }/>
                            <i style={{ fontSize: '2rem' }} className={ `${ item.icon } text-${ item.color }` }/>
                          </div>
                              <div className="widget-content-wrapper">
                                  <div className="widget-content-left">
                                      <div className="widget-heading">
                                          { item.title }
                                      </div>
                                      <div className="widget-subheading mt-1">
                                        <p className='mb-0'>
                                          <strong>
                                          { `${ dashboardMetricData[ item.success_value ] || 0 } ` }
                                          </strong>
                                          { `approved `}
                                          <strong>
                                            { `  ${ dashboardMetricData[ item.total_value ] - dashboardMetricData[ item.success_value ]} ` }
                                          </strong>
                                          { ` pending`}
                                        </p>
                                      </div>
                                  </div>
                                  <div className="widget-content-right">
                                      <div className={ `widget-numbers text-${ item.color }` }>
                                        { `${ dashboardMetricData[ item.total_value ] || 0 }` }
                                      </div>
                                  </div>
                              </div>
                              <div className="widget-progress-wrapper">
                                  <Progress 
                                    className="progress-bar-sm" 
                                    color={ item.color } value={ 
                                      dashboardMetricData[ item.success_value ]/dashboardMetricData[ item.total_value ] * 100
                                    }/>
                                  <div className="progress-sub-label">
                                      <div className="sub-label-left">
                                        Approval Rate
                                      </div>
                                      <div className="sub-label-right">
                                        { 
                                          dashboardMetricData[ item.total_value ] === 0
                                            ? '0%'
                                            : `${ (dashboardMetricData[ item.success_value ]/dashboardMetricData[ item.total_value ] * 100).toFixed(2) }%` 
                                        }
                                      </div>
                                  </div>
                              </div>
                              {
                                [ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0 && (
                                  <Button 
                                    size="sm" className='mt-2 btn' color="focus"
                                    onClick={ () => this.props.history.push( item.link )}
                                  >
                                    View Details
                                  </Button>
                                )
                              }
                          </div>
                      </div>
                  </Col>
                  ))
                }
                {/* Student Metric */}
                {
                  [ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0 && MetricsForm.map( (item, index) => (
                    <Col key={index} xs="12" md={ 5 } lg="3">
                      <div className="card widget-content">
                          <div className="widget-content-outer">
                          <div className="icon-wrapper rounded mb-3">
                            <div className={ `icon-wrapper-bg bg-${ item.color }` }/>
                            <i style={{ fontSize: '2rem' }} className={ `${ item.icon } text-${ item.color }` }/>
                          </div>
                              <div className="widget-content-wrapper">
                                  <div className="widget-content-left">
                                      <div className="widget-heading">
                                          { item.title }
                                      </div>
                                      <div className="widget-subheading mt-1">
                                        <p className='mb-0'>
                                          <strong>
                                          { `${ formData[ item.success_value ] || 0 } ` }
                                          </strong>
                                          { item.success_text }
                                          <strong>
                                            { formData.total_student - formData[ item.success_value ] }
                                          </strong>
                                          { ` pending`}
                                        </p>
                                      </div>
                                  </div>
                                  <div className="widget-content-right">
                                      <div className={ `widget-numbers text-${ item.color }` }>
                                        { formData.total_student }
                                      </div>
                                  </div>
                              </div>
                              <div className="widget-progress-wrapper">
                                  <Progress 
                                    className="progress-bar-sm" 
                                    color={ item.color } value={ 
                                      formData[ item.success_value ]/formData.total_student * 100
                                    }/>
                                  <div className="progress-sub-label">
                                      <div className="sub-label-left">
                                        { item.subtitle }
                                      </div>
                                      <div className="sub-label-right">
                                        { 
                                          formData.total_student === 0
                                            ? '0%'
                                            : `${ (formData[ item.success_value ]/formData.total_student * 100).toFixed(2) }%` 
                                        }
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </Col>
                  ))
                }
              </Row>
            </Col>
            <Col md="8" lg="4">
              <AnnouncementContent
                current_selected_session_id={ this.props.data.SessionReducer.current_selected_session_id }
                selectedAnnouncement={ this.props.selectedAnnouncement }
                showAnnouncementDetails={ this.props.showAnnouncementDetails }
              />
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        { 
          ( 
            this.props.onLoadDashboard || 
            this.props.onLoadAcademicAdvisors || 
            this.props.onLoadStudents
          ) && <LoadingOverlay />
        }
        <EditForm
          {...this.props}
          selectedFormData={ this.props.selectedUser }
          open={ this.props.showUpdateUsersModal }
          onClose={ () => this.props.onChangeUsersHOC( 'showUpdateUsersModal', false )}
        />
        { 
          ( this.props.data.ProfileReducer.current_role_id === 14 || this.props.data.ProfileReducer.current_role_id === 15 ) && !_.isEmpty( this.props.selectedStudent ) && (
            <StudentDetailsModal
              showStudentDetailsModal={ this.props.showStudentDetailsModal }
              selectedStudent={ this.props.selectedStudent }
              onChangeStudentsHOC={ this.props.onChangeStudentsHOC } />
          )
        }
      </>
    )
  }
}

export default compose(
  StatisticsHOC, 
  WithInternalSupervisorStudent,
  WithStudents,
  WithExternalSupervisorStudents,
  WithAcademicAdvisorStudents,
)(Statistics)