import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Delete, Post, Put, Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedUser: null,

      showCreateUserModal: false,
      showDeleteUserModal: false,
      showUpdateUserModal: false,
      externalSV: []
    }

    onChangesUserHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    updateUser = dataToSubmit => Put(
      `/users/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateUserSuccess,
      this.updateUserError,
      this.load
    )
    updateUserSuccess = () => {
      let { id: companyId } = this.props.selectedCompany?.company
      this.props.getSelectedCompany( companyId )
      this.setState({ showUpdateUserModal: false })
      this.requestSuccess( 'User info was updated successfully.' )
    }
    
    updateUserError = error => this.requestError( error )
    
    createUser = dataToSubmit => Post(
      `/users`,
      {
        ... dataToSubmit,
        company_id: this.props.selectedCompany.company.id
      },
      this.createUserSuccess,
      this.createUserError,
      this.load
    )
    createUserSuccess = payload => {
      this.props.getSelectedCompany( payload.company_id )
      this.setState({ showCreateUserModal: false })
      this.requestSuccess( 'User info was created successfully.' )
    }
    createUserError = error => this.requestError( error )
    
    deleteUser = data => Put( 
      `/users/${ data.id }`,
      { ...data, company_id: null },
      this.deleteUserSuccess,
      this.deleteError,
      this.load
    )
    deleteUserSuccess = payload => {
      this.props.getSelectedCompany( this.props.selectedCompany.company.id )
      this.setState({ showDeleteUserModal: false })
      this.requestSuccess( 'User has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    getExternalSV = () => Get(
      `/users?filter_role_id=[15]`,
      this.getExternalSVSuccess,
      this.getExternalSVError,
      this.load
    )
    getExternalSVSuccess = payload => this.setState({ externalSV: payload?.data??'' })
    getExternalSVError = err => this.requestError( err )

    assignCompanyUser = ({ company_id, user_id }) => Put(
      `/companies/${ company_id }/users/${ user_id }`,
      '',
      () => this.assignCompanyUserSuccess( company_id ),
      this.assignCompanyUserError,
      this.load
    )
    assignCompanyUserSuccess = company_id => {
      this.props.getSelectedCompany( company_id )
      this.setState({ showCreateUserModal: false })

      this.requestSuccess( 'User info was created successfully.' )
    }
    assignCompanyUserError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadUser={ this.state.loading }
          selectedUser={ this.state.selectedUser }
          externalSV={ this.state.externalSV }
          showCreateUserModal={ this.state.showCreateUserModal }
          showDeleteUserModal={ this.state.showDeleteUserModal }
          showUpdateUserModal={ this.state.showUpdateUserModal }

          updateUser={ this.updateUser }
          createUser={ this.createUser }
          deleteUser={ this.deleteUser }
          assignCompanyUser={ this.assignCompanyUser}
          getExternalSV={ this.getExternalSV }
          onChangesUserHOC={ this.onChangesUserHOC }/>
      )
    }
  }
  return WithHOC
}

export default HOC