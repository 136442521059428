import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form, Input,
  FormGroup, Label,
  Card, CardBody, CardHeader
} from 'reactstrap'
import _ from 'lodash'
import AcceptedListModalContent from './acceptedListContent'
import DatePicker from 'react-datepicker'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { FormDefaultValue, FieldData } from './assets'

const FormContent = ({
  open,
  onLoadForm,

  onClose,
  onClickSubmit
}) => {

  const [ formData, onChangeFormData ] = useState( FormDefaultValue )
  const [ showAcceptedModal, onToggleAcceptedModal ] = useState( false )
  const [ selectedAcceptedVacancies, onSelectAcceptedVacancies ] = useState( null )

  const onChangeFormField = ( context, val ) => {
    let temp = _.cloneDeep( formData )
    temp[context] = val

    onChangeFormData( temp )
  }

  useEffect(() => {
    !open && onChangeFormData( FormDefaultValue )
  }, [ open ])

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create Lectuter Visit Schedule` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
          <Col lg={ 12 }>
            <FormGroup>
              <Card>
                <CardHeader>
                  Assigned Vacancy
                  <span className="text-danger ml-1">*</span>
                  <Button 
                    size={ 'sm' }
                    color="primary"
                    className="ml-auto d-block"
                    onClick={() => onToggleAcceptedModal( true )}>
                    Assign
                  </Button>
                </CardHeader>
                <CardBody>
                {
                  selectedAcceptedVacancies && (
                    <>
                      <div className={ 'd-flex align-items-center' }>
                        <i  id={ 'TooltipStudent' } className="font-size-lg header-icon pe-7s-study mr-2 text-muted opacity-6"/>
                        { `Student name - ` }
                        <b className={ 'text-warning ml-1' }>{ ` ${ selectedAcceptedVacancies.student_name }` }</b>
                      </div>
                      <div className={ 'd-flex align-items-center mt-2' }>
                        <i className="font-size-lg header-icon pe-7s-culture mr-2 text-muted opacity-6"/>
                        { `Company name -` }
                        <b className={ 'text-warning ml-1' }>{ `${ selectedAcceptedVacancies.company_name }` }</b>
                      </div>
                    </>
                  )
                }
                {
                  !selectedAcceptedVacancies && (
                    <i>No vacancy is assigned yet.</i>
                  )
                }
                </CardBody>
              </Card>
            </FormGroup>
          </Col>
          {
            FieldData.map( field => (
              <Col lg={ field.col }>
                <FormGroup>
                  <Label>
                    { `${ field.label } ` }
                    { field.required && <span className="text-danger">*</span> }
                  </Label>
                  {
                    field.type === 'textarea' && (
                      <Input
                        type={ field.type }
                        rows={ 5 }
                        value={ formData[ field.value ] }
                        onChange={ e => onChangeFormField( field.value, e.target.value )}
                      />
                    )
                  }
                  {
                    field.type !== 'textarea' && (
                      <DatePicker
                        className={ 'w-100' }
                        showTimeSelect={ field.showTimeSelect }
                        showTimeSelectOnly={ field.showTimeSelect }
                        dateFormat={ field.showTimeSelect
                          ? "h:mm aa"
                          : "dd-MM-yyyy"
                        }
                        selected={ formData[ field.value ] }
                        onChange={ val => {
                          onChangeFormField( field.value, val )
                        }}/>
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          </Row>
        </Form>
        { onLoadForm && <LoadingOverlay/> }
        <Modal
          isOpen={ showAcceptedModal }
          size={ 'xl' }>
          <ModalHeader toggle={() => onToggleAcceptedModal( false )}>{ `Assign Internship` }</ModalHeader>
          <ModalBody>
            <AcceptedListModalContent
              selectedVisitVacancy={ selectedAcceptedVacancies }
              onSelectAcceptedVacancies={ onSelectAcceptedVacancies }
            />
          </ModalBody>
        </Modal>
      </ModalBody>
      <ModalFooter>
        <Button 
          color={
            ( FieldData.some( item => item.required && !formData[ item.value ]) || !selectedAcceptedVacancies )
              ? 'secondary' 
              : 'primary'
          }
          className="mr-2"
          disabled={ FieldData.some( item => item.required && !formData[ item.value ]) || !selectedAcceptedVacancies }
          onClick={() => {
            onClickSubmit({
              ... formData,
              date: Moment( formData.date ).format(),
              start_time: Moment( formData.start_time ).format(),
              end_time: Moment( formData.end_time ).format(),
              user_id: selectedAcceptedVacancies.user_id,
              company_id: selectedAcceptedVacancies.company_id,
              session_id: selectedAcceptedVacancies.session_id,
              student_vacancy_accepted_list_id: selectedAcceptedVacancies.id,
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent