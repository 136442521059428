import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import AboutMeContent from './AboutMe'
import EducationContent from './Education'
import ExperienceContent from './Experiences'
import SkillsContent from './Skills'
import LanguageContent from './LanguageContent'

import DictionaryHOC from '../actions/dictionary'

const tabOptions = [
  { id: 1, label: 'About me' },
  { id: 2, label: 'Education' },
  { id: 3, label: 'Experiences' },
  { id: 4, label: 'Skills' },
  { id: 5, label: 'Languages' },
  { id: 6, label: 'Summary' }
]

const EditForm = props => {
  const {
    open
  } = props

  const [ activeTab, onChangeActiveTab ] = useState( 1 )
  useEffect(() => {
    if ( !open ) {
      onChangeActiveTab( 1 )
    }
  }, [open])

  return (
    <>
      <div className="d-flex mb-3 flex-wrap" style={{ gap: 10 }}>
        {
          tabOptions.map( tab => (
            <button
              key={ tab.id }
              className={ `btn ${ activeTab === tab.id ? 'btn-primary' : 'btn-outline-primary' }` }
              onClick={ () => onChangeActiveTab( tab.id )}
            >
              { tab.label }
            </button>
          ))
        }
      </div>
      {
        activeTab === 1 && (
          <AboutMeContent 
            { ... props }
            mode={ 'normal' }
          />
        )
      }
      {
        activeTab === 2 && (
          <EducationContent 
            { ... props }
            mode={ 'normal' }
          />
        )
      }
      {
        activeTab === 3 && (
          <ExperienceContent 
            { ... props }
            mode={ 'normal' }
          />
        )
      }
      {
        activeTab === 4 && (
          <SkillsContent 
            { ... props }
            mode={ 'normal' }
          />
        )
      }
      {
        activeTab === 5 && (
          <LanguageContent 
            { ... props }
            mode={ 'normal' }
          />
        )
      }
      {
        activeTab === 6 && (
          <>
            <AboutMeContent 
              { ... props }
              mode={ 'view' }
            />
            <EducationContent 
              { ... props }
              mode={ 'view' }
            />
            <ExperienceContent 
              { ... props }
              mode={ 'view' }
            />
            <SkillsContent 
              { ... props }
              mode={ 'view' }
            />
            <LanguageContent 
              { ... props }
              mode={ 'view' }
            />
          </>
        )
      }
    </>
  )
}

export default DictionaryHOC( EditForm );