import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      designations: [],
      industryCat: [],
      countries: [],
      states: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCountry = () => Get(
      `/countries`,
      this.getCountrySuccess,
      this.getCountryError,
      this.load
    )
    getCountrySuccess = payload => this.setState({ countries: payload })
    getCountryError = error => this.requestError( error )

    getCoutryState = id => Get(
      `/countries/${ id }/states`,
      this.getCoutryStateSuccess,
      this.getCoutryStateError,
      this.load
    )
    getCoutryStateSuccess = payload => this.setState({ states: payload })
    getCoutryStateError = error => this.requestError( error )

    getDesignations = () => Get(
      `/designations?query=${ btoa(`{"page":0, "per_page": 1000}`)}`,
      this.getDesignationsSuccess,
      this.getDesignationsError,
      this.load
    )
    getDesignationsSuccess = payload => this.setState({ designations: payload.data })
    getDesignationsError = error => this.requestError( error )

    getIndustryCat = () => Get(
      `/industry_categories?query=${ btoa(`{"page": 0}`)}`,
      this.getIndustryCatSuccess,
      this.getIndustryCatError,
      this.load
    )
    getIndustryCatSuccess = payload => this.setState({ industryCat: payload.data })
    getIndustryCatError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          states={ this.state.states }
          countries={ this.state.countries }
          onLoadDictionary={ this.state.loading }
          designations={ this.state.designations }
          industryCat={ this.state.industryCat }

          getCountry={ this.getCountry }
          getCoutryState={ this.getCoutryState }
          getIndustryCat={ this.getIndustryCat }
          getDesignations={ this.getDesignations }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC