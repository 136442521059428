import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardHeader, CardBody,
  Modal, ModalHeader, 
  Button, UncontrolledTooltip, Collapse 
} from 'reactstrap'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'  
import EditForm from './Edit'
import Pagination from 'components/Pagination'

import FormHOC from './actions'
import AcceptedListHOC from './actions/acceptedList'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class Form extends Component {

  componentDidUpdate = pp => {
    const { data } = this.props
    const { current_selected_session_id } = this.props.data.SessionReducer
    const { current_role_id } = this.props.data.ProfileReducer
    const contact_person_email = this.props.data.ProfileReducer.profile.company?.contact_person_email || ""
    const { id, email } = this.props.data.ProfileReducer.profile.user

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
        this.props.getForm( btoa(`{ "page": 1, "filter":{ "s.session_id": ${ current_selected_session_id } } }`) )
      } else if (current_role_id === 14) {
        this.props.getForm( btoa(`{ "page": 1, "filter":{ "sval.external_supervisor_id": ${ id }, "s.session_id": ${ current_selected_session_id } } }`) )
      } else {
        this.props.getForm( btoa(`{ "page": 1, "filter":{ "s.session_id": ${ current_selected_session_id } } }`) )
      }

      if ( data.ProfileReducer.current_role_id === 13  ){
        this.props.getAcceptedList( btoa(`{"page":1, "filter": { "student_vacancies.student_id": ${ data.ProfileReducer.profile.student.id } }}`) )
      }
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
        this.props.getForm( btoa(`{ "page": 1, "filter":{ "s.session_id": ${ current_selected_session_id } } }`) )
      } else if (current_role_id === 14) {
        this.props.getForm( btoa(`{ "page": 1, "filter":{ "sval.external_supervisor_id": ${ id }, "s.session_id": ${ current_selected_session_id } } }`) )
      } else {
        this.props.getForm( btoa(`{ "page": 1, "filter":{ "s.session_id": ${ current_selected_session_id } } }`) )
      }
    }
  }

  renderNonStudentContent = () => {
    const { is_update } = this.props.currentPermission

    return (
      <>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    this.props.dutyForms.data.length < 1? 3: 
                    this.props.dutyForms.data.length < 10? this.props.dutyForms.data.length:
                    10
                  }
                  data={ this.props.dutyForms.data }
                  columns={[
                    {
                      Header: 'Student Name',
                      accessor: 'student_name'
                    },
                    {
                      Header: 'Student Email',
                      accessor: 'student_email'
                    },
                    {
                      Header: 'Company',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'Reviewed ?',
                      Cell: cell =>(
                        <>
                          {
                            cell.original.is_reviewed 
                              ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                              : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
                          }
                        </>
                      )
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="UpdateForm"
                              className="mr-2 btn-icon btn-icon-only"
                              disabled={ !is_update }
                              color={ is_update ? 'primary' : 'secondary' }
                              onClick={() => {
                                this.props.getSelectedForm( row.original.id )
                                
                                if ( this.props.data.ProfileReducer.current_role_id !== 13 ){
                                  this.props.getAcceptedList( btoa(`{"page":1, "filter": { "student_vacancies.student_id": ${ row.original.student_id } }}`))
                                }
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="UpdateForm" placement="left">Update the information of this report duty form</UncontrolledTooltip>
                            <Button
                              id="DownloadReportDuty"
                              className="mr-2 btn-icon btn-icon-only"
                              color="warning"
                              onClick={() => this.props.getStudentReportDuty_pdf(row.original.id, row.original.student_name) }
                            >
                              <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="DownloadReportDuty" placement="left">Download this report duty form</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={false} />
                <Pagination 
                  metadata={ this.props.dutyForms.meta } 
                  onChangePage={ pg => {
                    const { search } = this.props.data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    this.props.getForm( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={ this.props.showUpdateFormModal }
          size={ 'xl' }>
          <ModalHeader toggle={() => this.props.onChangeFormHOC( 'showUpdateFormModal', false )}>{
            `Edit Report Duty Form` }
          </ModalHeader>
          <EditForm
            selectedForm={ this.props.selectedForm }
            onLoadForm={ this.props.onLoadForm }
            selectedAcceptedList={ this.props.selectedAcceptedList }
            onClickSubmit={ this.props.updateForm }
          />
        </Modal>
      </>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Report Duty Form"
          subheading="Listings of all the report duty form present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeFormHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeFormHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeFormHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 's.session_id' )

                this.props.getForm( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          {
            this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent()
          }
          {
            this.props.data.ProfileReducer.current_role_id === 13 &&  (
              <>
                {
                  _.keys( this.props.selectedForm ).length > 0 && (
                    <EditForm
                      selectedForm={ this.props.selectedForm }
                      onLoadForm={ this.props.onLoadForm }
                      selectedAcceptedList={ this.props.selectedAcceptedList }
                      onClickSubmit={ this.props.updateForm }
                    />
                  )
                }
                {
                  _.keys( this.props.selectedForm ).length < 1 && (
                    <Card>
                      <CardHeader>No Report Duty Form is available. </CardHeader>
                    </Card>
                  )
                }
              </>
            )
          }
        </ReactCSSTransitionGroup>
        {( this.props.onLoadForm || this.props.onLoadAcceptedList ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  FormHOC,
  AcceptedListHOC
)(Form)