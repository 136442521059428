export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL'
export const AJAX_CALL_SUCCESS = 'AJAX_CALL_SUCCESS'
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR'

export const GET_PROFILE = 'GET_PROFILE'
export const SET_CURRENT_ROLE = 'SET_CURRENT_ROLE'

export const STORE_LAST_VIEW = 'STORE_LAST_VIEW'

export const GET_DESIGNATION = 'GET_DESIGNATION'
export const GET_DICTIONARY_ROLE = 'GET_DICTIONARY_ROLE'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'

export const STORE_LOGIN_EMAIL = 'STORE_LOGIN_EMAIL'

export const SET_CURRENT_SESSION= 'SET_CURRENT_SESSION'
export const GET_SESSIONS= 'GET_SESSIONS'