import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      announcements: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      showAnnouncementDetails: '',
      selectedAnnouncement: null
    }

    onChangeAnnouncementsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAnnouncements = search => Get(
      `/announcements?query=${ search }`,
      this.getAnnouncementsSuccess,
      this.getAnnouncementsError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getAnnouncementsSuccess = payload => this.setState({ announcements: payload })
    getAnnouncementsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          announcements={ this.state.announcements }
          showAnnouncementDetails={ this.state.showAnnouncementDetails }
          selectedAnnouncement={ this.state.selectedAnnouncement }
          onLoadAnnouncement={ this.state.loading }


          getAnnouncements={ this.getAnnouncements }
          onChangeAnnouncementsHOC={ this.onChangeAnnouncementsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC