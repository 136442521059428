export const FieldData = [
  {
    card_name: 'Student Details',
    card_obj: 'student',
    editable: false,
    fields: [
      { label: 'Name', value: 'name', type: 'text' },
      { label: 'Matric No.', value: 'matric_number', type: 'text', col: 6 },
      { label: 'IC No.', value: 'ic', type: 'text', col: 6 },
      { label: 'Email', value: 'email', type: 'text' },
      { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678' }
    ]
  },
  {
    card_name: 'Company Details',
    card_obj: 'company',
    editable: false,
    fields: [
      { 
        label: 'Company Name', value: 'company_name',type: 'text'
      },
      { 
        label: 'Company Address', value: 'company_address',type: 'textarea'
      },
    ]
  },
  {
    card_name: 'Report Duty Details',
    card_obj: 'createData',
    editable: true,
    fields: [
      { label: 'HR Name', type: 'text', value: 'hr_officer_name', required: true },
      { label: 'HR Email', type: 'text', value: 'hr_officer_email', required: true  },
      { label: 'HR Phone No.', type: 'text', value: 'hr_officer_phone', required: true },
      { label: 'HR Mobile Phone No.', type: 'text', value: 'hr_officer_mobile_phone', required: true },
      { label: 'Date of Report Duty', type: 'date', value: 'report_duty_date', col: 6, required: true },
    ]
  }
]

export const CheckboxFields = [
  {
    label: 'Allowance',
    value: 'is_have_allowance'
  },
  {
    label: 'Changes in Job scope',
    subLabel: 'If yes please fill in the job scope form again',
    value: 'is_change_job_scope'
  },
  {
    label: 'Report Duty Form has been checked.',
    value: 'is_reviewed'
  }
]

export const defaultValue = {
  hr_officer_name: '',
  hr_officer_email: '',
  hr_officer_phone: '',
  hr_officer_mobile_phone: '',
  report_duty_date: new Date(),
  is_have_allowance: false,
  is_change_job_scope: false
}