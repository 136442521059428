import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Put, Delete } from 'utils/axios'
import Cookies from 'js-cookie'
import Axios from 'axios'
import getDomainURL from 'utils/api'
import getBackendURL from 'utils/backendUrl'

import { storeLastView } from 'actions/lastView'

const searchParams = [
  { 
    label: 'Name', 
    value: 'su.name',
    type: 'text',
    param: '',
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      insurances: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },

      sessions: [],
      selectedInsurance: {
        // doc_url: '',
        // is_clerk_approved: false
      },
      searchParams: searchParams,
      showUploadInsuranceTemplate: false,
      showDeleteInsuranceModal: false,
      showUpdateInsurancesModal: false,
      showSearchForms: false
    }

    onChangeInsurancesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSessions = search => Get(
      `/sessions?query=${ search }`,
      this.getSessionsSuccess,
      this.getSessionsError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getSessionsSuccess = payload => {
      this.setState({ sessions: payload?.data[0].session })
    }
    getSessionsError = error => this.requestError( error )

    getInsurances = search => {
      this.props.storeLastView({ search })

      Get(
        `/student_insurance_forms/all_students?query=${ search }`,
        this.getInsurancesSuccess,
        this.getInsurancesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getInsurancesSuccess = payload => {
      let temp = _.map( payload.data, ({ student_insurance_form, student_user,company }, index ) => {

      return ({
        number: (index + 1)  + (( payload.meta.page - 1) * 10 ),
        ... student_insurance_form,
        student_name: student_user.name,
        company_name: company.name,
        doc_url: student_insurance_form.doc_url
          ? `${ student_insurance_form.doc_url }`
          : ''
        })
      })

      this.setState({ insurances: {
        meta: payload.meta,
        data: temp
      }})
    }
    getInsurancesError = error => this.requestError( error )

    uploadInsuranceDoc = ( id, dataToSubmit, removeFilePond ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/student_insurance_forms/doc/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'insuranceDoc', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
       Axios.post(url, tempForm, config ).then( payload => {
        this.load( false )
        removeFilePond()
        this.uploadInsuranceDocSuccess( payload?.data?.link??'' )
       }).catch( err => {
        this.load( false )
        this.uploadInsuranceDocError( err )
       })
    }
    uploadInsuranceDocSuccess = link => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getInsurances( search )
      this.setState( prevState => ({ 
        selectedInsurance: {
          ... prevState.selectedInsurance,
          doc_url: link ? `${ link }` : ''
        },
        showuploadInsuranceDocsModal: false 
      }))
      this.requestSuccess( 'Insurances has been created successfully.' )
    }
    uploadInsuranceDocError = error => this.requestError( error )

    uploadInsuranceTemplate = ( id, dataToSubmit, removeFilePond ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/student_insurance_forms/template/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'insuranceTemplate', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
       Axios.put(url, tempForm, config ).then( payload => {
        this.load( false )
        removeFilePond()
        this.uploadInsuranceTemplateSuccess( payload?.data?.link??'' )
      }).catch( err => {
        this.load( false )
        this.uploadInsuranceTemplateError( err )
       })
    }
    uploadInsuranceTemplateSuccess = link => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getSessions( search )
      this.setState( prevState => ({ 
        sessions: {
          ... prevState.sessions,
          student_insurance_form_template_url: link ? `${ link }` : ''
        },
        showUploadInsuranceTemplate: false 
      }))
      this.requestSuccess( 'Insurances Template has been created successfully.' )
    }
    uploadInsuranceTemplateError = error => this.requestError( error )

    deleteInsurance = id => Delete( 
      `/student_insurance_forms/${ id }`,
      this.deleteInsuranceSuccess,
      this.deleteError,
      this.load
    )
    deleteInsuranceSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getInsurances( search )
      this.setState({ showDeleteInsuranceModal: false })
      this.requestSuccess( 'Insurance has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateInsurance = dataToSubmit => Put(
      `/student_insurance_forms/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateInsuranceSucces,
      this.updateInsuranceError,
      this.load
    )
    updateInsuranceSucces = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getInsurances( search )
      this.setState({ showUpdateInsurancesModal: false })
      this.requestSuccess( 'Insurance was updated successfully.' )
    }
    updateInsuranceError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          sessions={ this.state.sessions }
          insurances={ this.state.insurances }
          onLoadInsurance={ this.state.loading }
          selectedInsurance={ this.state.selectedInsurance }
          showSearchForms={ this.state.showSearchForms }
          showUploadInsuranceTemplate={ this.state.showUploadInsuranceTemplate }
          showDeleteInsuranceModal={ this.state.showDeleteInsuranceModal }
          showUpdateInsurancesModal={ this.state.showUpdateInsurancesModal }

          getSessions={ this.getSessions }
          getInsurances={ this.getInsurances }
          uploadInsuranceDoc={ this.uploadInsuranceDoc }
          uploadInsuranceTemplate={ this.uploadInsuranceTemplate }
          deleteInsurance={ this.deleteInsurance }
          updateInsurance={ this.updateInsurance }
          onChangeInsurancesHOC={ this.onChangeInsurancesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC