import { 
    SET_CURRENT_SESSION,
    GET_SESSIONS
} from 'actions/type'

const initialState = {
  current_selected_session_id: null,
  sessions: [],
}

export default ( state = initialState, actions ) => {

  switch ( actions.type ) {
    case SET_CURRENT_SESSION:
      return ({
        ...state,
        current_selected_session_id: actions.payload
      })
    
    case GET_SESSIONS:
      return ({
        ...state,
        sessions: actions.payload
      })
  
    default: return state
  }
}