import React, { useEffect, useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
 
const defaultValue = {
  name: '',
}

const FormContent = ({
  mode,
  open,
  onClose,
  onLoadDesignation,
  selectedFormData,
  onClickSubmit
}) => {

  const [ designationsData, onChangeDesignationsData ] = useState( defaultValue )

  useEffect(() => {
    if ( open && mode === 'Edit' ){
      onChangeDesignationsData( selectedFormData )
    } 
    if ( !open ){
      onChangeDesignationsData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    mode === 'Edit' && onChangeDesignationsData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeDesignationsField = ( key, val ) => {
    let tmp = _.cloneDeep( designationsData )
    tmp[ key ] = val
    return onChangeDesignationsData( tmp )
  }
  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Designations` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>
              Name
              <span className={ 'text-danger' }> *</span>
            </Label>
            <Input
              type={ 'text' }
              value={ designationsData.name }
              onChange={ e => {
                onChangeDesignationsField( 'name', e.target.value )
              }} />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ 
            _.values( designationsData ).some( item => !item ) 
              ? "secondary" 
              : 'primary'
          }
          className="mr-2"
          disabled={ _.values( designationsData ).some( item => !item )}
          onClick={() => {
            onClickSubmit({
              ... designationsData,
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
      { onLoadDesignation && <LoadingOverlay/> }
    </Modal>
  )
}

export default FormContent