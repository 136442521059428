export const StatusLib = [
  { label: 'Started' },
  { label: 'On-going' },
  { label: 'Complete' },
]

export const ViewData = [
  {
    card_name: 'Trainee Info',
    card_obj: 'student_user',
    fields: [
      { label: 'Name', value: 'name', type: 'text' },
      { label: 'Email', value: 'email', type: 'text', col: 6 },
      { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', col: 6 }
    ]
  },
  {
    card_name: 'Supervisor Info',
    card_obj: 'company',
    fields: [
      { 
        label: 'Company Name', value: 'company_name',type: 'text'
      },
      { 
        label: 'Supervisor Name', value: 'external_sv_name',type: 'text'
      }
    ]
  }
]