import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import RoleDetails from './Details'
import Pagination from 'components/Pagination'

import RolesHOC from 'actions/hoc/role'
import RoleDetailsHOC from './actions/details'

class Roles extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getRoles( btoa(`{"page":1}`) )
    }
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Roles"
          subheading="Listings of all the roles present in the system."
          icon="pe-7s-user icon-gradient bg-happy-itmeo"
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.roles.data.length < 1? 3: 
                      this.props.roles.data.length < 10? this.props.roles.data.length:
                      10
                    }
                    data={ this.props.roles.data }
                    columns={[
                      {
                        Header: 'Role',
                        accessor: 'name'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateStudent"
                                className="mr-2 btn-icon btn-icon-only"
                                color="primary"
                                onClick={() => {
                                  this.props.getRoleSecModules(row.original)
                                }}>
                                <i className="pe-7s-info btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateStudent" placement="left">View Role Details </UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]
                  }
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.roles.meta } 
                    onChangePage={ pg => this.props.getRoles( btoa( `{"page":${ pg }}` ))} 
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <RoleDetails
            { ...this.props }
            open={ this.props.showRolesDetails }
            onClose={ () => this.props.onChangeDetailsHOC( 'showRolesDetails', false )}
          />
        </ReactCSSTransitionGroup>
        { ( this.props.onLoadRoles || this.props.onLoadDetails ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  RolesHOC,
  RoleDetailsHOC
)(Roles)