import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Card, CardBody, CardHeader,
  Button, 
  UncontrolledTooltip 
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateExperienceForm from './Create'
import UpdateExperienceForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import ExperienceHOC from './actions'

class Experience extends Component {

  componentDidMount = () => {
    this.props.getExperience( btoa(`{ "page": 1, "filter": { "student_cvs.id": ${ this.props.selectedStudentCV.student_cv.id } }}`) )
  }

  render = () => {
    const { mode } = this.props
    return (
      <>
        <Card className="main-card mb-3">
          <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
            Student's Experience
            {
              mode === 'normal' && (
                <Button
                  className="ml-auto btn-sm"
                  color="primary"
                  onClick={() => this.props.onChangeExperienceHOC( 'showCreateExperienceModal', true )}
                >
                  Add Experience
                </Button>
              )
            }
          </CardHeader>
            <CardBody>
              <ReactTable
                pageSize={ 
                  this.props.studentExperience.data.length < 1? 3: 
                  this.props.studentExperience.data.length < 10? this.props.studentExperience.data.length:
                  10
                }
                data={ this.props.studentExperience.data }
                columns={[
                  {
                    Header: 'Company Name',
                    accessor: 'company_name'
                  },
                  {
                    Header: 'Position',
                    accessor: 'position'
                  },
                  {
                    Header: 'Start Date',
                    accessor: 'start_date'
                  },
                  {
                    Header: 'End Date',
                    accessor: 'end_date'
                  },
                  ...( mode === 'normal' ? [{
                    Header: 'Actions',
                    Cell: (row) => {
                      return (
                        <>
                          <Button
                            id="UpdateExperience"
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={() => {
                              this.props.getSelectedExperience( row.original.id )
                            }}>
                            <i className="pe-7s-pen btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="UpdateExperience" placement="left">Update student's CV working experience.</UncontrolledTooltip>
                          <Button
                            id="DeleteExperience"
                            className="mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={() => {
                              this.props.onChangeExperienceHOC( 'showDeleteExperienceModal', true )
                              this.props.onChangeExperienceHOC( 'selectedExperience', row.original )
                            }}>
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="DeleteExperience" placement="left">Delete this student's CV working experience.</UncontrolledTooltip>
                        </>
                      )
                    }
                  }] : [] )
                ]}
                showPagination={false} />
              <Pagination 
                metadata={ this.props.studentExperience.meta } 
                onChangePage={ pg => {
                  let tempJSON = JSON.parse( atob( this.props.experienceSearchQuery ))
                  tempJSON.page = pg
                  
                  this.props.getExperience( btoa( JSON.stringify( tempJSON )))
                }} />
            </CardBody>
          </Card>
        <CreateExperienceForm
          {...this.props}
          open={ this.props.showCreateExperienceModal }
          onClose={ () => this.props.onChangeExperienceHOC( 'showCreateExperienceModal', false )}
          onClickSubmit={ this.props.createExperience }
        />
        <UpdateExperienceForm
          {...this.props}
          selectedFormData={ this.props.selectedExperience }
          open={ this.props.showUpdateExperienceModal }
          onClose={ () => this.props.onChangeExperienceHOC( 'showUpdateExperienceModal', false )}
          onClickSubmit={ this.props.updateExperience }
        />
        <ConfirmationModal
          loading={ this.props.onLoadExperience }
          open={ this.props.showDeleteExperienceModal}
          title={ `Delete` }
          message={ 
            <p>
              Are you sure you want to delete this <b>student's working experience record</b> from his/her CV ? (This process can't be undone)
            </p>
          }
          onClose={ () => this.props.onChangeExperienceHOC( 'showDeleteExperienceModal', false )}
          onClickConfirm={ () => this.props.deleteExperience( this.props.selectedExperience.id )}/>
        { this.props.onLoadExperience && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  ExperienceHOC
)(Experience)