import React, { Component } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { storeLastView } from 'actions/lastView'

import { Get } from 'utils/axios'
import { DropdownItem } from 'reactstrap'

const searchParams = []

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentAcceptedVacancies: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },

      showSearchForms: false,
      selectedAcceptedVacancies: null,
      searchParams: searchParams
    }

    onChangeStudentAcceptedVacanciesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentAcceptedVacancies = search => {
      this.props.storeLastView({ search })
      Get(
        `/student_vacancy_accepted_lists?query=${ search }`,
        this.getStudentAcceptedVacanciesSuccess,
        this.getStudentAcceptedVacanciesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentAcceptedVacanciesSuccess = payload => {
      let tempData = []
      payload.data && payload.data.map( item => {
        tempData.push({
          ... item,
          ... item.student_vacancy_accepted_list,
          accepted_date: Moment.utc( item.student_vacancy_accepted_list.accepted_date ).format( 'DD-MM-yyyy h:mm a' ),
          student_name: item.student.user.name,
          company_name: item.company_vacancy.company.name,
          internal_supervisor: item.external_supervisor?.name??'Internal',
          external_supervisor: item.internal_supervisor?.name??'External',
          user_id: item.student.user_id,
          company_id: item.company_vacancy.company_id,
          session_id: item.student.session_id,
          student_vacancy_accepted_list_id: item.id,
        })
      })

      this.setState({ studentAcceptedVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getStudentAcceptedVacanciesError = error => this.requestError( error )


    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          selectedAcceptedVacancies={ this.state.selectedAcceptedVacancies }
          studentAcceptedVacancies={ this.state.studentAcceptedVacancies }
          onLoadStudentAcceptedVacancies={ this.state.loading }
          searchParams={ this.state.searchParams }
          showSearchForms={ this.state.showSearchForms }
          showUpdateScheduledLecturerModal={ this.state.showUpdateScheduledLecturerModal }
          showUpdateStudentAcceptedVacanciesModal={ this.state.showUpdateStudentAcceptedVacanciesModal }
          showDeleteStudentAcceptedVacanciesModal={ this.state.showDeleteStudentAcceptedVacanciesModal }

          uploadFinalReport={ this.uploadFinalReport }
          deleteStudentAcceptedVacancies={ this.deleteStudentAcceptedVacancies }
          getStudentAcceptedVacancies={ this.getStudentAcceptedVacancies }
          getSelectedStudentAcceptedVacancies={ this.getSelectedStudentAcceptedVacancies }
          updateStudentAcceptedVacancies={ this.updateStudentAcceptedVacancies }
          onChangeStudentAcceptedVacanciesHOC={ this.onChangeStudentAcceptedVacanciesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC