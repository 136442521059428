import React, { useMemo, useState } from 'react'
import { 
  Modal, ModalHeader, ModalBody,
  Card, CardBody, CardHeader,
  FormGroup,
  Input,
  Label,
  Row, Col
} from 'reactstrap'
import ConfirmationModal from 'components/Modal/confirmation'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'
import UserForm from './UserForm'
import StudentForm from './StudentForm'

import UserRoleHOC from '../actions/userRole'

const FormContent = ({
  data,
  open,
  onClose,

  selectedFormData,
  onLoadStudent,
  onLoadUser,
  onLoadLecturer,
  onLoadUserRole,
  
  updateUser,
  submitCangred,
  updateStudent,

  createUserRole,
  deleteUserRole
}) => {

  const { roles } = data.DictionaryReducer
  const [ newRole, onChangeNewRole ] = useState( null )
  const [ removeRole, onChangeRemoveRole ] = useState( null)

  let tempRoleName = useMemo(() => {
    let temp = _.find( roles, { id: selectedFormData?.role_id??1 })

    return temp?.name?? ''
  }, [ selectedFormData ])

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Edit User` }</ModalHeader>
      <ModalBody>
        <Card className={ 'mb-3' }>
          <CardHeader style={{ fontSize: '1rem' }}>
            User's Current Role
          </CardHeader>
          <CardBody className={ 'd-flex flex-wrap' }>
          {
            selectedFormData?.rolesInfo?.[0] &&
            selectedFormData.rolesInfo.map( roleItem => (
              <span 
                className="p-2 badge badge-secondary mr-2" 
                style={{ fontSize: 11, width: 'max-content', verticalAlign: 'center' }}
              >
                { roleItem?.name??'' }
                {
                  selectedFormData.rolesInfo.length > 1 && [ 1, 14, 15 ].indexOf( roleItem.id ) < 0 && (
                    <AiOutlineCloseCircle 
                      style={{ cursor: 'pointer', fontSize: 20, marginLeft: 8 }}
                      className={ 'color-warning' }
                      onClick={ () => onChangeRemoveRole( roleItem )}
                    />
                  )
                }
              </span>
            ))
          }
          </CardBody>
        </Card>
        {
          selectedFormData && selectedFormData.role_ids && selectedFormData.role_ids.indexOf( 13 ) < 0 && (
            <Card className={ 'mb-3' }>
              <CardHeader style={{ fontSize: '1rem' }}>
                Assign New Role
              </CardHeader>
              <CardBody>
                <Row>
                {
                  roles.map(({ name: roleName, id: roleId }) => (
                    <>
                      {
                        roleId !== 13 && selectedFormData.role_ids.indexOf( roleId ) < 0 && (
                          <Col lg={ 6 }>
                            <FormGroup check>
                              <Label check>
                                <Input 
                                  checked={ false } 
                                  type="checkbox" 
                                  disabled={ (roleId === 14 || roleId === 15) &&(selectedFormData.role_ids.indexOf( 14 ) >= 0 || selectedFormData.role_ids.indexOf( 15 ) >= 0) }
                                  onClick={ () => onChangeNewRole({
                                    name: roleName,
                                    id: roleId
                                  })}
                                />
                                { roleName }
                              </Label>
                            </FormGroup>
                          </Col>
                        )
                      }
                    </>
                  ))
                }
              </Row>
              </CardBody>
            </Card>
          )
        }
        {
          selectedFormData && selectedFormData.rolesInfo && selectedFormData.rolesInfo.find(o => o.id === 13)  && (
            <StudentForm
              selectedFormData={ selectedFormData }
              submitCangred={ submitCangred }
              updateStudent={ updateStudent }
            />
          )
        }
        {/* <Card className="main-card mb-3">
          <CardHeader style={{ fontSize: '1rem' }}>
            { `${ tempRoleName } Details`}
          </CardHeader>
          <UserForm 
            open={ open }
            selectedFormData={ selectedFormData }
            updateUser={ updateUser }
          /> 
        </Card> */}
        {
          selectedFormData && selectedFormData.role_ids && selectedFormData.role_ids.indexOf( 13 ) < 0  && (
            <Card className="main-card mb-3">
              <CardHeader style={{ fontSize: '1rem' }}>
                { `${ tempRoleName } Details`}
              </CardHeader>
              <UserForm 
                open={ open }
                selectedFormData={ selectedFormData }
                updateUser={ updateUser }
              /> 
            </Card>
          )
        }
        {( onLoadUser || 
            onLoadLecturer ||
            onLoadStudent
          ) && <LoadingOverlay />}
        <ConfirmationModal
          open={ newRole && _.values( newRole ).length > 0 }
          title={ `Add ${ newRole?.name??'' } Role` }
          loading={ onLoadUserRole }
          message={ `Are you sure you want to add ${ newRole?.name??'' } role for this user?` }
          onClose={ () => onChangeNewRole( null )}
          onClickConfirm={ () => {
            Promise.all([
              createUserRole({
                role_id: newRole.id,
                user_id: selectedFormData.id
              })
            ]).then(() => {
              onChangeNewRole( null )
            })
          }}/>
        <ConfirmationModal
          open={ removeRole && _.values( removeRole ).length > 0 }
          title={ `Remove ${ removeRole?.name??'' } Role` }
          loading={ onLoadUserRole }
          message={ `Are you sure you want to remove ${ removeRole?.name??'' } role from this user?` }
          onClose={ () => onChangeRemoveRole( null )}
          onClickConfirm={ () => {
            Promise.all([
              deleteUserRole( selectedFormData.id, removeRole.id )
            ]).then(() => onChangeRemoveRole( null ))
          }}/>
      </ModalBody>
    </Modal>
  )
}

export default UserRoleHOC( FormContent );
