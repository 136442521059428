export const UserFieldData = [
	{ label: "Name", value: "name", type: "text", required: true },
	{ label: "Email", value: "email", type: "text", required: true },
	{
		label: "Phone",
		value: "phone",
		type: "text",
		subLabel: "Eg: +6012345678",
		required: true,
	},
	{
		label: "Password",
		value: "password",
		type: "password",
		col: 6,
		required: true,
	},
	{
		label: "Confirm Password",
		value: "password_confirm",
		type: "password",
		col: 6,
		required: true,
	},
];

export const DefaultFormValue = {
	role_id: 14,
	name: "",
	email: "",
	phone: "",
	password: "",
	password_confirm: "",
	company_id: "",
};
