import React from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Collapse, Row, Col, 
  Card, CardBody,
  Button, 
  UncontrolledTooltip
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import StudentAcceptedVacanciesHOC from '../actions/acceptedVacancies'
import sessionQueryHandler from 'utils/sessionQueryHandler'
import { useEffect } from 'react'
  
const StudentAcceptedVacancies = ({
  studentAcceptedVacancies,
  data,
  searchParams,
  selectedAcceptedVacancy,
  showSearchForms,
  onLoadStudentAcceptedVacancies,

  onSelectAcceptedVacancies,
  getStudentAcceptedVacancies,
  onChangeStudentAcceptedVacanciesHOC
}) => {

  useEffect(( )=> {
    const { current_selected_session_id } = data.SessionReducer
    const { current_role_id } = data.ProfileReducer
    const contact_person_email = data.ProfileReducer.profile.company?.contact_person_email || ""
    const { id, email } = data.ProfileReducer.profile.user

    if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
      getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
    } else if (current_role_id === 14) {
      getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "student_vacancy_accepted_lists.external_supervisor_id": ${ id }, "students.session_id": ${ current_selected_session_id } } }`) )
    } else {
      getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
    }
  }, [])

  const { user } = data.ProfileReducer.profile
  const { current_role_id } = data.ProfileReducer

  return (
    <>
      <Button 
        color="warning"
        className={ 'btn-icon ml-auto d-flex mb-2' }
        onClick={ () => {
          onChangeStudentAcceptedVacanciesHOC( 'showSearchForms', !showSearchForms )
        }}
      >
        <>
          <i className="pe-7s-search btn-icon-wrapper"> </i>
          <span>Search</span>
        </>
      </Button>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}>
        <Collapse
          isOpen={ showSearchForms }
          onExiting={ () => onChangeStudentAcceptedVacanciesHOC( 'showSearchForms', false )}
        >
          <SearchForm
            searchParams={ searchParams }
            onChangeHOCState={ onChangeStudentAcceptedVacanciesHOC }
            getListAPI={ val => {
              let tempJSON = JSON.parse( atob( val ))
              tempJSON = sessionQueryHandler( tempJSON, data.SessionReducer.current_selected_session_id, 'students.session_id' )

              getStudentAcceptedVacancies( btoa( JSON.stringify( tempJSON )) )
            }}
          />
        </Collapse>
        <Row>
          <Col lg={ 12 }>
            <Card className="main-card mb-3">
              <CardBody>
                {/* <p className="text-warning mb-3 font-italic">Please make sure the student have submitted their final report.</p> */}
                <ReactTable
                  pageSize={ 
                    studentAcceptedVacancies.data.length < 1? 3: 
                    studentAcceptedVacancies.data.length < 10? studentAcceptedVacancies.data.length:
                    10
                  }
                  data={ studentAcceptedVacancies.data }
                  columns={[
                    {
                      Header: 'Student Name',
                      accessor: 'student_name'
                    },
                    {
                      Header: 'Company',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            {
                              row.original.has_external_evaluation && row.original.has_internal_evaluation && (
                                <Button
                                  disabled={ true }
                                  color={ 'secondary' }
                                  onClick={() => {}}
                                >
                                  Fully Assigned
                                </Button>
                              )
                            }
                            {
                              ( !row.original.has_external_evaluation || !row.original.has_internal_evaluation ) && (
                                <>
                                  <Button
                                    id="AssignStudentVacancies"
                                    className="mr-2"
                                    disabled={ 
                                      [ 1, 11 ].indexOf( current_role_id ) >= 0 ? selectedAcceptedVacancy?.id === row.original.id
                                      :(
                                      // check if student already selected
                                      selectedAcceptedVacancy?.id === row.original.id
                                      ||
                                      // student already has internal eval
                                      (
                                        data.ProfileReducer.current_role_id === 15 && row.original.has_internal_evaluation
                                      ) ||
                                      // student already has external eval
                                      (
                                        data.ProfileReducer.current_role_id === 14 && row.original.has_external_evaluation
                                      ))
                                      // ||
                                      // // student not submit final report
                                      // (
                                      //   _.isEmpty(row.original.final_report_url) 
                                      // )
                                    }
                                    color={ 
                                      ([ 1, 11 ].indexOf( current_role_id ) >= 0 ? selectedAcceptedVacancy?.id === row.original.id
                                      :(
                                      // check if student already selected
                                      selectedAcceptedVacancy?.id === row.original.id
                                      ||
                                      // student already has internal eval
                                      (
                                        data.ProfileReducer.current_role_id === 15 && row.original.has_internal_evaluation
                                      ) ||
                                      // student already has external eval
                                      (
                                        data.ProfileReducer.current_role_id === 14 && row.original.has_external_evaluation
                                      )))
                                      ? 'secondary'
                                      : 'success' 
                                    }
                                    onClick={() => onSelectAcceptedVacancies( row.original )}
                                  >
                                    { 
                                      ([ 1, 11 ].indexOf( current_role_id ) >= 0 ? selectedAcceptedVacancy?.id === row.original.id
                                      :(
                                      // check if student already selected
                                      selectedAcceptedVacancy?.id === row.original.id
                                      ||
                                      // student already has internal eval
                                      (
                                        data.ProfileReducer.current_role_id === 15 && row.original.has_internal_evaluation
                                      ) ||
                                      // student already has external eval
                                      (
                                        data.ProfileReducer.current_role_id === 14 && row.original.has_external_evaluation
                                      ))) 
                                      ? 'Assigned' 
                                      : 'Assign' 
                                    }
                                  </Button>
                                  <UncontrolledTooltip target="AssignStudentVacancies" placement="left">Assign student for evaluation form.</UncontrolledTooltip>
                                </>
                              )
                            }
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={ false } />
                <Pagination 
                  metadata={ studentAcceptedVacancies.meta } 
                  onChangePage={ pg => {
                    const { search } = data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    getStudentAcceptedVacancies( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
      { onLoadStudentAcceptedVacancies && <LoadingOverlay />}
    </> 
  )
}

export default compose(
  StudentAcceptedVacanciesHOC
)(StudentAcceptedVacancies)