export const DashboardMetrics = [
  {
    icon: 'pe-7s-culture',
    color: 'success',
    title: 'Total Companies',
    subtitle: 'Percentage of Approval',
    total_value: 'total_companies',
    success_value: 'total_approved_companies',
    link: '/dashboard/company'
  },
  {
    icon: 'pe-7s-tools',
    color: 'warning',
    title: 'Total Vacancies',
    subtitle: 'Percentage of Approval',
    total_value: 'total_vacancies',
    success_value: 'total_accepted_vacancies',
    link: '/dashboard/student_vacancies'
  }
]

export const MetricsForm = [
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Offer Letters',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'offer_letter_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Placement Form',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'placement_form_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Report Duty Form',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'report_duty_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Insurance Form',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'offer_letter_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Job Scope Form',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'job_scope_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Visit Schedule',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'visit_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total External Evaluation Form',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'external_eval_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Internal Evaluation Form',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'internal_eval_count'
  },
  {
    icon: 'pe-7s-note2',
    color: 'success',
    title: 'Total Final Report',
    subtitle: 'Submitted Rate',
    success_text: ' submitted ',
    success_value: 'final_report_count'
  },
]

//Asset for Edit Form
export const searchParams = [
  { 
    label: 'Name', 
    value: 'u.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Email', 
    value: 'u.email',
    type: 'text',
    param: ''
  },
  { 
    label: 'Phone', 
    value: 'u.phone',
    type: 'text',
    param: ''
  }
]

export const FormDefaultValue = {
  name: '',
  email: '',
  phone: '',
  password: '',
  password_confirm: '',
  address: '',
  cgpa: '',
  ic: '',
  matric_number: '',
  specialization: '',
  session_id: '',
  at_cgpa: '',
  academic_status: '',
  accumulated_credits: null,
  remark: '',
  is_accepted_vacancy: false
}

const AcademicStatusLib = [ 
  {
    value: 'Active',
    name: 'Active'
  }, 
  {
    value: 'P1',
    name: 'P1'
  }, 
  {
    value: 'P2',
    name: 'P2'
  }, 
  {
    value: 'P3',
    name: 'P3'
  } 
]

const specializationOptions = [ 
  {
    id: 'Major: Intelligent.Computing',
    name: 'Major: Intelligent.Computing'
  }, 
  {
    id: 'Major: Computing Infrastructure',
    name: 'Major: Computing Infrastructure'
  }, 
  {
    id: 'Major: SE',
    name: 'Major: SE'
  }
]

export const GradeOptions = [ 'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'F', 'N/A']

export const MuetGrade = [ '1', '2', '3', '4', '5', '6' ]

export const DefaultFieldData = [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  // { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  // { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true },
  { label: 'Address', value: 'address', type: 'textarea', required: false },
  { label: 'CGPA', value: 'cgpa', type: 'number', col: 6, required: true },
  { label: 'AT CGPA', value: 'at_cgpa', type: 'number', col: 6, required: true },
  { label: 'Academic Status', value: 'academic_status', type: 'text', options: AcademicStatusLib, col: 6, required: true },
  { label: 'Acumulated Credits', value: 'accumulated_credits', type: 'number', col: 6, required: false },
  { label: 'NRIC', value: 'ic', type: 'text', required: true },
  { label: 'Matric No.', value: 'matric_number', type: 'text', required: true },
  { label: 'Session Id', value: 'session_id', type: 'typeahead', required: true },
  { label: 'Specialization', value: 'specialization', type: 'text', options: specializationOptions, required: false },
  { label: 'Remark', value: 'remark', type: 'textarea', required: false },
]