import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import Axios from 'axios'

import { storeLastView } from 'actions/lastView'
import getDomainURL from 'utils/api'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Company name', 
    value: 'companies.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Job Title', 
    value: 'company_vacancies.job_title',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companyVacancies: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedCompanyVacancies: {},

      showCreateCompanyVacanciesModal: false,
      showUpdateCompanyVacanciesModal: false,
      showDeleteCompanyVacanciesModal: false,
      showSearchForms: false,

      searchParams: searchParams,

      selectedDeleteCompanyVacanciesId: null
    }

    onChangeCompanyVacanciesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCompanyVacancies = search => {
      const { current_role_id } = this.props.data.ProfileReducer
      this.props.storeLastView({ search })   
      current_role_id === 14 
        ? this.getComCompanyVacancies( search )
        : this.getNonComCompanyVacancies( search )
    }
    // for others user
    getNonComCompanyVacancies = search => Get(
      `/company_vacancies?query=${ search }`,
      this.getNonComCompanyVacanciesSuccess,
      this.getNonComCompanyVacanciesError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getNonComCompanyVacanciesSuccess = payload => {
      let tempData = []
      payload.data && payload.data.map( item => {
        tempData.push({
          company_name: item.company.name,
          company_state: item.company.state,
          ... item.company_vacancy
        })
      })

      this.setState({ companyVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getNonComCompanyVacanciesError = error => this.requestError( error )
    // for company user
    getComCompanyVacancies = search => Get(
      `/company/company_vacancies?query=${ search }`,
      this.getComCompanyVacanciesSuccess,
      this.getComCompanyVacanciesError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getComCompanyVacanciesSuccess = payload => {
      let tempData = []
      payload.data && payload.data.map( item => {
        tempData.push({
          ... item,
          company_name: this.props.data.ProfileReducer.profile.company.name,
          company_state: this.props.data.ProfileReducer.profile.company.state,
        })
      })
      this.setState({ companyVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getComCompanyVacanciesError = error => this.requestError( error )
    getSelectedCompanyVacancies = search => {
      const { current_role_id } = this.props.data.ProfileReducer
      return current_role_id === 14 
        ? this.getSelectedComCompanyVacancies( search )
        : this.getSelectedNonComCompanyVacancies( search )
    }
    // for company user
    getSelectedComCompanyVacancies = id => Get(
      `/company_vacancies/${ id }`,
      this.getSelectedComCompanyVacanciesSuccess,
      this.getSelectedComCompanyVacanciesError,
      this.load
    )
    getSelectedComCompanyVacanciesSuccess = ({ company_vacancy }) => this.setState({ 
      selectedCompanyVacancies: company_vacancy, 
      showUpdateCompanyVacanciesModal: true 
    })
    getSelectedComCompanyVacanciesError = error => this.requestError ( error )
    // for others user
    getSelectedNonComCompanyVacancies = id => Get(
      `/company_vacancies/${ id }`,
      this.getSelectedNonComCompanyVacanciesSuccess,
      this.getSelectedNonComCompanyVacanciesError,
      this.load
    )
    getSelectedNonComCompanyVacanciesSuccess = ({ company_vacancy }) => this.setState({ 
      selectedCompanyVacancies: company_vacancy, 
      showUpdateCompanyVacanciesModal: true 
    })
    getSelectedNonComCompanyVacanciesError = error => this.requestError ( error )

    
    createCompanyVacancies = dataToSubmit => Post(
      `/company_vacancies`,
      dataToSubmit,
      this.createCompanyVacanciesSuccess,
      this.createCompanyVacanciesError,
      this.load
    )
    createCompanyVacanciesSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getCompanyVacancies( search )
      this.setState({ showCreateCompanyVacanciesModal: false })
      this.requestSuccess( 'Company Vacancies has been created successfully.' )
    }
    createCompanyVacanciesError = error => this.requestError( error )

    updateCompanyVacancies = dataToSubmit => Put(
      `/company_vacancies/${ dataToSubmit.id }`,
      dataToSubmit,
      () => this.updateCompanyVacanciesSucces( dataToSubmit ),
      this.updateCompanyVacanciesError,
      this.load
    )
    updateCompanyVacanciesSucces = payload => {
      this.getSelectedCompanyVacancies( payload.id )
      const { search } = this.props.data.LastViewReducer.lastView

      this.getCompanyVacancies( search )
      this.requestSuccess( 'Company Vacancies info was updated successfully.' )
    }
    updateCompanyVacanciesError = error => this.requestError( error )

    deleteCompanyVacancies = id => Delete(
      `/company_vacancies/${ id }`,
      this.deleteCompanyVacanciesSuccess,
      this.deleteCompanyVacanciesError,
      this.load
    )
    deleteCompanyVacanciesSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getCompanyVacancies( search )
      this.setState({ showDeleteCompanyVacanciesModal: false })
      this.requestSuccess( 'Company Vacancies info was deleted successfully.' )
    }
    deleteCompanyVacanciesError = error => this.requestError( error )

    uploadPoster = ( id, dataToSubmit, pondRef ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/company_vacancies/poster/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'poster', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
       Axios.post(url, tempForm, config ).then(() => {
        this.load( false )
         this.uploadPosterSuccess( id, pondRef )
       }).catch( err => {
        this.load( false )
        this.uploadPosterError( err )
       })
    }
    uploadPosterSuccess = ( id, pondRef ) => {
      pondRef.current.removeFiles()
      this.getSelectedCompanyVacancies( id )
      this.requestSuccess( 'Vacancy Poster has been uploaded succesfuly.' )
    }
    uploadPosterError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          companyVacancies={ this.state.companyVacancies }
          onLoadCompanyVacancies={ this.state.loading }
          searchParams={ this.state.searchParams }
          selectedCompanyVacancies={ this.state.selectedCompanyVacancies }
          showSearchForms={ this.state.showSearchForms }
          selectedDeleteCompanyVacanciesId={ this.state.selectedDeleteCompanyVacanciesId }
          showDeleteCompanyVacanciesModal={ this.state.showDeleteCompanyVacanciesModal }
          showCreateCompanyVacanciesModal={ this.state.showCreateCompanyVacanciesModal }
          showUpdateCompanyVacanciesModal={ this.state.showUpdateCompanyVacanciesModal }

          uploadPoster={ this.uploadPoster }
          deleteCompanyVacancies={ this.deleteCompanyVacancies }
          getCompanyVacancies={ this.getCompanyVacancies }
          getSelectedCompanyVacancies={ this.getSelectedCompanyVacancies }
          createCompanyVacancies={ this.createCompanyVacancies }
          updateCompanyVacancies={ this.updateCompanyVacancies }
          onChangeCompanyVacanciesHOC={ this.onChangeCompanyVacanciesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC