import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PrerequisiteContent from './Prerequisite'
import StudentDetailsContent from './Form'

import SessionHOC from 'actions/hoc/session'

const tabOptions = [
  { id: 1, label: 'Student Details' },
  { id: 2, label: 'Prerequisite Courses' }
]

const FormContent = ({
  open,
  onLoadStudent,
  ... props
}) => {

  
  const [ activeTab, onChangeActiveTab ] = useState( 1 )
  
  useEffect(() => {
    if ( !open ){
      props.onChangeSessionsHOC( 'sessions', [] )
    }
  }, [open])

  return (
    <>
      <div className="d-flex mb-3 flex-wrap">
        {
          tabOptions.map( tab => (
            <button
              className={ `btn mr-1 btn-wide ${ activeTab === tab.id ? 'btn-primary' : 'btn-outline-primary' }` }
              onClick={ () => onChangeActiveTab( tab.id )}
            >
              { tab.label }
            </button>
          ))
        }
      </div>
      {
        activeTab === 1 && (
          <StudentDetailsContent { ... props }/>
        )
      }
      {
        activeTab === 2 && (
          <PrerequisiteContent { ... props }/>
        )
      }
      { onLoadStudent && <LoadingOverlay />}
    </>
  )
}

export default SessionHOC( FormContent );