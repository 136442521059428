import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      students: [],
      advisorOptions: [],
      onLoadStudent: false,
      onLoadUserAdvisor: false,
    }

    onChangeUserHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudents = search =>  Get(
      `/students?query=${ search }`,
      this.getStudentsSuccess,
      this.getStudentsError,
      val => this.setState({ onLoadStudent: val }),
      this.props.data.ProfileReducer.current_role_id
    )
    getStudentsSuccess = payload => {
      let tempData = _.map( payload?.data??[], ({ user, student }) => {
        return ({ 
          id: student?.id??null,
          name: user?.name??'',
          email: user?.email??''
        })
      })
      this.setState({ students: tempData })
    }
    getStudentsError = error => this.requestError( error )

    getAdvisorUsers = search =>  Get(
      `/users?query=${ search }&filter_role_id=[15,18]`,
      this.getAdvisorUsersSuccess,
      this.getAdvisorUsersError,
      val => this.setState({ onLoadUserAdvisor: val }),
      this.props.data.ProfileReducer.current_role_id
    )
    getAdvisorUsersSuccess = payload => this.setState({ advisorOptions: payload.data })
    getAdvisorUsersError = error => this.requestError( error )

    getSelectedStudent = id => {
      Get(
        `/students/${ id }`,
        this.getSelectedStudentSuccess,
        this.getSelectedStudentError,
        this.load
      )
    }
    getSelectedStudentSuccess = ({ user, student }) => {
      this.setState({ 
        students: [{
          id: student?.id??'',
          name: user?.name??'',
          email: user?.email??''
        }]
      })
    }
    getSelectedStudentError = error => this.requestError ( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadStudent={ this.state.onLoadStudent }
          onLoadUserAdvisor={ this.state.onLoadUserAdvisor }
          students={ this.state.students }
          advisorOptions={ this.state.advisorOptions }

          getStudents={ this.getStudents }
          getSelectedStudent={ this.getSelectedStudent }
          getAdvisorUsers={ this.getAdvisorUsers }
          onChangeUserHOC={ this.onChangeUserHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC