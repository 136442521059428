import React, { useEffect, useState, useCallback } from 'react'
import Moment from 'moment'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DatePicker from 'react-datepicker'
import SessionTypeahead from 'components/Typeahead/session'
import { PulseLoader } from 'react-spinners';

import SessionHOC from 'actions/hoc/session'
 
const defaultValue = {
  start_date: null,
  end_date: null,
  session_id: ''
}

const fieldData = [
  { label: 'Sessions', value: 'session_id', type: 'typeahead', col: 12, required: true },
  { label: 'Start Date', value: 'start_date', type: 'date', required: true },
  { label: 'End Date', value: 'end_date', type: 'date', required: true },
]

const FormContent = ({
  mode,
  open,
  selectedFormData,
  onLoadLogBookDateTime,

  onClose,
  onClickSubmit,

  sessions,
  onChangeSessionsHOC,
  onLoadSession,
  getSessions
}) => {

  const [ logBookDateTimeData, onChangelogBookDateTimeData ] = useState( defaultValue )

  useEffect(() => {
    if ( open && mode === 'Edit' ){
      onChangelogBookDateTimeData( selectedFormData )
    } 
    if ( !open ){
      onChangeSessionsHOC( 'sessions', [] )
      onChangelogBookDateTimeData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    mode === 'Edit' && onChangelogBookDateTimeData( selectedFormData )

    selectedFormData?.session_id && getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ selectedFormData.session_id } }}`))

  }, [ selectedFormData ])

  const onChangelogBookDateTimeField = ( key, val ) => {
    let tmp = _.cloneDeep( logBookDateTimeData )
    tmp[ key ] = val
    return onChangelogBookDateTimeData( tmp )
  }

  const onChangeTypeaheadSearch = useCallback( _.debounce(
    val => {
      getSessions( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sessions.name": {"$like": "%${ val }%" } }}`))
    }, 600 ), []
  );

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Logbook Datelines` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
          {
            fieldData.map( field => (
              <Col lg={ field.col || 6 }>
                <FormGroup>
                  <Label>
                    { `${ field.label } ` }
                    { field.required && <span className="text-danger mr-2">*</span> }

                  </Label>
                  {
                    field.value === 'session_id' && (
                      <PulseLoader  
                        sizeUnit={"px"}
                        size={ 10 }
                        color={ '#16232c' }
                        loading={ onLoadSession } 
                      />
                    )
                  }
                  {
                    field.type === 'typeahead' && (
                        <SessionTypeahead
                          options={ sessions }
                          onSelect={ val => val?.[0]?.id && onChangelogBookDateTimeField( 'session_id', val[0].id )}
                          selectedValue={ 
                            logBookDateTimeData?.session_id && _.find( sessions, { id: +logBookDateTimeData.session_id })
                              ? [ _.find( sessions, { id: +logBookDateTimeData.session_id })] 
                              : [] 
                          }
                          onSearch={ val => {
                            onChangelogBookDateTimeField( 'session_id', null )

                            val.length > 0
                              ? onChangeTypeaheadSearch( val )
                              : onChangeSessionsHOC( 'sessions', [] )
                          }}
                          filterBy={ 'name' }
                          disableEdit={ onLoadSession }
                          helpText={ 'Please enter session name to search.' }
                        />
                    )
                  }
                  {
                    field.type === 'date' && (
                      <DatePicker
                        className={ 'w-100' }
                        showTimeSelect
                        showYearDropdown={ true }
                        dateFormat="dd-MM-yyyy h:mm aa"
                        selected={ logBookDateTimeData[ field.value ] }
                        onChange={ val => onChangelogBookDateTimeField( field.value, val )}
                      />
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          </Row>
        </Form>
        { onLoadLogBookDateTime && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          color={ 
            _.values( logBookDateTimeData ).some( item => !item ) 
              ? "secondary" 
              : 'primary' 
          }
          disabled={ _.values( logBookDateTimeData ).some( item => !item )}
          onClick={() => {
            onClickSubmit({
              ...logBookDateTimeData,
              session_id: parseInt( logBookDateTimeData.session_id ),
              start_date: Moment( logBookDateTimeData.start_date ).format(),
              end_date: Moment( logBookDateTimeData.end_date ).format()
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SessionHOC( FormContent );