import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap'
import _ from 'lodash'

import { queryHandler } from 'utils/queryHandler'
import { useCallback } from 'react'

const SearchForm = ({
  searchParams,
  onChangeHOCState,
  getListAPI
}) => {

  const onChangeParam = useCallback(( index, val ) => {
    let tmp = _.cloneDeep( searchParams )

    tmp[ index ][ 'param' ] = val
    onChangeHOCState( 'searchParams', tmp )
  }, [searchParams])

  return (
    <Card className="main-card mb-3">
      <CardHeader>{ 'Search' }</CardHeader>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault() }>
          <Row>
            {
              searchParams.map(( item, index ) => {
                return (
                  <Col key={ index } md={ item.col || 12 } xs={ 12 }>
                    <FormGroup key={ item.value }>
                      <Label>{ item.label }</Label>
                      {
                        item.type === 'select' && (
                          <Input
                            type={ 'select' }
                            value={ item.param }
                            onChange={ e => {
                              let tempVal = parseInt( e.target.value )

                              if ( item.valueType === 'string' ) {
                                tempVal = e.target.value
                              } 
                              if ( item.valueType === 'boolean' ) {
                                tempVal = e.target.value === 'true'
                              }

                              onChangeParam( index, tempVal )
                            }}>
                            <option></option>
                            { item.options.map( data => (
                              <option key={ data.id } value= { data.id }>{ data.name }</option>
                            ))}
                          </Input>
                        )
                      }
                      {
                        item.type === 'text' && (
                          <Input
                            type={ 'text' }
                            value={ item.param }
                            onChange={ e => onChangeParam( index, e.target.value )} 
                          />
                        )
                      }
                    </FormGroup>
                  </Col>
                )
              })
            }
          </Row>
          <div className="d-flex">
            <Button 
              color="primary"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                let searchQuery = queryHandler( searchParams, 1 )
                getListAPI( searchQuery )
              }}>{ 'Search' }</Button>
            <Button 
              color="danger"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                let tmp = []
                let tmpSearchParams = _.cloneDeep( searchParams )
                tmpSearchParams.map( item => {
                  tmp.push({
                    ...item,
                    param: ''
                  })
                })
                onChangeHOCState( 'searchParams', tmp )
                let searchQuery = queryHandler( [], 1 )
                getListAPI( searchQuery )
              }}>{ 'Reset' }</Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}

export default SearchForm