import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentExperience: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedExperience: {},

      showCreateExperienceModal: false,
      showDeleteExperienceModal: false,
      showUpdateExperienceModal: false,

      experienceSearchQuery: ''
    }

    onChangeExperienceHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getExperience = search => {
      this.setState({ experienceSearchQuery: search }, () => {
        Get(
          `/student_cv_experiences?query=${ search }`,
          this.getExperienceSuccess,
          this.getExperienceError,
          this.load,
          this.props.data.ProfileReducer.current_role_id
        )
      })
    }
    getExperienceSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_cv_experience }) => {
        tempData.push({
          ... student_cv_experience,
          start_date: Moment.utc( student_cv_experience.start_date ).format( 'DD-MM-yyyy' ),
          end_date: Moment.utc( student_cv_experience.end_date ).format( 'DD-MM-yyyy' ),
        })
      })
      this.setState({ studentExperience: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getExperienceError = error => this.requestError( error )

    getSelectedExperience = ( id, context ) => {
      Get(
        `/student_cv_experiences/${ id }`,
        payload => this.getSelectedExperienceSuccess( payload, context ),
        this.getSelectedExperienceError,
        this.load
      )
    }
    getSelectedExperienceSuccess = ({ student_cv_experience }) => {
      let tempStartDate = new Date( student_cv_experience.start_date )  
      let tempEndDate = new Date( student_cv_experience.end_date )
      var userTimezoneOffset = tempStartDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedExperience: {
          ... student_cv_experience,
          start_date: new Date(tempStartDate.getTime() + userTimezoneOffset ),
          end_date: new Date(tempEndDate.getTime() + userTimezoneOffset ),
        }, 
        showUpdateExperienceModal: true
      })
    }
    getSelectedExperienceError = error => this.requestError ( error )

    createExperience = dataToSubmit => Post(
      `/student_cv_experiences`,
      dataToSubmit,
      this.createExperienceSuccess,
      this.createExperienceError,
      this.load
    )
    createExperienceSuccess = () => {
      this.getExperience( this.state.experienceSearchQuery )
      this.setState({ showCreateExperienceModal: false })
      this.requestSuccess( 'Experience has been created successfully.' )
    }
    createExperienceError = error => this.requestError( error )

    deleteExperience = id => Delete( 
      `/student_cv_experiences/${ id }`,
      this.deleteExperienceSuccess,
      this.deleteError,
      this.load
    )
    deleteExperienceSuccess = () => {
      this.getExperience( this.state.experienceSearchQuery )
      this.setState({ showDeleteExperienceModal: false })
      this.requestSuccess( 'Experience has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateExperience = dataToSubmit => Put(
      `/student_cv_experiences/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateExperienceSucces,
      this.updateExperienceError,
      this.load
    )
    updateExperienceSucces = payload => {
      this.getExperience( this.state.experienceSearchQuery )
      this.setState({ showUpdateExperienceModal: false })
      this.getSelectedExperience( payload.id )
      this.requestSuccess( 'Experience was updated successfully.' )
    }
    updateExperienceError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          experienceSearchQuery={ this.state.experienceSearchQuery }
          studentExperience={ this.state.studentExperience }
          onLoadExperience={ this.state.loading }
          selectedExperience={ this.state.selectedExperience }
          showCreateExperienceModal={ this.state.showCreateExperienceModal }
          showDeleteExperienceModal={ this.state.showDeleteExperienceModal }
          showUpdateExperienceModal={ this.state.showUpdateExperienceModal }
          
          getExperience={ this.getExperience }
          getSelectedExperience={ this.getSelectedExperience }
          createExperience={ this.createExperience }
          deleteExperience={ this.deleteExperience }
          updateExperience={ this.updateExperience }
          onChangeExperienceHOC={ this.onChangeExperienceHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC