import React, { useEffect, useState, useCallback } from 'react'

import { 
  Button,
  Form,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardFooter,
  FormText
} from 'reactstrap'
import { GrView, GrFormViewHide } from 'react-icons/gr'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'

import CompanyHOC from '../actions/company'
import { useMemo } from 'react'
import { mobileChecker } from 'utils/mobileChecker'

const defaultValue = {
  name: '',
  email: '',
  phone: '',
  password: '',
  password_confirm: ''
}

const DefaultFieldData = [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  { label: 'Password', value: 'password', type: 'password', col: 6, required: false },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: false }
]

const FormContent = ({
  selectedFormData,
  companies,
  onLoadCompany,

  getCompany,
  updateUser
}) => {

  const [ userData, onChangeUserData ] = useState( defaultValue )
  const [ showPassword, onChangeShowPassword ] = useState(false)

  useEffect(() => {
    selectedFormData && onChangeUserData({
      ... selectedFormData,
      password: '',
      password_confirm: ''
    })
    
    if ( selectedFormData.role_ids.includes(14) ){
      getCompany()
    }
  }, [selectedFormData])

  const onChangeUserField = useCallback(( key, val ) => {
    let tmp = _.cloneDeep( userData )
    tmp[ key ] = val

    return onChangeUserData( tmp )
  }, [userData])

  const debounceMobile = useCallback(_.debounce(( fullData, val ) => {
    onChangeUserData({
      ... fullData,
      phone: mobileChecker( val )
    })
  }, 500), [])

  const disableSubmit = useMemo(() => {
    DefaultFieldData.map(({ value,  required }) => {
      if ( required && !userData[value] ){
        return true 
      }

      if ( value === 'company_id' && 
        selectedFormData.role_ids.includes(14) && 
          !userData.company_id ){
          return true
        }
    })

    return false;
  }, [ userData ])
  
  return (
    <>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}> 
          <Row>
            {
              selectedFormData.role_ids.includes(14) && (
                <Col xs={ 12 }>
                  <FormGroup>
                    <Label>Company</Label>
                    <Input
                      type="select"
                      value={ userData.company_id } 
                      onChange={ e => onChangeUserField( 'company_id', parseInt( e.target.value ) )}
                    >
                      <option value={ '' }></option>
                      {
                        companies.map( data => (
                          <option value={ data.id }>{ data.name }</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </Col>
              )
            }
            {
              DefaultFieldData.map( field => (
                <Col md={ field.col || 12 } xs={ 12 }>
                  <FormGroup>
                    <Label>
                      {
                        field.type === 'password' ? (
                          <div style={{ display: 'flex' }}>
                            {`${field.label} `}
                            <div
                              style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                              onClick={() => onChangeShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <GrView style={{ width: '100%', height: '100%' }} />
                              ) : (
                                <GrFormViewHide style={{ width: '100%', height: '100%' }} />
                              )}
                            </div>
                          </div>
                        ): (
                          <div>
                            { `${ field.label } ` }
                            { field.required && <span className="text-danger mr-2">*</span> }
                          </div>
                        )
                      }
                    </Label>
                    <Input
                      type={ field.type === 'password'? (showPassword ? 'text' : 'password'): field.type }
                      value={ userData[ field.value ] }
                      onChange={ e => {
                        Promise.all([
                          onChangeUserField( field.value, e.target.value )
                        ]).then(() => {
                          field.value === 'phone' && debounceMobile( userData, e.target.value )
                        })
                      }} />
                    { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button 
          // disabled={ disableSubmit }
          // color={ !disableSubmit ? 'primary' : 'secondary' }
          className="mr-2"
          color={ 'primary' }
          onClick={() => {
            let temp = _.cloneDeep( userData )
            if ( !temp.password && !temp.password_confirm ){
              delete temp.password
              delete temp.password_confirm
            }
            updateUser( temp )
          }}
        >
          Update
        </Button>
      </CardFooter>
      { ( onLoadCompany ) && <LoadingOverlay />}
    </>
  )
}

export default CompanyHOC( FormContent );