import {
  Card, CardBody, Button
} from 'reactstrap';

const StudentCard = props => {
  return (
    <Card className="mr-3" style={{ width: 'max-content', display: 'inline-block' }}>
      {
        props.context === "external"
          && (
            <CardBody>
              <p>{ `Name: ${ props.data.user.name }` }</p>
              <p>{ `Email: ${ props.data.user.email }` }</p>
              <p>{ `Phone: ${ props.data.user.phone }` }</p>
              <Button 
                color="primary"
                onClick={() => {
                  props.getSelectedStudentDashboard( props.data.student.id )
                }}>
                <i className="pe-7s-search"></i>
              </Button>
            </CardBody>
          )
      }
      {
        props.context === "internal"
          && (
            <CardBody>
              <p>{ `Name: ${ props.data.user.name }` }</p>
              <p>{ `Email: ${ props.data.user.email }` }</p>
              <p>{ `Phone: ${ props.data.user.phone }` }</p>
              <Button 
                color="primary"
                onClick={() => {
                  props.getSelectedStudentDashboard( props.data.student.id )
                }}>
                <i className="pe-7s-search"></i>
              </Button>
            </CardBody>
          )
      }
      {
        props.context === 'academic' && (
          <CardBody>
            <p>{ `Name: ${ props.data.student_user.name }` }</p>
            <p>{ `Email: ${ props.data.student_user.email }` }</p>
            <p>{ `Phone: ${ props.data.student_user.phone }` }</p>
            <Button 
              color="primary"
              onClick={() => {
                props.getSelectedStudentEditForm ( props.data.student.id )
              }}>
              <i className="pe-7s-pen btn-icon-wrapper"></i>
            </Button>
          </CardBody>
        )
      }
    </Card>
  )
}

export default StudentCard