import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'
import Cookies from 'js-cookie'
import getDomainURL from 'utils/api'
import Axios from 'axios'
import FileSaver from 'file-saver'
import { Get, Put } from 'utils/axios'

const searchParams = [
  { 
    label: 'Student name', 
    value: 'su.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Company name', 
    value: 'c.name',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      jobScope: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedJobScope: {},
      searchParams: searchParams,

      showUpdateJobScopesModal: false,
      showSearchForms: false
    }

    onChangeJobScopeHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getJobScopes = search => {
      this.props.storeLastView({ search })

      Get(
        `/student_job_scope_forms?query=${ search }`,
        this.getJobScopesSuccess,
        this.getJobScopesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getJobScopesSuccess = payload => {
      let tempData = []
      payload.data.map(({ 
        student_job_scope_form, 
        company, 
        student_user 
      }) => {
        tempData.push({
          ... student_job_scope_form,
          company_name: company.name,
          student_name: student_user.name,
          student_email: student_user.email
        })
        if ( this.props.data.ProfileReducer.current_role_id === 13 && tempData.length > 0 ){
          this.getSelectedJobScope( tempData[0].id )
        } 
      })
      this.setState({ jobScope: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getJobScopesError = error => this.requestError( error )

    getSelectedJobScope = id => {
      Get(
        `/student_job_scope_forms/${ id }`,
        this.getSelectedJobScopeSuccess,
        this.getSelectedJobScopeError,
        this.load
      )
    }
    getSelectedJobScopeSuccess = ({ student_job_scope_form, company, student_user, student }) => {
      this.setState({   
        selectedJobScope: {
          id: student_job_scope_form.id,
          company_name: company.name,
          student_name: student_user.name,
          student_matric: student.matric_number,
          jkli_approval_status: student_job_scope_form.jkli_approval_status,
          jkli_comment: student_job_scope_form.jkli_comment,
        },
        showUpdateJobScopesModal: true
      })
    }
    getSelectedJobScopeError = error => this.requestError ( error )

    updateJobScope = dataToSubmit => Put (
      `/student_job_scope_forms/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateJobScopeSuccess,
      this.updateJobScopeError,
      this.load
    )
    updateJobScopeSuccess = payload => {
      this.getSelectedJobScope( payload.student_job_scope_form.id )
      this.requestSuccess( 'Job Scope updated successfully.' )
    }
    updateJobScopeError = error => this.requestError( error )

    getStudentJobScopeForm_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/student_job_scope_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_JobScopeForm.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          jobScope={ this.state.jobScope }
          onLoadJobScope={ this.state.loading }
          selectedJobScope={ this.state.selectedJobScope }
          showUpdateJobScopesModal={ this.state.showUpdateJobScopesModal }
          showSearchForms={ this.state.showSearchForms }
          
          updateJobScope={ this.updateJobScope }
          getJobScopes={ this.getJobScopes }
          getSelectedJobScope={ this.getSelectedJobScope }
          getStudentJobScopeForm_pdf={ this.getStudentJobScopeForm_pdf }
          onChangeJobScopeHOC={ this.onChangeJobScopeHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC