import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SecModulesRoleForm from './Form'
import Pagination from 'components/Pagination'
import { queryHandler } from 'utils/queryHandler'

import SecModulesRolesHOC from './actions'
import SecModulesHOC from './actions/secModules'

class SecModulesRoles extends Component {

  componentDidUpdate = pp => {
    if ( pp.secModules !== this.props.secModules && this.props.secModules ){
      let temp = _.cloneDeep( this.props.searchParams )
      let tempIndex = _.findIndex( this.props.searchParams, { value: 'sec_module_id' })
      temp[tempIndex].options = this.props.secModules
      
      this.props.onChangeSecModulesRolesHOC( 'searchParams', temp )
    }
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getSecModulesRoles( queryHandler( [], 1, 30 ) )
      this.props.getSecModules()
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <PageTitle
          heading="Sec Modules Roles"
          subheading="Listings of all the sec modules roles present in the system."
          icon="pe-7s-edit icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeSecModulesRolesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeSecModulesRolesHOC( 'showSearchForms', false )}
          >
            <SearchForm
              per_page={ 30 }
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeSecModulesRolesHOC }
              getListAPI={ this.props.getSecModulesRoles }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.SecModulesRoles.data.length < 1? 3: 
                      this.props.SecModulesRoles.data.length < 30? this.props.SecModulesRoles.data.length:
                      30
                    }
                    data={ this.props.SecModulesRoles.data }
                    columns={[
                      {
                        Header: 'Roles',
                        accessor: 'role_name'
                      },
                      {
                        Header: 'Sec Modules',
                        accessor: 'sec_modules'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateSecModulesRole"
                                className="mr-2 btn-icon btn-icon-only"
                                disabled={ !is_update }
                                color={ is_update ? 'primary' : 'secondary' }
                                onClick={() => {
                                  this.props.getSelectedSecModulesRole(row.original.id, 'edit' )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateSecModulesRole" placement="left">Update the information of this sec module </UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.SecModulesRoles.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getSecModulesRoles( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <SecModulesRoleForm
            {...this.props}
            secModules={ this.props.secModules }
            selectedFormData={ this.props.selectedSecModulesRole }
            open={ this.props.showUpdateSecModulesRolesModal }
            onClose={ () => this.props.onChangeSecModulesRolesHOC( 'showUpdateSecModulesRolesModal', false )}
            onClickSubmit={ this.props.updateSecModulesRole }
          />
        </ReactCSSTransitionGroup>
        { ( this.props.onLoadSecModulesRole || this.props.onLoadSecModules ) && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose(
  SecModulesRolesHOC,
  SecModulesHOC
)(SecModulesRoles)