import React from 'react'
import { 
  Modal, ModalBody, ModalHeader
} from 'reactstrap'

export default function Details ({
  open,
  header,
  content,
  onClose,
}) {
  return (
    <Modal
      isOpen={ open }
      size={ 'md' }>
      <ModalHeader toggle={() => onClose()}>{ header }</ModalHeader>
      <ModalBody>
        <span>{content}</span>
      </ModalBody>
    </Modal>
  )
}
