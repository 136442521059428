import React from 'react'

import { ListGroupItem } from 'reactstrap'

export default function Listgroup ({
  header,
  subheading,
  status
}) {
  return (
    <>
      <ListGroupItem>
        <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                  <div className="widget-heading">
                    <i className={ 'pe-7s-culture mr-1' }/>
                    { header }
                  </div>
                  <div className="widget-subheading">
                    { subheading }
                  </div>
              </div>
              <div className="widget-content-right">
                <div className="badge badge-primary">
                  { status }
                </div>
              </div>
            </div>
        </div>
      </ListGroupItem>
    </>
  )
}
