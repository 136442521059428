import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'

import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete, GetFile } from 'utils/axios'
import { searchParams } from '../asset'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      users: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      students: [],
      studentsVacancies: [],
      selectedUser: { 
        role_ids: [], 
        rolesInfo: [] 
      },
      searchParams: searchParams,

      showSearchForms: false,
      showCreateUsersModal: false,
      showDeleteUserModal: false,
      showUpdateUsersModal: false,
      showGenerateCSV: false,

      currentUserTab: null
    }

    onChangeUsersHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    //getters
    getUsers = ( search, role_id ) => {
      this.props.storeLastView({ search })
      Get(
        `/users?query=${ search }${ this.state.currentUserTab ? `&filter_role_id=[${ this.state.currentUserTab }]` : '' }`,
        this.getUsersSuccess,
        this.getUsersError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getUsersSuccess = payload => {
      const { data = [], meta } = payload
      const { roles } = this.props.data.DictionaryReducer

      let tempData = _.map( data, item => {
        let tempRole = _.map( item.role_ids, role_id => {
          let temp = _.find( roles, { id: role_id })  

          return {
            id: role_id,
            name: temp?.name??''
          }
        })

        return ({
          ... item,
          created_at: Moment( item.created_at ).format( 'DD-MM-yyyy h:mm a' ),
          rolesInfo: tempRole
        })
      })
      this.setState({ users: {
        data: tempData,
        meta: meta
      }})
    }
    getUsersError = error => this.requestError( error )

    getStudents = search =>  Get(
      `/students?query=${ search }`,
      this.getStudentsSuccess,
      this.getStudentsError,
      this.load
    )
    getStudentsSuccess = payload => {
      let tempData = _.map( payload?.data??[], ({ user, student }) => {
        return ({ 
          name: user.name,
          email: user.email,
          phone: user.phone,
          address: student.address,
          cgpa: student.cgpa,
          ic: student.ic,
          matric_number: student.matric_number,
          specialization: student.specialization,
          session_id: student.session_id,
          at_cgpa: student.at_cgpa,
          academic_status: student.academic_status,
          accumulated_credits: student.accumulated_credits,
          is_accepted_vacancy: student.is_accepted_vacancy
        })
      })
      this.setState({ students: tempData })
    }
    getStudentsError = error => this.requestError( error )

    getStudentsVacancies = search =>  Get(
      `/student_vacancies?query=${ search }`,
      this.getStudentsVacanciesSuccess,
      this.getStudentsVacanciesError,
      this.load
    )
    getStudentsVacanciesSuccess = payload => {
      let tempData = _.map( payload?.data??[], ({ student_user, company, company_vacancy, student_vacancy_status }) => {
        return ({ 
          name: student_user.name,
          company_name: company.name,
          company_address: company.address,
          company_description: company.description,
          job_scope: company_vacancy.job_scope,
          job_title: company_vacancy.job_title,
          operating_systems: company_vacancy.operating_systems,
          programming_languages: company_vacancy.programming_languages,
          vacancy_number: company_vacancy.vacancy_number,
          student_status: student_vacancy_status.name
        })
      })
      this.setState({ studentsVacancies: tempData })
    }
    getStudentsVacanciesError = error => this.requestError( error )

    getSelectedUser = id => {
      Get(
        `/users/${ id }`,
        this.getSelectedUserSuccess,
        this.getSelectedUserError,
        this.load
      )
    }
    getSelectedUserSuccess = payload => {
      let temp = _.cloneDeep( payload )
      const { roles } = this.props.data.DictionaryReducer

      let tempRole = _.map( payload.role_ids, role_id => {
        let temp = _.find( roles, { id: role_id })  

        return {
          id: role_id,
          name: temp?.name??''
        }
      })

      this.setState({ 
        selectedUser: {
          ... temp,
          rolesInfo: tempRole
        }, 
        showUpdateUsersModal: true 
      })
    }
    getSelectedUserError = error => this.requestError ( error )

    createUser = dataToSubmit => Post(
      `/users`,
      dataToSubmit,
      this.createUserSuccess,
      this.createUserError,
      this.load
    )
    createUserSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getUsers( search )
      this.setState({ showCreateUsersModal: false })
      this.requestSuccess( 'Users has been created successfully.' )
    }
    createUserError = error => this.requestError( error )

    deleteUser = id => Delete( 
      `/users/${ id }`,
      this.deleteUserSuccess,
      this.deleteError,
      this.load
    )
    deleteUserSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getUsers( search )
      this.setState({ showDeleteUserModal: false })
      this.requestSuccess( 'User has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateUser = dataToSubmit => Put(
      `/users/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateUserSucces,
      this.updateUserError,
      this.load
    )
    updateUserSucces = payload => {
      this.getSelectedUser( payload.id )
      const { search } = this.props.data.LastViewReducer.lastView

      this.getUsers( search )
      this.requestSuccess( 'User was updated successfully.' )
    }
    updateUserError = error => this.requestError( error )

    exportStudentCSV = (sessionID, filters) => {
      GetFile(
        `/students_csv/${sessionID}?include_columns=${encodeURIComponent(JSON.stringify({ ... filters }))}`,
        `StudentLists.csv`,
        () => {},
        error => this.requestError( error ),
        this.load
      )
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          users={ this.state.users }
          students={ this.state.students }
          studentsVacancies={ this.state.studentsVacancies }
          onLoadUser={ this.state.loading }
          currentUserTab={ this.state.currentUserTab }
          searchParams={ this.state.searchParams }
          showSearchForms={ this.state.showSearchForms }
          selectedUser={ this.state.selectedUser }
          showCreateUsersModal={ this.state.showCreateUsersModal }
          showDeleteUserModal={ this.state.showDeleteUserModal }
          showUpdateUsersModal={ this.state.showUpdateUsersModal }
          showGenerateCSV={ this.state.showGenerateCSV }

          exportStudentCSV={this.exportStudentCSV}
          getUsers={ this.getUsers }
          getStudents={ this.getStudents }
          getStudentsVacancies={ this.getStudentsVacancies }
          getSelectedUser={ this.getSelectedUser }
          createUser={ this.createUser }
          deleteUser={ this.deleteUser }
          updateUser={ this.updateUser }
          onChangeUsersHOC={ this.onChangeUsersHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC