import {
  Card, CardHeader, CardBody
} from 'reactstrap';

const ExternalSupervisor = props => {
  return (
    <Card className="mr-3" style={{ width: 'max-content', display: 'inline-block' }}>
      <CardBody>
        <h6>{ props.title }</h6>
        <hr />
        <p>{ `Name: ${ props.data.name }` }</p>
        <p>{ `Email: ${ props.data.email }` }</p>
        <p>{ `Phone: ${ props.data.phone }` }</p>
      </CardBody>
    </Card>
  )
}

export default ExternalSupervisor