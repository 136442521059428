import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedStudent: {},
      showStudentDetailsModal: false,
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeStudentsHOC = (key, val) => this.setState({ [key]: val })
    onChangeStudentHOC = ( key, val ) => this.setState({ [key]: val })

    getSelectedStudent = id => {
      Get(
        `/students/${ id }`,
        this.getSelectedStudentSuccess,
        this.getSelectedStudentError,
        this.load
      )
    }
    getSelectedStudentSuccess = (payload) => {
      this.setState({ selectedStudent: payload })
    }
    getSelectedStudentError = error => this.requestError ( error )

    getSelectedStudentDashboard = id => Get(
      `/students/${ id }`,
      this.getSelectedStudentDashboardSuccess,
      this.getSelectedStudentDashboardError,
      this.load
    )
    getSelectedStudentDashboardSuccess = payload => {

      this.setState({ selectedStudent: payload, showStudentDetailsModal: true })
    }
    getSelectedStudentDashboardError = error => this.requestError( error )

    getSelectedStudentEditForm = id => {
      Get(
        `/students/${ id }`,
        this.getSelectedStudentEditFormSuccess,
        this.getSelectedStudentEditFormError,
        this.load
      )
    }
    getSelectedStudentEditFormSuccess = ({ user, student }) => {
      let tempMerge = _.merge( user, student )
      
      this.props.onChangeUsersHOC( 'selectedUser', {
        ...tempMerge,
        rolesInfo: [{ id: 13, name: 'Student' }]
      })
      this.props.onChangeUsersHOC( 'showUpdateUsersModal', true )
    }
    getSelectedStudentEditFormError = error => this.requestError ( error )

    updateStudent = dataToSubmit => Put(
      `/students/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateStudentSucces,
      this.updateStudentError,
      this.load
    )
    updateStudentSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      
      this.props.getUsers( search )
      this.getSelectedStudent( payload.student.id )
      this.requestSuccess( 'Student was updated successfully.' )
    }
    updateStudentError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          selectedStudent={ this.state.selectedStudent }
          onLoadStudents={ this.state.loading }
          showStudentDetailsModal={ this.state.showStudentDetailsModal }
          getSelectedStudent={ this.getSelectedStudent }
          getSelectedStudentDashboard={ this.getSelectedStudentDashboard }
          onChangeStudentsHOC={ this.onChangeStudentsHOC } 

          getSelectedStudentEditForm={ this.getSelectedStudentEditForm }
          updateStudent={ this.updateStudent }
          onChangeStudentHOC={ this.onChangeStudentsHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC