import React, { useState, useCallback, useEffect } from 'react'
import { 
  CardBody, CardFooter,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormText
} from 'reactstrap'
import _ from 'lodash'
import { FormDefaultValue, DefaultFieldData } from '../asset'
import { PulseLoader } from 'react-spinners';
import { mobileChecker } from 'utils/mobileChecker'

import SessionHOC from 'actions/hoc/session'
import SessionTypeahead from 'components/Typeahead/session'

const FormContent = ({
  sessions,
  onLoadSession,
  getSessions,
  onChangeSessionsHOC,

  createStudent
}) => {

  const [ studentData, onChangeStudentData ] = useState( FormDefaultValue )

  const onChangeStudentField = ( key, val ) => {
    let tmp = _.cloneDeep( studentData )
    tmp[ key ] = val
    return onChangeStudentData( tmp )
  }

  const onChangeTypeaheadSearch = useCallback( _.debounce(
    val => {
      getSessions( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sessions.name": {"$like": "%${ val }%" } }}`))
    }, 600 ), []
  );

  useEffect(() => {
    getSessions( btoa(`{ "page": 1 , "per_page": 1000 }`))
  }, [])

  const debounceMobile = useCallback(_.debounce(( fullData, val ) => {
    onChangeStudentData({
      ... fullData,
      phone: mobileChecker( val )
    })
  }, 500), [])

  return (
    <>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}> 
          <Row>
            {
              DefaultFieldData.map( field => (
                <Col md={ field.col || 12 } xs={ 12 }>
                  <FormGroup>
                    <Label>
                      { `${ field.label } ` }
                      { field.required && <span className="text-danger mr-2">*</span> }
                      {
                        field.value === 'session_id' && (
                          <PulseLoader  
                            sizeUnit={"px"}
                            size={ 10 }
                            color={ '#16232c' }
                            loading={ onLoadSession } 
                          />
                        )
                      }
                    </Label>
                    {
                      field.type === 'select' && (
                        <Input
                          type={ 'select' }
                          value={ studentData[ field.value ] }
                          onChange={ e => onChangeStudentField( field.value, e.target.value )}
                        >
                          <option value={ null }></option>
                          { 
                            field.options?.[0] && field.options.map( option => (
                              <option key={ option.id } value={ option.id }>{ option.name }</option>
                            ))
                          }
                        </Input>
                      )
                    }
                    {
                      field.type === 'typeahead' && (
                        // <SessionTypeahead
                        //   options={ sessions }
                        //   onSelect={ val => {
                        //     if ( val?.[0]?.id ){
                        //       onChangeStudentField( field.value, val[0].id )
                        //     }
                        //   }}
                        //   selectedValue={ 
                        //     studentData?.session_id && _.find( sessions, { id: studentData?.session_id })
                        //       ? [ _.find( sessions, { id: studentData?.session_id })] 
                        //       : [] 
                        //   }
                        //   onSearch={ val => {
                        //     onChangeStudentField( 'session_id', null )
                        //     val.length > 0 
                        //       ? onChangeTypeaheadSearch( val )
                        //       : onChangeSessionsHOC( 'sessions', [] )
                        //   }}
                        //   filterBy={ 'name' }
                        //   disableEdit={ onLoadSession }
                        //   helpText={ 'Please enter session name to search.' }
                        // />
                        <Input 
                          type="select"
                          value={ studentData?.session_id }
                          onChange={ e => {
                            onChangeStudentField( field.value, e.target.value )
                          }}>
                          <option></option>
                          {
                            sessions.map( item => {
                              return <option key={ item.id } value={ item.id }>{ item.name }</option>
                            })
                          }
                        </Input>
                      )
                    }
                    {
                      [ 'select', 'typeahead' ].indexOf( field.type ) < 0 && (
                        <Input
                          type={ field.type }
                          step={ ['cgpa', "at-cgpa"].indexOf( field.value ) > -1 ? 0.01 : 1 }
                          value={ studentData[ field.value ] }
                          onChange={ e => {
                            Promise.all([
                              onChangeStudentField( field.value, e.target.value )
                            ]).then(() => {
                              field.value === 'phone' && debounceMobile( studentData, e.target.value )
                            })
                          }} />
                      )
                    }
                    { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button 
          className="mr-2"
          color={ 
            ( DefaultFieldData.some( item => item.required && !studentData[ item.value ]) ) 
              ? 'secondary' 
              : 'primary' 
          }
          disabled={ DefaultFieldData.some( item => item.required && !studentData[ item.value ])}
          onClick={() => createStudent({ 
            ... studentData,
            session_id: parseInt( studentData.session_id ),
            accumulated_credits: parseInt( studentData.accumulated_credits ),
            at_cgpa: parseFloat( studentData.at_cgpa ),
            cgpa: parseFloat( studentData.cgpa )
          })}>
          Submit
        </Button>
      </CardFooter>
    </>
  )
}

export default SessionHOC( FormContent );