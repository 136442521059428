import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup, Label, Input,
  Card, CardHeader, CardBody
} from 'reactstrap'
import _ from 'lodash'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import DatePicker from 'react-datepicker'
import { StatusLib, ViewData } from './assets'

const defaultValue = {
  date: null,
  tasks: '',
  status: null,
  is_external_checked: false,
  is_internal_checked: false
}

const CreateWeeklyLog = ({
  open,
  data,
  selectedAcceptedList,
  onLoadWeeklyLog,
  
  onClose,
  getAcceptedList,
  onClickSubmit
}) => {

  const [ weeklyData, onChangeWeeklyLogData ] = useState( defaultValue )

  useEffect(() => {
    !open && onChangeWeeklyLogData( defaultValue )
  }, [open])

  const onChangeField = ( key, val ) => {
    let temp = _.cloneDeep( weeklyData )
    temp[ key ] = val

    onChangeWeeklyLogData( temp )
  }
  
  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create Weekly Logs` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          {
            ViewData.map( card => (
              <Card className={ 'mb-3' }>
                <CardHeader style={{ fontSize: '1rem' }}> { card.card_name }</CardHeader>
                <CardBody>
                  <Row>
                    {
                      card.fields.map( item => (
                        <Col lg={ item.col || 12 }>
                          <FormGroup>
                            <Label>{ item.label }</Label>
                            <Input
                              type={ item.type }
                              disabled={ true }
                              value={ selectedAcceptedList?.[ card.card_obj ]?.[ item.value ]??'' }
                            />
                          </FormGroup>
                        </Col>
                      ))
                    }
                  </Row>
                </CardBody>
              </Card>
            ))
          }
          <Card>
            <CardHeader style={{ fontSize: '1rem' }}>Tasks</CardHeader>
            <CardBody>
              <Row>
                <Col lg={ 12 }>
                  <FormGroup>
                    <Label>
                      Date
                      <span className="text-danger">*</span>
                    </Label>
                    <DatePicker
                      className={ 'w-100' }
                      dateFormat="dd-MM-yyyy"
                      selected={ weeklyData.date }
                      onChange={ val => onChangeField( 'date', val )}
                    />
                  </FormGroup>
                </Col>
                <Col lg={ 12 }>
                  <FormGroup>
                    <Label>
                      Status
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      value={ weeklyData.status }
                      onChange={ e => onChangeField( 'status', e.target.value )}
                    >
                      <option value=""></option>
                      {
                        StatusLib.map( item => (
                          <option key={ item.label } value={ item.label }>{ item.label }</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={ 12 }>
                  <Label>
                    Task Descriptions
                    <span className="text-danger">*</span>
                  </Label>
                  <CKEditor
                    editor={ ClassicEditor }
                    data={ weeklyData.tasks }
                    onReady={ editor => {
                      // You can store the "editor" and use when it is needed.
                      // console.log( 'Editor is ready to use!', editor );
                    }}
                    onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      onChangeField( 'tasks', data )
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        { onLoadWeeklyLog && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          color={ 
            'primary' 
          }
          disabled={ weeklyData.tasks === '' }
          onClick={() => onClickSubmit({
            ... weeklyData,
            student_id: data.ProfileReducer.profile.student.id,
            date: Moment( weeklyData.date ).format(),
          })}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateWeeklyLog;