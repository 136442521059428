import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Moment from 'moment'
import { 
  Row,
  Col,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const FieldData = [
  { label: 'Date', value: 'date', type: 'date', required: true },
  { label: 'Status', value: 'status', type: 'select', required: true, 
    options: [
      { label: 'Started' },
      { label: 'On-going' },
      { label: 'Complete' },
    ]
  },
  { label: 'Task Description', value: 'tasks', type: 'textEditor', required: true },
  { label: 'Internal Checked', value: 'is_internal_checked', type: 'internal_checked', required: false },
  { label: 'External Checked', value: 'is_external_checked', type: 'external_checked', required: false },
]

const Edit = props => {

  const {
    data,
    showEditModal,
    selectedMonth,
    selectedWeeklyLog,
    selectedAcceptedVacancies,
    getSelectedStudentWeeklyLog,

    updateWeeklyLog,
    setShowEditModal
  } = props

  const [ formContent, setFormContent ] = useState({
    tasks: '',
    date: '',
    status: '',
    is_external_checked: false,
    is_internal_checked: false,
    student_id: selectedAcceptedVacancies.student.user.id,
  })

  const onChangeFormContent = ( context, val ) => {
    let temp = _.cloneDeep( formContent )
    temp[ context ] = val
    setFormContent( temp )
  }

  const onClickSubmit = data => {
    const { student_id } = selectedAcceptedVacancies.student_vacancy

    let parsedDate = Moment(selectedMonth, 'MMM YYYY');
    let currentMonth = new Date(parsedDate.year(), parsedDate.month(), 1);
    let to = new Date( currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1 );
    to = Moment( to ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'
    let from = new Date( currentMonth.getFullYear(), currentMonth.getMonth(), 0 );
    from = Moment( from ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'

    Promise.all([
      updateWeeklyLog( data )
    ]).then( () => {
      getSelectedStudentWeeklyLog( btoa(`{ "page": 1, "per_page": 1000, "filter": { "weekly_logs.date": { "$gt": "${ from }","$lt": "${ to }"}, "weekly_logs.student_id": ${ student_id } } }`))
      setShowEditModal( false )
    })
  }

  useEffect( () => {
    if( !_.isEmpty( selectedWeeklyLog )){
      setFormContent({ 
        ...selectedWeeklyLog,
        date: Moment( selectedWeeklyLog.date, 'DD-MM-YYYY' ).toDate( ) 
      })
    }
  }, [ showEditModal ])

  return(
    <Modal
      isOpen={ showEditModal }
      size={ 'xl' }>
      <ModalHeader toggle={ () => setShowEditModal( false )}>{ `Edit Log` }</ModalHeader>
      <ModalBody>
        <Row>
        {
          FieldData.map( item => (
            <Col lg={ item.col || 12 }>
              <FormGroup>
                {
                  item.type !== 'internal_checked' && item.type !== 'external_checked' && (
                    <Label>
                      { `${ item.label } ` }
                      { item.required && <span className="text-danger">*</span> }
                    </Label>
                  )
                }
                {
                  item.type === 'internal_checked' && (
                    <FormGroup className='mb-2' check> 
                    <Label check>
                      <Input 
                        checked={ formContent[ item.value ] } 
                        type="checkbox"
                        disabled={ [ 1, 11, 15 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                        onClick={ val => onChangeFormContent( item.value, val.target.checked ) }
                      />
                      Checked By Internal Supervisor
                    </Label>
                    </FormGroup>
                  )
                }
                {
                  item.type === 'external_checked' && (
                    <FormGroup className='mb-2' check> 
                    <Label check>
                      <Input 
                        checked={ formContent[ item.value ] } 
                        type="checkbox"
                        disabled={ [ 1, 11, 14 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                        onClick={ val => onChangeFormContent( item.value, val.target.checked ) }
                      />
                      Checked By External Supervisor
                    </Label>
                    </FormGroup>
                  )
                }
                {
                  item.type === 'date' && (
                    <DatePicker
                      className={ 'w-100 student-vacancy__datepicker' }
                      dateFormat={ "dd-MM-yyyy"}
                      disabled={ [ 1, 11, 13 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      selected={ formContent[ item.value ] }
                      onChange={ val => onChangeFormContent( item.value, val ) }/>
                  )
                }
                {
                  item.type === 'select' && (
                    <Input
                      type="select"
                      disabled={ [ 1, 11, 13 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      value={ formContent[ item.value ] }
                      onChange={ e => onChangeFormContent( item.value, e.target.value )}
                    >
                      <option value=""></option>
                      {
                        item.options.map( option => (
                          <option key={ option.label } value={ option.label }>{ option.label }</option>
                        ))
                      }
                    </Input>
                  )
                }
                {
                  item.type === 'textEditor' && (
                    <CKEditor
                      editor={ ClassicEditor }
                      disabled={ [ 1, 11, 13 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      data={ formContent[ item.value ] }
                      onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                      }}
                      onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        onChangeFormContent( item.value, data )
                      }}
                    />
                  )
                }
              </FormGroup>
            </Col>
          ))
        }
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ 
            FieldData.some( item => item.required && !formContent[ item.value ])
              ? 'secondary' 
              : 'primary'
          }
          className="mr-2"
          disabled={ FieldData.some( item => item.required && !formContent[ item.value ]) }
          onClick={() => {
            onClickSubmit({
              ... formContent,
              date: Moment( formContent.date ).format()
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Edit;