import React, { useCallback, useState } from 'react'
import {
  CardBody, CardFooter,
  Button,
  Form,
  Row,
  Col,
  ModalBody,
  UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import { CSVLink } from 'react-csv';
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const FormContent = ({
  data,
  onLoadEvaluation,
  getEvaluations,
  current_selected_session_id
}) => {

  return (
    <>
      <ModalBody>
        <CardBody>
          <Form> 
            <Row>
              <Col>
                <h6>Example Output</h6>
                <ul style={{ marginLeft: '2rem' }}>
                  <li>Please make sure to check the example output first before downloading CSV.</li>
                  <li>Choose type of Evaluation Form to download below.</li>
                </ul>
                <Button
                  className="mr-2 mb-2 btn-icon"
                  color={ 'primary' }
                  onClick={() => {
                    getEvaluations( btoa(`{ "page": 1, "per_page": 1000, "filter": { "$and":[ { "ief.session_id": ${ current_selected_session_id } }, { "ief.type": "External" } ]  }}` ))
                  }}>
                  External
                </Button>
                <Button
                  className="mb-2 btn-icon"
                  color={ 'primary' }
                  onClick={() => {
                    getEvaluations( btoa(`{ "page": 1, "per_page": 1000, "filter": { "$and":[ { "ief.session_id": ${ current_selected_session_id } }, { "ief.type": "Internal" } ]  }}` ))
                  }}>
                  Internal
                </Button>
                <pre>{ JSON.stringify(data[0], null, '\t') }</pre>
              </Col>
            </Row>
          </Form>
        </CardBody>
        <CardFooter>
          <CSVLink data={ data } filename={"EvaluationForms.csv"}>
            <Button
              id="DownloadStudent"
              className="btn-icon"
              color="warning">
                <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                Download CSV
            </Button>
          </CSVLink>
          <UncontrolledTooltip target="DownloadStudent" placement="right">Download Evaluation Forms as CSV</UncontrolledTooltip>
        </CardFooter>
        { onLoadEvaluation && <LoadingOverlay/> }
      </ModalBody>
    </>
  )
}

export default FormContent;