import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get, Post, Put, Delete } from 'utils/axios'
import { storeLastView } from 'actions/lastView'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      logBookDateTime: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedLogBookDateTime: {
        created_at: "",
        end_date: "",
        id: '',
        session_id: '',
        start_date: "",
        updated_at: "",
      },

      selectedDeleteLogBookDateTimeId: null,
      showCreateLogBookDateTimeModal: false,
      showDeleteLogBookDateTimeModal: false,
      showUpdateLogBookDateTimeModal: false
    }

    onChangeLogBookDateTimeHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getLogBookDateTime = search => {
      this.props.storeLastView({ search })

      Get(
        `/log_book_datelines?query=${ search }`,
        this.getLogBookDateTimeSuccess,
        this.getLogBookDateTimeError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getLogBookDateTimeSuccess = payload => {
      let tempData = []
      payload.data.map( item => {
        tempData.push({
          ... item,
          start_date: Moment.utc( item.start_date ).format( 'DD-MM-yyyy h:mm a' ),
          end_date: Moment.utc( item.end_date ).format( 'DD-MM-yyyy h:mm a' ),
        })
      })

      this.setState({ logBookDateTime: {
        data: tempData,
        meta: payload.meta
      }},)
    }
    getLogBookDateTimeError = error => this.requestError( error )

    getSelectedLogBookDateTime = ( id, context ) => {
      Get(
        `/log_book_datelines/${ id }`,
        payload=> this.getSelectedLogBookDateTimeSuccess( payload, context ),
        this.getSelectedLogBookDateTimeError,
        this.load
      )
    }
    getSelectedLogBookDateTimeSuccess = ( payload, context ) => {
      let tempStartDate = new Date( payload.start_date )  
      let tempEndDate = new Date( payload.end_date )
      var userTimezoneOffset = tempStartDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedLogBookDateTime: {
          ... payload,
          session_id: `${ payload.session_id }`,
          start_date: new Date(tempStartDate.getTime() + userTimezoneOffset ),
          end_date: new Date(tempEndDate.getTime() + userTimezoneOffset ),
        }, 
        showUpdateLogBookDateTimeModal: context === 'edit' 
      })
    }
    getSelectedLogBookDateTimeError = error => this.requestError ( error )

    createLogBookDateTime = dataToSubmit => Post(
      `/log_book_datelines`,
      dataToSubmit,
      this.createLogBookDateTimeSuccess,
      this.createLogBookDateTimeError,
      this.load
    )
    createLogBookDateTimeSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      
      this.getLogBookDateTime( search )
      this.setState({ showCreateLogBookDateTimeModal: false })
      this.requestSuccess( 'Logbook Datelines has been created successfully.' )
    }
    createLogBookDateTimeError = error => this.requestError( error )

    deleteLogBookDateTime = id => Delete( 
      `/log_book_datelines/${ id }`,
      this.deleteLogBookDateTimeSuccess,
      this.deleteError,
      this.load
    )
    deleteLogBookDateTimeSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      this.getLogBookDateTime( search )
      this.setState({ showDeleteLogBookDateTimeModal: false })
      this.requestSuccess( 'Logbook Datelines has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateLogBookDateTime = dataToSubmit => Put(
      `/log_book_datelines/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateLogBookDateTimeSucces,
      this.updateLogBookDateTimeError,
      this.load
    )
    updateLogBookDateTimeSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getLogBookDateTime( search )
      this.getSelectedLogBookDateTime( payload.id )
      this.setState({ showUpdateLogBookDateTimeModal: false })
      this.requestSuccess( 'Logbook Datelines was updated successfully.' )
    }
    updateLogBookDateTimeError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          logBookDateTime={ this.state.logBookDateTime }
          onLoadLogBookDateTime={ this.state.loading }
          selectedLogBookDateTime={ this.state.selectedLogBookDateTime }
          selectedDeleteLogBookDateTimeId={ this.state.selectedDeleteLogBookDateTimeId }
          showCreateLogBookDateTimeModal={ this.state.showCreateLogBookDateTimeModal }
          showDeleteLogBookDateTimeModal={ this.state.showDeleteLogBookDateTimeModal }
          showUpdateLogBookDateTimeModal={ this.state.showUpdateLogBookDateTimeModal }

          getLogBookDateTime={ this.getLogBookDateTime }
          getSelectedLogBookDateTime={ this.getSelectedLogBookDateTime }
          createLogBookDateTime={ this.createLogBookDateTime }
          deleteLogBookDateTime={ this.deleteLogBookDateTime }
          updateLogBookDateTime={ this.updateLogBookDateTime }
          onChangeLogBookDateTimeHOC={ this.onChangeLogBookDateTimeHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC