import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Collapse, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import EditCompany from './Edit'

import CompanyHOC from './actions'
  
class Company extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.currentPermission.is_read && this.props.getCompany( btoa(`{"page":1}`) )
    }
  }

  render = () => {
    const { is_update, is_delete } = this.props.currentPermission
    return (
      <>
        <PageTitle
          heading="Company"
          subheading="Listings of all the companies present in the system."
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeCompanyHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeCompanyHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeCompanyHOC }
              getListAPI={ this.props.getCompany }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.companies.data.length < 1? 3: 
                      this.props.companies.data.length < 10? this.props.companies.data.length:
                      10
                    }
                    data={ this.props.companies.data }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Email',
                        accessor: 'contact_person_email'
                      },
                      {
                        Header: 'Phone',
                        accessor: 'phone'
                      },
                      {
                        Header: 'Approval Status',
                        Cell: (row) => {
                          return (
                            <span className={ `badge badge-${ row.original.status === 'Active' ? 'success': 'danger' }`}>
                              { row.original.status }
                            </span>
                          )
                        }
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateCompany"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary'}
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.getSelectedCompany(row.original.id)
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateCompany" placement="left">Update the information of this company</UncontrolledTooltip>
                              <Button
                                id="DeleteCompany"
                                disabled={ [ 1, 11 ].indexOf( this.props.data.ProfileReducer.current_role_id ) < 0 }
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? 'danger' : 'secondary' }
                                onClick={() => {
                                  this.props.onChangeCompanyHOC( 'showDeleteCompanyModal', true )
                                  this.props.onChangeCompanyHOC( 'selectedDeleteCompanyId', row.original.id )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteCompany" placement="left">Delete this Company</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.companies.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getCompany( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <EditCompany
            { ... this.props }
            open={ this.props.showUpdateCompanyModal }
            onClose={ () => this.props.onChangeCompanyHOC( 'showUpdateCompanyModal', false )}
          />
          <ConfirmationModal
            open={ this.props.showDeleteCompanyModal }
            title={ `Delete Company` }
            loading={ this.props.onLoadCompany }
            message={ `Are you sure you want to delete this company? (This process can't be undone)` }
            onClose={ () => this.props.onChangeCompanyHOC( 'showDeleteCompanyModal', false )}
            onClickConfirm={ () => this.props.deleteCompany( this.props.selectedDeleteCompanyId )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadCompany && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  CompanyHOC
)(Company)