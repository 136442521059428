import React, { Component } from 'react'
import { compose } from 'redux'
import Moment from 'moment'
import { CheckmarkOutline, CloseOutline, CalendarOutline } from 'react-ionicons'
import { PulseLoader } from 'react-spinners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Collapse, Row, Col, 
  Card, CardBody,
  Button, 
  UncontrolledTooltip
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import EditModal from './Edit/index'
import DetailModal from './ColumnContent'

import SessionHOC from 'actions/hoc/session'
import StudentAcceptedVacanciesHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

import './index.scss'
  
class StudentAcceptedVacancies extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer
    const { current_role_id } = this.props.data.ProfileReducer
    const contact_person_email = this.props.data.ProfileReducer.profile.company?.contact_person_email || ""
    const { id, email, company_id} = this.props.data.ProfileReducer.profile.user

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
        this.props.getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
      } else if (current_role_id === 14) {
        this.props.getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "student_vacancy_accepted_lists.external_supervisor_id": ${ id }, "students.session_id": ${ current_selected_session_id } } }`) )
      } else {
        this.props.getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
      }
      this.props.getJobScopes( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sjsf.company_id": ${ company_id } } }`) )
      this.props.getInsurances( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sjsf.company_id": ${ company_id } } }`) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
        this.props.getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
      } else if (current_role_id === 14) {
        this.props.getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "student_vacancy_accepted_lists.external_supervisor_id": ${ id }, "students.session_id": ${ current_selected_session_id } } }`) )
      } else {
        this.props.getStudentAcceptedVacancies( btoa(`{ "page": 1, "filter":{ "students.session_id": ${ current_selected_session_id } } }`) )
      }
      this.props.getJobScopes( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sjsf.company_id": ${ company_id } } }`) )
      this.props.getInsurances( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sjsf.company_id": ${ company_id } } }`) )
    }
  }

  onClickViewDocument = ( flag, index, header ) => {
    const { student_id } = this.props.studentAcceptedVacancies.data[ index ].student_vacancy
    switch( header ){
      case 'Offer Letter':
        this.props.getSelectedStudentOfferLetters( student_id )
        break;
      case 'Placement Form':
        if( flag )
          this.props.getSelectedStudentPlacementForm( this.props.studentAcceptedVacancies.data[ index ].placement_form_id )
        break;
      case 'Duty Report':
        this.props.getSelectedStudentDutyReport( this.props.studentAcceptedVacancies.data[ index ].student_report_duty_form_id )
        break;
      case 'Insurance Form':
        if( flag )
          this.props.getSelectedStudentInsuranceForm( flag )
        break;
      case 'Job Scope Form':
        this.props.getSelectedStudentJobScopeForm( flag )
        break;
      case 'Lecturer Visit Schedule':
        if( flag )
          this.props.getSelectedStudentVisitSchedule( flag )
        break;
      case 'External Evaluation Form':
        if( flag )
          this.props.getSelectedStudentEvaluation( flag )
        break;
      case 'Internal Evaluation Form':
        if( flag )
          this.props.getSelectedStudentEvaluation( flag )
        break;
      case 'Weekly Log':
        let currentMonth = new Date()
        let to = new Date( currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1 );
        to = Moment( to ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'
        let from = new Date( currentMonth.getFullYear(), currentMonth.getMonth(), 0 );
        from = Moment( from ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'
        this.props.getSelectedStudentWeeklyLog( btoa(`{ "page": 1, "per_page": 1000, "filter": { "weekly_logs.date": { "$gt": "${ from }","$lt": "${ to }"}, "weekly_logs.student_id": ${ student_id } } }`))
        break;
    }
    this.props.onChangeStudentAcceptedVacanciesHOC( 'studentAcceptedVacanciesDocHeader', header )
    this.props.onChangeStudentAcceptedVacanciesHOC( 'showStudentAcceptedVacanciesDocModal', true )
  }

  renderBoolIcon = flag => (
    flag === 'date'?
      <CalendarOutline color={ 'blue' } style={{ width: 18, height: 18 }}/>:
    flag && flag !== 'Pending'?
      <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>:
      <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
  )

  renderClickableCheck = ( flag, index, header, otherStatus ) => (
    <div 
      className={ 'student-vacancy-clickable-check' }
      onClick={ () => {
        this.props.onChangeStudentAcceptedVacanciesHOC( 'selectedAcceptedVacancies', this.props.studentAcceptedVacancies.data[ index ] )
        this.onClickViewDocument( flag, index, header )
      }}>
      { (header === 'Job Scope Form' || header === 'Insurance Form')? this.renderBoolIcon( otherStatus ): this.renderBoolIcon( flag )
       }
    </div>
  )

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    const { studentAcceptedVacancies, jobScope, insurances } = this.props
    studentAcceptedVacancies.data = studentAcceptedVacancies.data.map(v => ({ ...v, ...jobScope.data.find(sp => sp.student_id === v.student_vacancy.student_id) }));
    studentAcceptedVacancies.data = studentAcceptedVacancies.data.map(v => ({ ...v, ...insurances.data.find(sp => sp.student_id === v.student_vacancy.student_id) }));

    return (
      <>
        <PageTitle
          heading="Internship Management"
          subheading="Internship which is applied by student and accepted by company rep is listed here."
          icon="pe-7s-tools icon-gradient bg-happy-itmeo"
          buttons={[
            ... is_create ? [{
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeStudentAcceptedVacanciesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }] : []
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeStudentAcceptedVacanciesHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeStudentAcceptedVacanciesHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 'students.session_id' )

                this.props.getStudentAcceptedVacancies( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          <Row>
            <Col lg={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.studentAcceptedVacancies.data.length < 1? 3: 
                      this.props.studentAcceptedVacancies.data.length < 10? this.props.studentAcceptedVacancies.data.length:
                      10 }
                    data={ this.props.studentAcceptedVacancies.data }
                    columns={[
                      {
                        Header: 'Student Name',
                        accessor: 'student_name',
                        minWidth: 150
                      },
                      {
                        Header: 'Company',
                        accessor: 'company_name',
                        minWidth: 150
                      },
                      {
                        Header: 'Offer Letter',
                        accessor: 'offer_letter_urls',
                        width: 100,
                        Cell: cell => this.renderClickableCheck( cell.original.offer_letter_urls, cell.index , 'Offer Letter' )
                      },
                      {
                        Header: 'Placement Form',
                        accessor: 'is_placement_form_check',
                        width: 100,
                        Cell: cell => this.renderClickableCheck( cell.original.placement_form_id, cell.index, 'Placement Form' )
                      },
                      {
                        Header: 'Job Scope Form',
                        accessor: 'student_job_scope_form_id',
                        width: 100,
                        Cell: cell => this.renderClickableCheck( cell.original.student_job_scope_form_id, cell.index, 'Job Scope Form', cell.original.jkli_approval_status )
                      },
                      {
                        Header: 'Insurance Form',
                        accessor: 'is_clerk_approved',
                        width: 100,
                        Cell: cell => this.renderClickableCheck( cell.original.student_insurance_form_id, cell.index, 'Insurance Form', cell.original.is_clerk_approved )
                      },
                      {
                        Header: 'Duty Report',
                        accessor: 'student_report_duty_form_id',
                        width: 100,
                        Cell: cell => this.renderClickableCheck( cell.original.student_report_duty_form_id, cell.index, 'Duty Report' )
                      },
                      {
                        Header: 'Weekly Log',
                        accessor: 'is_duty_report_check',
                        width: 100,
                        Cell: cell => this.renderClickableCheck( 'date' , cell.index, 'Weekly Log' )
                      },
                      {
                        Header: 'Lecturer Visit Schedule',
                        accessor: 'visit_schedule_id',
                        minWidth: 100,
                        Cell: cell => this.renderClickableCheck( cell.original.visit_schedule_id, cell.index, 'Lecturer Visit Schedule' )
                      },
                      {
                        Header: 'Accepted Date',
                        accessor: 'accepted_date',
                        minWidth: 150
                      },
                      {
                        Header: 'Final Report',
                        accessor: 'accepted_date',
                        minWidth: 100,
                        Cell: cell => (
                          <div 
                            className={ 'student-vacancy-clickable-check' }
                            style={{ cursor: (!(cell.original.final_report_url == "")) ? 'pointer' : 'default'}}
                            onClick={() => {
                              if (!(cell.original.final_report_url == "")) {
                                window.open( `${ cell.original.final_report_url }` , "_blank" );
                              }
                            }}>
                            { this.renderBoolIcon( cell.original.final_report_url ) }
                          </div>
                        )
                      },
                      {
                        Header: 'External Supervisor',
                        accessor: 'external_sv',
                        minWidth: 150,
                        Cell: cell => (
                          <div 
                            className={ 'd-block' }
                            style={ this.props.data.ProfileReducer.current_role_id !== 13 ? { cursor: 'pointer' } : { pointerEvents: 'none' } }
                            onClick={ () => {
                              this.props.onChangeStudentAcceptedVacanciesHOC( 'selectedAcceptedVacancies', cell.original )
                              this.onClickViewDocument( cell.original.external_evaluation_form_id, cell.index, 'External Evaluation Form' )
                            }}>
                            <strong>{ cell.original.external_supervisor }</strong>
                            <div className={ 'mt-1' } style={{ cursor: 'pointer' }}>
                              <small className={ 'mr-1' }>Final Report Check</small>
                              { this.renderBoolIcon( cell.original.is_external_final_report_checked ) }
                            </div>
                            <div style={{ cursor: 'pointer' }}>
                              <small className={ 'mr-1' }>Evaluation Form</small>
                              { this.renderBoolIcon( cell.original.external_evaluation_form_id ) }
                            </div>
                          </div>
                        )
                      },
                      {
                        Header: 'Internal Supervisor',
                        accessor: 'internal_sv',
                        minWidth: 150,
                        Cell: cell => (
                          <div 
                            className={ 'd-block' }
                            style={ this.props.data.ProfileReducer.current_role_id !== 13 ? { cursor: 'pointer' } : { pointerEvents: 'none' } }
                            onClick={ () => {
                              this.props.onChangeStudentAcceptedVacanciesHOC( 'selectedAcceptedVacancies', cell.original )
                              this.onClickViewDocument( cell.original.internal_evaluation_form_id, cell.index, 'Internal Evaluation Form' )
                            }}>
                            <strong>{ cell.original.internal_supervisor }</strong>
                            <div className={ 'mt-1' }>
                              <small className={ 'mr-1' }>Final Report Check</small>
                              { this.renderBoolIcon( cell.original.internal_evaluation_form_id ) }
                            </div>
                            <div>
                              <small className={ 'mr-1 mb-0' }>Evaluation Form</small>
                              { this.renderBoolIcon( cell.original.internal_evaluation_form_id ) }
                            </div>
                          </div>
                        )
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateStudentVacancies"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => {
                                  Promise.all([
                                    this.props.getSelectedStudentAcceptedVacancies( row.original.id )
                                  ]).then(() => {
                                    this.props.onChangeStudentAcceptedVacanciesHOC( 'showUpdateStudentAcceptedVacanciesModal', true )
                                  })
                                }}
                              >
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateStudentVacancies" placement="left">Update internship application</UncontrolledTooltip>
                              <Button
                                id="DeleteStudentVacancies"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? "danger" : 'secondary' }
                                disabled={ !is_delete || this.props.data.ProfileReducer.current_role_id === 13 }
                                onClick={() => {
                                  this.props.onChangeStudentAcceptedVacanciesHOC( 'selectedAcceptedVacancies', row.original.id )
                                  this.props.onChangeStudentAcceptedVacanciesHOC( 'showDeleteStudentAcceptedVacanciesModal', true )
                                }}
                              >
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteStudentVacancies" placement="left">Delete internship application</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.studentAcceptedVacancies.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getStudentAcceptedVacancies( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <EditModal
            { ... this.props }
            open={ this.props.showUpdateStudentAcceptedVacanciesModal }
            onClickSubmit={ this.props.updateStudentAcceptedVacancies }
            onClickUpload={ this.props.uploadFinalReport }
            selectedAcceptedVacancies={ this.props.selectedAcceptedVacancies }
            onClose={ () => this.props.onChangeStudentAcceptedVacanciesHOC( 'showUpdateStudentAcceptedVacanciesModal', false )}
          />
          <DetailModal 
            { ... this.props }
            open={ this.props.showStudentAcceptedVacanciesDocModal }
            selectedAcceptedVacancies={ this.props.selectedAcceptedVacancies }
            is_update={ is_update }
            is_create={ is_create }
            onClose={ () => this.props.onChangeStudentAcceptedVacanciesHOC( 'showStudentAcceptedVacanciesDocModal', false )}
          />
          <ConfirmationModal
            open={ this.props.showDeleteStudentAcceptedVacanciesModal }
            title={ `Delete internship` }
            loading={ this.props.onLoadStudentAcceptedVacancies }
            message={ `Are you sure you want to delete internship` }
            onClose={ () => this.props.onChangeStudentAcceptedVacanciesHOC( 'showDeleteStudentAcceptedVacanciesModal', false )}
            onClickConfirm={ () => this.props.deleteStudentAcceptedVacancies( this.props.selectedAcceptedVacancies )
          }/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadStudentAcceptedVacancies && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  SessionHOC,
  StudentAcceptedVacanciesHOC
)(StudentAcceptedVacancies)