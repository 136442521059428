import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SessionForm from './Form'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import SessionsHOC from './actions'
import UserHOC from './actions/user'

class Sessions extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getSessions( btoa(`{"page":1}`) )
      this.props.getUsers( btoa(`{"page":1, "per_page": 1000}`))
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    const { current_selected_session_id } = this.props.data.SessionReducer

    return (
      <>
        <PageTitle
          heading="Sessions"
          subheading="Listings of all the sessions present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeSessionsHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... (
              is_create ? [{
                color: 'primary',
                onClick: () => {
                  this.props.onChangeSessionsHOC( 'showCreateSessionsModal', true )
                },
                content: 'Create'
              }] : []
            )
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeSessionsHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeSessionsHOC }
              getListAPI={ this.props.getSessions }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.sessions.data.length < 1? 3: 
                      this.props.sessions.data.length < 10? this.props.sessions.data.length:
                      10
                    }
                    data={ this.props.sessions.data }
                    columns={[
                      {
                        Header: 'Session Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Start Date',
                        accessor: 'start_date'
                      },
                      {
                        Header: 'End Date',
                        accessor: 'end_date'
                      },
                      {
                        Header: 'Person In Charge',
                        accessor: 'person_in_charge'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateSession"
                                disabled={ !is_update }
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? 'primary' : 'secondary' }
                                onClick={() => {
                                  this.props.getSelectedSession(row.original.id, 'edit' )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateSession" placement="left">Update the information of this session</UncontrolledTooltip>
                              {
                                current_selected_session_id !== row.original.id && (
                                  <>
                                    <Button
                                      id="DeleteSession"
                                      disabled={ !is_delete }
                                      className="mr-2 btn-icon btn-icon-only"
                                      color={ is_delete ? 'danger' : 'secondary' }
                                      onClick={() => {
                                        this.props.onChangeSessionsHOC( 'showDeleteSessionModal', true )
                                        this.props.onChangeSessionsHOC( 'selectedDeleteSessionId', row.original.id )
                                      }}>
                                      <i className="pe-7s-close btn-icon-wrapper"> </i>
                                    </Button>
                                    <UncontrolledTooltip target="DeleteSession" placement="left">Delete this Session</UncontrolledTooltip>
                                  </>    
                                )
                              }
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.sessions.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getSessions( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <SessionForm
            {...this.props}
            mode={ 'Create' }
            open={ this.props.showCreateSessionsModal }
            onClose={ () => this.props.onChangeSessionsHOC( 'showCreateSessionsModal', false )}
            onClickSubmit={ this.props.createSession }
          />
          <SessionForm
            {...this.props}
            mode={ 'Edit' }
            selectedFormData={ this.props.selectedSession }
            open={ this.props.showUpdateSessionsModal }
            onClose={ () => this.props.onChangeSessionsHOC( 'showUpdateSessionsModal', false )}
            onClickSubmit={ this.props.updateSession }
          />
          <ConfirmationModal
            loading={ this.props.onLoadSession }
            open={ this.props.showDeleteSessionModal}
            title={ `Delete Session` }
            message={ `Are you sure you want to delete this session? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeSessionsHOC( 'showDeleteSessionModal', false )}
            onClickConfirm={ () => this.props.deleteSession( this.props.selectedDeleteSessionId )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadSession && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  SessionsHOC,
  UserHOC
)(Sessions)