import React, { useEffect, useState, useRef } from 'react'
import { 
  Col, Row,
  Button,
  FormGroup,
  Input,
  Label,
  FormText,
  Card, CardHeader, CardBody, CardFooter,
  Modal, ModalHeader, ModalBody
} from 'reactstrap'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import DictionaryHOC from '../actions/dictionary'

import { AboutMeFieldData, AboutMeDefaultValue } from '../assets'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const UpdateForm = props => {
  const {
    data,
    mode, // if mode is view then all fields is disabled
    onLoadStudentCV,
    onLoadDictionary,
    selectedStudentCV,
  
    getCountry,
    onClickSubmit,
    getCountryState
  } = props

  const [ updateFormData, onChangeFormData ] = useState( AboutMeDefaultValue )
  const [ onShowCurrentPhoto, onToggleShowCurrentPhoto ] = useState( null )
  const [ CVPhoto, onChangePhoto ] = useState( null )
  const [ showZoomIn, onToggleZoomIn ] = useState( false )

  useEffect(() => {
    getCountry()
    onToggleShowCurrentPhoto( true  )
    getCountryState( selectedStudentCV.student_cv.country )
    onChangeFormData( selectedStudentCV.student_cv )
  }, [ selectedStudentCV ])

  const pond = useRef(null)

  function onChangeCreateFile ( fileItems ) {
    if( fileItems && fileItems.length > 0 && fileItems[ 0 ].file ) {
      const reader = new FileReader()
      reader.onload = e => {
        return onChangePhoto( e.target.result )
      }
      reader.readAsDataURL( fileItems[ 0 ].file )
    } 
    return onChangePhoto( null )
  }
  
  const onChangeUpdateField = ( key, val ) => {
    let tmp = _.cloneDeep( updateFormData )
    tmp[ key ] = val

    return onChangeFormData( tmp )
  }

  const onChangeCountryField = val => {
    let tmp = _.cloneDeep( updateFormData )
    tmp.country = val
    tmp.state = null

    getCountryState( val )
    onChangeFormData( tmp )
  }

  return (
    <>
      <Card className={ 'main-card mb-3' }>
        <CardHeader style={{ fontSize: '1rem' }}>{ `About Me` }</CardHeader>
        <CardBody>
          <Row>
            <Col lg={ mode !== 'view' ? 6 : 12 }>
              <FormGroup>
                <Label>Current Photo</Label>
                {
                  onShowCurrentPhoto && (
                    <img 
                      style={{ height: 200, cursor: 'zoom-in' }}
                      className={ 'rounded d-block' }
                      onClick={ () => onToggleZoomIn( true )}
                      src={ selectedStudentCV.student_cv.photo_url }
                      alt="cv-photo" 
                    />
                  )
                }
              </FormGroup>
            </Col>
            {
              mode !== 'view' && (
                <Col lg={ 6 }>
                  <FormGroup>
                    <Label>Photo Reupload</Label>
                    <br/>
                    <Label>(Please make sure the photo size does not reach 500 kb)</Label>
                    <FilePond
                      ref={ pond }
                      allowMultiple={ false }
                      fullWidth
                      acceptedFileTypes={['image/*']}
                      maxFiles={ 1 }
                      onremovefile={ () => onChangeCreateFile( null )}
                      onupdatefiles={ onChangeCreateFile }
                    />
                    <Button 
                      color={ CVPhoto ? 'primary' : 'secondary' }
                      className="ml-auto"
                      disabled={ !CVPhoto }
                      onClick={() => {
                        CVPhoto && onToggleShowCurrentPhoto( false )
                        pond.current.removeFile()
                        onClickSubmit({
                          id: selectedStudentCV.student_cv.id,
                          gender: selectedStudentCV.student_cv.gender,
                          additional_info: selectedStudentCV.student_cv.additional_info,
                          city: selectedStudentCV.student_cv.city,
                          postal_code: selectedStudentCV.student_cv.postal_code,
                          mailing_address: selectedStudentCV.student_cv.mailing_address,
                          state: +selectedStudentCV.student_cv.state,
                          country: +selectedStudentCV.student_cv.country,
                          student_id: selectedStudentCV.student_cv.student_id,
                          ... ( CVPhoto && { photo: CVPhoto })
                        })
                      }}
                    >
                      Reupload
                    </Button>
                  </FormGroup>
                </Col> 
              )
            }
            {
              AboutMeFieldData( props ).map( item => (
                <Col lg={ item.col || 12 } xs={ 12 }>
                  <FormGroup>
                    { 
                      item.label && (
                        <Label>
                          { `${ item.label } ` }
                          { item.required && <span className="text-danger">*</span> }
                        </Label>
                    )}
                    {
                      item.type === 'select' && (
                        <Input
                          type={ item.type }
                          disabled={ mode === 'view' }
                          value={ updateFormData[ item.value ] }
                          onChange={ e => {
                            if ( item.value === 'country' ){
                              return onChangeCountryField( e.target.value )
                            }
                            return onChangeUpdateField( item.value, e.target.value )
                          }}>
                          <option value=""></option>
                          { 
                            item.options.map( option => (
                              <option key={ option.id } value={ option.id }>{ option.name }</option>
                            ))
                          }
                        </Input>
                      )
                    }
                    {
                      item.type === 'details' && (
                        <Input
                          type='text'
                          disabled={ true }
                          value={ selectedStudentCV?.student_user?.[ item.value ] }/>
                      )
                    }
                    {
                      item.type !== 'select' && item.type !== 'details' && (
                        <Input
                          type={ item.type }
                          disabled={ mode === 'view' }
                          value={ updateFormData[ item.value ] }
                          onChange={ e => onChangeUpdateField( item.value, e.target.value )}/>
                      )
                    }
                    { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
          {( onLoadStudentCV || onLoadDictionary ) && <LoadingOverlay />}
        </CardBody>
        {
          mode === 'normal' && (
            <CardFooter>
              <Button 
                color="primary"
                className="mr-2"
                onClick={() => {
                  CVPhoto && onToggleShowCurrentPhoto( false )
                  pond.current.removeFile()
                  onClickSubmit({
                    id: updateFormData.id,
                    gender: updateFormData.gender,
                    additional_info: updateFormData.additional_info,
                    city: updateFormData.city,
                    postal_code: updateFormData.postal_code,
                    mailing_address: updateFormData.mailing_address,
                    state: +updateFormData.state,
                    country: +updateFormData.country,
                    student_id: selectedStudentCV.student_cv.student_id,
                    ... ( CVPhoto && { photo: CVPhoto })
                  })
                }}
              >
                Update
              </Button>
            </CardFooter>
          )
        }
      </Card>
      <Modal
        isOpen={ showZoomIn }
        size={ 'xl' }>
        <ModalHeader toggle={() => onToggleZoomIn( false )}>{ `Uploaded Photo` }</ModalHeader>
        <ModalBody>
          <img src={ selectedStudentCV.student_cv.photo_url } style={{ width: '100%' }} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default DictionaryHOC( UpdateForm );