import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Button, UncontrolledTooltip, Collapse,
  ModalHeader, Modal
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import EditForm from './Edit'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import CompanyHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class Company extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getPlacementForm( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getPlacementForm( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }
  }

  renderNonStudentContent = () => {
    const { is_update } = this.props.currentPermission

    return (
      <>
        <Collapse
          isOpen={ this.props.showSearchForms }
          onExiting={ () => this.props.onChangePlacementHOC( 'showSearchForms', false )}
        >
          <SearchForm
            searchParams={ this.props.searchParams }
            onChangeHOCState={ this.props.onChangePlacementHOC }
            getListAPI={ val => {
              let tempJSON = JSON.parse( atob( val ))
              tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 's.session_id' )

              this.props.getPlacementForm( btoa( JSON.stringify( tempJSON )) )
            }}
          />
        </Collapse>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    this.props.placementForms.data.length < 1? 3: 
                    this.props.placementForms.data.length < 10? this.props.placementForms.data.length:
                    10
                  }
                  data={ this.props.placementForms.data || [] }
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name'
                    },
                    {
                      Header: 'Email',
                      accessor: 'email'
                    },
                    {
                      Header: 'Phone',
                      accessor: 'phone'
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              disabled={ !is_update }
                              id="UpdatePlacement"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_update ? 'primary' : 'secondary' }
                              onClick={() => {
                                this.props.getSelectedPlacementForm( row.original.id )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="UpdatePlacement" placement="left">Update placement form</UncontrolledTooltip>
                            <Button
                              id="DownloadPlacement"
                              className="mr-2 btn-icon btn-icon-only"
                              color="warning"
                              onClick={() => this.props.getStudentPlacementForm_pdf(row.original.id, row.original.name) }
                            >
                              <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="DownloadPlacement" placement="left">Download this placement form</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={ false } />
                <Pagination 
                  metadata={ this.props.placementForms.meta } 
                  onChangePage={ pg => {
                    const { search } = this.props.data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    this.props.getPlacementForm( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={ this.props.showUpdatePlacementModal }
          size={ 'xl' }>
          <ModalHeader toggle={() => this.props.onChangePlacementHOC( 'showUpdatePlacementModal', false )}>{ `Edit Placement Form` }</ModalHeader>
          <EditForm 
            data={ this.props.data }
            onLoadPlacement={ this.props.onLoadPlacement }
            selectedPlacementForm={ this.props.selectedPlacementForm }
            onClickSubmit={ this.props.updatePlacementForm }
          />
        </Modal>
      </>
    )
  }
  
  render = () => {
    return (
      <>
        <PageTitle
          heading="Placement Forms"
          subheading="Listings of all the placement forms present in the system."
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangePlacementHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
          ]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent() }
          { this.props.data.ProfileReducer.current_role_id === 13 && 
            this.props.placementForms.data.length > 0 && ( 
            <>
              {
                _.keys( this.props.selectedPlacementForm ).length > 0 && (
                  <Card>
                    <EditForm 
                      data={ this.props.data }
                      onLoadPlacement={ this.props.onLoadPlacement }
                      selectedPlacementForm={ this.props.selectedPlacementForm }
                      onClickSubmit={ this.props.updatePlacementForm }
                    />
                  </Card>
                )
              }
              {
                _.keys( this.props.selectedPlacementForm ).length < 1 && (
                  <Card>
                    <CardHeader>No Placement Form is available. </CardHeader>
                  </Card>
                )
              }
            </>
          )}
        </ReactCSSTransitionGroup>
        { this.props.onLoadPlacement && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  CompanyHOC
)(Company)