import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import Moment from 'moment'

import { 
  Button, 
  UncontrolledTooltip,
} from 'reactstrap'
import ReactTable from 'react-table'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'
import ReactHtmlParser from 'react-html-parser';
import CreateModal from './Create'
import EditModal from './Edit'
import ConfirmationModal from 'components/Modal/confirmation'

import WeeklyLogHOC from '../../../WeeklyLogs/actions'
import LogBookDatelinesHOC from '../../../LogBookDatelines/actions'

import sessionQueryHandler from 'utils/sessionQueryHandler'

const currentMonth = new Date()

const WeeklyLog = props => {

  const { weekly_log } = props.selectedAcceptedVacancies
  const { student_id } = props.selectedAcceptedVacancies.student_vacancy
  const { is_create, is_update } = props
  const { is_delete } = props.currentPermission
  const [ documentList, setDocumentList ] = useState( [] )
  const [ monthList, setMonthList ] = useState( [] )
  const [ selectedMonth, setSelectedMonth ] = useState()
  const [ selectedWeeklyLog, setSelectedWeeklyLog ] = useState( {} )
  const [ showCreateModal, setShowCreateModal ] = useState( false )
  const [ showEditModal, setShowEditModal ] = useState( false )

  useEffect( () => {
    const { current_selected_session_id } = props.data.SessionReducer

    setSelectedMonth( Moment( new Date( currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0 )).format( 'MMM YYYY' ))

    props.getLogBookDateTime( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'session_id' ))) )
  }, [ props.open ])

  useEffect( () => {
    if (props.logBookDateTime && props.logBookDateTime.data.length > 0) {
      const { start_date, end_date } = props.logBookDateTime.data[0];

      let startDate = Moment(start_date, "DD-MM-YYYY hh:mm a").startOf('month');
      let endDate = Moment(end_date, "DD-MM-YYYY hh:mm a").endOf('month');

      let monthArray = [];

      while (startDate.isSameOrBefore(endDate)) {
        monthArray.push({
          label: startDate.format('MMM YYYY'),
          from: startDate.clone().startOf('month').subtract(1, 'day').format('YYYY-MM-DD') + 'T00:00:00.00000Z',
          to: startDate.clone().endOf('month').add(1, 'day').format('YYYY-MM-DD') + 'T00:00:00.00000Z'
        });
    
        startDate.add(1, 'month');
      }

      setMonthList( monthArray )
      setDocumentList( weekly_log?.data??[] )
    } else {
      let monthArray = Array.from({ length: 12 }, ( _, index ) => { 
        let label = new Date( currentMonth.getFullYear(), currentMonth.getMonth() - index, 1 );
        let to = new Date( currentMonth.getFullYear(), currentMonth.getMonth() - index + 1, 1 );
        let from = new Date( currentMonth.getFullYear(), currentMonth.getMonth() - index, 0 );
        return { 
          label: Moment( label ).format( 'MMM YYYY' ), 
          from: Moment( from ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z', 
          to: Moment( to ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'
        }
      });

      setMonthList( _.reverse( monthArray ))
      setDocumentList( weekly_log?.data??[] )
    }
  }, [ props.selectedAcceptedVacancies, props.logBookDateTime ])

  return(
    <>
      <div className={ 'd-flex flex-wrap mb-3' }>
        {
          monthList.map( month => (
            <button 
              className={ `mr-3 mb-3 btn ${ selectedMonth === month.label ? 'btn-secondary' : 'btn-outline-secondary' }` }
              onClick={ () => {
                props.getSelectedStudentWeeklyLog( btoa(`{ "page": 1, "per_page": 1000, "filter": { "weekly_logs.date": { "$gt": "${ month.from }","$lt": "${ month.to }"}, "weekly_logs.student_id": ${ student_id } } }`))
                setSelectedMonth( month.label )
              }}>
              { month.label }
            </button>
          ))
        }
        {
          is_create && (
            <button className="btn btn-primary rounded-pill ml-auto" onClick={ () => setShowCreateModal( true )}>Create</button>
          )
        }
      </div>
      <ReactTable
        pageSize={documentList.length < 1 ? 3 : documentList.length}
        data={ documentList }
        columns={[
          {
            Header: 'No.',
            width: 50,
            Cell: cell => cell.index + 1
          },
          {
            Header: 'Date',
            width: 100,
            accessor: 'date'
          },
          {
            Header: 'Task',
            accessor: 'tasks',
            minWidth: 400,
            Cell: cell => ReactHtmlParser( cell.original.tasks )
          },
          {
            Header: 'Internal Check',
            accessor: 'is_internal_checked',
            width: 100,
            Cell: cell => (
              <>
                 { 
                  cell.original.is_internal_checked
                    ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                    : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/> 
                }
              </>
            )
          },
          {
            Header: 'External Check',
            accessor: 'is_external_checked',
            width: 100,
            class: 'text-center',
            Cell: cell => (
              <>
                 { 
                  cell.original.is_external_checked
                    ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                    : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/> 
                }
              </>
            )
          },
          {
            Header: 'Progress Status',
            accessor: 'status',
            width: 100,
            Cell: cell => (
              <span className={ `badge badge-${ cell.original.status === 'Complete' ? 'success': "secondary" }` }>
                { cell.original.status }
              </span>
            )
          },
          {
            Header: 'Created At',
            width: 150,
            accessor: 'created_at'
          },
          {
            Header: 'Actions',
            Cell: cell => {
              return (
                <>
                  <Button
                    id="UpdateWeeklyLog"
                    className="mr-2 btn-icon btn-icon-only"
                    color={ is_update ? "primary" : 'secondary' }
                    disabled={ !is_update }
                    onClick={() => {
                      setSelectedWeeklyLog( cell.original )
                      setShowEditModal( true )
                    }}
                  >
                    <i className="pe-7s-pen btn-icon-wrapper"> </i>
                  </Button>
                  <UncontrolledTooltip target="UpdateWeeklyLog" placement="left">Update weekly log</UncontrolledTooltip>
                  <Button
                    id="DeleteWeeklyLog"
                    className="mr-2 btn-icon btn-icon-only"
                    color={ is_delete ? "danger" : 'secondary' }
                    disabled={ !is_delete }
                    onClick={() => {
                      Promise.all([
                        props.onChangeWeeklyLogHOC( 'showDeleteWeeklyLogModal', true )
                      ]).then(() => {
                        props.onChangeWeeklyLogHOC( 'selectedWeeklyLog', cell.original )
                      })
                    }}>
                    <i className="pe-7s-close btn-icon-wrapper"> </i>
                  </Button>
                  <UncontrolledTooltip target="DeleteWeeklyLog" placement="left">Delete this weekly Log</UncontrolledTooltip>
                </>
              )
            }
          }
        ]}
        showPagination={ false } />
        <CreateModal 
          { ...props } 
          selectedMonth={ selectedMonth }
          showCreateModal={ showCreateModal }
          setShowCreateModal={ setShowCreateModal }
        />
        <EditModal
          { ...props } 
          showEditModal={ showEditModal }
          selectedMonth={ selectedMonth }
          selectedWeeklyLog={ selectedWeeklyLog }
          setShowEditModal={ setShowEditModal }
        />
        <ConfirmationModal
          loading={ props.onLoadWeeklyLog }
          open={ props.showDeleteWeeklyLogModal}
          title={ `Delete Weekly Log` }
          message={ `Are you sure you want to delete this weekly logs? (This process can't be undone)` }
        
          onClose={ () => props.onChangeWeeklyLogHOC( 'showDeleteWeeklyLogModal', false )}
          onClickConfirm={ () => {
            const { student_id } = props.selectedAcceptedVacancies.student_vacancy

            let parsedDate = Moment(selectedMonth, 'MMM YYYY');
            let currentMonth = new Date(parsedDate.year(), parsedDate.month(), 1);
            let to = new Date( currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1 );
            to = Moment( to ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'
            let from = new Date( currentMonth.getFullYear(), currentMonth.getMonth(), 0 );
            from = Moment( from ).format( 'YYYY-MM-DD' ) + 'T00:00:00.00000Z'

            Promise.all([
              props.deleteWeeklyLog( props.selectedWeeklyLog.id )
            ]).then( () => {
              props.getSelectedStudentWeeklyLog( btoa(`{ "page": 1, "per_page": 1000, "filter": { "weekly_logs.date": { "$gt": "${ from }","$lt": "${ to }"}, "weekly_logs.student_id": ${ student_id } } }`))
            })
          }}
        />
    </>
  )
}

export default compose(
  WeeklyLogHOC,
  LogBookDatelinesHOC
)(WeeklyLog)