import {
  SET_CURRENT_SESSION,
  GET_SESSIONS
} from './type'
import Axios from 'axios'
import { getItem } from 'utils/tokenStore';
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'
import getDomainURL from 'utils/api'

export const getSessions = search => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'USM_IMS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/sessions?query=${ search }`
  ).then( response => {
    dispatch( getSessionsSuccess( response.data.data ) )
    dispatch( getSessionsSuccess( response.data.data ))
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getSessionsSuccess = payload => ({
  type: GET_SESSIONS,
  payload
})

export const setSessionID = payload => ({
  type: SET_CURRENT_SESSION,
  payload
})