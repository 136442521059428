import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      result: [],
      showSearchForms: false
    }

    onChangeResultsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getResult = id => Get(
      `/sessions/results${ id ? `?session_id=${ id }` : '' }`,
      this.getResultSuccess,
      this.getResultError,
      this.load
    )
    getResultSuccess = payload => this.setState({ result: payload })
    getResultError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          result={ this.state.result }
          onLoadResults={ this.state.loading }
          showSearchForms={ this.state.showSearchForms }

          getResult={ this.getResult }
          onChangeResultsHOC={ this.onChangeResultsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC