import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentSkill: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedSkill: {},

      showCreateSkillModal: false,
      showDeleteSkillModal: false,
      showUpdateSkillModal: false,

      skillSearchQuery: ''
    }

    onChangeSkillHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSkill = search => {
      this.setState({ skillSearchQuery: search }, () => {
        Get(
          `/student_cv_skills?query=${ search }`,
          this.getSkillSuccess,
          this.getSkillError,
          this.load,
          this.props.data.ProfileReducer.current_role_id
        )
      })
    }
    getSkillSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_cv_skill }) => {
        tempData.push({
          ... student_cv_skill
        })
      })
      this.setState({ studentSkill: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getSkillError = error => this.requestError( error )

    getSelectedSkill = ( id, context ) => {
      Get(
        `/student_cv_skills/${ id }`,
        payload => this.getSelectedSkillSuccess( payload, context ),
        this.getSelectedSkillError,
        this.load
      )
    }
    getSelectedSkillSuccess = ({ student_cv_skill }, context ) => {
      this.setState({   
        selectedSkill: student_cv_skill, 
        showUpdateSkillModal: context === 'edit' 
      })
    }
    getSelectedSkillError = error => this.requestError ( error )

    createSkill = dataToSubmit => Post(
      `/student_cv_skills`,
      dataToSubmit,
      this.createSkillSuccess,
      this.createSkillError,
      this.load
    )
    createSkillSuccess = () => {
      this.getSkill( this.state.skillSearchQuery )
      this.setState({ showCreateSkillModal: false })
      this.requestSuccess( 'Skill has been created successfully.' )
    }
    createSkillError = error => this.requestError( error )

    deleteSkill = id => Delete( 
      `/student_cv_skills/${ id }`,
      this.deleteSkillSuccess,
      this.deleteError,
      this.load
    )
    deleteSkillSuccess = () => {
      this.getSkill( this.state.skillSearchQuery )
      this.setState({ showDeleteSkillModal: false })
      this.requestSuccess( 'Skill has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateSkill = dataToSubmit => Put(
      `/student_cv_skills/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSkillSucces,
      this.updateSkillError,
      this.load
    )
    updateSkillSucces = ({ student_cv_skill }) => {
      this.getSkill( this.state.skillSearchQuery )
      this.getSelectedSkill( student_cv_skill.id )
      this.requestSuccess( 'Skill was updated successfully.' )
    }
    updateSkillError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          skillSearchQuery={ this.state.skillSearchQuery }
          studentSkill={ this.state.studentSkill }
          onLoadSkill={ this.state.loading }
          selectedSkill={ this.state.selectedSkill }
          showCreateSkillModal={ this.state.showCreateSkillModal }
          showDeleteSkillModal={ this.state.showDeleteSkillModal }
          showUpdateSkillModal={ this.state.showUpdateSkillModal }
          
          getSkill={ this.getSkill }
          getSelectedSkill={ this.getSelectedSkill }
          createSkill={ this.createSkill }
          deleteSkill={ this.deleteSkill }
          updateSkill={ this.updateSkill }
          onChangeSkillHOC={ this.onChangeSkillHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC