import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
 
const defaultValue = {
  description: '',
  department_sv: '',
}

const fieldData = [
  { label: 'Department Supervisor', value: 'department_sv', required: true, type: 'text' },
  { label: 'Description', value: 'description', required: true, type: 'textarea' }
]

const FormContent = ({
  mode,
  open,
  
  onClose,
  selectedJobTask,
  onClickSubmit
}) => {

  const [ taskData, onChangeTaskData ] = useState( defaultValue )

  useEffect(() => {
    if ( !open ){
      onChangeTaskData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    mode === 'Edit' && onChangeTaskData( selectedJobTask )
  }, [ selectedJobTask ])

  const onChangeSessionField = ( key, val ) => {
    let tmp = _.cloneDeep( taskData )
    tmp[ key ] = val
    return onChangeTaskData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Task` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
          {
            fieldData.map( field => (
              <Col lg={ 12 }>
                <FormGroup>
                  <Label>
                    { `${ field.label } ` }
                    { field.required && <span className="text-danger">*</span> }
                  </Label>
                  <Input
                    rows={ 6 }
                    type={ field.type }
                    value={ taskData[ field.value ] }
                    onChange={ e => {
                      onChangeSessionField( field.value, e.target.value )
                    }} />
                </FormGroup>
              </Col>
            ))
          }
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          color={ 
            _.values( taskData ).some( item => !item ) 
              ? "secondary" 
              : 'primary' 
          }
          disabled={ _.values( taskData ).some( item => !item )}
          onClick={() => {
            onClickSubmit( taskData )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent