import LoadingOverlay from "components/Indicator/LoadingOverlay";
import _ from "lodash";
import { useCallback } from "react";
import { PulseLoader } from "react-spinners";

import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	FormGroup,
	FormText,
	Input,
	Label,
	Row,
} from "reactstrap";

import { mobileChecker } from "utils/mobileChecker";
import { UserFieldData } from "./assets";
import CompanyTypeahead from "components/Typeahead/company";

import HOC from "./actions";

const UserFormUI = ({
	companyUser,
	getCompany,
	companies,
	onLoadCompanyUser,
	createCompanyUser,
	onChangeUserHOC,
}) => {
	const onChangeUserField = (key, val) => {
		let tmp = _.cloneDeep(companyUser);
		tmp[key] = val;

		return onChangeUserHOC("companyUser", tmp);
	};

	const debounceMobile = useCallback(
		_.debounce((fullData, val) => {
			onChangeUserHOC("companyUser", {
				...fullData,
				phone: mobileChecker(val),
			});
		}, 500),
		[]
	);

	const onChangeCompanyTypeahead = useCallback(
		_.debounce((val) => {
			getCompany(
				btoa(
					`{ "page": 1 , "per_page": 1000, "filter":{ "companies.name": {"$like": "%${val}%" } }}`
				)
			);
		}, 600),
		[]
	);

	return (
		<Card>
			<CardBody>
				<Row>
					<>
						<Col md={12}>
							<FormGroup>
								<Label>
									Company
									<span className="text-danger mr-2">*</span>
									<PulseLoader
										sizeUnit={"px"}
										size={10}
										color={"#16232c"}
										loading={onLoadCompanyUser}
									/>
								</Label>
								<CompanyTypeahead
									options={companies}
									onSelect={(val) => {
										if (val?.[0]?.id) {
											onChangeUserField("company_id", val[0].id);
										}
									}}
									selectedValue={
										companyUser.company_id &&
										_.find(companies, { id: companyUser.company_id })
											? [
													_.find(companies, {
														id: companyUser.company_id,
													}),
											  ]
											: []
									}
									onSearch={(val) => {
										val.length > 0
											? onChangeCompanyTypeahead(val)
											: onChangeUserHOC("companies", []);

										onChangeUserField("company_id", null);
									}}
									disableEdit={onLoadCompanyUser}
									helpText={"Please enter company name to search."}
								/>
							</FormGroup>
						</Col>
						{UserFieldData.map((field) => {
							return (
								<Col md={field.col || 12} xs={12} key={field.value}>
									<FormGroup>
										<Label>
											{`${field.label} `}
											{field.required && <span className="text-danger">*</span>}
										</Label>
										<Input
											type={field.type}
											step={field.value === "cgpa" ? 0.01 : 1}
											value={companyUser[field.value]}
											onChange={(e) => {
												Promise.all([
													onChangeUserField(field.value, e.target.value),
												]).then(() => {
													field.value === "phone" &&
														debounceMobile(companyUser, e.target.value);
												});
											}}
										/>
										{field.subLabel && <FormText>{field.subLabel}</FormText>}
									</FormGroup>
								</Col>
							);
						})}
					</>
				</Row>
			</CardBody>
			<CardFooter>
				<Button
					color={"primary"}
					className="mr-2"
					onClick={() => {
						createCompanyUser(companyUser);
					}}>
					Save
				</Button>
			</CardFooter>
			{onLoadCompanyUser && <LoadingOverlay />}
		</Card>
	);
};

export default HOC(UserFormUI);
