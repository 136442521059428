import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      registrationData: {
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirm: '',
        address: '',
        cgpa: '',
        ic: '',
        matric_number: '',
        specialization: '',
        session_id: '',
        at_cgpa: '',
        academic_status: '',
        accumulated_credits: null,
        remark: ''
      }
    }

    onChangeRegistrationHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    submitRegistration = dataToSubmit => Post(
      `/students`,
      dataToSubmit,
      this.submitRegistrationSuccess,
      this.submitRegistrationError,
      this.load
    )
    submitRegistrationSuccess = payload => {
      this.requestSuccess( `You've registered as student successfully.` )
      
      setTimeout(() => {
        this.props.history.push( '/login' )
      }, 1000);
    }
    submitRegistrationError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadRegistration={ this.state.loading }
          registrationData={ this.state.registrationData }

          submitRegistration={ this.submitRegistration }
          onChangeRegistrationHOC={ this.onChangeRegistrationHOC }/>
      )
    }
  }
  return WithHOC;
}

export default HOC