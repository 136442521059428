import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companies: []
    }

    onChangeCompanyHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    //getters
    getCompany = search => {
      Get(
        `/companies?query=${ search }`,
        this.getCompanySuccess,
        this.getCompanyError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getCompanySuccess = payload => {
      let tempData = []

      payload.data.map(({ company }) => {
        tempData.push( company )
      })
      this.setState({ companies: tempData })
    }
    getCompanyError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          companies={ this.state.companies }
          onLoadCompany={ this.state.loading }

          onChangeCompanyHOC={ this.onChangeCompanyHOC }
          getCompany={ this.getCompany }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC