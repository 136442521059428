import React, { Component } from 'react'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import _ from 'lodash'

import { setEnableMobileMenu } from 'reducers/ThemeOptions'

import { MainNav } from './dataMainNav'

class VerticalNavWrapper extends Component {

  state = {
    navData: []
  }

  componentDidMount = () => {
    const { current_role_id } = this.props.data.ProfileReducer
    
    current_role_id && this.onHandleInitialPermission()
  }

  componentDidUpdate = pp => {
    const { current_role_id } = this.props.data.ProfileReducer

    if ( pp.data.ProfileReducer.current_role_id !== current_role_id && current_role_id ){
      this.onHandleInitialPermission()
    }
  }

  onHandleInitialPermission = () => {
    const { current_role_id } = this.props.data.ProfileReducer
    const { sec_module_roles = [] } = this.props.data.ProfileReducer.profile

    let {
      sec_module_roles: currentSecModules
    } = _.find( sec_module_roles, { role_id: current_role_id } )
    
    let tempMenu = []
    let tempCanRead = _.filter( currentSecModules, module => module.is_read )
    
    MainNav.map( menuData => {
      let temp = this.permissionFilter( menuData, tempCanRead )
      temp && tempMenu.push( temp )
    })
    this.setState({ navData: tempMenu })  
  }
 
  permissionFilter = ( menuData, canReadMenu ) => {
    if ( menuData.content?.[0] ){
      let tempContent = [] 

      menuData.content.map( menu => {
        let temp = this.permissionFilter( menu, canReadMenu )

        temp && tempContent.push( temp )
      })

      if ( tempContent.length > 0 ){
        return ({
          ... menuData,
          content: tempContent
        })
      } 
      return false
    }

    if ( menuData.sec_module_id === 30 ){
      return menuData
    }

    let tempModules = _.find( canReadMenu, { sec_module_id: menuData.sec_module_id })
    return tempModules 
      ? this.props.data.ProfileReducer.current_role_id === 13 
        ? this.studentMenuChecker( menuData.sec_module_id, menuData )
        : menuData 
      : false
  }

  studentMenuChecker = ( menuId, menuData ) => {
    const { profile } = this.props.data.ProfileReducer
    const {
      is_accepted_vacancy,
      jkli_approval_status
    } = profile.student

    if ( [ 18, 31, 37 ].indexOf( menuId ) > -1 ){
      return is_accepted_vacancy ? menuData : false
    }

    if ( menuId === 32 ){
      return jkli_approval_status === 'Approved' ? menuData : false
    }

    return menuData
  }

  render = () => {
    return (
      <>
        <br />
        <MetisMenu
          content={ this.state.navData }
          onSelected={ () => this.props.setEnableMobileMenu( !this.props.enableMobileMenu ) }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down" />
      </>
    )
  }
}

const mapStateToProps = state => ({
  data: state,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu
})

export default connect( mapStateToProps, {
  setEnableMobileMenu
})( VerticalNavWrapper )