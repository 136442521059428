import React, { useState } from 'react'
import {
  UncontrolledTooltip,
  Button,
  Input,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap'
import ReactTable from 'react-table'

import StudentCVContent from './StudentCVContent'
import Pagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'

import StudentCVHOC from './actions/studentCV'

const StudentCard = props => {
  const {
    onLoadStudentCV,
    companyVacancyId,
    studentVacancyStatus,
    studentVacancies,
    studentCVData,
    showStudentCVContent,
    selectedStudentCV,

    onChangeStudentCVHOC,
    getSelectedStudentCV,
    getStudentLI,
    getStudentVacancies,
    updateStudentVacancy,
    onChangeAppliedStudentStatus
  } = props

  const [ selectedUser, onChangeSeletedUser ] = useState( null )
  const [ showAcceptedConfirmation, onToggleConfirmation ] = useState( null )
  studentVacancies.data = studentVacancies.data.map(v => ({ ...v, ...studentCVData.find(sp => sp.student_id === v.student_id) }));
  
  return (  
    <>
      <Card className="main-card mt-3">
        <CardHeader style={{ fontSize: '1rem' }}>
          Applied Internship Status
        </CardHeader>
        <CardBody>
          <ReactTable
            pageSize={ 
              studentVacancies.data.length < 1? 3: 
              studentVacancies.data.length < 10? studentVacancies.data.length:
              10
            }
            data={ studentVacancies.data }
            columns={[
              {
                Header: 'Name',
                accessor: 'student_name'
              },
              {
                Header: 'Status',
                accessor: 'status_id',
                Cell: data => (
                  <Input
                    type={ 'select' }
                    disabled={ data.original.disableStatusField }
                    value={ data.original.student_vacancy_status_id }
                    onChange={ e => onChangeAppliedStudentStatus( e.target.value, data.index )}>
                    <option value={ '' }></option>
                    { 
                      data.original.disableStatusField ? 
                      studentVacancyStatus.data.map( item => (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )) :
                      data.original.student_confirmation ? 
                      studentVacancyStatus.data.filter((item) => {
                        return [5, 6, 7].indexOf(item.id) >= 0
                      }).map( item => (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      )) :
                      studentVacancyStatus.data.filter((item) => {
                        return [6, 7].indexOf(item.id) < 0
                      }).map( item => (
                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                      ))
                    }
                  </Input>
                )
              },
              {
                Header: 'Actions',
                Cell: row => {
                  return (
                    <>
                      <Button
                        id="UpdateCompanyVacancies"
                        className="mr-2"
                        color={ 
                          !row.original.disableStatusField 
                            ? "primary" 
                            : 'secondary'
                        }
                        disabled={ row.original.disableStatusField }
                        onClick={() => {
                          if ( row.original.student_vacancy_status_id !== '6'  ) {
                            return updateStudentVacancy({
                              student_vacancy_id: row.original.student_vacancy_id,
                              company_vacancy_id : companyVacancyId,
                              student_id : row.original.student_id,
                              student_vacancy_status_id: parseInt( row.original.student_vacancy_status_id )
                            })
                          }
                          onChangeSeletedUser( row.original )
                          onToggleConfirmation( true )
                      }}>
                        Update
                      </Button>
                      <UncontrolledTooltip target="UpdateCompanyVacancies" placement="left">Update the information of this company</UncontrolledTooltip>
                      <Button
                        id="ViewStudentCV"
                        color="primary"
                        className="mr-2"
                        onClick={() => {
                          // Promise.all([
                          //   onChangeSeletedUser( row.original )
                          // ]).then(() => {
                          //   getSelectedStudentCV(row.original.id)
                          // })
                          getSelectedStudentCV(row.original.id)
                        }
                      }>
                        Student CV
                      </Button>
                      <UncontrolledTooltip target="ViewStudentCV" placement="left">View CV of this student</UncontrolledTooltip>
                      <Button 
                        id='DownloadLICertificate'
                        color="primary"
                        onClick={() => {
                          getStudentLI(row.original.student_id, row.original.student_name)
                        }
                      }>
                        LI Certificate
                      </Button>
                      <UncontrolledTooltip target="DownloadLICertificate" placement="left">Download LI Certificate of this student</UncontrolledTooltip>
                    </>
                  )
                }
              }
            ]}
            showPagination={ false } />
          <Pagination 
            metadata={ studentVacancies.meta } 
            onChangePage={ pg => {
              getStudentVacancies( btoa(`{  "page": ${ pg }, "filter": { "student_vacancies.company_vacancy_id": ${ companyVacancyId } }}`) )
            }} />
        </CardBody>
        { onLoadStudentCV && <LoadingOverlay/> }
      </Card>   
      <StudentCVContent 
        open={ showStudentCVContent }
        onClose={ () => onChangeStudentCVHOC( 'showStudentCVContent', false )}
        selectedStudentCV={ selectedStudentCV }
      />
      <ConfirmationModal
        loading={ onLoadStudentCV }
        open={ showAcceptedConfirmation }
        title={ `Notice` }
        message={ `This action is irreversible. Are you sure to proceed converting this record into Accepted status ?` }
        onClose={ () => onToggleConfirmation( false )}
        onClickConfirm={ () => {
          onToggleConfirmation( false )
          updateStudentVacancy({
            student_vacancy_id: selectedUser.student_vacancy_id,
            company_vacancy_id : companyVacancyId,
            student_id : selectedUser.student_id,
            student_vacancy_status_id: 6
          })
        }}/>
    </>
  )
}

export default StudentCVHOC( StudentCard );
