import React, { useEffect, useState } from 'react'
import { 
  Card, CardHeader,CardBody
} from 'reactstrap'

const OfferLetter = props => {

  const { selectedAcceptedVacancies } = props

  const [ documentList, setDocumentList ] = useState( [] )

  useEffect( () => {
    let temp = []
    if( selectedAcceptedVacancies.offer_letter_urls && selectedAcceptedVacancies.offer_letter_urls.length > 0 ){
      for( let item of selectedAcceptedVacancies.offer_letter_urls ){
        temp.push({ url: item })
      }
    }
    setDocumentList( temp )
  }, [])

  return(
    <Card className="mt-2 mb-3">
      <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
        Document URL
      </CardHeader>
      <CardBody>
        <ul className='pl-3'>
          {
            documentList.length > 0 && documentList.map( data => (
              <li><a href={ data.url } target="_blank">{ data.url }</a></li>
            ))
          }
        </ul>
        {
          !documentList.length > 0 && (
            <span className='student-vacancy__detail-subtitle'>No Offer Letter URL</span>
          )
        }
      </CardBody>
    </Card>
  )
}

export default OfferLetter;