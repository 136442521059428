import React, { Component } from 'react'
import { compose } from 'redux'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody,
  UncontrolledTooltip, Button,
  Modal, ModalBody, ModalHeader
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'
import { AiFillStar } from 'react-icons/ai'
import { FaBuilding } from 'react-icons/fa'

import EvaluationContent from './EvaluationContent/index'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import GenerateCSV from './GenerateCSV'

import ResultHOC from './actions'
import SessionHOC from 'actions/hoc/session'
import EvaluationHOC from './actions/evaluation'
  
class Result extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getResult( current_selected_session_id )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getResult( current_selected_session_id )
    }
  }

  render = () => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    return (
      <>
        <PageTitle
          heading="Result"
          subheading="Listings of all the student's result present in the system."
          icon="pe-7s-tools icon-gradient bg-happy-itmeo"
          buttons={[
            // {
            //   color: 'warning',
            //   className: 'btn-icon mr-2',
            //   onClick: () => {
            //     this.props.onChangeResultsHOC( 'showSearchForms', !this.props.showSearchForms )
            //   },
            //   content:<>
            //     <i className="pe-7s-search btn-icon-wrapper"> </i>
            //     <span>Search</span>
            //   </>
            // }
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.getEvaluations( btoa(`{ "page": 1, "per_page": 1000, "filter": { "$and":[ { "ief.session_id": ${ current_selected_session_id } }, { "ief.type": "External" } ]  }}` ))
              },
              content:<>
                <i className="pe-7s-server btn-icon-wrapper"> </i>
                <span>Generate CSV</span>
              </>
            },
          ]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col lg={ 12 }>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.result.length < 1? 3:
                      this.props.result.length
                    }
                    data={ this.props.result }
                    columns={[
                      {
                        Header: 'Student Name',
                        accessor: 'student_name',
                        width: 150
                      },
                      {
                        Header: 'Company',
                        accessor: 'company_name',
                        width: 150,
                        Cell: cell => (
                          <div className='d-block'>
                            {/* <p className='mb-2'>{ cell.original.position_name || 'N/A' }</p> */}
                            <div className="d-flex align-items-center">
                              <FaBuilding  style={{ width: 17, height: 17 }} className='mr-2'/>
                              <p className='mb-0'>{ cell.original.company_name || 'N/A' }</p>
                            </div>
                          </div>
                        )
                      },
                      {
                        Header: 'External Supervisor',
                        accessor: 'external_sv_marks',
                        Cell: cell => (
                          <div className={ 'd-block' }>
                            <strong>{ cell.original.external_sv_name || 'N/A' }</strong>
                            <small className={ 'mt-1 d-block' }>{ `Evaluation Marks: ${ cell.original.external_sv_marks }%`}</small>
                            <div className="mt-2">
                              {
                                cell.original.external_evaluation.is_student_passed
                                  ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                                  : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
                              }
                              <small  className='ml-2' style={{ fontWeight: 600 }}>Is student passed ?</small>
                            </div>
                            <div className="mt-2">
                              {
                                cell.original.external_evaluation.is_recommend_industrial_training_excellence
                                ? <AiFillStar style={{ width: 17, height: 17, fill: '#f7b924' }}/>
                                : <AiFillStar style={{ width: 17, height: 17 }}/>
                              }
                              <small  className='ml-2' style={{ fontWeight: 600 }}>Industrial Training Certificate of Excellence</small>
                            </div>
                            <div className="mt-2">
                              {
                                cell.original.external_evaluation.is_best_intern_awards
                                ? <AiFillStar style={{ width: 17, height: 17, fill: '#f7b924' }}/>
                                : <AiFillStar style={{ width: 17, height: 17 }}/>
                              }
                              <small  className='ml-2' style={{ fontWeight: 600 }}>Best Intern Award</small>
                            </div>
                          </div>
                        )
                      },
                      {
                        Header: 'Internal Supervisor',
                        accessor: 'internal_sv_marks',
                        Cell: cell => (
                          <div className={ 'd-block' }>
                            <strong>{ cell.original.internal_sv_name || 'N/A' }</strong>
                            <small className={ 'mt-1 d-block' }>{ `Evaluation Marks: ${ cell.original.internal_sv_marks }%`}</small>
                            <div className="mt-2">
                              {
                                cell.original.external_evaluation.is_student_passed
                                ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                                : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
                              }
                              <small  className='ml-2' style={{ fontWeight: 600 }}>Is student passed ?</small>
                            </div>
                            <div className="mt-2">
                              {
                                cell.original.internal_evaluation.is_recommend_industrial_training_excellence
                                ? <AiFillStar style={{ width: 17, height: 17, fill: '#f7b924' }}/>
                                : <AiFillStar style={{ width: 17, height: 17 }}/>
                              }
                              <small  className='ml-2' style={{ fontWeight: 600 }}>Industrial Training Certificate of Excellence</small>
                            </div>
                            <div className="mt-2">
                              {
                                cell.original.internal_evaluation.is_best_intern_awards
                                ? <AiFillStar style={{ width: 17, height: 17, fill: '#f7b924' }}/>
                                : <AiFillStar style={{ width: 17, height: 17 }}/>
                              }
                              <small  className='ml-2' style={{ fontWeight: 600 }}>Best Intern Award</small>
                            </div>
                          </div>
                        )
                      },
                      {
                        Header: 'Action',
                        Cell: cell => (
                          <>
                            <Button
                              id="ViewEvaluation"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ 'primary' }
                              onClick={() => {
                                this.props.getSelectedEvaluation( btoa(`{ "page": 1, "filter": { "ief.student_id": ${ cell.original.student_id } }}` ))
                              }}>
                              <i className="pe-7s-info btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="ViewEvaluation " placement="left">View Student's Evaluation Form</UncontrolledTooltip>
                          </>
                        )
                      }
                    ]}
                    showPagination={ false } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal isOpen={ this.props.showGenerateCSV } size={ 'lg' }>
          <ModalHeader toggle={ () => this.props.onChangeEvaluationsHOC( 'showGenerateCSV', false )}>
            Generate CSV
          </ModalHeader>
          <GenerateCSV
            {...this.props}
            onLoadEvaluation={ this.props.onLoadEvaluation }
            data={ this.props.evaluationList }
            getEvaluations={ this.props.getEvaluations }
            current_selected_session_id={ this.props.data.SessionReducer.current_selected_session_id }
          />
        </Modal>
        <Modal size={ 'xl' } isOpen={ this.props.showStudentEvaluation }>
          <ModalHeader 
            style={{ fontSize: '1.1rem' }} 
            toggle={() => this.props.onChangeEvaluationsHOC( 'showStudentEvaluation', false )}
            >
            Student Evaluation
          </ModalHeader>
          <ModalBody>
            <EvaluationContent
              studentEvaluation={ this.props.studentEvaluation }
              />
          </ModalBody>
        </Modal>
        {( this.props.onLoadResults || this.props.onLoadEvaluation ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  ResultHOC,
  EvaluationHOC,
  SessionHOC
)(Result)