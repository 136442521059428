import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import Axios from 'axios'

import getDomainURL from 'utils/api'
import { Get, Delete } from 'utils/axios'
import { storeLastView }  from 'actions/lastView'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      offerLetters: [],
      newOfferLetter: {
        file: '',
        student_id: 0
      },
      showCreateOfferLetter: false
    }

    onChangeOfferLetterHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getOfferLetters = ( student_id ) => Get(
      `/student_offer_letters/${ student_id }`,
      this.getOfferLettersSuccess,
      this.getOfferLettersError,
      this.load
    )
    getOfferLettersSuccess = payload => this.setState({ offerLetters: payload })
    getOfferLettersError = error => this.requestError( error )

    removeOfferLetter = id => Delete(
      `/student_offer_letters/${ id }`,
      this.removeOfferLetterSuccess,
      this.removeOfferLetterError,
      this.load
    )
    removeOfferLetterSuccess = () => {
      this.requestSuccess( 'Offer letter is removed successfully.' )
      this.getOfferLetters( this.props.data.ProfileReducer.profile.student.id )
    }
    removeOfferLetterError = error => this.requestError( error )

    createOfferLetter = (file, removeFilePond) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/student_offer_letters/${ this.props.data.ProfileReducer.profile.student.id }`;
      let tempForm = new FormData();
      tempForm.append( 'letter', file );
      const config = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${token}`,
          'content-type': 'multipart/form-data'
        }
      }
      Axios.post( url, tempForm, config ).then( payload => {
        this.load( false )
        removeFilePond()
        this.setState({ showCreateOfferLetter: false })
        this.requestSuccess( 'File is uploaded successfully.' )
        this.getOfferLetters( this.props.data.ProfileReducer.profile.student.id )
      }).catch( err => {
        this.load( false )
        this.requestError( err )
      })
    }

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadOfferLetters={ this.state.loading }
          offerLetters={ this.state.offerLetters }
          newOfferLetter={ this.state.newOfferLetter }
          showCreateOfferLetter={ this.state.showCreateOfferLetter }  
          getOfferLetters={ this.getOfferLetters }
          removeOfferLetter={ this.removeOfferLetter }
          createOfferLetter={ this.createOfferLetter }
          onChangeOfferLetterHOC={ this.onChangeOfferLetterHOC } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeLastView
  })( WithHOC )
}

export default HOC