import _ from "lodash";
import { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	FormGroup,
	FormText,
	Input,
	Label,
	Row,
} from "reactstrap";
import { compose } from "redux";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title/PageTitle";
import { mobileChecker } from "utils/mobileChecker";
import { RegistrationDetails } from "./assets";

import DictionaryHOC from "./actions/dictionary";
import RegisterHOC from "./actions/index";

class Registration extends Component {
	componentDidMount = () => {
		this.props.getDesignations();
		this.props.getIndustryCat();
		this.props.getCountry();

		this.debounceMobile = _.debounce((context, val) => {
			this.onChangeField(context, mobileChecker(val));
		}, 500);
	};

	onChangeField = (context, val, callback) => {
		let temp = _.cloneDeep(this.props.registrationData);
		temp[context] = val;

		callback && callback();
		this.props.onChangeRegistrationHOC("registrationData", temp);
	};

	renderFormContent = () => {
		const { registrationData } = this.props;
		let disabledSubmit = () => {
			let tempFields = [];
			_.map(RegistrationDetails({}), "fields").map((item) =>
				tempFields.push(...item)
			);

			return tempFields.some(
				(item) => item.required && !registrationData[item.value]
			);
		};

		return (
			<>
				{RegistrationDetails(this.props).map((card, index) => (
					<Fragment key={index}>
						<ReactCSSTransitionGroup
							component="div"
							transitionName="TabsAnimation"
							transitionAppear={true}
							transitionAppearTimeout={0}
							transitionEnter={false}
							transitionLeave={false}>
							<Card className="main-card mb-3">
								<CardHeader style={{ fontSize: "1rem" }}>
									{card.cardName}
								</CardHeader>
								<CardBody>
									<Row>
										{card.fields.map((item) => (
											<Col md={item.col || 12} xs={12}>
												<FormGroup>
													{item.label && (
														<Label>
															{`${item.label} `}
															{item.required && (
																<span className="text-danger">*</span>
															)}
														</Label>
													)}
													{item.type === "select" && (
														<Input
															type={item.type}
															value={registrationData[item.value]}
															onChange={(e) => {
																this.onChangeField(item.value, e.target.value);
																item.value === "country" &&
																	this.props.getCoutryState(e.target.value);
															}}>
															<option value=""></option>
															{item.options.map((option) => (
																<option value={option.id}>{option.name}</option>
															))}
														</Input>
													)}
													{item.type === "textarea" && (
														<Input
															type={"textarea"}
															maxlength={500}
															value={registrationData[item.value]}
															rows={4}
															onChange={(e) =>
																this.onChangeField(item.value, e.target.value)
															}
														/>
													)}
													{["textarea", "select"].indexOf(item.type) < 0 && (
														<Input
															type={item.type}
															value={registrationData[item.value]}
															onChange={(e) =>
																this.onChangeField(
																	item.value,
																	e.target.value,
																	() => {
																		["fax_number", "phone"].indexOf(
																			item.value
																		) > -1 &&
																			this.debounceMobile(
																				item.value,
																				e.target.value
																			);
																	}
																)
															}
														/>
													)}
													{item.subLabel && (
														<FormText>{item.subLabel}</FormText>
													)}
												</FormGroup>
											</Col>
										))}
									</Row>
								</CardBody>
							</Card>
						</ReactCSSTransitionGroup>
					</Fragment>
				))}
				<Button
					color={disabledSubmit() ? "secondary" : "primary"}
					className="mb-2"
					disabled={disabledSubmit()}
					onClick={() =>
						this.props.submitRegistration({
							...registrationData,
							status: "Inactive",
							contact_person_designation_id:
								+registrationData.contact_person_designation_id,
							industry_category_id: +registrationData.industry_category_id,
							country: +registrationData.country,
							state: +registrationData.state,
						})
					}>
					Submit
				</Button>
			</>
		);
	};

	render = () => {
		const { onLoadRegistration, onLoadDictionary } = this.props;

		return (
			<>
				{this.renderFormContent()}
				{(onLoadRegistration || onLoadDictionary) && <LoadingOverlay />}
			</>
		);
	};
}

export default compose(RegisterHOC, DictionaryHOC)(Registration);
