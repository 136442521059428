import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { Get, Post, Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,       
      selectedUser: [],

      showUpdateUsersModal: false
    }
    onChangeUsersHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )    

    submitForgotPassword = ( data ) => Post(
      `/api/user/auth/ForgetPassword`,
      data,
      this.submitForgotPasswordSuccess,
      this.submitForgotPasswordError,
      this.load
    )
    submitForgotPasswordSuccess = payload => {
      this.requestSuccess( 'Request submitted successfully. Please check your email inbox.' )
    }
    submitForgotPasswordError = error => this.requestError( error )

    getSelectedUser = id => {
      Get(
        `/users/${ id }`,
        this.getSelectedUserSuccess,
        this.getSelectedUserError,
        this.load
      )
    }
    getSelectedUserSuccess = payload => {
      let temp = []

      this.setState({ 
        selectedUser: {
          ... payload
        }, 
        showUpdateUsersModal: true 
      })
    }
    getSelectedUserError = error => this.requestError ( error )

    updateUser = (dataToSubmit) => Put(
      `/users/update_password`,
      dataToSubmit,
      this.updateUserSucces,
      this.updateUserError,
      this.load
    )
    updateUserSucces = () => {
      this.setState({ showUpdateUsersModal: false })
      this.requestSuccess( 'Password was updated successfully.' )
    }
    updateUserError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadForgotPassword={ this.state.loading }                 
          submitForgotPassword={ this.submitForgotPassword }
          selectedUser={ this.state.selectedUser }
          showUpdateUsersModal={ this.state.showUpdateUsersModal }
          getSelectedUser={ this.getSelectedUser }
          updateUser={ this.updateUser }
          onChangeUsersHOC={ this.onChangeUsersHOC }
        />
      )
    }
  }
  return WithHOC
}

export default HOC