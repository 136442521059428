import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Collapse, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateCourse from './Create'
import UpdateCourse from './Update'
import ConfirmationModal from 'components/Modal/confirmation'
import sessionQueryHandler from 'utils/sessionQueryHandler'

import WithPrincipalCourses from './actions'

class PrincipalCoursesManagement extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getCourses( btoa( JSON.stringify( sessionQueryHandler( 
        { "page": 1 }, 
        current_selected_session_id, 
        'session_id'
      ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getCourses( btoa( JSON.stringify( sessionQueryHandler( 
        { "page": 1 }, 
        current_selected_session_id, 
        'session_id'
      ))) )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <PageTitle
          heading="Principal Courses Management"
          subheading="Lists of academic courses which is going to be showed at student CV."
          icon="pe-7s-notebook icon-gradient bg-happy-itmeo"
          buttons={[
           {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeCoursesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... is_create ? [{
              color: 'primary',
              onClick: () => {
                this.props.onChangeCoursesHOC( 'showCreateCourse', true )
              },
              className: 'mr-2',
              content: 'Create'
            }] : []
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeCoursesHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeCoursesHOC }
              getListAPI={ this.props.getCourses }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.courses.data.length < 1? 3: 
                      this.props.courses.data.length < 10? this.props.courses.data.length:
                      10
                    }
                    data={ this.props.courses.data }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateSecModule"
                                className="mr-2 btn-icon btn-icon-only"
                                disabled={ !is_update }
                                color={ is_update ? 'primary' : 'secondary' }
                                onClick={() => {
                                  this.props.getSelectedCourse( row.original.id )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateSecModule" placement="left">Update the information of this course</UncontrolledTooltip>
                              <Button
                                id="DeleteSecModule"
                                className="mr-2 btn-icon btn-icon-only"
                                disabled={ !is_delete }
                                color={ is_delete ? 'danger' : 'secondary' }
                                onClick={() => {
                                  this.props.onChangeCoursesHOC( 'showConfirmDeleteModal', true )
                                  this.props.onChangeCoursesHOC( 'toRemoveID', row.original.id )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteSecModule" placement="left">Delete this course</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.courses.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getCourses( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <CreateCourse { ...this.props } />
        <UpdateCourse { ...this.props } />
        <ConfirmationModal
          open={ this.props.showConfirmDeleteModal }
          title={ `Delete course` }
          loading={ this.props.onLoadCourses }
          message={ `Are you sure you want to delete this course? (This process can't be undone)` }
          onClose={ () => this.props.onChangeCoursesHOC( 'showConfirmDeleteModal', false )}
          onClickConfirm={ () => this.props.deleteCourse( this.props.toRemoveID )} />
        { this.props.onLoadCourses && <LoadingOverlay /> }
      </>
    )
  }
}

export default WithPrincipalCourses( PrincipalCoursesManagement )