import { useCallback } from "react";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import DatePicker from "react-datepicker";
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
} from "reactstrap";
import SesssionHOC from "actions/hoc/session";
import SessionTypeahead from "components/Typeahead/session";
import CompanyTypeahead from "components/Typeahead/company";
import { PulseLoader } from "react-spinners";
import { fieldData } from "./assets";
import HOC from "./action";

const CreateForm = ({
	companies,
	sessions,
	onLoadVacancy,

	onLoadSession,
	companyVacancyData,
	onChangeSessionsHOC,
	onChangeVacancyHOC,
	getSessions,
	getCompany,
	createCompanyVacancies,
}) => {
	const onChangeCompanyVacancyField = (key, val) => {
		let tmp = _.cloneDeep(companyVacancyData);
		tmp[key] = val;

		return onChangeVacancyHOC("companyVacancyData", tmp);
	};

	const onChangeCompanyTypeahead = useCallback(
		_.debounce((val) => {
			getCompany(
				btoa(
					`{ "page": 1 , "per_page": 1000, "filter":{ "companies.name": {"$like": "%${val}%" } }}`
				)
			);
		}, 600),
		[]
	);

	const onChangeTypeaheadSearch = useCallback(
		_.debounce((val) => {
			getSessions(
				btoa(
					`{ "page": 1 , "per_page": 1000, "filter":{ "sessions.name": {"$like": "%${val}%" } }}`
				)
			);
		}, 600),
		[]
	);

	return (
		<Card>
			<CardBody>
				<Form onSubmit={(e) => e.preventDefault()}>
					<Row>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label>Session</Label>
								<SessionTypeahead
									options={sessions}
									onSelect={(val) =>
										val?.[0]?.id &&
										onChangeCompanyVacancyField("session_id", val[0].id)
									}
									selectedValue={
										companyVacancyData?.session_id &&
										_.find(sessions, { id: companyVacancyData.session_id })
											? [
													_.find(sessions, {
														id: companyVacancyData.session_id,
													}),
											  ]
											: []
									}
									onSearch={(val) => {
										onChangeCompanyVacancyField("session_id", null);
										val.length > 0
											? onChangeTypeaheadSearch(val)
											: onChangeSessionsHOC("sessions", []);
									}}
									filterBy={"name"}
									disableEdit={onLoadSession}
									helpText={"Please enter session name to search."}
								/>
							</FormGroup>
						</Col>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label>
									Company
									<span className="text-danger mr-2">*</span>
									<PulseLoader
										sizeUnit={"px"}
										size={10}
										color={"#16232c"}
										loading={onLoadVacancy}
									/>
								</Label>
								<CompanyTypeahead
									options={companies}
									onSelect={(val) => {
										if (val?.[0]?.id) {
											let tempState = _.find(companies, {
												id: parseInt(val[0].id),
											}).state;
											let tmp = _.cloneDeep(companyVacancyData);
											tmp.company_id = val[0].id;
											tmp.company_state = tempState;

											return onChangeVacancyHOC("companyVacancyData", tmp);
										}
									}}
									selectedValue={
										companyVacancyData.company_id &&
										_.find(companies, { id: companyVacancyData.company_id })
											? [
													_.find(companies, {
														id: companyVacancyData.company_id,
													}),
											  ]
											: []
									}
									onSearch={(val) => {
										val.length > 0
											? onChangeCompanyTypeahead(val)
											: onChangeVacancyHOC("companies", []);

										let tmp = _.cloneDeep(companyVacancyData);
										tmp.company_id = null;
										tmp.company_state = null;

										return onChangeVacancyHOC("companyVacancyData", tmp);
									}}
									disableEdit={onLoadVacancy}
									helpText={"Please enter company name to search."}
								/>
							</FormGroup>
						</Col>
						<Col md={6} xs={12}>
							<FormGroup>
								<Label>Company State</Label>
								<Input
									disabled={true}
									type={"text"}
									value={companyVacancyData.company_state}
								/>
							</FormGroup>
						</Col>
						{fieldData.map((item) => (
							<Col key={item.value} md={item.col || 6} xs={12}>
								<FormGroup>
									<Label>
										{`${item.label} `}
										{item.required && <span className="text-danger">*</span>}
									</Label>
									{item.type !== "date" && (
										<Input
											type={item.type}
											min="1"
											rows={5}
											step="1"
											value={companyVacancyData[item.value]}
											onChange={(e) => {
												onChangeCompanyVacancyField(item.value, e.target.value);
											}}
										/>
									)}
									{item.type === "date" && (
										<DatePicker
											className={"w-100"}
											showYearDropdown={true}
											dateFormat="dd-MM-yyyy"
											selected={companyVacancyData[item.value]}
											onChange={(val) => {
												onChangeCompanyVacancyField(item.value, val);
											}}
										/>
									)}
								</FormGroup>
							</Col>
						))}
					</Row>
				</Form>
				{onLoadVacancy && <LoadingOverlay />}
			</CardBody>
			<CardFooter>
				<Button
					color={
						_.values(companyVacancyData).some((item) => !item)
							? "secondary"
							: "primary"
					}
					className="mr-2"
					disabled={_.values(companyVacancyData).some((item) => !item)}
					onClick={() => {
						createCompanyVacancies({
							job_title: companyVacancyData.job_title,
							vacancy_number: companyVacancyData.vacancy_number,
							job_scope: companyVacancyData.job_scope,
							programming_languages: companyVacancyData.programming_languages,
							operating_systems: companyVacancyData.operating_systems,
							company_id: parseInt(companyVacancyData.company_id),
							vacancy_number: parseInt(companyVacancyData.vacancy_number),
							session_id: parseInt(companyVacancyData.session_id),
							valid_until_date: Moment(
								companyVacancyData.valid_until_date
							).format(),
						});
					}}>
					Submit
				</Button>
			</CardFooter>
		</Card>
	);
};

export default compose(SesssionHOC, HOC)(CreateForm);
