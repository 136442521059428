import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      internalUsers: []
    }

    onChangeUserHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getUsers = search => Get(
      `/users?query=${ search }&filter_role_id=[1,11]`,
      this.getUsersSuccess,
      this.getUsersError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getUsersSuccess = ({ data = [] }) => this.setState({ internalUsers: data })
    getUsersError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadUsers={ this.state.loading }
          internalUsers={ this.state.internalUsers }

          getUsers={ this.getUsers }
          onChangeUserHOC={ this.onChangeUserHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC