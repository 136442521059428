import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { 
  Row,
  Col,

  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { CompanyFieldData } from './assets'

import { mobileChecker } from 'utils/mobileChecker'

const defaultForm = {
  admin_remarks: '',
  remarks: '',
  is_checked: false,

  contact_person_designation_id: null,
  contact_person_mobile: '',
  contact_person_name: '',
  contact_person_phone_number: '',
  contact_person_email_address: ''
}

const FormContent = ({
  open,
  data,
  selectedPlacementForm,
  onLoadPlacement,

  onClickSubmit
}) => {

  const [ placementFormDisplayData, onChangeDisplayFormData ] = useState( null )

  const [ placementForm, onChangeFormData ] = useState( defaultForm )

  useEffect(() => {
    if ( selectedPlacementForm  ){
      let temp = {}
      _.keys( defaultForm ).map( key => {
        temp[ key ] = selectedPlacementForm[ key ]
      })
      onChangeFormData( temp )
      onChangeDisplayFormData( selectedPlacementForm )
    }
  }, [selectedPlacementForm])

  const onChangePlacementField = ( context, val ) => {
    let temp = _.cloneDeep( placementForm )
    temp[context] = val 

    onChangeFormData( temp )
  }

  const debounceMobile = useCallback(_.debounce(( fullData, context, val ) => {
    onChangeFormData({
      ... fullData,
      [context]: mobileChecker( val )
    })
  }, 500), [])

  return (
    <>
      <ModalBody>
        <Row>
          {
            CompanyFieldData( data.DictionaryReducer.designations ).map( item => (
              <Col lg={ item.col || 6 } xs={ 12 }>
                <FormGroup>
                  { item.label && <Label>{ item.label }</Label> }
                  {
                    item.type === 'select' && (
                      <Input
                        type={ 'select' }
                        value={ placementForm?.[ item.value ]}
                        onChange={ e => onChangePlacementField( item.value, +e.target.value )}
                      >
                        <option value=""></option>
                        {
                          item.options.map( item => (
                            <option key={ item.id } value={ item.id }>{ item.name }</option>
                          ))
                        }
                      </Input>
                    )
                  }
                  {
                    item.type !== 'select' && (
                      <Input
                        type={ item.type }
                        value={ 
                          item.editable
                            ? placementForm?.[ item.value ] ?? ''
                            : placementFormDisplayData?.[ item.value ] ?? '' 
                        }
                        disabled={ !item.editable }
                        onChange={ e => {
                          Promise.all([
                            onChangePlacementField( item.value, e.target.value )
                          ]).then(() => {
                            [ 'contact_person_phone_number', 'contact_person_mobile'].indexOf( item.value ) > -1 && 
                            debounceMobile( placementForm, item.value, e.target.value )
                          })
                        }}
                      />
                    )
                  }
                  { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                </FormGroup>
              </Col>
            ))
          }
          <Col lg={ 6 }>
            <FormGroup>
              <Label>Remarks</Label> 
              <Input
                type={ 'textarea' }
                value={ placementForm.remarks }
                onChange={ e => onChangePlacementField( 'remarks', e.target.value )}
              />  
              <FormText color="muted">
                { `Submitted Date: ${ placementFormDisplayData?.submitted_date_time??'N/A' }` }
              </FormText>
            </FormGroup>            
          </Col>
          {
            data.ProfileReducer.current_role_id !== 13 && (
              <>
                <Col lg={ 6 }>
                  <FormGroup>
                    <Label>Admin Remarks</Label> 
                    <Input
                      type={ 'textarea' }
                      value={ placementForm.admin_remarks }
                      onChange={ e => onChangePlacementField( 'admin_remarks', e.target.value )}
                    />
                    <FormText color="muted">
                      { `Review Date: ${ placementFormDisplayData?.review_date_time??'N/A' }` }
                    </FormText>
                  </FormGroup>
                </Col>
                <Col lg={ 12 }>
                <FormGroup check>
                  <Label check className={ 'mb-1'}>
                    <Input 
                      checked={ placementForm.is_checked } 
                      type="checkbox"
                      onChange={ e => onChangePlacementField( 'is_checked', e.target.checked )}
                    />
                    Industrial training placement form has been checked.
                  </Label>
                </FormGroup>
              </Col>
              </>
            )
          }
        </Row>
        { onLoadPlacement && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
      <Button 
        color="primary"
        className="mr-2"
        onClick={() => onClickSubmit({ 
          ... placementForm,
          id: selectedPlacementForm.id
        })}>
          Submit
        </Button>
      </ModalFooter>
    </>
  )
}

const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps )( FormContent )