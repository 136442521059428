import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showTNCModal: true, 

      tncChecked: false,
      registrationData: {
        name: '',
        phone: '',
        password: '',
        password_confirm: '',
        
        registration_number: '',
        address: '',
        description: '',
        state: '',
        country: '',
        zipcode: '',
        fax_number: '',
        industry_category_id: null,
        website: '',
        contact_person_name: '',
        contact_person_designation_id: null,
        contact_person_email: '',
        is_read_agreement: true
      }
    }

    onChangeRegistrationHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    submitRegistration = dataToSubmit => Post(
      `/companies`,
      dataToSubmit,
      this.submitRegistrationSuccess,
      this.submitRegistrationError,
      this.load
    )
    submitRegistrationSuccess = () => { 
      this.requestSuccess( 'New company has been registered successfully.' )
      
      setTimeout(() => {
        this.props.history.push( '/login' )
      }, 1000);
    }
    submitRegistrationError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          registrationData={ this.state.registrationData }
          tncChecked={ this.state.tncChecked }
          showTNCModal={ this.state.showTNCModal }
          onLoadRegistration={ this.state.loading }

          onChangeRegistrationHOC={ this.onChangeRegistrationHOC }
          submitRegistration={ this.submitRegistration }
        />
      )
    }
  }
  return WithHOC
}

export default HOC