import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Collapse, Row, Col, Card, CardBody, Button, UncontrolledTooltip, Modal, ModalHeader } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from './searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import ImportFileForm from 'components/importFileForm'
import CreateForm from './Create/index'
import EditForm from './Edit'
import GenerateCSV from './GenerateCSV'

import UsersHOC from './actions'
import StudentHOC from './actions/student'
import LecturerHOC from './actions/lecturer'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class Users extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getUsers( btoa(`{"page":1}`) )
      this.props.getStudents( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
      this.props.getStudentsVacancies( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
    }

    if ( pp.currentUserTab !== this.props.currentUserTab ){
      if ( this.props.currentUserTab !== 13 ){
        this.props.getUsers( btoa(`{ "page": 1 }`) )
      } else {
        this.props.getUsers(btoa(`{ "page": 1, "filter":{ "s.session_id": ${ current_selected_session_id } }}`)  )
        this.props.getStudents( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
        this.props.getStudentsVacancies( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
      }
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id && 
      current_selected_session_id && this.props.currentUserTab === 13
    ) {
      this.props.getUsers(btoa(`{ "page": 1, "filter":{ "s.session_id": ${ current_selected_session_id } }}`)  )
      this.props.getStudents( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
      this.props.getStudentsVacancies( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
    }
  }

  onSubmitDelete = () => {
    const { selectedUser } = this.props
    
    if ( selectedUser.role_ids.indexOf( 13 ) > -1 ){
      return this.props.deleteStudent( selectedUser.student_id )
    }

    return this.props.deleteUser( selectedUser.id )
  }

  onClickEdit = userData => {
    if ( userData.role_ids.indexOf( 13 ) > -1 ){
      return this.props.getSelectedStudent( userData.student_id )
    }

    return this.props.getSelectedUser( userData.id )
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    const { students, studentsVacancies } = this.props
    const mergeData = students.map(v => ({ ...v, ...studentsVacancies.find(sp => sp.name === v.name) }))
    const { roles } = this.props.data.DictionaryReducer
    const { current_selected_session_id, sessions } = this.props.data.SessionReducer
    const selectedSession = _.find( sessions, item => {
      return current_selected_session_id === item.session.id
    })

    return (
      <>
        <PageTitle
          heading="Users Management"
          subheading="Listings of all the users present in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeUsersHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeUsersHOC( 'showGenerateCSV', !this.props.showGenerateCSV )
              },
              content:<>
                <i className="pe-7s-server btn-icon-wrapper"> </i>
                <span>Generate CSV</span>
              </>
            },
            ... (
              is_create ? [{
                color: 'primary',
                onClick: () => {
                  this.props.onChangeUsersHOC( 'showCreateUsersModal', true )
                },
                className: 'mr-2',
                content: 'Create'
              },
              {
                color: 'secondary',
                className: 'mr-2',
                onClick: () => {
                  this.props.onChangeStudentsHOC( 'showImportStudentsModal', !this.props.showImportStudentsModal )
                },
                content: 'Import Students'
              },
              {
                color: 'secondary',
                onClick: () => {
                  this.props.onChangeLecturersHOC( 'showImportLecturersModal', !this.props.showImportLecturersModal )
                },
                content: 'Import Internal Supervisor'
              }
            ] : []
            )
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeUsersHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeUsersHOC }
              getListAPI={ val => {
                
                if ( this.props.currentUserTab !== 13 ) {
                  return this.props.getUsers( val ) 
                }
                
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, "s.session_id" )

                return this.props.getUsers( btoa( JSON.stringify( tempJSON )) )
              }}
              
            />
          </Collapse>
          <div 
            className="card mb-3 p-3 d-flex" 
            style={{ overflowX: 'scroll', flexDirection: 'row' }}
          >
            <Button 
              color={ !this.props.currentUserTab ? 'primary' : 'secondary' }  
              onClick={ () => this.props.onChangeUsersHOC( 'currentUserTab', null ) }  
              style={{ 
                minWidth: 'max-content', width: 'max-content', 
                marginRight: 10 
              }}
            >
              All
            </Button>
            { 
             roles?.[0] && roles.map( item => (
                <Button 
                  color={ this.props.currentUserTab === item.id ? 'primary' : 'secondary' }  
                  onClick={ () => this.props.onChangeUsersHOC( 'currentUserTab', item.id ) } 
                  key={ item.id }
                  style={{ 
                    minWidth: 'max-content', width: 'max-content', 
                    marginRight: 10 
                  }}
                >
                  { item.name }
                </Button>
              )) 
            }
          </div>
          
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  {
                    this.props.currentUserTab == 13 && (
                      <>
                        <p>
                          Students shown below belongs the session: 
                          <strong>{ ` ${ selectedSession?.session?.name }` }</strong>
                        </p>
                      </>
                    )
                  }
                  <ReactTable
                    pageSize={ 
                      this.props.users.data.length < 1? 3: 
                      this.props.users.data.length < 10? this.props.users.data.length:
                      10
                    }
                    data={ this.props.users.data }
                    columns={[
                      {
                        Header: 'Role',
                        Cell: ({ original })=> (
                          <div className={ 'd-block'}>
                            {
                              original.rolesInfo?.[0] && original.rolesInfo.map(({ name: roleName, id: roleId }) => (
                                <span
                                  style={{ width: 'max-content' }} 
                                  className={ 
                                    `d-block mb-2 badge badge-${ this.props.currentUserTab === roleId ? 'warning' : 'secondary' }` 
                                  }
                                >
                                  { roleName }
                                </span>
                              ))
                            }
                          </div>
                        )
                      },
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Email',
                        accessor: 'email'
                      },
                      {
                        Header: 'Phone',
                        accessor: 'phone'
                      },
                      {
                        Header: 'Created At',
                        accessor: 'created_at'
                      },
                      ... this.props.currentUserTab === 13 ? [{
                        Header: 'Intern Egligible',
                        Cell: row => (
                          <>
                            { 
                              row.original.is_eligible_intern && (
                                <span className="badge badge-success">
                                  Approved
                                </span>
                              )
                            }
                            {
                              !row.original.is_eligible_intern && (
                                <span className="badge badge-secondary">
                                  Pending
                                </span>
                              )
                            }
                          </>
                        )
                      },] : [],
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateUser"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => this.onClickEdit( row.original )}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateUser" placement="left">Update the information of this user</UncontrolledTooltip>
                              <Button
                                id="DeleteUser"
                                color={ 
                                  ( is_delete && row.original.id !== this.props.data.ProfileReducer.profile.user.id ) 
                                    ? "danger" 
                                    : 'secondary' 
                                }
                                disabled={( !is_delete || row.original.id === this.props.data.ProfileReducer.profile.user.id )}
                                className="mr-2 btn-icon btn-icon-only"
                                onClick={ () => {
                                  this.props.onChangeUsersHOC( 'showDeleteUserModal', true )
                                  this.props.onChangeUsersHOC( 'selectedUser', row.original )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteUser" placement="left">Delete this User</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.users.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getUsers( btoa( JSON.stringify( tempJSON )) )
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CreateForm
            {...this.props}
            open={ this.props.showCreateUsersModal }
            onClose={ () => this.props.onChangeUsersHOC( 'showCreateUsersModal', false )}
          />
          <EditForm
            {...this.props}
            selectedFormData={ this.props.selectedUser }
            open={ this.props.showUpdateUsersModal }
            onClose={ () => this.props.onChangeUsersHOC( 'showUpdateUsersModal', false )}
          />
          <Modal isOpen={ this.props.showGenerateCSV } size={ 'lg' }>
            <ModalHeader toggle={ () => this.props.onChangeUsersHOC( 'showGenerateCSV', false )}>
              Generate CSV
            </ModalHeader>
            <GenerateCSV
              {...this.props}
              exportStudentCSV={val => this.props.exportStudentCSV(current_selected_session_id, val)}
              onLoadUser={ this.props.onLoadUser }
              data={ mergeData }
            />
          </Modal>
          <ConfirmationModal
            open={ this.props.showDeleteUserModal }
            title={ `Delete User` }
            loading={ this.props.onLoadUser }
            message={ `Are you sure you want to delete this user? (This process can't be undone)` }
            onClose={ () => this.props.onChangeUsersHOC( 'showDeleteUserModal', false )}
            onClickConfirm={ () => this.onSubmitDelete()}/>
          <ImportFileForm
            session_id={ this.props.data.SessionReducer.current_selected_session_id }
            onLoad={ this.props.onLoadStudent }
            open={ this.props.showImportStudentsModal }
            context={ 'Student' }
            fileURL={ `https://ims-dev-api.cs.usm.my/static/student_sample_csv.csv` }
            onClose={ () => this.props.onChangeStudentsHOC( 'showImportStudentsModal', false )}
            onSubmitFile={ this.props.importStudents }
          />
          <ImportFileForm
            open={ this.props.showImportLecturersModal }
            context={ 'Lecturer' }
            onLoad={ this.props.onLoadLecturer }
            fileURL={ ` https://ims-dev-api.cs.usm.my/static/lecturer_sample_csv.csv` }
            onClose={ () => this.props.onChangeLecturersHOC( 'showImportLecturersModal', false )}
            onSubmitFile={ this.props.importLecturers }
          />
        </ReactCSSTransitionGroup>
        {( 
            this.props.onLoadUser || 
            this.props.onLoadStudent ||
            this.props.onLoadLecturer
          ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  UsersHOC  ,
  StudentHOC,
  LecturerHOC
)(Users)