import React, { useEffect, useState } from 'react'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup, Label, Input, FormText
} from 'reactstrap'
import _ from 'lodash'
import DatePicker from 'react-datepicker'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import { LanguagesFieldData, LanguagesDefaultValue } from '../../assets'

const FormContent = ({
  open,
  onLoadLanguage,
  selectedStudentCV,

  onClose,
  onClickSubmit
}) => {

  const [ languageFormData, onChangeLanguageData ] = useState( LanguagesDefaultValue )

  useEffect(() => {
    if ( !open ){
      onChangeLanguageData( LanguagesDefaultValue )
    }
  }, [open])

  const onChangeLanguageField = ( key, val ) => {
    let tmp = _.cloneDeep( languageFormData )
    tmp[ key ] = val
    return onChangeLanguageData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create Language` }</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
          {
            LanguagesFieldData.map( item => (
              <Col key={ item.value } lg={ item.col || 12 }>
                <FormGroup>
                  { 
                    item.label && (
                      <Label>
                        { `${ item.label } ` }
                        { item.required && <span className="text-danger">*</span> }
                      </Label>
                  )}
                  {
                    item.type === 'select' && (
                      <Input
                        type={ item.type }
                        value={ languageFormData[ item.value ] }
                        onChange={ e =>  onChangeLanguageField( item.value, e.target.value )}
                        >
                        <option value=""></option>
                        { 
                          item.options.map( option => (
                            <option key={ option.id } value={ option.id }>{ option.name }</option>
                          ))
                        }
                      </Input>
                    )
                  }
                  {
                    ( item.type !== 'select' && item.type !== 'date' ) && (
                      <Input
                        type={ item.type }
                        value={ languageFormData[ item.value ] }
                        onChange={ e => onChangeLanguageField( item.value, e.target.value )}/>
                    )
                  }
                  {
                    item.type === 'date' && (
                      <DatePicker
                        className={ 'w-100' }
                        dateFormat="dd-MM-yyyy"
                        selected={ languageFormData[ item.value ] }
                        onChange={ val => onChangeLanguageField( item.value, val )}
                      />
                    )
                  }
                  { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                </FormGroup>
              </Col>
            ))
          }
          </Row>
        </Form>
        { onLoadLanguage && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          disabled={ LanguagesFieldData.some( item => item.required && !languageFormData[ item.value ])}
          color={ 
            (LanguagesFieldData.some( item => item.required && !languageFormData[ item.value ])) 
              ? 'secondary' 
              : 'primary' 
          }
          onClick={() => {
            onClickSubmit({
              ... languageFormData,
              spoken: +languageFormData.spoken,
              written: +languageFormData.written,
              student_cv_id: selectedStudentCV.student_cv.id,
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent