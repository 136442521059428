import React, { useEffect, useState, useRef } from 'react'

import { 
  Modal, 
  ModalHeader,
  ModalBody,
  CardFooter,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Card, CardHeader, CardBody
} from 'reactstrap'
import _ from 'lodash'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import { compose } from 'redux'
import { PulseLoader } from 'react-spinners'

import CompanyTypeahead from 'components/Typeahead/company'
import { CloseOutline } from 'react-ionicons'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import StudentCard from './StudentCard'
import { defaultCreateValue, fieldData } from './assets'

import StudentVacanciesHOC from './actions/studentVacancies'
import StudentCVHOC from './actions/studentCV'
import CompanyVacanciesHOC from './actions'

import sessionQueryHandler from 'utils/sessionQueryHandler'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const EditForm = ({
  open,
  data,
  selectedData,
  companies,
  studentVacancies,
  studentCV,
  studentVacancyStatus,
  onLoadCompanyVacancies,
  onLoadStudentVacancies,
  onLoadCompany,

  onClose,
  getCompany,
  onChangeAppliedStudentStatus,
  updateStudentVacancy,
  getStudentVacancies,
  getStudentCV,
  onClickSubmit,
  updateCompanyVacancies,
  onChangeCompanyHOC,
  uploadPoster
}) => {

  const [ companyVacancyData, onChangeCompanyVacancyData ] = useState( defaultCreateValue )
  const [ vacancyPoster, onChangePoster ] = useState( null )
  const { current_selected_session_id } = data.SessionReducer
  const pond = useRef(null)

  useEffect(() => {
    if ( open ){
      return getStudentVacancies( btoa(`{ "page": 1, "filter": { "student_vacancies.company_vacancy_id": ${ selectedData.id } }}`) ),
      getStudentCV( btoa(`{ "per_page": 1000, "filter": { "s.session_id": ${ current_selected_session_id } }}`) )
    }

    onChangeCompanyHOC( 'companies', [] )
    onChangeCompanyVacancyData( defaultCreateValue )
  }, [open])

  useEffect(( ) => {
    selectedData.company_id && getCompany( btoa(`{ "page": 1, "filter":{ "companies.id": ${ selectedData.company_id } }}`))
  }, [selectedData])

  useEffect(() => {
    let tempState = _.find( companies, { id: selectedData.company_id })
    onChangeCompanyVacancyData({
      ... selectedData,
      valid_until_date: selectedData.valid_until_date ? new Date( selectedData.valid_until_date ) : new Date(),
      company_state: tempState ? tempState.state : ''
    })
  }, [selectedData, companies])

  const onChangeCompanyVacancyField = ( key, val ) => {
    let tmp = _.cloneDeep( companyVacancyData )
    tmp[ key ] = val

    return onChangeCompanyVacancyData( tmp )
  }

  const onDropPoster = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangePoster( fileItems[ 0 ].file )
    }
  }
  
  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Edit Company Vacancies` }</ModalHeader>
      <ModalBody>
        <Card>
          <CardHeader style={{ fontSize: '1rem' }}>Vacancies Details</CardHeader>
          <CardBody>
            <Form onSubmit={ e => e.preventDefault()}>
              <Row>
                <Col md={ 6 } xs={ 12 }>
                  <FormGroup >
                    <Label>
                      Company
                      <span className="text-danger mr-2">*</span>
                      <PulseLoader  
                        sizeUnit={"px"}
                        size={ 10 }
                        color={ '#16232c' }
                        loading={ onLoadCompany  } 
                      />
                    </Label>
                    <CompanyTypeahead
                      options={ companies }
                      selectedValue={ 
                        companyVacancyData.company_id && _.find( companies, { id: companyVacancyData.company_id })
                          ? [ _.find( companies, { id: companyVacancyData.company_id })] 
                          : [] 
                      }
                      disableEdit={ true }
                    />
                  </FormGroup>
                </Col>
                <Col md={ 6 } xs={ 12 }> 
                  <FormGroup >
                    <Label>Company State</Label>
                    <Input
                      disabled={ true }
                      type={ 'text' }
                      value={ companyVacancyData.company_state }
                    />
                  </FormGroup>
                </Col>
                {
                  fieldData.map( item => (
                    <Col key={ item.value } md={ item.col || 6 } xs={ 12 }>
                      <FormGroup >
                        <Label>
                          { `${ item.label } ` }
                          { item.required && <span className="text-danger">*</span> }
                        </Label>
                        {
                          item.type !== 'date' && (
                            <Input
                              type={ item.type }
                              min="1"
                              rows={ 5 }
                              step="1" 
                              value={ companyVacancyData[item.value] }
                              onChange={ e => {
                                onChangeCompanyVacancyField( item.value, e.target.value )
                              }} />
                          )
                        }
                        {
                          item.type === 'date' && (
                            <DatePicker
                              className={ 'w-100' }
                              showYearDropdown={ true }
                              dateFormat="dd-MM-yyyy"
                              selected={ companyVacancyData[item.value] }
                              onChange={ val => {
                                onChangeCompanyVacancyField( item.value, val )
                            }}/>
                          )
                        }
                      </FormGroup>
                    </Col>
                  ))
                }
              </Row>
            </Form>
          </CardBody>
          <CardFooter>
            <Button 
              color="primary"
              className="mr-2"
              onClick={() => {
                onClickSubmit({ 
                  session_id: selectedData.session_id,
                  id: companyVacancyData.id,
                  job_title: companyVacancyData.job_title,
                  vacancy_number: companyVacancyData.vacancy_number,
                  job_scope: companyVacancyData.job_scope,
                  programming_languages: companyVacancyData.programming_languages,
                  operating_systems: companyVacancyData.operating_systems,
                  company_id: parseInt( companyVacancyData.company_id ),
                  vacancy_number: parseInt( companyVacancyData.vacancy_number ),
                  valid_until_date: Moment( companyVacancyData.valid_until_date ).format(),
                })
              }}>
              Update
            </Button>
          </CardFooter>
        </Card>
        <Card className={ 'mt-3' }>
          <CardHeader style={{ fontSize: '1rem' }}>Vacancy Poster</CardHeader>
          <CardBody>
            <FormGroup>
              <Label>Vacancy Poster</Label>
              {
                companyVacancyData.poster_url && (
                  <img 
                    src={ companyVacancyData.poster_url } alt="company_poster"
                    style={{ height: 200, display: 'block' }}
                  />
                )
              }
              {
                !companyVacancyData.poster_url && (
                  <p>
                    <strong>No poster is uploaded</strong>
                    <CloseOutline cssClasses={ 'text-danger ml-1' }/>
                  </p>
                )
              }
              <hr />
            </FormGroup>
            <FormGroup>
              <Label>Upload Poster</Label>
              <FilePond
                ref={ pond }
                allowMultiple={ false }
                fullWidth
                acceptedFileTypes={['image/*']}
                maxFiles={ 1 }
                onremovefile={ () => onChangePoster( null )}
                onupdatefiles={ onDropPoster }
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button 
              color={ !vacancyPoster ? 'secondary': 'primary' }
              className="mr-2"
              onClick={() => uploadPoster(
                companyVacancyData.id,
                vacancyPoster,
                pond
              )
            }>
              Upload
            </Button>
          </CardFooter>
        </Card>
        <StudentCard
          studentCVData={ studentCV.data }
          companyVacancyId={ selectedData.id }
          studentVacancyStatus={ studentVacancyStatus }
          studentVacancies={ studentVacancies }
          updateStudentVacancy={ updateStudentVacancy }
          getStudentVacancies={ getStudentVacancies }
          onChangeAppliedStudentStatus={ onChangeAppliedStudentStatus }
        />
        {( onLoadCompanyVacancies || onLoadStudentVacancies ) && <LoadingOverlay />}
      </ModalBody>
    </Modal>
  )
}

export default compose(
  StudentVacanciesHOC,
  StudentCVHOC,
  CompanyVacanciesHOC
)( EditForm );