import React, { Component } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormText,
  FormGroup,
  Input,
  Label,
  Button
} from 'reactstrap'
import { compose } from 'redux'
import _ from 'lodash'
import { PulseLoader } from 'react-spinners';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { mobileChecker } from 'utils/mobileChecker'

import SessionTypeahead from 'components/Typeahead/session'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import RegisterHOC from './actions'
import SessionHOC from 'actions/hoc/session'

const AcademicStatusLib = [ 
  {
    id: 'Active',
    name: 'Active'
  }, 
  {
    id: 'P1',
    name: 'P1'
  }, 
  {
    id: 'P2',
    name: 'P2'
  }, 
  {
    id: 'P3',
    name: 'P3'
  } 
]

const RegistrationFields = sessions => [
  { label: 'Name', value: 'name', type: 'text', required: true },
  { label: 'Email', value: 'email', type: 'text', required: true },
  { label: 'Phone', value: 'phone', type: 'text', subLabel: 'Eg: +6012345678', required: true },
  { label: 'Password', value: 'password', type: 'password', col: 6, required: true },
  { label: 'Confirm Password', value: 'password_confirm', type: 'password', col: 6, required: true },
  { label: 'Address', value: 'address', type: 'textarea', required: false },
  { label: 'CGPA', value: 'cgpa', type: 'number', col: 6, required: true },
  { label: 'AT CGPA', value: 'at_cgpa', type: 'number', col: 6, required: true },
  { label: 'Academic Status', value: 'academic_status', type: 'select', options: AcademicStatusLib, col: 6, required: true },
  { label: 'Acumulated Credits', value: 'accumulated_credits', type: 'number', col: 6, required: false },
  { label: 'NRIC', value: 'ic', type: 'text', required: true },
  { label: 'Matric No.', value: 'matric_number', type: 'text', required: true },
  { label: 'Select your internship academic session', value: 'session_id', type: 'typeahead', options: sessions || [], required: true },
  { label: 'Specialization', value: 'specialization', type: 'select', options: specializationOptions, required: false },
  { label: 'Remark', value: 'remark', type: 'textarea', required: false },
]

const specializationOptions = [ 
  {
    id: 'Major: Intelligent.Computing',
    name: 'Major: Intelligent.Computing'
  }, 
  {
    id: 'Major: Computing Infrastructure',
    name: 'Major: Computing Infrastructure'
  }, 
  {
    id: 'Major: SE',
    name: 'Major: SE'
  }
]

class Registration extends Component {

  componentDidMount = () => { 
    this.props.getSessions(btoa(`{"page":0, "per_page": 1000, "user_id": ${ this.props.data.ProfileReducer.profile.user.id }}`))

    this.debounceService = _.debounce( this.onChangeTypeaheadSearch, 600 );
    this.debounceMobile= _.debounce( val => {
      this.onChangeField( 'phone', mobileChecker( val ))
    }, 500 )
  }

  onChangeField = ( context, val, callback ) => {
    let temp = _.cloneDeep( this.props.registrationData )
    temp[context] = val

    callback && callback()
    this.props.onChangeRegistrationHOC( 'registrationData', temp )
  }

  onChangeTypeaheadSearch = val => {
    this.props.getSessions( btoa(`{ "page": 1 , "per_page": 1000, "filter":{ "sessions.name": {"$like": "%${ val }%" } }}`))
  }

  renderFormContent = () => {
    const { registrationData } = this.props
    
    return (
      <div className="app-main">
        <div className="app-main__outer">
          <div className="app-main__inner">
            <PageTitle
              heading="Register as Student"
              subheading="Fill up this form to apply for Student-Internship Network in School of Computer Sciences, USM"
              icon="pe-7s-id icon-gradient bg-happy-itmeo"
              buttons={[
                {
                  className: 'btn-icon',
                  color: 'secondary',
                  onClick: () => this.props.history.push( '/login' ),
                  content: <>
                    <i class="btn-icon-wrapper pe-7s-angle-left-circle"></i>
                    <span>Back to login</span>
                  </>
                }
              ]}/>
            <ReactCSSTransitionGroup
              component="div"
              transitionName="TabsAnimation"
              transitionAppear={true}
              transitionAppearTimeout={0}
              transitionEnter={false}
              transitionLeave={false}>
              <Card className="main-card mb-3">
                <CardHeader style={{ fontSize: '1rem' }}>
                  Student's Details
                </CardHeader>
                <CardBody>
                  <Row>
                    {
                      RegistrationFields( this.props.sessions ).map( field => (
                        <Col md={ field.col || 12 } xs={ 12 }>
                          <FormGroup>
                            <Label>
                              { `${ field.label } ` }
                              { field.required && <span className="text-danger mr-2">*</span> }
                              {
                                field.value === 'session_id' && (
                                  <PulseLoader  
                                    sizeUnit={"px"}
                                    size={ 10 }
                                    color={ '#16232c' }
                                    loading={ this.props.onLoadSession } 
                                  />
                                )
                              }
                            </Label>
                            {
                              field.type === 'select' && (
                                <Input
                                  type={ 'select' }
                                  value={ registrationData[ field.value ] }
                                  onChange={ e => this.onChangeField( field.value, e.target.value )}
                                >
                                  <option value={ null }></option>
                                  { 
                                    field.options?.[0] && field.options.map( option => (
                                      <option key={ option.id } value={ option.id }>{ option.name }</option>
                                    ))
                                  }
                                </Input>
                              )
                            }
                            {
                              field.type === 'typeahead' && (
                                // <SessionTypeahead
                                //   options={ this.props.sessions }
                                //   onSelect={ val => {
                                //     if ( val?.[0]?.id ){
                                //       this.onChangeField( field.value, val[0].id )
                                //     }
                                //   }}
                                //   selectedValue={ 
                                //     registrationData?.session_id && _.find( this.props.sessions, { id: registrationData?.session_id })
                                //       ? [ _.find( this.props.sessions, { id: registrationData?.session_id })] 
                                //       : [] 
                                //   }
                                //   onSearch={ val => {
                                //     this.onChangeField( field.value, null )
                                //     val && val.length > 0
                                //       ? this.debounceService( val )
                                //       : this.props.onChangeSessionsHOC( 'sessions', [] )
                                //   }}
                                //   filterBy={ 'name' }
                                //   disableEdit={ this.props.onLoadSession }
                                //   helpText={ 'Please enter session name to search.' }
                                // />
                                <Input 
                                  type="select"
                                  value={
                                    registrationData?.session_id
                                  }
                                  onChange={ e => {
                                    this.onChangeField( field.value, e.target.value )
                                  }}>
                                  <option></option>
                                  {
                                    this.props.sessions.map( item => {
                                      return (
                                        <option key={ item.id } value={ item.id }>{ item.name }</option>
                                      )
                                    })
                                  }
                                </Input>
                              )
                            }
                            {
                              [ 'select', 'typeahead' ].indexOf( field.type ) < 0 && (
                                <Input
                                  type={ field.type }
                                  step={ ['cgpa', "at-cgpa"].indexOf( field.value ) > -1 ? 0.01 : 1 }
                                  value={ registrationData[ field.value ] }
                                  onChange={ e => {
                                    this.onChangeField( field.value, e.target.value, () => {
                                      field.value === 'phone' && this.debounceMobile( e.target.value )
                                    })
                                  }} />
                              )
                            }
                            { field.subLabel && <FormText>{ field.subLabel }</FormText> }
                          </FormGroup>
                        </Col>
                      ))
                    }
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button 
                    color={ 
                      RegistrationFields({}).some( item => item.required && !registrationData[ item.value ])
                        ? 'secondary' 
                        : 'primary'
                    }
                    disabled={ RegistrationFields({}).some( item => item.required && !registrationData[ item.value ])}
                    onClick={() => this.props.submitRegistration({
                      ... registrationData,
                      // role_id: 13,
                      session_id: parseInt( registrationData.session_id ),
                      accumulated_credits: parseInt( registrationData.accumulated_credits ),
                      at_cgpa: parseFloat( registrationData.at_cgpa ),
                      cgpa: parseFloat( registrationData.cgpa ),
                      is_accepted_vacancy: false
                    })}
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            </ReactCSSTransitionGroup>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const { onLoadRegistration } = this.props
    return (
      <>
        { this.renderFormContent() }
        { onLoadRegistration && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose( RegisterHOC, SessionHOC ) ( Registration );