import React, { useState } from 'react'
import { Button } from 'reactstrap'
import _ from 'lodash'

import EvaluationForm from './Form'

const  EvaluationContent = ({
  studentEvaluation
}) => {
  let tempEvaluationKey = _.keys( studentEvaluation )
  const [ activeTab, setActiveTab ] = useState( tempEvaluationKey[0] )

  return (
    <>
      {
        tempEvaluationKey.length < 1 && (
          <p>No Evaluation Form is available under this student.</p>
        )
      }
      {
        tempEvaluationKey.length > 0 && (
          <>
            <div className="d-flex mb-3 flex-wrap" style={{ gap: 10 }}>
              {
                tempEvaluationKey.map( key => (
                  <Button
                    key={ key }
                    color={ `${ activeTab === key ? 'primary' : 'secondary' }` }
                    style={{ textTransform: 'capitalize' }}
                    onClick={ () => setActiveTab( key )}
                  >
                    { key }
                  </Button>
                ))
              }
            </div>
            {
              activeTab && (
                <EvaluationForm 
                  selectedData={ studentEvaluation[ activeTab ] }
                />
              )
            }
          </>
        )
      }
    </>
  )
}

export default EvaluationContent;