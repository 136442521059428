import React, { useEffect, useState, useCallback } from 'react'
import Moment from 'moment'
import { 
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Button,
  Form, FormText,FormGroup, 
  Label, Input,
  Card, CardBody, CardHeader
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import UserHOC from './actions/users'

import DatePicker from 'react-datepicker'
import { FieldData, ViewData, defaultValue } from './assets'

import { mobileChecker } from 'utils/mobileChecker'

const EditReport = ({
  data,
  companyUsers,
  onLoadReport,
  onLoadUsers,
  selectedReport,

  getAllCompanyUsers,
  onClickSubmit
}) => {

  const [ reportData, onChangeReportData ] = useState( defaultValue )

  useEffect(() => {
    if ( data.ProfileReducer.current_role_id !== 13 ){
      return getAllCompanyUsers( 
        btoa(`{"page":1, "per_page": 1000, "filter": { "company_id": ${ selectedReport.company.id } }}`)
      )
    }
  }, [])

  useEffect(() => {
    if ( selectedReport ){
      onChangeReportData( selectedReport.internship_duty_report )
    }
  }, [selectedReport])

  const onChangeField = ( key, val ) => {
    let temp = _.cloneDeep( reportData )
    temp[ key ] = val

    onChangeReportData( temp )
  }

  const debounceMobile = useCallback(_.debounce(( fullData, context, val ) => {
    onChangeReportData({
      ... fullData,
      [context]: mobileChecker( val )
    })
  }, 500), [])

  const renderSubmitContent = useCallback(() => {
    return (
      <Button 
        color="primary"
        className="mr-2"
        onClick={() => onClickSubmit({
          ... reportData, 
          report_duty_date: Moment( reportData.report_duty_date ).format(),
          external_supervisor_id: reportData.external_supervisor_id ? +reportData.external_supervisor_id : null
        })}>
        Submit
      </Button>
    )
  }, [reportData])

  return (
    <>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          {
            ViewData.map( card => (
              <Card className={ 'mb-3' }>
                <CardHeader style={{ fontSize: '1rem' }}>{ card.card_name }</CardHeader>
                <CardBody>
                  <Row>
                    {
                      card.fields.map( item => (
                        <Col lg={ item.col || 12 }>
                          <FormGroup>
                            <Label>{ item.label }</Label>
                            <Input
                              type={ item.type }
                              disabled={ true }
                              value={ selectedReport?.[ card.card_obj ]?.[ item.value ]??'' }
                            />
                          </FormGroup>
                        </Col>
                      ))
                    }
                  </Row>
                </CardBody>
              </Card>
            ))
          }
          <Card>
            <CardHeader style={{ fontSize: '1rem' }}>
              { `Report Details` }
            </CardHeader>
            <CardBody>
              <Row>
                {
                  FieldData({ companyUsers }).map( item => (
                    <Col lg={ item.col || 12 }>
                      <FormGroup>
                        <Label>{ item.label }</Label>
                        {
                          item.type === 'date' && (
                            <DatePicker
                              showYearDropdown={ true }
                              className={ 'w-100' }
                              dateFormat="dd-MM-yyyy"
                              selected={ reportData[ item.value ] }
                              onChange={ val => onChangeField( item.value, val )}
                            />
                          )
                        }
                        {
                          ( item.type === 'text' || item.type === 'textarea' ) && (
                            <Input
                              type={ item.type }
                              disabled={ 
                                item.value === 'external_sv_remark' && 
                                data.ProfileReducer.current_role_id === 13  
                              }
                              value={ reportData[ item.value ]}
                              onChange={ e => {
                                Promise.all([
                                  onChangeField( item.value, e.target.value )
                                ]).then(() => {
                                  [ 'supervisor_fax', 'supervisor_mobile'].indexOf( item.value ) > -1 && 
                                  debounceMobile( reportData, item.value, e.target.value )
                                })
                              }}
                            />
                          )
                        }
                        {
                          item.type === 'select' && (
                            <Input
                              type={ item.type }
                              disabled={ data.ProfileReducer.current_role_id === 13  }
                              value={ reportData[ item.value ] }
                              onChange={ e => onChangeField( item.value, e.target.value )}
                            >
                            <option value=""></option>
                            { 
                              item.options?.[0] && item.options.map( option => (
                                <option value={ option.id }>{ `${ option.name } - ${ option.email }` }</option>
                              ))  
                            }
                            </Input>
                          )
                        }
                        { item.subLabel && <FormText>{ item.subLabel }</FormText> }
                      </FormGroup>
                    </Col>
                  ))
                }
                <Col lg={ 12 }>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        disabled={ data.ProfileReducer.current_role_id === 13  }
                        checked={ reportData.have_intern_allowance } 
                        type="checkbox" 
                        onChange={ e => onChangeField( 'have_intern_allowance', e.target.checked )}
                      />
                      Has intern allowance
                    </Label>
                  </FormGroup>
                </Col>
                <Col lg={ 12 }>
                  <FormGroup check>
                    <Label check>
                      <Input 
                        disabled={ data.ProfileReducer.current_role_id === 13  }
                        checked={ reportData.is_checked } 
                        type="checkbox" 
                        onChange={ e => onChangeField( 'is_checked', e.target.checked )}
                      />
                      Has been checked
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        {
          data.ProfileReducer.current_role_id === 13 && (
            <div className="mt-3">
              { renderSubmitContent() }
            </div>
          )
        }
        {( onLoadReport || onLoadUsers ) && <LoadingOverlay/> }
      </ModalBody>
      {
        data.ProfileReducer.current_role_id !== 13 && (
          <ModalFooter>
            { renderSubmitContent() }
          </ModalFooter>
        )
      }
    </>
  )
}

export default UserHOC( EditReport )