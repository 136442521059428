import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'
import Cookies from 'js-cookie'
import getDomainURL from 'utils/api'
import Axios from 'axios'
import FileSaver from 'file-saver'
import { storeLastView } from 'actions/lastView'

import { Get, Post, Put, Delete } from 'utils/axios'
import { getProfile } from 'actions/profile' 

const searchParams = [
  { 
    label: 'Student Name', 
    value: 'u.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Student Email', 
    value: 'u.email',
    type: 'text',
    param: ''
  },
  { 
    label: 'Student Phone', 
    value: 'u.phone',
    type: 'text',
    param: ''
  }
]
const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentCV: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedStudentCV: {},
      searchParams: searchParams,

      showCreateStudentCVModal: false,
      showDeleteStudentCVModal: false,
      showUpdateStudentCVModal: false,
      showSearchForms: false
    }

    onChangeStudentCVHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentCV = search => {
      this.props.storeLastView({ search })

      Get(
        `/student_cvs?query=${ search }`,
        this.getStudentCVSuccess,
        this.getStudentCVError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentCVSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_cv, user }) => {
        tempData.push({
          ... student_cv,
          student_name: user.name,
          student_email: user.email,
          student_mobile_contact: user.phone,
          created_at: Moment.utc( student_cv.created_at ).format( 'DD-MM-yyyy' ),
        })
      })
      
      if ( this.props.data.ProfileReducer.current_role_id === 13 && payload.data?.[0] ){
        this.getSelectedStudentCV( payload.data?.[0]?.student_cv?.id )
      }

      this.setState({ studentCV: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getStudentCVError = error => this.requestError( error )

    getSelectedStudentCV = id => {
      Get(
        `/student_cvs/${ id }`,
        this.getSelectedStudentCVSuccess,
        this.getSelectedStudentCVError,
        this.load
      )
    }
    getSelectedStudentCVSuccess = payload => {
      this.setState({   
        selectedStudentCV: {
          ... payload
        }, 
        showUpdateStudentCVModal: true
      })
    }
    getSelectedStudentCVError = error => this.requestError ( error )

    createStudentCV = dataToSubmit => Post(
      `/student_cvs`,
      dataToSubmit,
      this.createStudentCVSuccess,
      this.createStudentCVError,
      this.load
    )
    createStudentCVSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getStudentCV( search )
      this.setState({ showCreateStudentCVModal: false })
      this.props.getProfile()
      this.requestSuccess( 'StudentCV has been created successfully.' )
    }
    createStudentCVError = error => this.requestError( error )

    deleteStudentCV = id => Delete( 
      `/student_cvs/${ id }`,
      this.deleteStudentCVSuccess,
      this.deleteError,
      this.load
    )
    deleteStudentCVSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getStudentCV( search )
      this.setState({ showDeleteStudentCVModal: false })
      this.requestSuccess( 'Student CV has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateStudentCV = dataToSubmit => Put(
      `/student_cvs/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateStudentCVSucces,
      this.updateStudentCVError,
      this.load
    )
    updateStudentCVSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getStudentCV( search )
      this.getSelectedStudentCV( payload.student_cv.id )
      this.requestSuccess( 'Student CV was updated successfully.' )
    }
    updateStudentCVError = error => this.requestError( error )

    getStudentLI = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/students/li_cert_letter/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_LI_Certificate.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    getStudentCV_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/student_cvs/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_CV.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          studentCV={ this.state.studentCV }
          onLoadStudentCV={ this.state.loading }
          selectedStudentCV={ this.state.selectedStudentCV }
          showCreateStudentCVModal={ this.state.showCreateStudentCVModal }
          showDeleteStudentCVModal={ this.state.showDeleteStudentCVModal }
          showUpdateStudentCVModal={ this.state.showUpdateStudentCVModal }
          showSearchForms={ this.state.showSearchForms }
          
          getStudentCV={ this.getStudentCV }
          getSelectedStudentCV={ this.getSelectedStudentCV }
          createStudentCV={ this.createStudentCV }
          deleteStudentCV={ this.deleteStudentCV }
          updateStudentCV={ this.updateStudentCV }
          getStudentLI={ this.getStudentLI }
          getStudentCV_pdf={ this.getStudentCV_pdf }
          onChangeStudentCVHOC={ this.onChangeStudentCVHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView,
    getProfile
  } )( WithHOC )
}

export default HOC