import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import { storeLastView } from 'actions/lastView'

const searchParams = [
  { 
    label: 'Name', 
    value: 'name',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      industryCat: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedIndustryCat: {},

      searchParams: searchParams,
      selectedDeleteIndustryCatId: null,
      showCreateIndustryCatModal: false,
      showDeleteIndustryCatModal: false,
      showUpdateIndustryCatModal: false,
      showSearchForms: false
    }

    onChangeIndustryCatHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getIndustryCat = search => {
      this.props.storeLastView({ search })

      Get(
        `/industry_categories?query=${ search }`,
        this.getIndustryCatSuccess,
        this.getIndustryCatError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getIndustryCatSuccess = payload => this.setState({ industryCat: payload })
    getIndustryCatError = error => this.requestError( error )

    getSelectedIndustryCat = ( id, context ) => {
      Get(
        `/industry_categories/${ id }`,
        payload=> this.getSelectedIndustryCatSuccess( payload, context ),
        this.getSelectedIndustryCatError,
        this.load
      )
    }
    getSelectedIndustryCatSuccess = ( payload, context ) => this.setState({ 
      selectedIndustryCat: payload,
      showUpdateIndustryCatModal: context === 'edit' 
    })
    getSelectedIndustryCatError = error => this.requestError ( error )

    createIndustryCat = dataToSubmit => Post(
      `/industry_categories`,
      dataToSubmit,
      this.createIndustryCatSuccess,
      this.createIndustryCatError,
      this.load
    )
    createIndustryCatSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      
      this.getIndustryCat( search )
      this.setState({ showCreateIndustryCatModal: false })
      this.requestSuccess( 'Industry category has been created successfully.' )
    }
    createIndustryCatError = error => this.requestError( error )

    deleteIndustryCat = id => Delete( 
      `/industry_categories/${ id }`,
      this.deleteIndustryCatSuccess,
      this.deleteError,
      this.load
    )
    deleteIndustryCatSuccess = payload => {
      this.getIndustryCat()
      this.setState({ showDeleteIndustryCatModal: false })
      this.requestSuccess( 'Industry category has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateIndustryCat = dataToSubmit => Put(
      `/industry_categories/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateIndustryCatSucces,
      this.updateIndustryCatError,
      this.load
    )
    updateIndustryCatSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getIndustryCat( search )
      this.getSelectedIndustryCat( payload.id )
      this.setState({ showUpdateIndustryCatModal: false })
      this.requestSuccess( 'Industry category was updated successfully.' )
    }
    updateIndustryCatError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          industryCat={ this.state.industryCat }
          onLoadIndustryCat={ this.state.loading }
          selectedIndustryCat={ this.state.selectedIndustryCat }
          showSearchForms={ this.state.showSearchForms }
          selectedDeleteIndustryCatId={ this.state.selectedDeleteIndustryCatId }
          showCreateIndustryCatModal={ this.state.showCreateIndustryCatModal }
          showDeleteIndustryCatModal={ this.state.showDeleteIndustryCatModal }
          showUpdateIndustryCatModal={ this.state.showUpdateIndustryCatModal }

          getIndustryCat={ this.getIndustryCat }
          getSelectedIndustryCat={ this.getSelectedIndustryCat }
          createIndustryCat={ this.createIndustryCat }
          deleteIndustryCat={ this.deleteIndustryCat }
          updateIndustryCat={ this.updateIndustryCat }
          onChangeIndustryCatHOC={ this.onChangeIndustryCatHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC