import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      externalSupervisorStudents: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getExternalSupervisorStudents = ( id ) => Get(
      `/external_supervisor/students/${ id }`,
      this.getExternalSupervisorStudentsSuccess,
      this.getExternalSupervisorStudentsError,
      this.load
    )
    getExternalSupervisorStudentsSuccess = payload => this.setState({ externalSupervisorStudents: payload })
    getExternalSupervisorStudentsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          externalSupervisorStudents={ this.state.externalSupervisorStudents }
          onLoadExternalSupervisorStudents={ this.state.loading }
          getExternalSupervisorStudents={ this.getExternalSupervisorStudents } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC