import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentVacancyStatus: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedStudentVacancy: {},

      showDetailsModal: false
    }

    onChangesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentVacancyStatus = () => Get(
      `/student_vacancy_statuses`,
      this.getStudentVacancyStatusSuccess,
      this.getStudentVacancyStatusError,
      this.load
    )
    getStudentVacancyStatusSuccess = payload => this.setState({ studentVacancyStatus: payload })
    getStudentVacancyStatusError = error => this.requestError( error )

    getSelectedStudentVacancyStatus = id => Get(
      `/student_vacancy_statuses/${ id }`,
      this.getSelectedStudentVacancyStatusSuccess,
      this.getSelectedStudentVacancyStatusError,
      this.load
    )
    getSelectedStudentVacancyStatusSuccess = payload => this.setState({ selected: payload })
    getSelectedStudentVacancyStatusError = error => this.requestError ( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          studentVacancyStatus={ this.state.studentVacancyStatus }
          onLoadVacancyStatus={ this.state.loading }
          showDetailsModal={ this.state.showDetailsModal }

          getStudentVacancyStatus={ this.getStudentVacancyStatus }
          getSelectedStudentVacancyStatus={ this.getSelectedStudentVacancyStatus }
          onChangesHOC={ this.onChangesHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC