import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form, Input,
  FormGroup, Label,
  Card, CardBody, CardHeader
} from 'reactstrap'
import _ from 'lodash'
import InternalEvaluation from './components/InternalEvaluation'
import ExternalEvaluation from './components/ExternalEvaluation'
import ExternalJSON from './assets/external.json'
import InternalJSON from './assets/internal.json'

import NewExternalJSON from './assets/new_external.json'
import NewInternalJSON from './assets/new_internal.json'

import AcceptedListModalContent from './components/acceptedListContent'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { FormDefaultValue, FieldData } from './assets/index'

const FormContent = ({
  data,
  open,
  onLoadForm,

  onClose,
  onClickSubmit
}) => {

  const [formData, onChangeFormData] = useState(FormDefaultValue)
  const [showAcceptedModal, onToggleAcceptedModal] = useState(false)
  const [selectedAcceptedVacancies, onSelectAcceptedVacancies] = useState(null)
  const [tempJSON, onChangeJSON] = useState(null)

  const { user } = data.ProfileReducer.profile
  const { current_role_id } = data.ProfileReducer
  const {
    current_selected_session_id,
  } = data.SessionReducer

  const onChangeFormField = (context, val) => {
    let temp = _.cloneDeep(formData)
    temp[context] = val

    if (context === 'type') {
      onChangeJSON(val ===
        'Internal'
        ? [5, 4, 3].indexOf(current_selected_session_id) > -1
          ? InternalJSON
          : NewInternalJSON
        : [5, 4, 3].indexOf(current_selected_session_id) > -1
          ? ExternalJSON
          : NewExternalJSON
      )
    }

    onChangeFormData(temp)
  }

  useEffect(() => {
    if (!open) {
      onChangeFormData(FormDefaultValue)
      onSelectAcceptedVacancies(null)
      onChangeJSON(null)
    }
  }, [open])

  useEffect(() => {
    const contact_person_email = data.ProfileReducer.profile.company?.contact_person_email || ""
    const { email } = data.ProfileReducer.profile.user

    if (selectedAcceptedVacancies && [1, 11].indexOf(current_role_id) < 0) {
      if (selectedAcceptedVacancies?.internal_supervisor_id === user.id) {
        onChangeFormField('type', 'Internal')
      }
      if (selectedAcceptedVacancies?.external_supervisor_id === user.id || (!_.isEmpty(contact_person_email) && email === contact_person_email)) {
        onChangeFormField('type', 'External')
      }
    } else if (selectedAcceptedVacancies && [1, 11].indexOf(current_role_id) > -1) {
      onChangeFormField(
        'type',
        !selectedAcceptedVacancies?.has_external_evaluation
          ? 'External'
          : 'Internal'
      )
    }
  }, [selectedAcceptedVacancies])


  return (
    <Modal
      isOpen={open}
      size={'xl'}>
      <ModalHeader toggle={() => onClose()}>{`Create Evaluation Form`}</ModalHeader>
      <ModalBody>
        <Form onSubmit={e => e.preventDefault()}>
          <Card>
            <CardHeader style={{ fontSize: '1rem' }}>
              Internship
              <Button
                size={'sm'}
                color="primary"
                className="ml-auto d-block"
                onClick={() => onToggleAcceptedModal(true)}
              >
                Assign
              </Button>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <FormGroup>
                    {
                      selectedAcceptedVacancies && (
                        <>
                          <Label>Assigned Internship</Label>
                          <Row className={'mt-2'}>
                            <Col lg={6}>
                              <div className={'d-flex align-items-center'}>
                                <i id={'TooltipStudent'} className="font-size-lg header-icon pe-7s-study mr-2 text-muted opacity-6" />
                                {`Student name - `}
                                <b className={'text-warning ml-1'}>{` ${selectedAcceptedVacancies.student_name}`}</b>
                              </div>
                              <div className={'d-flex align-items-center mt-2'}>
                                <i className="font-size-lg header-icon pe-7s-culture mr-2 text-muted opacity-6" />
                                {`Company name -`}
                                <b className={'text-warning ml-1'}>{`${selectedAcceptedVacancies.company_name}`}</b>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className={'d-flex align-items-center mt-2'}>
                                <i className="font-size-lg header-icon pe-7s-users mr-2 text-muted opacity-6" />
                                {`Internal Supervisor -`}
                                <b className={'text-warning ml-1'}>{`${selectedAcceptedVacancies.internal_supervisor}`}</b>
                              </div>
                              <div className={'d-flex align-items-center mt-2'}>
                                <i className="font-size-lg header-icon pe-7s-users mr-2 text-muted opacity-6" />
                                {`External Supervisor -`}
                                <b className={'text-warning ml-1'}>{`${selectedAcceptedVacancies.external_supervisor}`}</b>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )
                    }
                    {
                      !selectedAcceptedVacancies && (
                        <>
                          <Label>Assigned Internship</Label>
                          <br />
                          <i className={'text-warning'} style={{ fontSize: '0.85rem' }}>
                            No internship is assigned yet.
                          </i>
                        </>
                      )
                    }
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <hr className={'mt-0'} />
                  <FormGroup>
                    <Label>Type</Label>
                    <Input
                      type={'select'}
                      disabled={
                        !selectedAcceptedVacancies ||
                        [1, 11].indexOf(current_role_id) < 0 ||
                        !(
                          !selectedAcceptedVacancies.has_external_evaluation &&
                          !selectedAcceptedVacancies.has_internal_evaluation
                        )
                      }
                      value={formData.type}
                      onChange={e => onChangeFormField('type', e.target.value)}
                    >
                      <option></option>
                      {['Internal', 'External'].map(option => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                {
                  FieldData.map(field => (
                    <Col lg={field.col || 12}>
                      <FormGroup check={field.type === 'checkbox'}>
                        <Label check={field.type === 'checkbox'}>
                          {
                            field.type === 'checkbox' && (
                              <Input
                                type={field.type}
                                rows={5}
                                disabled={!selectedAcceptedVacancies}
                                value={formData[field.value]}
                                onChange={e => onChangeFormField(field.value, e.target.checked)}
                              />
                            )
                          }
                          {`${field.label} `}
                          {field.required && <span className="text-danger">*</span>}
                        </Label>
                        {
                          field.type === 'textarea' && (
                            <Input
                              disabled={!selectedAcceptedVacancies}
                              type={field.type}
                              rows={5}
                              value={formData[field.value]}
                              onChange={e => onChangeFormField(field.value, e.target.value)}
                            />
                          )
                        }
                      </FormGroup>
                    </Col>
                  ))
                }
              </Row>
            </CardBody>
          </Card>
          {
            formData.type && (
              <Card className={'mt-3'}>
                <CardHeader style={{ fontSize: '1rem' }}>INTERN’S EVALUATION </CardHeader>
                <CardBody>
                  <i>Please state the grade based on the rubrics in the appendix</i>
                  {
                    formData.type === 'Internal' && (
                      <InternalEvaluation
                        tempJSON={tempJSON}
                        onChangeJSON={onChangeJSON}
                      />
                    )
                  }
                  {
                    formData.type === 'External' && (
                      <ExternalEvaluation
                        tempJSON={tempJSON}
                        onChangeJSON={onChangeJSON}
                      />
                    )
                  }
                </CardBody>
              </Card>
            )
          }
        </Form>
        {onLoadForm && <LoadingOverlay />}
        <Modal
          isOpen={showAcceptedModal}
          size={'xl'}>
          <ModalHeader toggle={() => onToggleAcceptedModal(false)}>{`Assign Internship`}</ModalHeader>
          <ModalBody>
            <AcceptedListModalContent
              selectedAcceptedVacancy={selectedAcceptedVacancies}
              onSelectAcceptedVacancies={onSelectAcceptedVacancies}
            />
          </ModalBody>
        </Modal>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="mr-2"
          disabled={
            !selectedAcceptedVacancies ||
            !tempJSON
          }
          onClick={() => {
            onClickSubmit({
              ...formData,
              evaluation: JSON.stringify(tempJSON),
              final_total_marks: tempJSON?.total,
              session_id: selectedAcceptedVacancies.session_id,
              student_id: selectedAcceptedVacancies.student_id,
              user_id: selectedAcceptedVacancies.user_id,
              company_id: selectedAcceptedVacancies.company_id,
              session_id: selectedAcceptedVacancies.session_id,
              student_vacancy_accepted_list_id: selectedAcceptedVacancies.id,
            })
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent