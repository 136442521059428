import React, { Component } from 'react'
import _ from 'lodash'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import getDomainURL from 'utils/api'
import { getProfile } from 'actions/profile'

import { Get, Put } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      students: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedStudent: {},
    }

    onChangeStudentsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSelectedStudent = id => {
      Get(
        `/students/${ id }`,
        this.getSelectedStudentSuccess,
        this.getSelectedStudentError,
        this.load
      )
    }
    getSelectedStudentSuccess = ({ user, student }) => {
      let tempMerge = _.merge( user, student )

      this.setState({ selectedStudent: tempMerge })
    }
    getSelectedStudentError = error => this.requestError ( error )

    updateStudent = dataToSubmit => Put(
      `/students/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateStudentSucces,
      this.updateStudentError,
      this.load
    )
    updateStudentSucces = payload => {
      this.getSelectedStudent( payload.student.id )
      this.requestSuccess( 'Info was updated successfully.' )
    }
    updateStudentError = error => this.requestError( error )

    submitCangred = ( id, dataToSubmit, pondRef ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/students/cangred_pdf/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'cangred', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
       Axios.post(url, tempForm, config ).then(() => {
        this.load( false )
         this.submitCangredSuccess( id, pondRef )
       }).catch( err => {
        this.load( false )
        this.submitCangredError( err )
       })
    }
    submitCangredSuccess = ( id, pondRef ) => {
      pondRef.current.removeFiles()
      this.props.getProfile()
      this.requestSuccess( 'Cangred has been uploaded succesfuly.' )
    }
    submitCangredError = error => this.requestError( error )
    
    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadStudent={ this.state.loading }
          selectedStudent={ this.state.selectedStudent }
          showImportStudentsModal={ this.state.showImportStudentsModal }

          getSelectedStudent={ this.getSelectedStudent }
          updateStudent={ this.updateStudent }
          submitCangred={ this.submitCangred }
          onChangeStudentsHOC={ this.onChangeStudentsHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    getProfile
  })( WithHOC )
}

export default HOC