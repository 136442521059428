
const QualificationsOptions = [
  { id: 1, name: 'Primary School' },
  { id: 2, name: 'Secondary School' },
  { id: 3, name: 'SPM' },
  { id: 4, name: '"O" Level Higher Secondary' },
  { id: 5, name: 'STPM' },
  { id: 6, name: '"A" Level' },
  { id: 7, name: 'Professional Certificate' },
  { id: 8, name: 'Advanced Graduate Diploma' },
  { id: 9, name: 'Higher Graduate Diploma' },
  { id: 10, name: `Bachelor's Degree` },
  { id: 11, name: 'Post Graduate Diploma' },
  { id: 12, name: 'Professional Degree' },
  { id: 13, name: `Master's Degree` },
  { id: 14, name: 'Doctorate (PhD)' },
]

export const AboutMeFieldData = ({ states = [], countries = [] }) => [
  { 
    label: 'Gender',
    value: 'gender',
    type: 'select',
    options: [
      { id: 'Male', name: 'Male' },
      { id: 'Female', name: 'Female' },
    ],
    required: true,
    col: 6,
  },
  { 
    label: 'Country',
    value: 'country',
    type: 'select',
    options: countries,
    required: true,
    col: 6,
    subLabel: 'e.g. Malaysia/ China'
  },
  { 
    label: 'State',
    value: 'state',
    type: 'select',
    options: states,
    required: true,
    col: 6,
    subLabel: 'Please a country first. e.g. Penang'
  },
  { 
    label: 'City',
    value: 'city',
    type: 'text',
    required: true,
    col: 6,
    subLabel: 'City/ Area'
  },
  { 
    label: 'Postal Code',
    value: 'postal_code',
    type: 'text',
    required: true,
    col: 6
  },
  {
    label: 'Email Address',
    type: 'details',
    value: 'email',
    col: 6
  },
  {
    label: 'Phone Number',
    type: 'details',
    value: 'phone',
    col: 6
  },
  { 
    label: 'Mailing Address',
    value: 'mailing_address',
    required: true,
    type: 'textarea',
    subLabel: 'Please add a valid address'
  },
  { 
    label: 'Additional Info',
    value: 'additional_info',
    type: 'textarea'
  },
]

export const EducationFieldData = [
  { 
    label: 'Institute Name',
    value: 'institute_name',
    type: 'text',
    required: true,
    subLabel: 'e.g. University Sains Malaysia'
  },
  { 
    label: 'Institute Location',
    value: 'institute_location',
    type: 'text',
    required: true,
    subLabel: 'e.g. Main Campus, Penang'
  },
  { 
    label: 'Start Date',
    value: 'start_date',
    type: 'date',
    required: true,
    col: 6
  },
  { 
    label: 'End Date',
    value: 'end_date',
    type: 'date',
    required: true,
    col: 6
  },
  { 
    label: 'Qualifications',
    value: 'qualification',
    type: 'select',
    required: true,
    options: QualificationsOptions,
    col: 6
  },
  { 
    label: 'Field of study',
    value: 'field_of_study',
    type: 'text',
    required: true,
    col: 6,
    subLabel: 'e.g. Computer Science'
  },
  { 
    label: 'Other field of study',
    value: 'others_field_of_study',
    type: 'text',
    col: 6,
    required: false,
    subLabel: 'e.g. Phycology'
  },
  { 
    label: 'Major',
    value: 'major',
    type: 'text',
    required: true,
    col: 6,
    subLabel: 'e.g. Software Engineering'
  },
  { 
    label: 'CGPA',
    value: 'cgpa',
    type: 'number',
    required: true,
    col: 6
  },
  { 
    label: 'Extra Info',
    value: 'extra_info',
    required: false,
    type: 'textarea'
  }
]

export const ExperiencesFieldData = [
  { 
    label: 'Position',
    value: 'position',
    type: 'text',
    required: true,
    col: 6,
    subLabel: 'e.g. Application Developer'
  },
  { 
    label: 'Company Name',
    value: 'company_name',
    type: 'text',
    col: 6,
    required: true,
    subLabel: ''
  },
  { 
    label: 'Company Location',
    value: 'company_location',
    type: 'text',
    required: true,
    subLabel: 'e.g. Bayan Lepas'
  },
  { 
    label: 'Start Date',
    value: 'start_date',
    type: 'date',
    required: true,
    col: 6
  },
  { 
    label: 'End Date',
    value: 'end_date',
    type: 'date',
    required: true,
    col: 6
  },
  { 
    label: 'Extra Info',
    value: 'extra_info',
    type: 'textarea',
    required: false,
    subLabel: 'e.g. Intern of the year 2020'
  }
]

export const SkillsFieldData = [
  { 
    label: 'Skill Name',
    value: 'name',
    type: 'text',
    required: true,
    subLabel: 'e.g. Python'
  },
  { 
    label: 'Proficiency',
    value: 'proficiency',
    type: 'select', 
    required: true,
    options: [
      { id: 1, name: 'Beginner' },
      { id: 2, name: 'Intermediate' },
      { id: 3, name: 'Advanced' }
    ]
  }
]

export const LanguagesFieldData = [
  { 
    label: 'Language Name',
    value: 'name',
    type: 'text',
    col: 6,
    required: true,
    subLabel: 'e.g. English'
  },
  { 
    label: 'Spoken',
    value: 'spoken',
    type: 'number',
    col: 6,
    required: true,

    subLabel: 'e.g. rate 1-10'
  },
  { 
    label: 'Written',
    value: 'written',
    type: 'number',
    col: 6,
    required: true,

    subLabel: 'e.g. rate 1-10'
  },
  { 
    label: 'Relevant Certs',
    value: 'relevant_certs',
    type: 'text',
    col: 6,
    required: false,

    subLabel: 'e.g. Japanese-Language Proficiency Test'
  },
  { 
    label: 'Band/Level',
    value: 'score',
    type: 'text',
    col: 6,
    required: true,
  },
]


export const AboutMeDefaultValue = {
  photo_url: '',
  gender: null,
  country: null,
  state: null,
  city: '',
  postal_code: '',
  additional_info: '',
  mailing_address: '',
}

export const EducationDefaultValue = {
  institute_name: '',
  institute_location: '',
  start_date: null,
  end_date: null,
  qualification: '',
  field_of_study: '',
  others_field_of_study: '',
  major: '',
  cgpa: null,
  extra_info: '',
}

export const ExperiencesDefaultValue = {
  position: '',
  start_date: null,
  end_date: null,
  company_name: '',
  company_location: '',
  extra_info: '',
}

export const SkillsDefaultValue = {
  name: '',
  proficiency: ''
}

export const LanguagesDefaultValue = {
  name: '',
  spoken: '',
  written: '',
  relevant_certs: '',
  score: ''
}