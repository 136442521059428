import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Modal, ModalBody, ModalHeader,
  Collapse, Row, Col, Card, CardHeader, CardBody, Button, UncontrolledTooltip 
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateStudentCVForm from './Create'
import UpdateStudentCVForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'
import SearchForm from 'components/searchForm'

import StudentCVHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class StudentCV extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getStudentCV( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getStudentCV( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }
  }

  renderNonStudentContent = () => {
    const { is_delete, is_update } = this.props.currentPermission
    return (
      <>
         <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.studentCV.data.length < 1? 3: 
                      this.props.studentCV.data.length < 10? this.props.studentCV.data.length:
                      10
                    }
                    data={ this.props.studentCV.data }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'student_name'
                      },
                      {
                        Header: 'Email',
                        accessor: 'student_email'
                      },
                      {
                        Header: 'Mobile Contact',
                        accessor: 'student_mobile_contact'
                      },
                      {
                        Header: 'Create Date',
                        accessor: 'created_at'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                disabled={ !is_update }
                                id="UpdateStudentCV"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? 'primary' : 'secondary' }
                                onClick={() => {
                                  this.props.getSelectedStudentCV(row.original.id, 'edit' )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateStudentCV" placement="left">Update the information of this student's CV</UncontrolledTooltip>
                              <Button
                                id="DeleteStudentCV"
                                disabled={ !is_delete }
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? 'danger' : 'secondary' }
                                onClick={() => {
                                  this.props.onChangeStudentCVHOC( 'showDeleteStudentCVModal', true )
                                  this.props.onChangeStudentCVHOC( 'selectedStudentCV', row.original )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteStudentCV" placement="left">Delete this student's CV</UncontrolledTooltip>
                              <Button
                                id="DownloadStudentCV"
                                className="mr-2 btn-icon btn-icon-only"
                                color="warning"
                                onClick={() => this.props.getStudentCV_pdf(row.original.id, row.original.student_name)}
                              >
                                <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DownloadStudentCV" placement="left">Download this student's CV</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.studentCV.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getStudentCV( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={ this.props.showUpdateStudentCVModal }
            size={ 'xl' }>
            <ModalHeader toggle={ () => this.props.onChangeStudentCVHOC( 'showUpdateStudentCVModal', false )}>
              { `Edit Student CV` }
            </ModalHeader>
            <ModalBody>
              <UpdateStudentCVForm
                {...this.props}
                onClose={ () => this.props.onChangeStudentCVHOC( 'showUpdateStudentCVModal', false )}
                onClickSubmit={ this.props.updateStudentCV }
              />
            </ModalBody>
          </Modal>
      </>
    )
  }

  render = () => {
    const { is_create } = this.props.currentPermission
    return (
      <>
        <PageTitle
          heading="Student's CV"
          subheading="Listings of all the students' CV present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            ... (
              [ 1, 11 ].indexOf( this.props.data.ProfileReducer.current_role_id ) > -1 ? [{
                color: 'warning',
                className: 'btn-icon mr-2',
                onClick: () => {
                  this.props.onChangeStudentCVHOC( 'showSearchForms', !this.props.showSearchForms )
                },
                content:<>
                  <i className="pe-7s-search btn-icon-wrapper"> </i>
                  <span>Search</span>
                </>
              }] : []
            ),
            ... (
              is_create && this.props.studentCV.data.length < 1 ? [{
                color: 'primary',
                className: 'mr-2',
                onClick: () => {
                  this.props.onChangeStudentCVHOC( 'showCreateStudentCVModal', true )
                },
                content: 'Create'
              }] : []
            ),
            ... (
              [ 13 ].indexOf( this.props.data.ProfileReducer.current_role_id ) > -1 ? [{
                className: 'bg-transparent border-0 p-0',
                content:<>
                  <Button 
                    id='DownloadStudentLI' color='warning'
                    className='mr-2 btn-icon btn-icon'
                    disabled={this.props.studentCV.data[0] ? false : true}
                    onClick={() => {
                      this.props.getStudentLI(this.props.studentCV.data[0].student_id, this.props.studentCV.data[0].student_name)
                    }}
                  >
                    <i className="pe-7s-cloud-download btn-icon-wrapper"></i>
                    Download LI Certificate
                  </Button>
                </>
              }] : []
            ),
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeStudentCVHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeStudentCVHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 's.session_id' )

                this.props.getStudentCV( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          {
            this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent()
          }
          {
            this.props.data.ProfileReducer.current_role_id === 13 &&  (
              <>
                {
                  _.keys( this.props.selectedStudentCV ).length > 0 && (
                    <UpdateStudentCVForm
                      {...this.props}
                      onClickSubmit={ this.props.updateStudentCV }
                    />
                  )
                }
                {
                  _.keys( this.props.selectedStudentCV ).length < 1 && (
                    <Card>
                      <CardHeader>No CV is found. </CardHeader>
                      <CardBody>Please click <strong className='text-warning'>Create</strong> to create your own CV.</CardBody>
                    </Card>
                  )
                }
              </>
            )
          }
          <CreateStudentCVForm
            {...this.props}
            open={ this.props.showCreateStudentCVModal }
            onClose={ () => this.props.onChangeStudentCVHOC( 'showCreateStudentCVModal', false )}
            onClickSubmit={ this.props.createStudentCV }
          />
          <ConfirmationModal
            loading={ this.props.onLoadStudentCV }
            open={ this.props.showDeleteStudentCVModal}
            title={ `Delete student's CV` }
            message={ `Are you sure you want to delete this student's CV? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeStudentCVHOC( 'showDeleteStudentCVModal', false )}
            onClickConfirm={ () => this.props.deleteStudentCV( this.props.selectedStudentCV.id )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadStudentCV && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  StudentCVHOC
)(StudentCV)