import React, { Component } from 'react'
import _ from 'lodash'
import Cookies from 'js-cookie'
import getDomainURL from 'utils/api'
import Moment from 'moment'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import FileSaver from 'file-saver'
import { storeLastView } from 'actions/lastView'

import { Get, Delete, Put, Post } from 'utils/axios'

const searchParams = [
  { 
    label: 'Student Name', 
    value: 'users.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Company Name', 
    value: 'companies.name',
    type: 'text',
    param: ''
  },
  { 
    label: 'Placement Form Check', 
    value: 'student_vacancy_accepted_lists.is_placement_form_check',
    type: 'select',
    param: '',
    col: 6,
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },
  { 
    label: 'Duty Report Check', 
    value: 'student_vacancy_accepted_lists.is_duty_report_check',
    type: 'select',
    param: '',
    col: 6,
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },
  { 
    label: 'External Final Report Check', 
    value: 'student_vacancy_accepted_lists.is_external_final_report_checked',
    type: 'select',
    param: '',
    col: 6,
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },
  { 
    label: 'External Evaluation Report Check', 
    value: 'student_vacancy_accepted_lists.is_external_evaluation_form_checked',
    type: 'select',
    param: '',
    col: 6,
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },
  { 
    label: 'Internal Final Report Check', 
    value: 'student_vacancy_accepted_lists.is_internal_final_report_checked',
    type: 'select',
    param: '',
    col: 6,
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },
  { 
    label: 'Internal Evaluation Report Check', 
    value: 'student_vacancy_accepted_lists.is_internal_evaluation_form_checked',
    type: 'select',
    param: '',
    col: 6,
    valueType: 'boolean',
    options: [
      { id: 'true', name: 'Checked' },
      { id: 'false', name: 'Unchecked' },
    ]
  },

]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentAcceptedVacancies: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      jobScope: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      insurances: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },

      showDeleteStudentAcceptedVacanciesModal: false,
      showUpdateStudentAcceptedVacanciesModal: false,
      showStudentAcceptedVacanciesDocModal: false,
      studentAcceptedVacanciesDocHeader: '',
      showSearchForms: false,
      selectedAcceptedVacancies: {
        student_name: '',
        student_email: '',
        student_phone: '',
        company_name: '',
      },

      searchParams: searchParams
    }

    onChangeStudentAcceptedVacanciesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getStudentAcceptedVacancies = search => {
      this.props.storeLastView({ search })
      Get(
        `/student_vacancy_accepted_lists?query=${ search }`,
        this.getStudentAcceptedVacanciesSuccess,
        this.getStudentAcceptedVacanciesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getStudentAcceptedVacanciesSuccess = payload => {
      let tempData = []
      
      payload.data && payload.data.map( item => {
        tempData.push({
          ... item,
          ... item.student_vacancy_accepted_list,
          accepted_date: Moment( item.student_vacancy_accepted_list.accepted_date ).format( 'DD-MM-yyyy h:mm a' ),
          student_name: item.student.user.name,
          student_email: item.student.user.email,
          student_phone: item.student.user.phone,
          company_name: item.company_vacancy.company.name,
          internal_supervisor: item.internal_supervisor?.name??'N/A',
          external_supervisor: item.external_supervisor?.name??'N/A',
        })
      })

      this.setState({ studentAcceptedVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getStudentAcceptedVacanciesError = error => this.requestError( error )

    getJobScopes = search => {
      Get(
        `/student_job_scope_forms?query=${ search }`,
        this.getJobScopesSuccess,
        this.getJobScopesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getJobScopesSuccess = payload => {
      let tempData = []
      payload.data.map(({ 
        student_job_scope_form, 
        student 
      }) => {
        tempData.push({
          jkli_approval_status: student_job_scope_form.jkli_approval_status,
          student_id: student.id,
        }) 
      })
      this.setState({ jobScope: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getJobScopesError = error => this.requestError( error )

    getInsurances = search => {
      Get(
        `/student_insurance_forms?query=${ search }`,
        this.getInsurancesSuccess,
        this.getInsurancesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getInsurancesSuccess = payload => {
      let temp = _.map( payload.data, ({ student_insurance_form, student }) => {

      return ({
        is_clerk_approved: student_insurance_form.is_clerk_approved,
        student_id: student.id,
        })
      })

      this.setState({ insurances: {
        meta: payload.meta,
        data: temp
      }})
    }
    getInsurancesError = error => this.requestError( error )

    getSelectedStudentAcceptedVacancies = id => Get(
      `/student_vacancy_accepted_lists/${ id }`,
      this.getSelectedStudentAcceptedVacanciesSuccess,
      this.getSelectedStudentAcceptedVacanciesError,
      this.load
    )
    getSelectedStudentAcceptedVacanciesSuccess = ({
      student,
      company_vacancy,
      external_supervisor,
      internal_supervisor,
      student_vacancy_accepted_list
    }) => {
      let tempFile = ''
      this.setState({ 
        selectedAcceptedVacancies: {
          ...student,
          id: student_vacancy_accepted_list.id,
          company_id: company_vacancy.company_id,
          final_report_url: student_vacancy_accepted_list.final_report_url,
          company_name: company_vacancy.company.name,
          external_supervisor_id: external_supervisor?.id??null,
          internal_supervisor_id: internal_supervisor?.id??null,
          is_duty_report_check: student_vacancy_accepted_list.is_duty_report_check,
          is_external_evaluation_form_checked: student_vacancy_accepted_list.is_external_evaluation_form_checked,
          is_external_final_report_checked: student_vacancy_accepted_list.is_external_final_report_checked,
          is_final_report_submitted: student_vacancy_accepted_list.is_final_report_submitted,
          is_internal_evaluation_form_checked: student_vacancy_accepted_list.is_internal_evaluation_form_checked,
          is_internal_final_report_checked: student_vacancy_accepted_list.is_internal_final_report_checked,
          is_placement_form_check: student_vacancy_accepted_list.is_placement_form_check,
          internal_sv_remark: student_vacancy_accepted_list.internal_sv_remark || '',

          external_supervisor_name: external_supervisor?.name??'N/A',
          internal_supervisor_name: internal_supervisor?.name??'N/A'
        }
      })
    }
    getSelectedStudentAcceptedVacanciesError = error => this.requestError( error )

    getSelectedStudentWeeklyLog = search => Get(
      `/weekly_logs?query=${ search }`,
      this.getSelectedStudentWeeklyLogSuccess,
      this.getSelectedStudentWeeklyLogError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getSelectedStudentWeeklyLogSuccess = payload => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      let tempData = []
      payload.data?.[0] && payload.data.map( item => {
        tempData.push({
          ... item.weekly_log,
          student_name: item.student_user.name,
          created_at:  Moment.utc( item.weekly_log.created_at ).format( 'DD-MM-yyyy' ),
          date: Moment.utc( item.weekly_log.date ).format( 'DD-MM-yyyy' ),
        })
      })
      this.setState({ 
        selectedAcceptedVacancies : {
          ...temp,
          weekly_log: {
            data: tempData,
            meta: payload.meta
          }
        }
      })
    }
    getSelectedStudentWeeklyLogError = error => this.requestError( error )

    getSelectedStudentOfferLetters = ( student_id ) => Get(
      `/student_offer_letters/${ student_id }`,
      this.getSelectedStudentOfferLettersSuccess,
      this.getSelectedStudentOfferLettersError,
      this.load
    )
    getSelectedStudentOfferLettersSuccess = payload => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        offer_letter: {
          ...payload,
          created_at: Moment.utc( payload.created_at ).format( 'DD-MM-yyyy' )
        }
      }})
    }
    getSelectedStudentOfferLettersError = error => this.requestError( error )

    getSelectedStudentPlacementForm = id => Get(
      `/placement_forms/${ id }`,
      this.getSelectedStudentPlacementFormSuccess,
      this.getSelectedStudentPlacementFormError,
      this.load
    )
    getSelectedStudentPlacementFormSuccess = payload => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        placement_form: {
          ...payload.placement_form,
          review_date_time: Moment.utc( payload.placement_form.review_date_time ).format( 'DD-MM-YYYY' ),
          created_at: Moment.utc( payload.placement_form.created_at ).format( 'DD-MM-YYYY' )
        }
      }})
    }
    getSelectedStudentPlacementFormError = error => this.requestError( error )

    getSelectedStudentDutyReport = id => Get(
      `/student_report_duty_forms/${ id }`,
      this.getSelectedStudentDutyReportSuccess,
      this.getSelectedStudentDutyReportError,
      this.load
    )
    getSelectedStudentDutyReportSuccess = payload => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        student_report_duty_form: {
          ...payload.student_report_duty_form,
          report_duty_date: payload.student_report_duty_form.report_duty_date
            ? Moment.utc( payload.student_report_duty_form.report_duty_date ).format( 'DD-MM-yyyy' )
            : 'N/A'
        }
      }})
    }
    getSelectedStudentDutyReportError = error => this.requestError( error )

    getSelectedStudentInsuranceForm = id => Get(
      `/student_insurance_forms/${ id }`,
      this.getSelectedStudentInsuranceFormSuccess,
      this.getSelectedStudentInsuranceFormError,
      this.load
    )
    getSelectedStudentInsuranceFormSuccess = payload => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        student_insurance_form: {
          ...payload.student_insurance_form,
          is_clerk_approved: (payload.student_insurance_form.is_clerk_approved.toString().toLowerCase() === 'true'),
          created_at: Moment.utc( payload.student_insurance_form.created_at ).format( 'DD-MM-yyyy' )
        }
      }})
    }
    getSelectedStudentInsuranceFormError = error => this.requestError( error )

    getSelectedStudentJobScopeForm = id => Get(
      `/student_job_scope_forms/${ id }`,
      this.getSelectedStudentJobScopeFormSuccess,
      this.getSelectedStudentJobScopeFormError,
      this.load
    )
    getSelectedStudentJobScopeFormSuccess = payload => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        student_job_scope_form: {
          ...payload.student_job_scope_form,
          created_at: Moment.utc( payload.student_job_scope_form.created_at ).format( 'DD-MM-yyyy' )
        }
      }})
    }
    getSelectedStudentJobScopeFormError = error => this.requestError( error )

    getSelectedStudentEvaluation = id => Get(
      `/internship_evaluation_forms/${ id }`,
      this.getSelectedStudentEvaluationSuccess,
      this.getSelectedStudentEvaluationError,
      this.load
    )
    getSelectedStudentEvaluationSuccess = ({ external_supervisor, internal_supervisor, internship_evaluation_form }) => {
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        internship_evaluation_form: internship_evaluation_form,
      }})
    }
    getSelectedStudentEvaluationError = error => this.requestError( error )

    getSelectedStudentVisitSchedule = id => Get(
      `/visit_schedules/${ id }`,
      this.getSelectedStudentVisitScheduleSuccess,
      this.getSelectedStudentVisitScheduleError,
      this.load
    )
    getSelectedStudentVisitScheduleSuccess = ({ visit_schedule }) => {
      let tempStartTime = new Date( visit_schedule.start_time )  
      let tempEndTime = new Date( visit_schedule.end_time )
      let tempDate = new Date( visit_schedule.date )

      var userTimezoneOffset = tempDate.getTimezoneOffset() * 60000;
      let temp = _.cloneDeep( this.state.selectedAcceptedVacancies )
      this.setState({ selectedAcceptedVacancies : {
        ...temp,
        visit_schedules: {
          ...visit_schedule,
          date: new Date(tempDate.getTime() + userTimezoneOffset ),
          start_time: new Date(tempStartTime.getTime() + userTimezoneOffset ),
          end_time: new Date(tempEndTime.getTime() + userTimezoneOffset ),
        }
      }})
    }
    getSelectedStudentVisitScheduleError = error => this.requestError( error )
    
    deleteStudentAcceptedVacancies = id => Delete(
      `/student_vacancy_accepted_lists/${ id }`,
      this.deleteStudentAcceptedVacanciesSuccess,
      this.deleteStudentAcceptedVacanciesError,
      this.load
    )
    deleteStudentAcceptedVacanciesSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getStudentAcceptedVacancies( search )
      this.setState({ showDeleteStudentAcceptedVacanciesModal: false })
      this.requestSuccess( 'Student Vacancies info was deleted successfully.' )
    }
    deleteStudentAcceptedVacanciesError = error => this.requestError( error )

    updateStudentAcceptedVacancies = dataToSubmit => {
      delete dataToSubmit.external_supervisor_name
      delete dataToSubmit.internal_supervisor_name
      
      Put(
        `/student_vacancy_accepted_lists/${ dataToSubmit.id }`,
        dataToSubmit,
        this.updateStudentAcceptedVacanciesSuccess,
        this.updateStudentAcceptedVacanciesError,
        this.load
      )
    }
    updateStudentAcceptedVacanciesSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.getStudentAcceptedVacancies( search )
      this.setState({ showUpdateStudentAcceptedVacanciesModal: false })
      this.requestSuccess( 'Student Vacancies info was updated successfully.' )
    }
    updateStudentAcceptedVacanciesError = error => this.requestError( error )

    uploadFinalReport = ( id, dataToSubmit, pondRef ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/student_vacancy_accepted_lists/report/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'report', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
       Axios.post(url, tempForm, config ).then(() => {
        this.load( false )
         this.uploadFinalReportSuccess( id, pondRef )
       }).catch( err => {
        this.load( false )
        this.uploadFinalReportError( err )
       })
    }
    uploadFinalReportSuccess = ( id, pondRef ) => {
      pondRef.current.removeFiles()
      this.getSelectedStudentAcceptedVacancies( id )
      this.requestSuccess( 'Final report has been uploaded succesfuly.' )
    }
    uploadFinalReportError = error => this.requestError( error )

    getStudentPlacementForm_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/placement_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_PlacementForm.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    getStudentReportDuty_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/student_report_duty_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_ReportDuty.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    getStudentJobScopeForm_pdf = (id, name) => {
      this.load(true);
      let token = Cookies.get("USM_IMS_TOKEN");
      const url = `${getDomainURL()}/student_job_scope_forms/pdf/${id}`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        responseType: "blob",
      };
      Axios.get(url, config)
        .then((response) => {
          this.load(false);
          FileSaver.saveAs(response.data, `${name}_JobScopeForm.pdf`);
        })
        .catch((err) => {
          this.load(false);
          this.requestError(err);
        });
    };

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          selectedAcceptedVacancies={ this.state.selectedAcceptedVacancies }
          jobScope={ this.state.jobScope }
          insurances={ this.state.insurances }
          studentAcceptedVacancies={ this.state.studentAcceptedVacancies }
          onLoadStudentAcceptedVacancies={ this.state.loading }
          searchParams={ this.state.searchParams }
          showSearchForms={ this.state.showSearchForms }
          studentAcceptedVacanciesDocHeader={ this.state.studentAcceptedVacanciesDocHeader }
          showStudentAcceptedVacanciesDocModal={ this.state.showStudentAcceptedVacanciesDocModal }
          showUpdateStudentAcceptedVacanciesModal={ this.state.showUpdateStudentAcceptedVacanciesModal }
          showDeleteStudentAcceptedVacanciesModal={ this.state.showDeleteStudentAcceptedVacanciesModal }
          
          uploadFinalReport={ this.uploadFinalReport }
          deleteStudentAcceptedVacancies={ this.deleteStudentAcceptedVacancies }
          getStudentAcceptedVacancies={ this.getStudentAcceptedVacancies }
          getJobScopes={ this.getJobScopes }
          getInsurances={ this.getInsurances }
          getSelectedStudentWeeklyLog={ this.getSelectedStudentWeeklyLog }
          getSelectedStudentOfferLetters={ this.getSelectedStudentOfferLetters }
          getSelectedStudentPlacementForm={ this.getSelectedStudentPlacementForm }
          getSelectedStudentDutyReport={ this.getSelectedStudentDutyReport }
          getSelectedStudentInsuranceForm={ this.getSelectedStudentInsuranceForm }
          getSelectedStudentJobScopeForm={ this.getSelectedStudentJobScopeForm }
          getSelectedStudentAcceptedVacancies={ this.getSelectedStudentAcceptedVacancies }
          getSelectedStudentEvaluation={ this.getSelectedStudentEvaluation }
          getSelectedStudentVisitSchedule={ this.getSelectedStudentVisitSchedule }
          updateStudentAcceptedVacancies={ this.updateStudentAcceptedVacancies }
          getStudentPlacementForm_pdf={ this.getStudentPlacementForm_pdf }
          getStudentReportDuty_pdf={ this.getStudentReportDuty_pdf }
          getStudentJobScopeForm_pdf={ this.getStudentJobScopeForm_pdf }
          onChangeStudentAcceptedVacanciesHOC={ this.onChangeStudentAcceptedVacanciesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC