import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse, Modal, ModalHeader } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'
import CreateScheduleForm from './Create'
import EditScheduleForm from './Edit'
import UploadGuideline from './UploadGuideline'

import EvaluationsHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class Evaluations extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer
    const { current_role_id } = this.props.data.ProfileReducer
    const contact_person_email = this.props.data.ProfileReducer.profile.company?.contact_person_email || ""
    const { id, email } = this.props.data.ProfileReducer.profile.user

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "ief.session_id": ${ current_selected_session_id }, "ief.type": "External" } }`) )
      } else if (current_role_id === 14) {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "sval.external_supervisor_id": ${ id }, "ief.session_id": ${ current_selected_session_id }, "ief.type": "External" } }`) )
      } else if (current_role_id === 15) {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "ief.session_id": ${ current_selected_session_id }, "ief.type": "Internal" } }`) )
      } else {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "ief.session_id": ${ current_selected_session_id } } }`) )
      }
      this.props.getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ current_selected_session_id } }}`))
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      if (!_.isEmpty(contact_person_email) && current_role_id === 14 && email === contact_person_email) {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "ief.session_id": ${ current_selected_session_id }, "ief.type": "External" } }`) )
      } else if (current_role_id === 14) {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "sval.external_supervisor_id": ${ id }, "ief.session_id": ${ current_selected_session_id }, "ief.type": "External" } }`) )
      } else if (current_role_id === 15) {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "ief.session_id": ${ current_selected_session_id }, "ief.type": "Internal" } }`) )
      } else {
        this.props.getEvaluations( btoa(`{ "page": 1, "filter":{ "ief.session_id": ${ current_selected_session_id } } }`) )
      }
      this.props.getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ current_selected_session_id } }}`))
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    
    return (
      <>
        <PageTitle
          heading="Evaluation Form"
          subheading="Listings of all the Evaluation Form present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            ... ( [ 1, 11, 12 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0  ? [{
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeEvaluationsHOC( 'showUploadEvaluationGuideline', true )
              },
              content:<>
                <i className="pe-7s-cloud-upload btn-icon-wrapper"> </i>
                <span>Upload Evaluation Guideline</span>
              </>
            }] : []),
            ... ( [ 14, 15 ].indexOf( this.props.data.ProfileReducer.current_role_id ) >= 0  ? [{
              className: 'bg-transparent border-0 p-0',
              content:<>
                <Button 
                  id='DownloadEvaluationGuideline' color='warning'
                  className='mr-2 btn-icon btn-icon-only'
                  onClick={() => {
                    window.open(this.props.sessions?.student_evaluation_form_guidelines_url)
                  }}
                >
                  <i className="pe-7s-cloud-download btn-icon-wrapper">
                </i></Button>
                <UncontrolledTooltip target="DownloadEvaluationGuideline" placement="left">Download Evaluation Guideline</UncontrolledTooltip>
              </>
            }] : []),
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeEvaluationsHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... ( true ? [{
              color: 'primary',
              onClick: () => {
                this.props.onChangeEvaluationsHOC( 'showCreateEvaluationsModal', true )
              },
              content: 'Create'
            }] : [])
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeEvaluationsHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeEvaluationsHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 'ief.session_id' )

                this.props.getEvaluations( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.evaluation.data.length < 1? 3: 
                      this.props.evaluation.data.length < 10? this.props.evaluation.data.length:
                      10
                    }
                    data={ this.props.evaluation.data }
                    columns={[
                      {
                        Header: 'Company',
                        accessor: 'company_name'
                      },
                      {
                        Header: 'Student',
                        accessor: 'user_name'
                      },
                      {
                        Header: 'Type',
                        accessor: 'type'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="DownloadEvaluation"
                                className="mr-2 btn-icon btn-icon-only"
                                color="warning"
                                onClick={() => this.props.getStudentEvaluationForm_pdf(row.original.id, row.original.user_name, row.original.type) }
                              >
                                <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DownloadEvaluation" placement="left">Download this evaluation form</UncontrolledTooltip>
                              <Button
                                id="UpdateEvaluation"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.getSelectedEvaluation(row.original.id, 'edit' )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateEvaluation" placement="left">Update the information of this evaluation form</UncontrolledTooltip>
                              <Button
                                id="DeleteEvaluation"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? "danger" : 'secondary' }
                                disabled={ !is_delete }
                                onClick={() => {
                                  this.props.onChangeEvaluationsHOC( 'showDeleteEvaluationModal', true )
                                  this.props.onChangeEvaluationsHOC( 'selectedEvaluation', row.original )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteEvaluation" placement="left">Delete this evaluation form</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.evaluation.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getEvaluations( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={ this.props.showUploadEvaluationGuideline }>
            <ModalHeader toggle={ () => this.props.onChangeEvaluationsHOC( 'showUploadEvaluationGuideline', false )}>
              Edit Evaluation Form Guideline
            </ModalHeader>
            <UploadGuideline
              data={ this.props.data }
              session={ this.props.sessions }
              onLoadForm={ this.props.onLoadEvaluation }
              uploadEvaluationGuideline={ this.props.uploadEvaluationGuideline }
            />
          </Modal>
          <EditScheduleForm
            { ... this.props }
            selectedData={ this.props.selectedEvaluation }
            open={ this.props.showUpdateEvaluationsModal }  
            onLoadForm={ this.props.onLoadEvaluation }

            onClose={ () => this.props.onChangeEvaluationsHOC( 'showUpdateEvaluationsModal', false )}
            onClickSubmit={ this.props.updateEvaluation }
          />
          <CreateScheduleForm
            { ... this.props }
            open={ this.props.showCreateEvaluationsModal }  
            onLoadForm={ this.props.onLoadEvaluation }

            onClose={ () => this.props.onChangeEvaluationsHOC( 'showCreateEvaluationsModal', false )}
            onClickSubmit={ this.props.createEvaluation }
          />
          <ConfirmationModal
            loading={ this.props.onLoadEvaluation }
            open={ this.props.showDeleteEvaluationModal}
            title={ `Delete Evaluation Form` }
            message={ `Are you sure you want to delete this evaluation form? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeEvaluationsHOC( 'showDeleteEvaluationModal', false )}
            onClickConfirm={ () => this.props.deleteEvaluation( this.props.selectedEvaluation.id )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadEvaluation && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  EvaluationsHOC
)(Evaluations)