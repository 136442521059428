import { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";

import { DefaultFormValue } from "./assets";
import { storeLastView } from "actions/lastView";
import { Get, Post } from "utils/axios";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			vacancies: [],
			students: [],
			vacancyForm: DefaultFormValue,
		};

		onChangeVacancyHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });
		requestError = (error) => toast.error(error);
		requestSuccess = (success) => toast.success(success);

		getVacancies = (search) =>
			Get(
				`/company_vacancies?query=${search}`,
				this.getVacanciesSuccess,
				this.getVacanciesError,
				this.load,
				this.props.data.ProfileReducer.current_role_id
			);
		getVacanciesSuccess = (payload) => {
			let tempData = [];
			payload.data &&
				payload.data.map((item) => {
					tempData.push({
						name: `${item.company_vacancy.job_title} - ${item.company.name}`,
						...item.company_vacancy,
					});
				});

			this.setState({
				vacancies: tempData,
			});
		};
		getVacanciesError = (error) => this.requestError(error);

		getStudents = (search) =>
			Get(
				`/students?query=${search}&filter_role_id=[13]`,
				this.getStudentsSuccess,
				this.getStudentsError,
				this.load
			);
		getStudentsSuccess = (payload) => {
			let tempData = _.map(payload?.data ?? [], ({ user, student }) => {
				return {
					id: student.id,
					name: user.name,
					value: student.id,
					email: user.email,
					phone: user.phone,
					address: student.address,
					cgpa: student.cgpa,
					ic: student.ic,
					matric_number: student.matric_number,
					specialization: student.specialization,
					session_id: student.session_id,
					at_cgpa: student.at_cgpa,
					academic_status: student.academic_status,
					accumulated_credits: student.accumulated_credits,
					is_accepted_vacancy: student.is_accepted_vacancy,
				};
			});
			this.setState({ students: tempData });
		};
		getStudentsError = (error) => this.requestError(error);

		applyStudentVacancies = (dataToSubmit) =>
			Post(
				`/student_vacancies`,
				dataToSubmit,
				this.applyStudentVacanciesSuccess,
				this.applyStudentVacanciesError,
				this.load
			);
		applyStudentVacanciesSuccess = () => {
			this.setState({
				vacancyForm: DefaultFormValue,
			});
			this.requestSuccess("Vacancies has been applied successfully.");
		};
		applyStudentVacanciesError = (error) => this.requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					{...this.state}
					vacancyForm={this.state.vacancyForm}
					onLoadVacancy={this.state.loading}
					applyStudentVacancies={this.applyStudentVacancies}
					getStudents={this.getStudents}
					getVacancies={this.getVacancies}
					onChangeVacancyHOC={this.onChangeVacancyHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, {
		storeLastView,
	})(WithHOC);
};

export default HOC;
