import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateForm from './Create'
import EditForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import WeeklyLogHOC from './actions'
import StudentHOC from './actions/student'
import AcceptedListHOC from './actions/acceptedList'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class WeeklyLog extends Component {

  componentDidUpdate = pp => {
    const { data, searchParams } = this.props
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getWeeklyLog( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'students.session_id' ))) )

      if ( data.ProfileReducer.current_role_id === 13  ){
        this.props.getAcceptedList( btoa(`{"page":1, "filter": { "student_vacancies.student_id": ${ data.ProfileReducer.profile.student.id } }}`) )
      }
      
      if ( data.ProfileReducer.current_role_id !== 13  ){
        this.props.getStudents( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
      }
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getWeeklyLog( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'students.session_id' ))) )

      if ( data.ProfileReducer.current_role_id !== 13  ){
        this.props.getStudents( btoa( JSON.stringify( sessionQueryHandler( { "page": 1, "per_page": 1000 }, current_selected_session_id, 'students.session_id' ))) )
      }
    }

    if ( pp.students !== this.props.students ){
      let tempSearch = _.cloneDeep( searchParams )
        tempSearch.push( ... [
          {
            label: 'Student Name', 
            value: 'users.name',
            type: 'text',
            param: ''
          },
          {
            label: 'Status', 
            value: 'weekly_logs.status',
            type: 'select',
            valueType: 'string',
            param: '',
            options: [
              { id: 'Started', name: 'Started' },
              { id: 'On-going', name: 'On-going' },
              { id: 'Complete', name: 'Complete' },
            ]
          }
        ])
      this.props.onChangeWeeklyLogHOC( 'searchParams', tempSearch )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <PageTitle
          heading="Weekly Logs"
          subheading="Listings of all the weekly logs present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeWeeklyLogHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
           ... (
            is_create &&
            this.props.selectedAcceptedList &&
             this.props.data.ProfileReducer.current_role_id === 13 
              ?  [{
                  color: 'primary',
                  onClick: () => {
                    this.props.onChangeWeeklyLogHOC( 'showCreateWeeklyLogModal', true )
                  },
                  content: 'Create'
                }]
              : []
           )
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeWeeklyLogHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeWeeklyLogHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 'students.session_id' )
                
                this.props.getWeeklyLog( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.weeklyLogs.data.length < 1? 3: 
                      this.props.weeklyLogs.data.length < 10? this.props.weeklyLogs.data.length:
                      10
                    }
                    data={ this.props.weeklyLogs.data }
                    columns={[
                      {
                        Header: 'Date',
                        accessor: 'date'
                      },
                      {
                        Header: 'Status',
                        Cell: row => (
                          <span className={ `badge badge-${ row.original.status === 'Complete' ? 'success': "secondary" }` }>
                            { row.original.status }
                          </span>
                        )
                      },
                      {
                        Header: 'Student Name',
                        accessor: 'student_name'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateWeeklyLog"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.getSelectedWeeklyLog(row.original.weekly_log.id, 'edit' )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateWeeklyLog" placement="left">Update the information of this weekly log</UncontrolledTooltip>
                              <Button
                                id="DeleteWeeklyLog"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? "danger" : 'secondary' }
                                disabled={ !is_delete }
                                onClick={() => {
                                  Promise.all([
                                    this.props.onChangeWeeklyLogHOC( 'showDeleteWeeklyLogModal', true )
                                  ]).then(() => {
                                    this.props.onChangeWeeklyLogHOC( 'selectedWeeklyLog', row.original )
                                  })
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteWeeklyLog" placement="left">Delete this weekly Log</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.weeklyLogs.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getWeeklyLog( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CreateForm 
            { ... this.props }
            selectedAcceptedList={ this.props.selectedAcceptedList }
            onLoadWeeklyLog={ this.props.onLoadWeeklyLog }
            open={ this.props.showCreateWeeklyLogModal }
            onClose={ () => this.props.onChangeWeeklyLogHOC( 'showCreateWeeklyLogModal', false )}
            onClickSubmit={ this.props.createWeeklyLog }
          />
          <EditForm
            { ... this.props }
            selectedWeeklyLog={ this.props.selectedWeeklyLog }
            onLoadWeeklyLog={ this.props.onLoadWeeklyLog }
            open={ this.props.showUpdateWeeklyLogModal }
            onClose={ () => this.props.onChangeWeeklyLogHOC( 'showUpdateWeeklyLogModal', false )}
            onClickSubmit={ this.props.updateWeeklyLog }
          />
          <ConfirmationModal
            loading={ this.props.onLoadWeeklyLog }
            open={ this.props.showDeleteWeeklyLogModal}
            title={ `Delete Weekly Log` }
            message={ `Are you sure you want to delete this weekly logs? (This process can't be undone)` }
          
            onClose={ () => this.props.onChangeWeeklyLogHOC( 'showDeleteWeeklyLogModal', false )}
            onClickConfirm={ () => this.props.deleteWeeklyLog( this.props.selectedWeeklyLog.id )}/>
        </ReactCSSTransitionGroup>
        { ( this.props.onLoadWeeklyLog || 
            this.props.onLoadAcceptedList
          ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  WeeklyLogHOC,
  StudentHOC,
  AcceptedListHOC
)(WeeklyLog)