import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Button, UncontrolledTooltip, Collapse,
  Modal, ModalHeader
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'
import CreateScheduleForm from './Create'
import EditScheduleForm from './Edit'

import VisitSchedulesHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class VisitSchedules extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getVisitSchedules( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'session_id' ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getVisitSchedules( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'session_id' ))) )
    }
  }

  renderNonStudentContent = () => {
    const { is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <Collapse
          isOpen={ this.props.showSearchForms }
          onExiting={ () => this.props.onChangeVisitSchedulesHOC( 'showSearchForms', false )}
        >
          <SearchForm
            searchParams={ this.props.searchParams }
            onChangeHOCState={ this.props.onChangeVisitSchedulesHOC }
            getListAPI={ val => {
              let tempJSON = JSON.parse( atob( val ))
              tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 'session_id' )

              this.props.getVisitSchedules( btoa( JSON.stringify( tempJSON )) )
            }}
          />
        </Collapse>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    this.props.visitSchedule.data.length < 1? 3: 
                    this.props.visitSchedule.data.length < 10? this.props.visitSchedule.data.length:
                    10
                  }
                  data={ this.props.visitSchedule.data }
                  columns={[
                    {
                      Header: 'Company',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'Student',
                      accessor: 'user_name'
                    },
                    {
                      Header: 'Date',
                      accessor: 'date'
                    },
                    {
                      Header: 'Start Time',
                      accessor: 'start_time'
                    },
                    {
                      Header: 'End Time',
                      accessor: 'end_time'
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="UpdateVisitSchedule"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_update ? "primary" : 'secondary' }
                              disabled={ !is_update }
                              onClick={() => {
                                this.props.getSelectedVisitSchedule(row.original.id, 'edit' )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="UpdateVisitSchedule" placement="left">Update the information of this visit schedule</UncontrolledTooltip>
                            <Button
                              id="DeleteVisitSchedule"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_delete ? "danger" : 'secondary' }
                              disabled={ !is_delete }
                              onClick={() => {
                                this.props.onChangeVisitSchedulesHOC( 'showDeleteVisitScheduleModal', true )
                                this.props.onChangeVisitSchedulesHOC( 'selectedVisitSchedule', row.original )
                              }}>
                              <i className="pe-7s-close btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="DeleteVisitSchedule" placement="left">Delete this Visit Schedule</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={false} />
                <Pagination 
                  metadata={ this.props.visitSchedule.meta } 
                  onChangePage={ pg => {
                    const { search } = this.props.data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    this.props.getVisitSchedules( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={ this.props.showUpdateVisitSchedulesModal }
          size={ 'xl' }>
          <ModalHeader 
            toggle={() => this.props.onChangeVisitSchedulesHOC( 'showUpdateVisitSchedulesModal', false )}
          >
            { `Edit Lectuter Visit Schedule` }
          </ModalHeader>
          <EditScheduleForm
            data={ this.props.data }
            selectedData={ this.props.selectedVisitSchedule }
            onLoadForm={ this.props.onLoadVisitSchedule }

            onClickSubmit={ this.props.updateVisitSchedule }
          />
        </Modal>
        <ConfirmationModal
          loading={ this.props.onLoadVisitSchedule }
          open={ this.props.showDeleteVisitScheduleModal}
          title={ `Delete Visit Schedule` }
          message={ `Are you sure you want to delete this visit schedule? (This process can't be undone)` }
        
          onClose={ () => this.props.onChangeVisitSchedulesHOC( 'showDeleteVisitScheduleModal', false )}
          onClickConfirm={ () => this.props.deleteVisitSchedule( this.props.selectedVisitSchedule.id )}/>
      </>
    )
  }

  render = () => {
    const { is_create } = this.props.currentPermission
    
    return (
      <>
        <PageTitle
          heading="Visit Schedules"
          subheading="Listings of all the Visit Schedule present in the system."
          icon="pe-7s-display2 icon-gradient bg-happy-itmeo"
          buttons={[
            ... (
              this.props.data.ProfileReducer.current_role_id !== 13
            ) ? [{
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeVisitSchedulesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },] : [],
            ... (( is_create && (
              this.props.data.ProfileReducer.current_role_id === 13 && this.props.visitSchedule.data < 1 ||
              this.props.data.ProfileReducer.current_role_id !== 13
            ))? [{
              color: 'primary',
              onClick: () => {
                this.props.onChangeVisitSchedulesHOC( 'showCreateVisitSchedulesModal', true )
              },
              content: 'Create'
            }] : [])
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent() }
          { this.props.data.ProfileReducer.current_role_id === 13 && 
            this.props.visitSchedule.data.length > 0 && ( 
            <>
              {
                _.keys( this.props.selectedVisitSchedule ).length > 0 && (
                  <Card>
                    <EditScheduleForm
                      data={ this.props.data }
                      selectedData={ this.props.selectedVisitSchedule }
                      onLoadForm={ this.props.onLoadVisitSchedule }
            
                      onClickSubmit={ this.props.updateVisitSchedule }
                    />
                  </Card>
                )
              }
              {
                _.keys( this.props.selectedVisitSchedule ).length < 1 && (
                  <Card>
                    <CardHeader>No Visit Schedule is available. </CardHeader>
                  </Card>
                )
              }
            </>
          )}
        </ReactCSSTransitionGroup>
        <CreateScheduleForm
          open={ this.props.showCreateVisitSchedulesModal }  
          onLoadForm={ this.props.onLoadVisitSchedule }

          onClose={ () => this.props.onChangeVisitSchedulesHOC( 'showCreateVisitSchedulesModal', false )}
          onClickSubmit={ this.props.createVisitSchedule }
        />
        { this.props.onLoadVisitSchedule && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  VisitSchedulesHOC
)(VisitSchedules)