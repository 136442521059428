import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Collapse } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import SecModuleForm from './Form'
import Pagination from 'components/Pagination'

import SecModulesHOC from './actions'

class SecModules extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getSecModules( btoa(`{"page":1}`) )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <PageTitle
          heading="Sec Modules"
          subheading="Listings of all the sec modules present in the system."
          icon="pe-7s-box2 icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeSecModulesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeSecModulesHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeSecModulesHOC }
              getListAPI={ this.props.getSecModules }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.secModules.data.length < 1? 3: 
                      this.props.secModules.data.length < 10? this.props.secModules.data.length:
                      10
                    }
                    data={ this.props.secModules.data }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateSecModule"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? 'primary' : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.getSelectedSecModule(row.original.id, 'edit' )
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateSecModule" placement="left">Update the information of this sec module</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={false} />
                  <Pagination 
                    metadata={ this.props.secModules.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getSecModules( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <SecModuleForm
            {...this.props}
            mode={ 'Create' }
            open={ this.props.showCreateSecModulesModal }
            onClose={ () => this.props.onChangeSecModulesHOC( 'showCreateSecModulesModal', false )}
            onClickSubmit={ this.props.createSecModule }
          />
          <SecModuleForm
            {...this.props}
            mode={ 'Edit' }
            selectedFormData={ this.props.selectedSecModule }
            open={ this.props.showUpdateSecModulesModal }
            onClose={ () => this.props.onChangeSecModulesHOC( 'showUpdateSecModulesModal', false )}
            onClickSubmit={ this.props.updateSecModule }
          />
        </ReactCSSTransitionGroup>
        { this.props.onLoadSecModule && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  SecModulesHOC
)(SecModules)