import React, { useEffect } from 'react'

import { 
  Nav, NavItem,
  Card, CardHeader, CardBody 
} from 'reactstrap'
import Pagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import DetailsModal from './Details'

import AnnouncementHOC from './actions/announcement'
import sessionQueryHandler from 'utils/sessionQueryHandler'

const Announcement = ({
  current_selected_session_id,
  selectedAnnouncement,
  announcements,
  onLoadAnnouncement,

  getAnnouncements,
  showAnnouncementDetails,
  onChangeAnnouncementsHOC
}) => {

  useEffect(() => {
    getAnnouncements( btoa( JSON.stringify(sessionQueryHandler( { "page": 1 }, current_selected_session_id, 'session_id' ))) )
  }, [ current_selected_session_id ])

  return (
    <Card className='mb-3 mt-3'>
      <CardHeader style={{ fontSize: '1rem' }}>
        Announcement
      </CardHeader>
      <CardBody>
        <Nav vertical>
        {
          announcements.meta.total_count < 1 && (
            <NavItem className="text-center mt-3">
              <h6 className={ 'fw-600' }>No announcement is available.</h6>
            </NavItem>
          )
        }
        {
          announcements.meta.total_count > 0 && (
            <>
              {
                announcements.data?.[0] && announcements.data.map( data => (
                  <NavItem 
                    key={ data.id }
                    className="card nav-item-btn position-relative mb-2"
                    style={{ cursor: 'pointer' }}
                    onClick={ () => {
                      onChangeAnnouncementsHOC( 'selectedAnnouncement', data )
                      onChangeAnnouncementsHOC( 'showAnnouncementDetails', true )
                    }}
                  >
                    <div className="d-flex align-items-center mb-2">
                      <i className={ 'pe-7s-speaker' } style={{ fontSize: 19, marginRight: 10 }}/>
                      <strong style={{  fontSize: '0.95rem' }}>
                        { data.title }
                      </strong>
                    </div>
                    <span className={ 'ims-announcement-desc' }>
                      { `${ data.description.slice( 0, 150 ) } ${ data.description.length > 150 ? '...' : '' }`}
                    </span>
                  </NavItem>
                ))
              }
              <Pagination 
                metadata={ announcements.meta } 
                onChangePage={ pg => getAnnouncements( btoa( JSON.stringify(sessionQueryHandler( { "page": pg }, current_selected_session_id, 'session_id' ))))} 
              />
            </>
          )
        }
        </Nav>
        <DetailsModal
          header={  selectedAnnouncement?.title??'' }
          open={ showAnnouncementDetails }
          content={ selectedAnnouncement?.description??'' }
          onClose={ () => onChangeAnnouncementsHOC( 'showAnnouncementDetails', false )}
        />
        { 
          onLoadAnnouncement && <LoadingOverlay />
        }
      </CardBody>
    </Card>
  )
}

export default AnnouncementHOC( Announcement );