import React, { useEffect } from 'react'
import _ from 'lodash'
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap'
import { 
  AiFillCalendar
} from 'react-icons/ai'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import VisitHOC from './actions/visitSchedule'

const VisitSchedule = ({
  studentVisitSchedule,
  onLoadVisitSchedule,
  
  getVisitSchedules
}) => {

  useEffect(() => {
    getVisitSchedules( btoa(`{"page":1}`))
  }, [])

  return (
    <>
      <Card className='mb-3 mr-3' style={{ 
        width: 'max-content', 
        background: 'rgb(247, 185, 36,0.9)', boxShadow: 'none', display: 'inline-block'
      }}>
        <CardBody>
          {
            _.keys( studentVisitSchedule ).length < 1 && (
              <strong>No Visit Schedule is available.</strong>
            ) 
          }
          {
            _.keys( studentVisitSchedule ).length > 0 && (
              <div className='d-flex align-items-center'>  
                <AiFillCalendar 
                  id={ 'ScheduleCalendar' }
                  className='mr-3'
                  style={{ width: 35, height: 35, fill: '#ffff' }}
                />
                <UncontrolledTooltip target="ScheduleCalendar" placement="left">Lecturer Visit Schedule</UncontrolledTooltip>
                <div>
                  <div className="d-flex align-items-center">
                    <h2 style={{ fontWeight: 500 }}>
                      { studentVisitSchedule.date.format( 'Do' ) }
                    </h2>
                    <h6 style={{ fontWeight: 500, marginLeft: 5 }}>
                      { studentVisitSchedule.date.format( 'MMM' ) }
                    </h6>
                  </div>
                  <p className='mb-0'>
                    { `${ studentVisitSchedule.start_time } - ${ studentVisitSchedule.end_time }` }
                  </p>
                </div>
              </div>
            )
          }
        </CardBody>
      </Card>
      { onLoadVisitSchedule && <LoadingOverlay/> }
    </>
  )
}

export default VisitHOC( VisitSchedule );
