import React, { Component } from "react";
import { compose } from "redux";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {
	Collapse,
	Row,
	Col,
	Card,
	CardBody,
	CardHeader,
	CardFooter,
	Button,
	UncontrolledTooltip,
	ListGroup,
} from "reactstrap";
import ReactTable from "react-table";
import CountUp from "react-countup";
import _ from "lodash";

import SearchForm from "components/searchForm";
import Pagination from "components/Pagination";
import PageTitle from "components/Title/PageTitle";
import CustomListItem from "./components/listItem";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import DetailForm from "./Details";
import ConfirmationModal from "components/Modal/confirmation";
import ExtendedStudentVacanciesModal from "./VacanciesModal";

import StudentVacancyStatus from "actions/hoc/studentVacancyStatus";
import StudentVacanciesHOC from "./actions";
import CompanyVacanciesHOC from "./actions/company";
import sessionQueryHandler from "utils/sessionQueryHandler";

class StudentVacancies extends Component {
	componentDidUpdate = (pp) => {
		const { current_selected_session_id } = this.props.data.SessionReducer;

		if (
			pp.currentPermission !== this.props.currentPermission &&
			this.props.currentPermission.is_read
		) {
			this.props.getCompanyVacancies(
				btoa(
					JSON.stringify(
						sessionQueryHandler(
							{ page: 1 },
							current_selected_session_id,
							"company_vacancies.session_id"
						)
					)
				)
			);
			this.props.getStudentVacancies(
				btoa(
					JSON.stringify(
						sessionQueryHandler(
							{ page: 1, per_page: 5 },
							current_selected_session_id,
							"students.session_id"
						)
					)
				)
			);
			this.props.getStudentVacancyStatus(btoa(`{"page":1 }`));
		}

		if (
			pp.data.SessionReducer.current_selected_session_id !==
				current_selected_session_id &&
			current_selected_session_id
		) {
			this.props.getCompanyVacancies(
				btoa(
					JSON.stringify(
						sessionQueryHandler(
							{ page: 1 },
							current_selected_session_id,
							"company_vacancies.session_id"
						)
					)
				)
			);
			this.props.getStudentVacancies(
				btoa(
					JSON.stringify(
						sessionQueryHandler(
							{ page: 1, per_page: 5 },
							current_selected_session_id,
							"students.session_id"
						)
					)
				)
			);
		}
	};

	render = () => {
		const { is_create } = this.props.currentPermission;

		return (
			<>
				<PageTitle
					heading="Vacancies"
					subheading="Please create your own CV, you have the permission to apply for any vacancy."
					icon="pe-7s-culture icon-gradient bg-happy-itmeo"
					buttons={[
						{
							color: "warning",
							className: "btn-icon mr-2",
							onClick: () => {
								this.props.onChangeStudentVacanciesHOC(
									"showSearchForms",
									!this.props.showSearchForms
								);
							},
							content: (
								<>
									<i className="pe-7s-search btn-icon-wrapper"> </i>
									<span>Search</span>
								</>
							),
						},
					]}
				/>
				<ReactCSSTransitionGroup
					component="div"
					transitionName="TabsAnimation"
					transitionAppear={true}
					transitionAppearTimeout={0}
					transitionEnter={false}
					transitionLeave={false}>
					<Collapse
						isOpen={this.props.showSearchForms}
						onExiting={() =>
							this.props.onChangeStudentVacanciesHOC("showSearchForms", false)
						}>
						<SearchForm
							searchParams={this.props.searchParams}
							onChangeHOCState={this.props.onChangeStudentVacanciesHOC}
							getListAPI={(val) => {
								let tempJSON = JSON.parse(atob(val));
								tempJSON = sessionQueryHandler(
									tempJSON,
									this.props.data.SessionReducer.current_selected_session_id,
									"company_vacancies.session_id"
								);

								this.props.getCompanyVacancies(btoa(JSON.stringify(tempJSON)));
							}}
						/>
					</Collapse>
					<Row>
						<Col md="12" lg="8">
							<Card className="main-card mb-3">
								<CardBody>
									<ReactTable
										pageSize={
											this.props.companyVacancies.data.length < 1
												? 3
												: this.props.companyVacancies.data.length < 10
												? this.props.companyVacancies.data.length
												: 10
										}
										data={this.props.companyVacancies.data}
										columns={[
											{
												Header: "Company Name",
												accessor: "company_name",
											},
											{
												Header: "Job Title",
												accessor: "job_title",
											},
											{
												Header: "No. of positions available",
												accessor: "vacancy_number",
											},
											{
												Header: "Valid Until",
												accessor: "valid_until_date",
											},
											{
												Header: "Actions",
												Cell: (row) => {
													return (
														<>
															{this.props.data.ProfileReducer
																.current_role_id === 13 && (
																<>
																	<Button
																		id="ApplyStudentVacancies"
																		className="mr-2 btn-icon btn-icon-only"
																		color={
																			row.original.has_applied || !is_create
																				? "secondary"
																				: "success"
																		}
																		disabled={
																			row.original.has_applied ||
																			!is_create ||
																			!this.props.data.ProfileReducer.profile
																				.student.has_cv
																		}
																		onClick={() => {
																			this.props.onChangeStudentVacanciesHOC(
																				"showApplyConfirmation",
																				true
																			);
																			this.props.onChangeCompanyHOC(
																				"selectedCompanyVacancies",
																				row.original.id
																			);
																		}}>
																		Apply
																	</Button>
																	<UncontrolledTooltip
																		target="ApplyStudentVacancies"
																		placement="left">
																		Apply for this company vacancy
																	</UncontrolledTooltip>
																</>
															)}
															<Button
																id="InfoStudentVacancies"
																className="mr-2 btn-icon btn-icon-only"
																color="primary"
																onClick={() =>
																	this.props.getSelectedCompanyVacancies(
																		row.original.id
																	)
																}>
																<i className="pe-7s-info btn-icon-wrapper"> </i>
															</Button>
															<UncontrolledTooltip
																target="InfoStudentVacancies"
																placement="left">
																Show company vacancy details
															</UncontrolledTooltip>
														</>
													);
												},
											},
										]}
										showPagination={false}
									/>
									<Pagination
										metadata={this.props.companyVacancies.meta}
										onChangePage={(pg) => {
											const { search } =
												this.props.data.LastViewReducer.lastView;
											let tempJSON = JSON.parse(atob(search));
											tempJSON.page = pg;

											this.props.getCompanyVacancies(
												btoa(JSON.stringify(tempJSON))
											);
										}}
									/>
								</CardBody>
							</Card>
						</Col>
						<Col md="8" lg="4">
							<Card className="widget-chart text-left mb-3 card-btm-border card-shadow-warning border-warning">
								<div className="widget-chat-wrapper-outer">
									<div className="widget-title opacity-8 text-uppercase">
										Total Applied Vacancies
									</div>
									<div className="widget-numbers mt-2 fsize-5 mb-0 w-100">
										<div
											className="widget-chart-flex align-items-center"
											style={{ fontSize: "2.4rem" }}>
											<CountUp
												className={"text-primary"}
												start={-10}
												end={this.props.studentVacancies.meta.total_count}
												separator=""
												decimals={0}
												decimal=","
												duration="3"
											/>
										</div>
									</div>
								</div>
							</Card>
							<Card className="card-btm-border card-shadow-warning border-primary">
								<CardHeader>Latest Application</CardHeader>
								{this.props.studentVacancies.meta.total_count < 1 && (
									<CardBody>
										<p>No vacancies is available.</p>
									</CardBody>
								)}
								{true && (
									<>
										<CardBody style={{ maxWidth: 800, overflow: "scroll" }}>
											<ListGroup>
												{this.props.studentVacancies.data.map((item) => (
													<CustomListItem
														header={item.company_name}
														subheading={item.job_title}
														status={item.student_vacancy_status}
													/>
												))}
											</ListGroup>
										</CardBody>
										<CardFooter className="d-block text-center">
											<Button
												disabled={
													this.props.studentVacancies.meta.total_count < 1
												}
												color={
													this.props.studentVacancies.meta.total_count < 1
														? "secondary"
														: "primary"
												}
												className="btn-shadow btn-wide btn-pill"
												onClick={() =>
													this.props.onChangeStudentVacanciesHOC(
														"showFullStudentVacanciesModal",
														true
													)
												}>
												<div className="badge badge-dot badge-dot-lg badge-warning badge-pulse">
													Badge
												</div>
												View All Records
											</Button>
										</CardFooter>
									</>
								)}
							</Card>
						</Col>
					</Row>
					<ExtendedStudentVacanciesModal
						open={this.props.showFullStudentVacanciesModal}
						studentVacancyStatus={this.props.studentVacancyStatus}
						onClose={() =>
							this.props.onChangeStudentVacanciesHOC(
								"showFullStudentVacanciesModal",
								false
							)
						}
					/>
					<DetailForm
						selectedData={this.props.selectedCompanyVacancies}
						companies={[]}
						data={this.props.data}
						open={this.props.showDetailsCompanyVacanciesModal}
						onClose={() =>
							this.props.onChangeCompanyHOC(
								"showDetailsCompanyVacanciesModal",
								false
							)
						}
					/>
					<ConfirmationModal
						open={this.props.showApplyConfirmation}
						title={`Apply for vacancy`}
						loading={this.props.onLoadStudentVacancies}
						message={`Are you sure you want to apply for this vacancy`}
						onClose={() =>
							this.props.onChangeStudentVacanciesHOC(
								"showApplyConfirmation",
								false
							)
						}
						onClickConfirm={() =>
							this.props.applyStudentVacancies({
								company_vacancy_id: this.props.selectedCompanyVacancies,
								student_id: this.props.data.ProfileReducer.profile.student.id,
								student_vacancy_status_id: 1,
							})
						}
					/>
					{(this.props.onLoadStudentVacancies || this.props.onLoadCompany) && (
						<LoadingOverlay />
					)}
				</ReactCSSTransitionGroup>
			</>
		);
	};
}

export default compose(
	CompanyVacanciesHOC,
	StudentVacanciesHOC,
	StudentVacancyStatus
)(StudentVacancies);
