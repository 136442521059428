import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Get } from 'utils/axios' 
import { storeLastView }  from 'actions/lastView'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showSearchForms: false,

      academicAdvisors: [],
      academicAdvisorsList: []
    }
    

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAdvisor = ( search ) => {
      Get(
        `/users?query=${ search }&filter_role_id=[18]`,
        this.getAdvisorSuccess,
        this.getAdvisorError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getAdvisorSuccess = payload => {
      let tempData = _.map( payload.data, item => {
        return ({
          id: item.id,
          name: item?.name??''
        })
      })
      this.setState({ academicAdvisors: tempData })
    }
    getAdvisorError = error => this.requestError( error )

    getAdvisorList = ( search ) => {
      Get(
        `/academic_advisor_students?query=${ search }`,
        this.getAdvisorListSuccess,
        this.getAdvisorListError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getAdvisorListSuccess = payload => {
      let tempData = _.map( payload.data, item => {
        return ({
          id: item?.academic_advisor_student?.id??null,
          advisorName: item?.academic_advisor.name??'',
          studentName: item?.student_user?.name??''
        })
      })
      this.setState({ academicAdvisorsList: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getAdvisorListError = error => this.requestError( error )

    onChangeAdvisorHOC = ( key, val ) => this.setState({ [key]: val })

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          academicAdvisors={ this.state.academicAdvisors }
          academicAdvisorsList={ this.state.academicAdvisorsList }
          onLoadAdvisor={ this.state.loading }

          getAdvisor={ this.getAdvisor }
          getAdvisorList={ this.getAdvisorList }
          onChangeAdvisorHOC={ this.onChangeAdvisorHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    storeLastView
  })( WithHOC )
}

export default HOC