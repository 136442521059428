export const CompanyFieldData = designations => [
  { 
    label: "Name",
    value: 'student_name'
  },
  { 
    label: "Matric No.",
    value: 'student_matric'
  },
  { 
    label: "NRIC",
    value: 'student_nric'
  },
  { 
    label: "Email Address",
    value: 'student_email'
  },
  { 
    label: "Phone No.",
    value: 'student_phone'
  },
  { 
    label: "Specialization",
    value: 'student_specialization',
    col: 12
  },
  { 
    label: 'Company Name',
    subLabel: `Company's name`,
    value: 'company_name'
  },
  { 
    label: 'Company Contact Person',
    subLabel: 'Contact Person',
    value: 'contact_person_name',
    col: 6,
    editable: true,
  },
  { 
    label: 'Company Contact Person Designation',
    subLabel: 'Contact Person Designation',
    value: 'contact_person_designation_id',
    editable: true,
    type: 'select', options: designations,
    col: 6
  },
  { 
    label: 'Company Contact Phone',
    subLabel: 'Contact Person Phone',
    value: 'contact_person_phone_number',
    col: 6,
    editable: true
  },
  { 
    label: 'Company Email',
    subLabel: `Company's email`,
    value: 'contact_person_email_address',
    editable: true
  },  
  { 
    label: 'Company Phone',
    subLabel: `Company's phone contact`,
    value: 'contact_person_mobile',
    editable: true
  },
  { 
    label: 'Street Address',
    subLabel: 'Company of full address',
    value: 'company_address',
    col: 12
  },
  { 
    label: 'Internship Scope',
    value: 'company_vacancy_scope',
    type: 'textarea',
    col: 12
  }
]

export const defaultEditFormValue = {
  users: [],
  company: {
    registration_number: '',
    address: '',
    description: '',
    state: '',
    country: '',
    zipcode: '',
    fax_number: '',
    industry_category_id: '',
    website: '',
    contact_person_name: '',
    contact_person_designation_id: 1,
    contact_person_email_address: '',
    is_read_agreement: true,
    submitted_date_time: '',
  }
}