import React, { useEffect, useState } from 'react'

import { 
  Modal, ModalHeader, ModalBody,
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import UserTabContent from './UserTabContent'
import CompanyCard from './Form'

import DictionaryHOC from '../actions/dictionary'

const tabOptions = [
  { id: 1, label: 'User' },
  { id: 2, label: 'Company' }
]

const EditForm = props => {
  const {
    open,
    onLoadCompany,
    onLoadDictionary,
    selectedCompany,
  
    onClose,
    getCountry,
    getCountryState,
    getIndustryCat
  } = props
  const [ activeTab, onChangeActiveTab ] = useState( 1 )
  
  useEffect(() => {
    if ( open ){
      getIndustryCat() 
      getCountry()
      getCountryState( selectedCompany.company.country )
    }

    if ( !open ) {
      onChangeActiveTab( 1 )
    }
  }, [open])


  return (
    <Modal
      isOpen={ open } size={ 'xl' }
    >
      <ModalHeader toggle={() => onClose()}>{ `Edit Company` }</ModalHeader>
      <ModalBody>
        <div className="d-flex mb-3">
          {
            tabOptions.map( tab => (
              <button
                className={ `btn mr-2 btn-wide ${ activeTab === tab.id ? 'btn-primary' : 'btn-outline-primary' }` }
                onClick={ () => onChangeActiveTab( tab.id )}
              >
                { tab.label }
              </button>
            ))
          }
        </div>
        {
          activeTab === 1 && (
            <UserTabContent
              selectedCompany={ selectedCompany }
              getSelectedCompany={ props.getSelectedCompany }
            />
          )
        }
        {
          activeTab === 2 && (
            <CompanyCard { ... props }/>    
          )
        }
        {( onLoadCompany || onLoadDictionary ) && <LoadingOverlay />}
      </ModalBody>
    </Modal>
  )
}


export default DictionaryHOC( EditForm );