import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get, Post, Put, Delete } from 'utils/axios'
import { storeLastView } from 'actions/lastView'

const searchParams = [
  { 
    label: 'Name', 
    value: 'name',
    type: 'text',
    param: ''
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      secModules: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedSecModule: {},
      searchParams: searchParams,

      showCreateSecModulesModal: false,
      showUpdateSecModulesModal: false,
      showSearchForms: false
    }

    onChangeSecModulesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSecModules = search => {
      this.props.storeLastView({ search })

      Get(
        `/sec_modules?query=${ search }`,
        this.getSecModulesSuccess,
        this.getSecModulesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getSecModulesSuccess = payload => this.setState({ secModules: payload })
    getSecModulesError = error => this.requestError( error )

    getSelectedSecModule = ( id, context ) => {
      Get(
        `/sec_modules/${ id }`,
        payload => this.getSelectedSecModuleSuccess( payload, context ),
        this.getSelectedSecModuleError,
        this.load
      )
    }
    getSelectedSecModuleSuccess = ( payload, context ) => {
      this.setState({   
        selectedSecModule: payload, 
        showUpdateSecModulesModal: context === 'edit' 
      })
    }
    getSelectedSecModuleError = error => this.requestError ( error )

    createSecModule = dataToSubmit => Post(
      `/sec_modules`,
      dataToSubmit,
      this.createSecModuleSuccess,
      this.createSecModuleError,
      this.load
    )
    createSecModuleSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      
      this.getSecModules( search )
      this.setState({ showCreateSecModulesModal: false })
      this.requestSuccess( 'SecModules has been created successfully.' )
    }
    createSecModuleError = error => this.requestError( error )

    updateSecModule = dataToSubmit => Put(
      `/sec_modules/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSecModuleSuccess,
      this.updateSecModuleError,
      this.load
    )
    updateSecModuleSuccess = payload => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.setState({ showUpdateSecModulesModal: false })
      this.getSecModules( search )
      this.getSelectedSecModule( payload.id )
      this.requestSuccess( 'SecModule was updated successfully.' )
    }
    updateSecModuleError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          secModules={ this.state.secModules }
          onLoadSecModule={ this.state.loading }
          selectedSecModule={ this.state.selectedSecModule }
          showSearchForms={ this.state.showSearchForms }
          showCreateSecModulesModal={ this.state.showCreateSecModulesModal }
          showUpdateSecModulesModal={ this.state.showUpdateSecModulesModal }

          getSecModules={ this.getSecModules }
          getSelectedSecModule={ this.getSelectedSecModule }
          createSecModule={ this.createSecModule }
          updateSecModule={ this.updateSecModule }
          onChangeSecModulesHOC={ this.onChangeSecModulesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC