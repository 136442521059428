import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      companyUsers: [],
      lecturers: [],
      onLoadCompanyUsers: false,
      onLoadLecturer: false
    }

    onChangeUserHOC = ( key, val ) => this.setState({ [key]: val })

    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getAllCompanyUsers = search => Get(
      `/companies/users?query=${ search }`,
      this.getAllCompanyUsersSuccess,
      this.getAllCompanyUsersError,
      val => this.setState({ onLoadCompanyUsers: val }),
      this.props.data.ProfileReducer.current_role_id
    )
    getAllCompanyUsersSuccess = payload => this.setState({ companyUsers: payload.data })
    getAllCompanyUsersError = error => this.requestError( error )

    getLecturers = search => Get(
      `/users?query=${ search }&filter_role_id=[15]`,
      this.getLecturersSuccess,
      this.getLecturersError,
      val => this.setState({ onLoadLecturer: val }),
      this.props.data.ProfileReducer.current_role_id
    )
    getLecturersSuccess = payload => {
      let tempData = []

      payload.data?.[0] && payload.data.map( item => {
        tempData.push({
          id: item.id,
          name: item.name,
          email: item.email
        })
      })
      this.setState({ lecturers: tempData })
    }
    getLecturersError = error => this.requestError ( error )

    getSelectedLecturer = id => {
      Get(
        `/users/${ id }`,
        this.getSelectedLecturerSuccess,
        this.getSelectedLecturerError,
        val => this.setState({ onLoadLecturer: val })
      )
    }
    getSelectedLecturerSuccess = payload => this.setState({ 
      lecturers: [{
        id: payload.id,
        name: payload.name,
        email: payload.email
      }], 
    })
    getSelectedLecturerError = error => this.requestError ( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadCompanyUsers={ this.state.onLoadLecturer }
          onLoadLecturer={ this.state.onLoadLecturer }
          companyUsers={ this.state.companyUsers }
          lecturers={ this.state.lecturers }

          getAllCompanyUsers={ this.getAllCompanyUsers }
          getLecturers={ this.getLecturers }
          getSelectedLecturer={ this.getSelectedLecturer }
          onChangeUserHOC={ this.onChangeUserHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC