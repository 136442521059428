export const DefaultFormValue = {
	company_vacancy_id: null,
	student_id: null,
	student_vacancy_status_id: 1,
};

export const StatusOptions = [
	{
		id: 1,
		name: "Pending",
	},
	{
		id: 2,
		name: "Rejected",
	},
	{
		id: 3,
		name: "Call for Interview",
	},
	{
		id: 4,
		name: "Failed",
	},
	{
		id: 5,
		name: "Offered/Interested",
	},
	{
		id: 6,
		name: "Accepted",
	},
	{
		id: 7,
		name: "Declined",
	},
];
