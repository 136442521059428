import React, { Component } from 'react'
import { Col, Row, Button, Label } from 'reactstrap'
import Slider from "react-slick"
import {
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation'
import { GrView, GrFormViewHide } from 'react-icons/gr'

import TemplateContainerMain from 'components/Template'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import bg1 from 'assets/TemplateImages/originals/students.jpeg';
import bg2 from 'assets/TemplateImages/originals/dtsp.jpeg'
import bg3 from 'assets/TemplateImages/originals/usm.jpeg'
import icon from '../../assets/Images/icon.jpeg'

import WithLogin from './actions'

import { getItem } from 'utils/tokenStore';

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  componentDidMount = () => {
    if( getItem( 'USM_IMS_TOKEN' )) {
      return this.props.history.push( '/dashboard' );
    }

    this.props.setSessionID( null )
    this.props.setCurrentRole( null )
  }

  handleKeyPress = e => {
    if(e.key === 'Enter'){
      
      if ( this.state.email.length < 1 || this.state.password.length < 1){
        return false
      }

      this.props.onClickLogin({
        email: this.state.email, 
        password: this.state.password
      })
    }
  }


  render = () => {
    const { 
      onChangeHOC,
      showPassword
    } = this.props
    return (
      <TemplateContainerMain>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider { ...SliderSettings }>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg1 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg2 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                  <div
                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div
                      className="slide-img-bg"
                      style={{ backgroundImage: 'url(' + bg3 + ')' }}/>
                    <div className="slider-content"></div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <img src={ icon } style={{ width: 400, marginLeft: -10, marginBottom: '15px' }} />
                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <Row className="divider"/>
                <AvForm>
                  <Row form>
                    <Col md={6}>
                      <AvGroup>
                        <Label for="exampleEmail">Email</Label>
                        <AvInput
                          onChange={ e => {
                            this.setState({ email: e.target.value })
                          }}
                          type="email"
                          name="email"
                          id="exampleEmail"
                          onKeyPress={this.handleKeyPress}
                          value={ this.state.email }
                          placeholder="Email here..."
                          required />
                        {/* {
                          data.ajaxReducer.ajaxErrorMessage !== '' && (
                            <AvFeedback>{ processErrorMessage( data.ajaxReducer.ajaxErrorMessage, 'Email' ) }</AvFeedback>
                          )
                        } */}
                      </AvGroup>
                    </Col>
                    <Col md={6}>
                      <AvGroup>
                        <div className={ 'd-flex' }>
                          <Label for="examplePassword">Password</Label>
                          <div 
                            style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                            onClick={ () => onChangeHOC( !showPassword, 'showPassword' )}>
                            {
                              showPassword 
                                ? <GrView style={{ width: '100%', height: '100%' }}/>
                                : <GrFormViewHide style={{ width: '100%', height: '100%' }}/>
                            }
                          </div>
                        </div>
                        <AvInput
                          onChange={ e => {
                            this.setState({ password: e.target.value })
                          }}
                          type={ showPassword ? 'text' : 'password' }
                          name="password"
                          id="examplePassword"
                          value={ this.state.password }
                          onKeyPress={this.handleKeyPress}
                          placeholder="Password here..."
                          required />
                        {/* {
                          data.ajaxReducer.ajaxErrorMessage !== '' && (
                            <AvFeedback>{ processErrorMessage( data.ajaxReducer.ajaxErrorMessage, 'Password' ) }</AvFeedback>
                          )
                        } */}
                      </AvGroup>
                    </Col>
                  </Row>
                  { 
                    this.props.showErrorMessage && (
                      <p style={{ color: '#ff0000' }}>
                        { this.props.errorMessage + '  ' }
                        {/* <strong>Forgot Password ?</strong> */}
                      </p>
                  )}
                  {/* <Button
                    style={{ color: '#ff0000' }}
                    color="link"
                    size="lg"
                    disabled
                    className={ 'pl-1' }
                    onClick={() => this.props.history.push( '/company-register' )}>Forgot Password ? (Not working for now)</Button> */}
                  <Row className="divider" />
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      {/* <p>Not a Member? </p> */}
                      <Button
                        color="link"
                        size="lg"
                        className={ 'pl-1' }
                        onClick={() => this.props.history.push( '/company-register' )}>Register as company</Button>
                      <Button
                        color="link"
                        size="lg"
                        className={ 'pl-1' }
                        onClick={() => this.props.history.push( '/student-register' )}>Register as student</Button>
                      <Button
                        color="link"
                        size="lg"
                        className={ 'pl-1' }
                        onClick={() => {
                          const newWindow = window.open('https://csusm.padlet.org/sufril/ims-video-guidelines-c53888ytp2385z4d', '_blank', 'noopener,noreferrer');
                          if (newWindow) newWindow.opener = null;
                        }}>
                          Need help? Visit our IMS guidelines
                      </Button>
                    </div>
                    <div className="ml-auto">
                      <Button
                        color={
                          ( this.props.onLoadLogin || 
                            this.state.email.length < 1 || 
                            this.state.password.length < 1 
                          ) ? 'secondary' : 'primary'
                        }
                        size="lg"
                        disabled={ 
                          this.props.onLoadLogin || 
                          this.state.email.length < 1 || 
                          this.state.password.length < 1 
                        }
                        onClick={() => {
                          this.props.onClickLogin({
                            email: this.state.email, 
                            password: this.state.password
                          })
                        }}>Login to Dashboard</Button>
                    </div>
                  </div>
                </AvForm>
              </Col>
            </Col>
            </Row>
        </div>
        { this.props.onLoadLogin && <LoadingOverlay /> }
      </TemplateContainerMain>
    )
  }
}   


export default WithLogin( Login )