import React, { Component } from 'react'
import _ from 'lodash'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import getDomainURL from 'utils/api'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      showImportStudentsModal: false,
      showUpdateStudentsModal: false
    }

    onChangeStudentsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSelectedStudent = id => {
      Get(
        `/students/${ id }`,
        this.getSelectedStudentSuccess,
        this.getSelectedStudentError,
        this.load
      )
    }
    getSelectedStudentSuccess = ({ user, student }) => {
      let tempMerge = _.merge( user, student )

      this.props.onChangeUsersHOC( 'selectedUser', {
        ...tempMerge,
        rolesInfo: [{ id: 13, name: 'Student' }]
      })
      this.props.onChangeUsersHOC( 'showUpdateUsersModal', true )
    }
    getSelectedStudentError = error => this.requestError ( error )

    importStudents = (dataToSubmit, session_id) => Post(
      `/students/import`,
      {
        "data": dataToSubmit,
        "session_id": session_id
      },
      this.importStudentsSuccess,
      this.importFileNameError,
      this.load
    )
    importStudentsSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView
      this.setState({ showImportStudentsModal: false })
      this.props.getUsers( search )
      this.requestSuccess( 'User has been created successfully.' )
    }
    importFileNameError = error => this.requestError( error )

    createStudent = dataToSubmit => Post(
      `/students`,
      dataToSubmit,
      this.createStudentSuccess,
      this.createStudentError,
      this.load
    )
    createStudentSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getUsers( search )
      this.props.onChangeUsersHOC( 'showCreateUsersModal', false )
      this.requestSuccess( 'User has been created successfully.' )
    }
    createStudentError = error => this.requestError( error )

    deleteStudent = id => Delete( 
      `/students/${ id }`,
      this.deleteStudentSuccess,
      this.deleteError,
      this.load
    )
    deleteStudentSuccess = () => {
      const { search } = this.props.data.LastViewReducer.lastView

      this.props.getUsers( search )
      this.props.onChangeUsersHOC( 'showDeleteUserModal', false )
      this.requestSuccess( 'User has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateStudent = dataToSubmit => Put(
      `/students/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateStudentSucces,
      this.updateStudentError,
      this.load
    )
    updateStudentSucces = payload => {
      const { search } = this.props.data.LastViewReducer.lastView
      
      this.props.getUsers( search )
      this.getSelectedStudent( payload.student.id )
      this.requestSuccess( 'User was updated successfully.' )
    }
    updateStudentError = error => this.requestError( error )

    submitCangred = ( id, dataToSubmit, pondRef ) => {
      this.load( true )
      let token = Cookies.get('USM_IMS_TOKEN')
      const url = `${ getDomainURL() }/students/cangred_pdf/${ id }`;
        let tempForm = new FormData();
        tempForm.append( 'cangred', dataToSubmit );
        const config = {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data'
          }
        }
       Axios.post(url, tempForm, config ).then(() => {
        this.load( false )
         this.submitCangredSuccess( pondRef )
       }).catch( err => {
        this.load( false )
        this.submitCangredError( err )
       })
    }
    submitCangredSuccess = ( pondRef ) => {
      const { search } = this.props.data.LastViewReducer.lastView

      pondRef.current.removeFiles()
      this.props.getUsers( search )
      this.requestSuccess( 'Cangred has been uploaded succesfuly.' )
    }
    submitCangredError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadStudent={ this.state.loading }
          showUpdateStudentsModal={ this.state.showUpdateStudentsModal }
          showImportStudentsModal={ this.state.showImportStudentsModal }

          getSelectedStudent={ this.getSelectedStudent }
          createStudent={ this.createStudent }
          deleteStudent={ this.deleteStudent }
          updateStudent={ this.updateStudent }
          submitCangred={ this.submitCangred }
          importStudents={ this.importStudents }
          onChangeStudentsHOC={ this.onChangeStudentsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC