import React from 'react'
import ReactTable from 'react-table'
import { 
  Button,
  Card, CardHeader,
  UncontrolledTooltip
} from 'reactstrap'

import UserFormModal from './UserFormModal'
import ConfirmationModal from 'components/Modal/confirmation'

import UserHOC from '../actions/user'

const UserTabContent = ({
  onLoadUser,
  selectedCompany,
  externalSV,
  selectedUser,
  showCreateUserModal,
  showDeleteUserModal,
  showUpdateUserModal,

  onChangesUserHOC,
  getExternalSV,
  deleteUser,
  updateUser,
  createUser,
  assignCompanyUser
}) => {

  return (
    <>
      <Card>
        <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
          Company Users
          <Button
            className="ml-auto"
            color="primary"
            onClick={() => onChangesUserHOC( 'showCreateUserModal', true )}
          >
            Add User
          </Button>
        </CardHeader>
        <ReactTable
          pageSize={ 
            selectedCompany.users.length < 1? 3: 
            selectedCompany.users.length < 10? selectedCompany.users.length:
            10
          }
          data={ selectedCompany.users || [] }
          columns={[
            {
              Header: 'Name',
              accessor: 'name'
            },
            {
              Header: 'Email',
              accessor: 'email'
            },
            {
              Header: 'Phone',
              accessor: 'phone'
            },
            {
              Header: 'Actions',
              Cell: row => {
                return (
                  <>
                    {/* <Button
                      id="EditUser"
                      className="btn-icon mr-2 btn-icon-only"
                      color="primary"
                      onClick={() => {
                        onChangesUserHOC( 'showUpdateUserModal', true )
                        onChangesUserHOC( 'selectedUser', row.original )
                      }}>
                      <i className="pe-7s-pen btn-icon-wrapper"> </i>
                    </Button>
                    <UncontrolledTooltip target="EditUser" placement="left">Edit this user</UncontrolledTooltip> */}
                    <Button
                      id="RemoveUser"
                      className="btn-icon btn-icon-only"
                      color="danger"
                      disabled={ selectedCompany.users.length <= 1 }
                      onClick={() => {
                        onChangesUserHOC( 'showDeleteUserModal', true )
                        onChangesUserHOC( 'selectedUser', row.original )
                      }}>
                      <i className="pe-7s-close btn-icon-wrapper"> </i>
                    </Button>
                    <UncontrolledTooltip target="RemoveUser" placement="left">Remove this user</UncontrolledTooltip>
                  </>
                )
              }
            }
          ]}
          showPagination={ true } />
      </Card>
      <ConfirmationModal
        open={ showDeleteUserModal }
        title={ `Remove company user` }
        loading={ onLoadUser }
        message={ `Are you sure you want to remove this user?` }
        onClose={ () => onChangesUserHOC( 'showDeleteUserModal', false )}
        onClickConfirm={ () => deleteUser( selectedUser )}
      />
      <UserFormModal
        context={ 'Create' }
        externalSV={ externalSV }
        onLoadUser={ onLoadUser }
        selectedCompany={ selectedCompany }
        getExternalSV={ getExternalSV }
        open={ showCreateUserModal }
        onClickSave={( exisitingUser, val ) => {
          exisitingUser 
            ? assignCompanyUser( val )
            : createUser( val )
        }}
        onClose={ () => onChangesUserHOC( 'showCreateUserModal', false )}
      />
      <UserFormModal
        context={ 'Edit' }
        onLoadUser={ onLoadUser }
        open={ showUpdateUserModal }
        selectedUser={ selectedUser }
        onClickSave={ val => updateUser( val )}
        onClose={ () => onChangesUserHOC( 'showUpdateUserModal', false )}
      />
    </>
  )
}

export default UserHOC( UserTabContent )