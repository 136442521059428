import React, { useCallback } from 'react'
import { CloseOutline } from 'react-ionicons'
import { 
  Row,
  Col,
  Form,
  FormGroup, Label, Input,
  Card, CardBody, CardHeader 
} from 'reactstrap'
import _ from 'lodash'
import UserTypeahead from 'components/Typeahead/user'
import { PulseLoader } from 'react-spinners';

const NonStudentContent = ({
  data,
  lecturers,
  getLecturers,

  companyUsers,
  formData,
  onLoadLecturer,
  onChangeUserHOC,
  onChangeAcceptedVacanciesField
}) => {

  const onChangeLecturerTypeahead = useCallback( _.debounce(
    val => {
      getLecturers( btoa(`{"page":1, "per_page": 1000, "filter":{ "u.name": {"$like": "%${ val }%" } }}`))  
    }, 600 ), []
  );

  return (
    <Form onSubmit={ e => e.preventDefault()}>
      <Row>
        {/* <Col lg={ 12 } className={ 'mb-2'}>
          <FormGroup>
            <Label>Final Report Url</Label>
            {
              formData.final_report_url && (
                <strong 
                  style={{ cursor: 'pointer', textDecoration: 'underline', wordBreak: 'break-all' }}
                  className={ 'd-block'}
                  onClick={ () => window.open( formData.final_report_url)}

                >
                  { formData.final_report_url }
                </strong>
              )
            }
            {
              !formData.final_report_url && (
                <p>
                  <strong>No report is uploaded</strong>
                  <CloseOutline cssClasses={ 'text-danger ml-1' }/>
                </p>
              )
            }
          </FormGroup>
        </Col> */}
        {/* <Col lg={ 3 }>
          <FormGroup check>
            <Label check>
              <Input 
                checked={ formData.is_placement_form_check } 
                type="checkbox" 
                onChange={ e => onChangeAcceptedVacanciesField( 'is_placement_form_check', e.target.checked )}/>
              Placement Form Check
            </Label>
          </FormGroup>
        </Col> */}
        { data.ProfileReducer.current_role_id !== 15 && (
          <Col lg={ 12 } className="mt-3">
            <Card>
              <CardHeader style={{ fontSize: '1rem' }}>External Supervisor</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Supervisor</Label>
                  <Input 
                    value={ formData.external_supervisor_id } 
                    type="select" 
                    disabled={ [ 1, 11, 14 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                    onChange={ e => onChangeAcceptedVacanciesField( 'external_supervisor_id', e.target.value )}>
                    <option value={''}></option>
                    {
                      companyUsers?.[0] && companyUsers.map( item => (
                        <option value={ item.id } key={ item.id }>{ `${ item.name } - ${ item.email }` }</option>
                      ))
                    }
                  </Input>
                </FormGroup>
                {/* <FormGroup check>
                  <Label check>
                    <Input 
                      disabled={[ 1, 11, 14 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      checked={ formData.is_duty_report_check } 
                      type="checkbox" 
                      onChange={ e => onChangeAcceptedVacanciesField( 'is_duty_report_check', e.target.checked )}/>
                      Duty Report Check
                  </Label>
                </FormGroup> */}
                {/* <FormGroup check>
                  <Label check>
                    <Input
                      disabled={[ 1, 11, 14 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      checked={ formData.is_external_evaluation_form_checked } 
                      onChange={ e => onChangeAcceptedVacanciesField( 'is_external_evaluation_form_checked', e.target.checked )}
                      type="checkbox" 
                    />
                    Evaluation Form Check
                  </Label>
                </FormGroup> */}
                <FormGroup check>
                  <Label check>
                    <Input
                      disabled={ [ 1, 11, 14 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      checked={ formData.is_external_final_report_checked } 
                      type="checkbox" 
                      onChange={ e => onChangeAcceptedVacanciesField( 'is_external_final_report_checked', e.target.checked )}/>
                      Final Report Check
                  </Label>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        )}
        { data.ProfileReducer.current_role_id !== 14 && (
          <Col lg={ 12 } className="mt-3">
            <Card>
              <CardHeader style={{ fontSize: '1rem' }}>Internal Supervisor</CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>
                    <span className={ 'mr-2' }>Supervisor</span>
                    <PulseLoader  
                      sizeUnit={"px"}
                      size={ 10 }
                      color={ '#16232c' }
                      loading={ onLoadLecturer } 
                    />
                  </Label>
                  <UserTypeahead
                    options={ lecturers }
                    onSelect={ val => val?.[0]?.id && onChangeAcceptedVacanciesField( 'internal_supervisor_id', val[0].id )}
                    selectedValue={ 
                      formData.internal_supervisor_id && _.find( lecturers, { id: formData.internal_supervisor_id })
                        ? [ _.find( lecturers, { id: formData.internal_supervisor_id })] 
                        : [] 
                    }
                    onSearch={ val => {
                      onChangeAcceptedVacanciesField( 'internal_supervisor_id', null )
                      val.length > 0
                        ? onChangeLecturerTypeahead( val )
                        : onChangeUserHOC( 'lecturers', [] )
                    }}
                    disableEdit={( onLoadLecturer || [ 1, 11 ].indexOf( data.ProfileReducer.current_role_id ) < 0 )}
                    helpText={ 'Please enter lecturer name to search.' }
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label>Remarks</Label>
                  <Input
                    rows={ 3 }
                    value={ formData.internal_sv_remark } 
                    type="textarea" 
                    onChange={ e => onChangeAcceptedVacanciesField( 'internal_sv_remark', e.target.value )}
                    disabled={ data.ProfileReducer.profile.user.id !== formData.internal_supervisor_id }
                  />
                </FormGroup> */}
                {/* <FormGroup check>
                  <Label check>
                    <Input 
                      disabled={[ 1, 11, 15 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      checked={ formData.is_internal_evaluation_form_checked } 
                      onChange={ e => onChangeAcceptedVacanciesField( 'is_internal_evaluation_form_checked', e.target.checked )}
                      type="checkbox"
                    />
                    Evaluation Form Check
                  </Label>
                </FormGroup> */}
                {/* <FormGroup check>
                  <Label check>
                    <Input
                      disabled={ [ 1, 11, 15 ].indexOf( data.ProfileReducer.current_role_id ) < 0 }
                      checked={ formData.is_internal_final_report_checked } 
                      type="checkbox" 
                      onChange={ e => onChangeAcceptedVacanciesField( 'is_internal_final_report_checked', e.target.checked )}/>
                    Final Report Check
                  </Label>
                </FormGroup> */}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default NonStudentContent;