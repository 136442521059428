import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { storeLastView } from 'actions/lastView'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentEvaluation: {},
      evaluationList: {},
      showStudentEvaluation: false,
      showGenerateCSV: false,
    }

    onChangeEvaluationsHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getSelectedEvaluation = search => {
      this.props.storeLastView({ search })

      Get(
        `/internship_evaluation_forms?query=${ search }`,
        this.getSelectedEvaluationSuccess,
        this.getSelectedEvaluationError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getSelectedEvaluationSuccess = payload => {
      let tempData = {}
      payload.data.map(({ 
        internship_evaluation_form, company, student_user,
        external_supervisor, internal_supervisor
      }) => {
        tempData[ internship_evaluation_form.type === 'External'? 'external': 'internal' ] = {
          ... internship_evaluation_form,
          internship: {
            student_name: student_user.name,
            company_name: company.name,
            internal_supervisor: internal_supervisor?.name??'N/A',
            external_supervisor: external_supervisor?.name??'N/A',
          }
        }
      })
      this.setState({ 
        studentEvaluation: tempData,
        showStudentEvaluation: true
      })
    }
    getSelectedEvaluationError = error => this.requestError( error )

    getEvaluations = search => {
      this.props.storeLastView({ search })

      Get(
        `/internship_evaluation_forms?query=${ search }`,
        this.getEvaluationsSuccess,
        this.getEvaluationsError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getEvaluationsSuccess = payload => {
      let tempData = []
      let tempEval = []
      let tempChildren =[]
      payload.data?.[0] && payload.data.map(({
        internship_evaluation_form, 
        company, student_user,
      }) => {
        let tempJSON = []
        if (internship_evaluation_form.type == "External") {
          tempJSON = JSON.parse( internship_evaluation_form.evaluation ).data
          tempJSON.map((item) => {
            tempChildren = {
              [item.name]: `Total: ${item.total}`
            }
            item.children.map((child) => {
              tempChildren = {
                ... tempChildren,
                [`${item.name.slice(0, 3)}: ${child.name}`]: `Marks: ${child.marks}, Grade: ${child.grade}`,
              }
            })
            tempEval[student_user.name] = {
              ... tempEval[student_user.name],
              ... tempChildren,
            }
          })
          tempChildren = []
          tempData.push({
            type: internship_evaluation_form.type,
            student_name: student_user.name,
            company_name: company.name,
            final_total_marks: internship_evaluation_form.final_total_marks,
            general_comments: internship_evaluation_form.general_comments,
            is_best_intern_awards: internship_evaluation_form.is_best_intern_awards,
            is_confirmed_submission: internship_evaluation_form.is_confirmed_submission,
            is_recommend_industrial_training_excellence: internship_evaluation_form.is_recommend_industrial_training_excellence,
            is_student_passed: internship_evaluation_form.is_student_passed,
            ... tempEval[student_user.name],
          })
        } else if (internship_evaluation_form.type == "Internal") {
          tempJSON = JSON.parse( internship_evaluation_form.evaluation ).data
          tempJSON.map((title) => {
            title.data.map((item) => {
              item.children.map((child) => {
                tempChildren = {
                  ... tempChildren,
                  [`${title.name}: ${item.name.slice(0, 3)}: ${child.name}`]: `Marks: ${child.marks}, Grade: ${child.grade}`,
                }
              })
            })
            tempEval[student_user.name] = {
              ... tempEval[student_user.name],
              [`${title.name}`]: `Total: ${title.total}, Total After Weightage: ${title.total_after_weightage}`,
              ... tempChildren,
            }
            tempChildren = []
          })
          tempData.push({
            type: internship_evaluation_form.type,
            student_name: student_user.name,
            company_name: company.name,
            final_total_marks: internship_evaluation_form.final_total_marks,
            general_comments: internship_evaluation_form.general_comments,
            is_best_intern_awards: internship_evaluation_form.is_best_intern_awards,
            is_confirmed_submission: internship_evaluation_form.is_confirmed_submission,
            is_recommend_industrial_training_excellence: internship_evaluation_form.is_recommend_industrial_training_excellence,
            is_student_passed: internship_evaluation_form.is_student_passed,
            ... tempEval[student_user.name],
          })
        }
      })
      this.setState({ 
        evaluationList: tempData,
        showGenerateCSV: true
      })
    }
    getEvaluationsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          studentEvaluation={ this.state.studentEvaluation }
          evaluationList={ this.state.evaluationList }
          onLoadEvaluation={ this.state.loading }
          showStudentEvaluation={ this.state.showStudentEvaluation }
          showGenerateCSV={ this.state.showGenerateCSV }
          
          getEvaluations={ this.getEvaluations }
          getSelectedEvaluation={ this.getSelectedEvaluation }
          onChangeEvaluationsHOC={ this.onChangeEvaluationsHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC