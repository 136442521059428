import Axios from 'axios'
import { getItem, clearItem } from 'utils/tokenStore';

import { GET_PROFILE, SET_CURRENT_ROLE } from './type'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'
import getDomainURL from 'utils/api'

export const getProfile = flag => dispatch => {
  // flag is needed for only the inital current role setting when login
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'USM_IMS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/current_user`
  ).then( response => {
    dispatch( getProfileSuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
    flag && dispatch( setCurrentRole( response.data.sec_module_roles?.[0]?.role_id??null ) )
  }).catch( error => {
    if( error.response.status === 401 ){
      clearItem( 'USM_IMS_TOKEN' )
      window.location.reload()
    }
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getProfileSuccess = payload => ({
  type: GET_PROFILE,
  payload
})


export const setCurrentRole = payload => dispatch => {
  dispatch({
    payload,
    type: SET_CURRENT_ROLE
  })
}