import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Collapse, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import CreateForm from './Create'
import EditForm from './Edit'

import CompanyVacanciesHOC from './actions'
import CompanyHOC from './actions/company'
import StudentVacancyStatus from 'actions/hoc/studentVacancyStatus'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class CompanyVacancies extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer
    const { current_role_id } = this.props.data.ProfileReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getCompanyVacancies( btoa( JSON.stringify( sessionQueryHandler( 
        { "page": 1 }, 
        current_selected_session_id, 
        current_role_id === 14 ? 'session_id' : 'company_vacancies.session_id' 
      ))) )
      this.props.getStudentVacancyStatus( btoa(`{"page":1 }`) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getCompanyVacancies( btoa( JSON.stringify( sessionQueryHandler( 
        { "page": 1 }, 
        current_selected_session_id, 
        current_role_id === 14 ? 'session_id' : 'company_vacancies.session_id' 
      ))) )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    const { current_role_id } = this.props.data.ProfileReducer
    const { current_selected_session_id } = this.props.data.SessionReducer

    return (
      <>
        <PageTitle
          heading="Company Vacancies"
          subheading="Listings of all the company vacancies present in the system."
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeCompanyVacanciesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... is_create ? [{
              color: 'primary',
              onClick: () => {
                this.props.onChangeCompanyVacanciesHOC( 'showCreateCompanyVacanciesModal', true )
              },
              className: 'mr-2',
              content: 'Create'
            }] : []
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeCompanyVacanciesHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeCompanyVacanciesHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                
                tempJSON = sessionQueryHandler( 
                  tempJSON, 
                  this.props.data.SessionReducer.current_selected_session_id, 
                  current_role_id === 14 ? 'session_id' : 'company_vacancies.session_id' 
                )

                this.props.getCompanyVacancies( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.companyVacancies.data.length < 1? 3: 
                      this.props.companyVacancies.data.length < 10? this.props.companyVacancies.data.length:
                      10
                    }
                    data={ this.props.companyVacancies.data }
                    columns={[
                      {
                        Header: 'Company Name',
                        accessor: 'company_name'
                      },
                      {
                        Header: 'Job Title',
                        accessor: 'job_title'
                      },
                      {
                        Header: 'No. of positions available',
                        accessor: 'vacancy_number'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                disabled={ !is_update }
                                id="UpdateCompanyVacancies"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary'}
                                onClick={() => {
                                  this.props.getSelectedCompanyVacancies(row.original.id)
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateCompanyVacancies" placement="left">Update the information of this company vacancy</UncontrolledTooltip>
                              <Button
                                id="DeleteCompanyVacancies"
                                disabled={ !is_delete }
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? "danger" : 'secondary'}
                                onClick={() => {
                                  this.props.onChangeCompanyVacanciesHOC( 'showDeleteCompanyVacanciesModal', true )
                                  this.props.onChangeCompanyVacanciesHOC( 'selectedDeleteCompanyVacanciesId', row.original.id )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteCompanyVacancies" placement="left">Delete this company vacancy</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.companyVacancies.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getCompanyVacancies( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <EditForm
            { ... this.props }
            data={ this.props.data }
            selectedData={ this.props.selectedCompanyVacancies }
            open={ this.props.showUpdateCompanyVacanciesModal }
            uploadPoster={ this.props.uploadPoster }
            onClose={ () => this.props.onChangeCompanyVacanciesHOC( 'showUpdateCompanyVacanciesModal', false )}
            onClickSubmit={ this.props.updateCompanyVacancies }
          />
          <CreateForm
            { ... this.props }
            current_selected_session_id={ current_selected_session_id }
            profile={ this.props.data.ProfileReducer.profile }
            current_role_id={ this.props.data.ProfileReducer.current_role_id }
            open={ this.props.showCreateCompanyVacanciesModal }
            onClose={ () => this.props.onChangeCompanyVacanciesHOC( 'showCreateCompanyVacanciesModal', false )}
            onClickSubmit={ this.props.createCompanyVacancies}
          />
          <ConfirmationModal
            loading={ this.props.onLoadCompanyVacancies }
            open={ this.props.showDeleteCompanyVacanciesModal }
            title={ `Delete Company Vacancies` }
            message={ `Are you sure you want to delete this company? (This process can't be undone)` }
            onClose={ () => this.props.onChangeCompanyVacanciesHOC( 'showDeleteCompanyVacanciesModal', false )}
            onClickConfirm={ () => this.props.deleteCompanyVacancies( this.props.selectedDeleteCompanyVacanciesId )}/>
        </ReactCSSTransitionGroup>
        {( this.props.onLoadCompanyVacancies || 
            this.props.onLoadVacancyStatus
          ) && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  CompanyHOC,
  CompanyVacanciesHOC,
  StudentVacancyStatus
)(CompanyVacancies)