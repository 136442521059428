import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentVisitSchedule: {},
      visitSearch: ''
    }

    onChangeVisitSchedulesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getVisitSchedules = search => this.setState({ 
      visitSearch: search }, () => {
      Get(
        `/visit_schedules?query=${ search }`,
        this.getVisitSchedulesSuccess,
        this.getVisitSchedulesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    })
    getVisitSchedulesSuccess = payload => {
      let tempData = []
      payload.data.map(({ visit_schedule, company, user }) => {
        tempData.push({
          ... visit_schedule,
          company_name: company.name,
          user_name: user.name,
          date: Moment.utc( visit_schedule.date ),
          start_time: Moment.utc( visit_schedule.start_time ).format( 'h:mm a' ),
          end_time: Moment.utc( visit_schedule.end_time ).format( 'h:mm a' ),
        })
      })
      this.setState({ studentVisitSchedule: tempData?.[0]??{} })
    }
    getVisitSchedulesError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          visitSearch={ this.state.visitSearch }
          studentVisitSchedule={ this.state.studentVisitSchedule }
          onLoadVisitSchedule={ this.state.loading }
          
          getVisitSchedules={ this.getVisitSchedules }
          onChangeVisitSchedulesHOC={ this.onChangeVisitSchedulesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC