import { combineReducers } from 'redux'

import ThemeOptions from './ThemeOptions'
import ProfileReducer from './profile'
import DictionaryReducer from './dictionary'
import LastViewReducer from './lastView'
import AjaxReducer from './ajax'
import NotificationReducer from './notification'
import LoginEmailReducer from './email'
import SessionReducer from './session'

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  SessionReducer,
  ProfileReducer,
  DictionaryReducer,
  LastViewReducer,
  NotificationReducer,
  LoginEmailReducer
})