import {
  Modal, ModalHeader, ModalBody,
  FormGroup, Label
} from 'reactstrap'

import getBackendURL from 'utils/backendUrl'

const StudentDetailsModal = props => {
  return (
    <Modal isOpen={ props.showStudentDetailsModal }>
      <ModalHeader toggle={() => props.onChangeStudentsHOC( 'showStudentDetailsModal', false )}>Student details</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Name</Label>
          <h6>{ props.selectedStudent.user.name }</h6>
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <h6>{ props.selectedStudent.user.email }</h6>
        </FormGroup>
        <FormGroup>
          <Label>Phone</Label>
          <h6>{ props.selectedStudent.user.phone }</h6>
        </FormGroup>
        <FormGroup>
          <Label>Academic Advisor</Label>
          <h6>{ `${props.selectedStudent.academic_advisor?.name??'N/A'} (${props.selectedStudent.academic_advisor?.email??'N/A'})` }</h6>
        </FormGroup>
        <FormGroup>
          <Label>External Supervisor</Label>
          <h6>{ `${props.selectedStudent.external_supervisor?.name??'N/A'} (${props.selectedStudent.external_supervisor?.email??'N/A'})` }</h6>
        </FormGroup>
        <FormGroup>
          <Label>Internal Supervisor</Label>
          <h6>{ `${props.selectedStudent.internal_supervisor?.name??'N/A'} (${props.selectedStudent.internal_supervisor?.email??'N/A'})` }</h6>
        </FormGroup>
        <FormGroup>
          <Label>Offer Letters</Label>
          <h6>
          {
            props.selectedStudent.student_offer_letters ? props.selectedStudent.student_offer_letters.map( item => {
              return <a href={ `${ getBackendURL() }/${ item.url }` } target="_blank">{ `${ getBackendURL() }/${ item.url }` }</a>
            }) : "No offer letters found."
          }
          </h6>
        </FormGroup>
      </ModalBody>
    </Modal>
  )
}

export default StudentDetailsModal