import React, { useMemo, Fragment } from 'react'
import _ from 'lodash'
import { BiUser } from 'react-icons/bi'
import { 
  DropdownToggle, DropdownMenu,
  Nav, NavItem,
  UncontrolledButtonDropdown 
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'

import { setCurrentRole } from 'actions/profile'
import { getNotification } from 'actions/notifications'

const SwitchRoles = ({
  data,
  history,
  setCurrentRole,
  getNotification
}) => {
  let RolesOption = useMemo(() => {
    let tempOptions = _.map( data.ProfileReducer.profile.sec_module_roles, item => {
      return ({
        roleId: item.role_id,
        roleName: item.role_name,
      })
    })
    return [ ... tempOptions ]
  }, [ data.ProfileReducer.profile.sec_module_roles ])

  let RoleName = useMemo(() => {
    let tempRole = _.find( data.DictionaryReducer.roles, { id: data.ProfileReducer.current_role_id  })
    return tempRole?.name??''

  }, [data.ProfileReducer.current_role_id, data.ProfileReducer.profile.sec_module_roles, data.DictionaryReducer.roles])

  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle color="link" className={ 'd-flex align-items-center pl-0' }>
        <div 
          className="icon-wrapper bg-primary icon-wrapper-alt text-white" 
          style={{ width: 'initial', height: 'initial', padding: '8px 10px', overflow: 'initial', borderRadius: '1rem' }}
        >
          <BiUser style={{ fontSize: 18, color: 'white', marginRight: 7 }}/>
          <span style={{ textTransform: 'capitalize' }}>{ RoleName }</span>
        </div>
        <FontAwesomeIcon className="ml-2 opacity-8" icon={ faAngleDown } />
      </DropdownToggle>
      <DropdownMenu 
        right 
        className="dropdown-menu-xl rm-pointers" 
        style={{
          maxHeight: '240px',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}>
          <div className="dropdown-menu-header mb-0">
              <div className="dropdown-menu-header-inner" style={{ padding: '0.8rem 0.5rem' }}>
                  <div className="menu-header-content text-dark">
                      <h5 className="menu-header-title">
                        { `Switch User Roles` }
                      </h5>
                  </div>
              </div>
          </div>
          <Nav vertical>
          {
            RolesOption?.[0] && RolesOption.map(({ roleName, roleId  }) => (
              (
                <Fragment key={ roleId }>
                  <NavItem className="nav-item-divider text-align"/>
                  <NavItem 
                    className={ `nav-item-btn text-center` }
                    style={{ cursor: 'pointer' }}
                    onClick={ () => {
                      setCurrentRole( roleId )
                      getNotification( btoa(`{ "page": 1, "per_page": 5, "filter": { "is_read": true }}`), false, roleId )
                      history.push( '/dashboard/statistics' )
                    }}
                  >
                   <span style={{ textTransform: 'capitalize' }}>{ roleName }</span>
                   {
                     data.ProfileReducer.current_role_id === roleId && (
                       <div 
                        className="rounded-circle bg-primary ml-3" 
                        style={{ width: 10, height: 10, display: 'inline-block' }}
                      />
                     )
                   }
                  </NavItem>
                </Fragment>
              )
            ))
          }
        </Nav>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

const mapStateToProps = state => ({ data: state })
export default connect( mapStateToProps,{
  setCurrentRole,
  getNotification
})( SwitchRoles );