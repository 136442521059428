import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import Moment from 'moment'
import { 
  Row, Col,
  FormGroup, Label, Input,
  Card, CardHeader,CardBody,CardFooter,
  Button
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import VisitSchedulesHOC from '../../VisitSchedule/actions'

const FieldData = [
  { label: 'Date', value: 'date', col: 12, showTimeSelect:  false, required: true },
  { label: 'Start Time', value: 'start_time', col: 6, showTimeSelect: true, required: true },
  { label: 'End Time', value: 'end_time', col: 6, showTimeSelect: true, required: true , required: true },
  { label: 'Message', value: 'message', col: 6, showTimeSelect: true, type: 'textarea', required: false },
]

const LecturerVisitSchedule = props => {

  const { visit_schedules } = props.selectedAcceptedVacancies
  const { is_update } = props
  const [ formContent, setFormContent ] = useState({
    date: null,
    start_time: null,
    end_time: null,
    message: '',
  })

  const onChangeFormContent = ( context, val ) => {
    let temp = _.cloneDeep( formContent )
    temp[ context ] = val
    setFormContent( temp )
  }

  useEffect( () => {
    if( visit_schedules ){
      setFormContent({
        ...visit_schedules,
        // date: Moment( visit_schedules.date ).format( 'DD-MM-YYYY' ),
        // start_time: Moment( visit_schedules.start_time ).format( 'hh:mm a' ),
        // end_time: Moment( visit_schedules.end_time ).format( 'hh:mm a' )
      })
    }
  }, [ props.selectedAcceptedVacancies ])

  return(
    <Card className="mt-2 mb-3">
      <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
        Schedule Details
      </CardHeader>
      <CardBody>
        <Row>
          {
            visit_schedules && FieldData.map( field => (
              <Col lg={ 12 }>
                <FormGroup>
                <Label>
                    { `${ field.label } ` }
                    { field.required && <span className="text-danger">*</span> }
                  </Label>
                  {
                    field.type === 'textarea' && (
                      <Input
                        type={ field.type }
                        rows={ 5 }
                        value={ formContent[ field.value ] }
                        disabled={ 
                          (props.data.ProfileReducer.current_role_id === 13 || props.data.ProfileReducer.current_role_id === 15)? false:
                          true
                        }
                        onChange={ e => onChangeFormContent( field.value, e.target.value )}
                      />
                    )
                  }
                  {
                    field.type !== 'textarea' && (
                      <DatePicker
                        className={ 'w-100' }
                        showTimeSelect={ field.showTimeSelect }
                        showTimeSelectOnly={ field.showTimeSelect }
                        dateFormat={ field.showTimeSelect
                          ? "h:mm aa"
                          : "dd-MM-yyyy"
                        }
                        disabled={ 
                          (props.data.ProfileReducer.current_role_id === 13 || props.data.ProfileReducer.current_role_id === 15)? false:
                          true
                        }
                        selected={ formContent[ field.value ] }
                        onChange={ val => {
                          onChangeFormContent( field.value, val )
                        }}/>
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          {
            !visit_schedules && (
              <span className='student-vacancy__detail-subtitle pl-3'>No Visit Scheduled</span>
            )
          }
        </Row>
      </CardBody>
      <CardFooter>
        { (props.data.ProfileReducer.current_role_id === 13 || props.data.ProfileReducer.current_role_id === 15) && 
          visit_schedules && (
          <Button 
            style={{ 
              width: 'max-content'
            }}
            className="mr-2"
            color={
              ( FieldData.some( item => item.required && !formContent[ item.value ]) || !visit_schedules )
                ? 'secondary' 
                : 'primary'
            }
            disabled={ FieldData.some( item => item.required && !formContent[ item.value ]) || !visit_schedules }
            onClick={() => {
              props.updateVisitSchedule({
                ... formContent,
                date: Moment( formContent.date ).format(),
                start_time: Moment( formContent.start_time ).format(),
                end_time: Moment( formContent.end_time ).format()
              })
            }}>
            Submit
          </Button>
        ) }
      </CardFooter>
    </Card>
  )
}

export default compose(
  VisitSchedulesHOC
)(LecturerVisitSchedule)