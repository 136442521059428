import React, { useState, useMemo } from 'react'
import { 
  Modal, ModalHeader, ModalBody,
  Form,
  Card,
  CardHeader,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'
import UserForm from './UserForm'
import StudentForm from './StudentForm'
import { useEffect } from 'react'

const FormContent = ({
  data,
  open,
  onClose,

  onLoadStudent,
  onLoadUser,
  onLoadLecturer,

  createUser,
  createStudent
}) => {

  const [ selectedRole, setRole ] = useState( 1 )
  const [ roleOptions, setRoleOptions ] = useState( {} )
  let roles = {}

  if( !_.isEmpty( data.DictionaryReducer ) ) {
    roles = _.cloneDeep( data.DictionaryReducer.roles )
  }

  let tempRoleName = useMemo(() => {
    let temp = _.find( roles, { id: selectedRole })

    return temp?.name?? ''
  }, [ selectedRole ])

  useEffect(() => {
    setRole( 1 )
  }, [open])

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Create User` }</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            { `Choose the user's role ` }
            <span className="text-danger">*</span>
          </Label>
          <Input
            type="select"
            value={ selectedRole } 
            onChange={ e => setRole( +e.target.value )}
          >
            <option value={ '' }>{ '' }</option>
            {
              roles.map( role => {
                if( data.ProfileReducer.current_role_id === 11 || data.ProfileReducer.current_role_id === 12 ) {
                  if( role.id === 13 || role.id === 12 || role.id ===  5 || role.id === 20 || role.id === 18 ) {
                    return <option key={ role.id } value={ role.id }>{ role.name }</option>
                  }
                } else {
                  return <option key={ role.id } value={ role.id }>{ role.name }</option>
                }
              })
            }
          </Input>
        </FormGroup>
        <Form onSubmit={ e => e.preventDefault()}> 
          <Card className="main-card mb-3">
            <CardHeader style={{ fontSize: '1rem' }}>
              { `${ tempRoleName } Details`}
            </CardHeader>
            {
              selectedRole !== 13 && (
                <UserForm 
                  selectedRole={ selectedRole }
                  open={ open }
                  createUser={ createUser }
                /> 
              )
            }
            {
              selectedRole === 13 && (
                <StudentForm
                  createStudent={ createStudent }
                />
              )
            }
          </Card>
        </Form>
        {( onLoadUser || 
            onLoadLecturer ||
            onLoadStudent
          ) && <LoadingOverlay />}
      </ModalBody>
    </Modal>
  )
}

export default FormContent;
