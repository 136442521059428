import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get, Post, Put, Delete } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentLanguage: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedLanguage: {},

      showCreateLanguageModal: false,
      showDeleteLanguageModal: false,
      showUpdateLanguageModal: false,

      languageSearchQuery: ''
    }

    onChangeLanguageHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getLanguage = search => {
      this.setState({ languageSearchQuery: search }, () => {
        Get(
          `/student_cv_languages?query=${ search }`,
          this.getLanguageSuccess,
          this.getLanguageError,
          this.load,
          this.props.data.ProfileReducer.current_role_id
        )
      })
    }
    getLanguageSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_cv_language }) => {
        tempData.push({
          ... student_cv_language,
          start_date: Moment.utc( student_cv_language.start_date ).format( 'DD-MM-yyyy h:mm a' ),
          end_date: Moment.utc( student_cv_language.end_date ).format( 'DD-MM-yyyy h:mm a' ),
        })
      })
      this.setState({ studentLanguage: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getLanguageError = error => this.requestError( error )

    getSelectedLanguage = id => {
      Get(
        `/student_cv_languages/${ id }`,
        this.getSelectedLanguageSuccess,
        this.getSelectedLanguageError,
        this.load
      )
    }
    getSelectedLanguageSuccess = ({ student_cv_language }) => {
      let tempStartDate = new Date( student_cv_language.start_date )  
      let tempEndDate = new Date( student_cv_language.end_date )
      var userTimezoneOffset = tempStartDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedLanguage: {
          ... student_cv_language,
          start_date: new Date(tempStartDate.getTime() + userTimezoneOffset ),
          end_date: new Date(tempEndDate.getTime() + userTimezoneOffset ),
        }, 
        showUpdateLanguageModal: true
      })
    }
    getSelectedLanguageError = error => this.requestError ( error )

    createLanguage = dataToSubmit => Post(
      `/student_cv_languages`,
      dataToSubmit,
      this.createLanguageSuccess,
      this.createLanguageError,
      this.load
    )
    createLanguageSuccess = () => {
      this.getLanguage( this.state.languageSearchQuery )
      this.setState({ showCreateLanguageModal: false })
      this.requestSuccess( 'Language has been created successfully.' )
    }
    createLanguageError = error => this.requestError( error )

    deleteLanguage = id => Delete( 
      `/student_cv_languages/${ id }`,
      this.deleteLanguageSuccess,
      this.deleteError,
      this.load
    )
    deleteLanguageSuccess = () => {
      this.getLanguage( this.state.languageSearchQuery )
      this.setState({ showDeleteLanguageModal: false })
      this.requestSuccess( 'Language has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateLanguage = dataToSubmit => Put(
      `/student_cv_languages/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateLanguageSucces,
      this.updateLanguageError,
      this.load
    )
    updateLanguageSucces = ({ student_cv_language }) => {
      this.getLanguage( this.state.languageSearchQuery )
      this.getSelectedLanguage( student_cv_language.id )
      this.requestSuccess( 'Language was updated successfully.' )
    }
    updateLanguageError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          languageSearchQuery={ this.state.languageSearchQuery }
          studentLanguage={ this.state.studentLanguage }
          onLoadLanguage={ this.state.loading }
          selectedLanguage={ this.state.selectedLanguage }
          showCreateLanguageModal={ this.state.showCreateLanguageModal }
          showDeleteLanguageModal={ this.state.showDeleteLanguageModal }
          showUpdateLanguageModal={ this.state.showUpdateLanguageModal }
          
          getLanguage={ this.getLanguage }
          getSelectedLanguage={ this.getSelectedLanguage }
          createLanguage={ this.createLanguage }
          deleteLanguage={ this.deleteLanguage }
          updateLanguage={ this.updateLanguage }
          onChangeLanguageHOC={ this.onChangeLanguageHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC