import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Card, CardBody, CardHeader,
  Button, 
  UncontrolledTooltip 
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateSkillForm from './Create'
import UpdateSkillForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import SkillHOC from './actions'

class Skill extends Component {

  componentDidMount = () => {
    this.props.getSkill( btoa(`{ "page": 1, "filter": { "student_cvs.id": ${ this.props.selectedStudentCV.student_cv.id } }}`) )
  }

  render = () => {
    const { mode } = this.props

    return (
      <>
        <Card className="main-card mb-3">
          <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
            Student's Skill
            {
              mode === 'normal' && (
                <Button
                  className="ml-md-auto btn-sm d-md-block"
                  color="primary"
                  onClick={() => this.props.onChangeSkillHOC( 'showCreateSkillModal', true )}
                >
                  Add Skill
                </Button>   
              )
            }
          </CardHeader>
            <CardBody>
              <ReactTable
                pageSize={ 
                  this.props.studentSkill.data.length < 1? 3: 
                  this.props.studentSkill.data.length < 10? this.props.studentSkill.data.length:
                  10
                }
                data={ this.props.studentSkill.data }
                columns={[
                  {
                    Header: 'Skill Name',
                    accessor: 'name'
                  },
                  {
                    Header: 'Proficiency',
                    accessor: 'proficiency'
                  },
                  ...( mode === 'normal' ? [{
                    Header: 'Actions',
                    Cell: (row) => {
                      return (
                        <>
                          <Button
                            id="UpdateSkill"
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={() => {
                              this.props.getSelectedSkill(row.original.id, 'edit' )
                            }}>
                            <i className="pe-7s-pen btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="UpdateSkill" placement="left">Update student's skill from CV.</UncontrolledTooltip>
                          <Button
                            id="DeleteSkill"
                            className="mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={() => {
                              this.props.onChangeSkillHOC( 'showDeleteSkillModal', true )
                              this.props.onChangeSkillHOC( 'selectedSkill', row.original )
                            }}>
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="DeleteSkill" placement="left">Delete student's skill from CV.</UncontrolledTooltip>
                        </>
                      )
                    }
                  }] : [])
                ]}
                showPagination={false} />
              <Pagination 
                metadata={ this.props.studentSkill.meta } 
                onChangePage={ pg => {
                  let tempJSON = JSON.parse( atob( this.props.skillSearchQuery ))
                  tempJSON.page = pg
                  
                  this.props.getSkill( btoa( JSON.stringify( tempJSON )))
                }} />
            </CardBody>
          </Card>
        <CreateSkillForm
          {...this.props}
          open={ this.props.showCreateSkillModal }
          onClose={ () => this.props.onChangeSkillHOC( 'showCreateSkillModal', false )}
          onClickSubmit={ this.props.createSkill }
        />
        <UpdateSkillForm
          {...this.props}
          selectedFormData={ this.props.selectedSkill }
          open={ this.props.showUpdateSkillModal }
          onClose={ () => this.props.onChangeSkillHOC( 'showUpdateSkillModal', false )}
          onClickSubmit={ this.props.updateSkill }
        />
        <ConfirmationModal
          loading={ this.props.onLoadSkill }
          open={ this.props.showDeleteSkillModal}
          title={ `Delete` }
          message={ 
            <p>
              Are you sure you want to delete this <b>student's skill record</b> from his/her CV ? (This process can't be undone)
            </p>
          }
          onClose={ () => this.props.onChangeSkillHOC( 'showDeleteSkillModal', false )}
          onClickConfirm={ () => this.props.deleteSkill( this.props.selectedSkill.id )}/>
        { this.props.onLoadSkill && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  SkillHOC
)(Skill)