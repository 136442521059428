import React from "react";
import {
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom"

import Login from './containers/Login'
import CompanyRegistration from './containers/CompanyRegistration'
import StudentRegistration from './containers/StudentRegistration'
import Dashboard from './containers/Dashboards'
import { getItem } from './utils/tokenStore'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !getItem( 'USM_IMS_TOKEN' )
      ? <Redirect to='/login' />
      : <Component {...props} />
  )} />
);

function CustomRouter({ location }) {
  return (
    <Switch>
      <Route exact path="/login" component={ Login } initialPath />
      <Route exact path="/company-register" component={ CompanyRegistration }/>
      <Route exact path="/student-register" component={ StudentRegistration }/>
      
      <PrivateRoute path="/dashboard" component={ Dashboard } />
      <Redirect from={ '/' } to={ '/login' } />
    </Switch>
  );
}

export default withRouter( CustomRouter );