import React, { useState, useRef, useMemo } from 'react'
import { 
  Card, CardBody, CardHeader, CardFooter,
  FormGroup, Button,
  Label
} from 'reactstrap'
import { CloseOutline } from 'react-ionicons'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

export default function Cangred ({
  selectedStudent,
  cangred_pdf_url,

  submitCangred
}) {
  const [ tempCangred, onChangeFile ] = useState( null )

  let pondRef = useRef( null )

  const onDropFile = fileItems => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangeFile( fileItems[ 0 ].file )
    }
  }

  return (
    <Card className={ 'mt-3' }>
      <CardHeader style={{ fontSize: '1rem' }}>
        Student's Cangred
      </CardHeader>
      <CardBody>
        <FormGroup>
          <Label>Cangred Url</Label>
          {
            selectedStudent.cangred_pdf_url && (
              <strong 
                style={{ cursor: 'pointer', textDecoration: 'underline', wordBreak: 'break-all' }}
                className={ 'd-block'}
                onClick={ () => window.open( selectedStudent.cangred_pdf_url )}

              >
                { selectedStudent.cangred_pdf_url }
              </strong>
            )
          }
          {
            !selectedStudent.cangred_pdf_url && (
              <p>
                <strong>No cangred is uploaded</strong>
                <CloseOutline cssClasses={ 'text-danger ml-1' }/>
              </p>
            )
          }
        </FormGroup>
        <hr />
        <FormGroup>
          <FilePond
            allowMultiple={ false }
            fullWidth
            ref={ pondRef }
            acceptedFileTypes={[ "application/pdf"]}
            maxFiles={ 1 }
            onremovefile={ () => onChangeFile( null )}
            onupdatefiles={ fileItem => onDropFile( fileItem ) }
          />
        </FormGroup>
      </CardBody>
      <CardFooter>
        <Button 
          color={ tempCangred ? 'primary' : 'secondary' } 
          disabled={ !tempCangred }
          className="mr-2 mt-3"
          onClick={ () => submitCangred( selectedStudent.id, tempCangred, pondRef )}>
          Submit
        </Button>
      </CardFooter>
    </Card>
  )
}
