import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import Moment from 'moment'

import { Get } from 'utils/axios'
import { storeLastView }  from 'actions/lastView'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companyVacancies: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },

      selectedCompanyVacancies:  null,
      showDetailsCompanyVacanciesModal: false
    }

    onChangeCompanyHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    //getters
    getCompany = search => Get(
      `/companies?query=${ search }`,
      this.getCompanySuccess,
      this.getCompanyError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getCompanySuccess = payload => {
      let tempData = []

      payload.data.map(({ company }) => {
        tempData.push( company )
      })
      this.setState({ companies: tempData })
    }
    getCompanyError = error => this.requestError( error )

    getCompanyVacancies = search => {
      this.props.storeLastView({ search })
      Get(
        `/company_vacancies?query=${ search }`,
        this.getCompanyVacanciesSuccess,
        this.getCompanyVacanciesError,
        this.load,
        this.props.data.ProfileReducer.current_role_id
      )
    }
    getCompanyVacanciesSuccess = payload => {
      let tempData = []
      payload.data && payload.data.map( item => {
        tempData.push({
          company_name: item.company.name,
          company_state: item.company.state,
          has_applied: item.has_applied,
          ... item.company_vacancy,
          valid_until_date: item.company_vacancy.valid_until_date ? Moment(item.company_vacancy.valid_until_date).format("DD-MM-YYYY") : "Not Available"
        })
      })

      this.setState({ companyVacancies: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getCompanyVacanciesError = error => this.requestError( error )

    getSelectedCompanyVacancies = id => Get(
      `/company_vacancies/${ id }`,
      this.getSelectedCompanyVacanciesSuccess,
      this.getSelectedCompanyVacanciesError,
      this.load
    )
    getSelectedCompanyVacanciesSuccess = ({ company, company_vacancy }) => this.setState({ 
      selectedCompanyVacancies: { 
        ... company_vacancy,
        company_name: company.name,
        company_state: company.state,
        description: company.description,
        website: company.website,
        contact_person_designation_id: company.contact_person_designation_id,
        contact_person_name: company.contact_person_name,
        contact_person_email: company.contact_person_email,
        valid_until_date: company_vacancy.valid_until_date ? Moment(company_vacancy.valid_until_date).format("DD-MM-YYYY") : "Not Available"
      }, 
      showDetailsCompanyVacanciesModal: true 
    })
    getSelectedCompanyVacanciesError = error => this.requestError ( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          showDetailsCompanyVacanciesModal={ this.state.showDetailsCompanyVacanciesModal }
          selectedCompanyVacancies={ this.state.selectedCompanyVacancies }
          companyVacancies={ this.state.companyVacancies }
          onLoadCompany={ this.state.loading }

          getCompanyVacancies={ this.getCompanyVacancies }
          onChangeCompanyHOC={ this.onChangeCompanyHOC }
          getSelectedCompanyVacancies={ this.getSelectedCompanyVacancies }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, { storeLastView })( WithHOC )
}

export default HOC