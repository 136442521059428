import React, { Component } from 'react'
import { compose } from 'redux'
import { 
  Card, CardBody, CardHeader,
  Button, 
  UncontrolledTooltip 
} from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateEducationForm from './Create'
import UpdateEducationForm from './Edit'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'

import EducationHOC from './actions'

class Education extends Component {

  componentDidMount = () => {
    this.props.getEducation( btoa(`{ "page": 1, "filter": { "student_cvs.id": ${ this.props.selectedStudentCV.student_cv.id } }}`) )
  }

  render = () => {
    const { mode } = this.props
    // when mode is normal then actions will be shown
    return (
      <>
        <Card className="main-card mb-3">
          <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
            Student's Education
            {
              mode === 'normal' && (
                <Button
                  className="ml-auto btn-sm"
                  color="primary"
                  onClick={() => this.props.onChangeEducationHOC( 'showCreateEducationModal', true )}
                >
                  Add Education
                </Button>
              )
            }
          </CardHeader>
            <CardBody>
              <ReactTable
                pageSize={ 
                  this.props.studentEducation.data.length < 1? 3: 
                  this.props.studentEducation.data.length < 10? this.props.studentEducation.data.length:
                  10
                 }
                data={ this.props.studentEducation.data }
                columns={[
                  {
                    Header: 'Institute Name',
                    accessor: 'institute_name'
                  },
                  {
                    Header: 'Qualifications',
                    accessor: 'qualification'
                  },
                  {
                    Header: 'Major',
                    accessor: 'major'
                  },
                  {
                    Header: 'Start Date',
                    accessor: 'start_date'
                  },
                  {
                    Header: 'End Date',
                    accessor: 'end_date'
                  },
                  ...( mode === 'normal' ? [{
                    Header: 'Actions',
                    Cell: (row) => {
                      return (
                        <>
                          <Button
                            id="UpdateEducation"
                            className="mr-2 btn-icon btn-icon-only"
                            color="primary"
                            onClick={() => {
                              this.props.getSelectedEducation(row.original.id, 'edit' )
                            }}>
                            <i className="pe-7s-pen btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="UpdateEducation" placement="left">Update student's CV education info.</UncontrolledTooltip>
                          <Button
                            id="DeleteEducation"
                            className="mr-2 btn-icon btn-icon-only"
                            color="danger"
                            onClick={() => {
                              this.props.onChangeEducationHOC( 'showDeleteEducationModal', true )
                              this.props.onChangeEducationHOC( 'selectedEducation', row.original )
                            }}>
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="DeleteEducation" placement="left">Delete this student's CV education info.</UncontrolledTooltip>
                        </>
                      )
                    }
                  }] : [])
                ]}
                showPagination={false} />
              <Pagination 
                metadata={ this.props.studentEducation.meta } 
                onChangePage={ pg => {
                  let tempJSON = JSON.parse( atob( this.props.educationSearchQuery ))
                  tempJSON.page = pg
                  
                  this.props.getEducation( btoa( JSON.stringify( tempJSON )))
                }} />
            </CardBody>
          </Card>
        <CreateEducationForm
          {...this.props}
          open={ this.props.showCreateEducationModal }
          onClose={ () => this.props.onChangeEducationHOC( 'showCreateEducationModal', false )}
          onClickSubmit={ this.props.createEducation }
        />
        <UpdateEducationForm
          {...this.props}
          selectedFormData={ this.props.selectedEducation }
          open={ this.props.showUpdateEducationModal }
          onClose={ () => this.props.onChangeEducationHOC( 'showUpdateEducationModal', false )}
          onClickSubmit={ this.props.updateEducation }
        />
        <ConfirmationModal
          loading={ this.props.onLoadEducation }
          open={ this.props.showDeleteEducationModal}
          title={ `Delete` }
          message={  
            <p>
              Are you sure you want to delete this <b>student's education record</b> from his/her CV ? (This process can't be undone)
            </p>
          }
          onClose={ () => this.props.onChangeEducationHOC( 'showDeleteEducationModal', false )}
          onClickConfirm={ () => this.props.deleteEducation( this.props.selectedEducation.id )}/>
        { this.props.onLoadEducation && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  EducationHOC
)(Education)