
import React from 'react'
import ReactTable from 'react-table'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Modal, 
  ModalHeader,
  ModalBody
} from 'reactstrap'
import _ from 'lodash'

const RolesDetails = ({
  open,
  onClose,
  selectedRole,
  selectedSecModules
}) => {
  
  const renderBoolIcon = flag => (
    flag 
      ? <CheckmarkOutline cssClasses={ 'text-success' }/>
      : <CloseOutline cssClasses={ 'text-danger' }/>
  )
  
  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader 
        style={{ textTransform: 'uppercase' }}
        toggle={() => onClose()}>
        { `${ selectedRole.name } Sec Modules` }
      </ModalHeader>
      <ModalBody>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <ReactTable
            pageSize={ 
              selectedSecModules.length < 1? 3: 
              selectedSecModules.length
            }
            data={ selectedSecModules }
            columns={[
              {
                Header: 'Sec Modules',
                accessor: 'sec_modules'
              },
              {
                Header: 'Can Read',
                accessor: 'is_read',
                Cell: cell => renderBoolIcon( cell.original.is_read )
              },
              {
                Header: 'Can Create',
                accessor: 'is_create',
                Cell: cell => renderBoolIcon( cell.original.is_create )
              },
              {
                Header: 'Can Edit',
                accessor: 'is_update',
                Cell: cell => renderBoolIcon( cell.original.is_update )
              },
              {
                Header: 'Can Delete',
                accessor: 'is_delete',
                Cell: cell => renderBoolIcon( cell.original.is_delete )
              }
            ]
          }
            showPagination={false} />
        </ReactCSSTransitionGroup>
      </ModalBody>
    </Modal>
  )
}

export default RolesDetails