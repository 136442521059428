import React from 'react'
import _ from 'lodash'
import { 
  Row,
  Col,
  Modal, 
  ModalHeader,
  ModalBody,
  FormGroup, Label, Input,
} from 'reactstrap'
import OfferLetter from './offerLetter'
import PlacementForm from './placementForm'
import DutyReport from './dutyReport'
import InsuranceForm from './insuranceForm'
import JobScopeForm from './jobScopeForm'
import LecturerVisitSchedule from './visitSchedule'
import WeeklyLog from './WeeklyLog'
import EvaluationForm from './evaluationForm'

const studentDetail = [
  { label: 'Student Name', value: 'student_name', editable: false },
  { label: 'Email', value: 'student_email', editable: false },
  { label: 'Phone', value: 'student_phone', editable: false },
  { label: 'Company Name', value: 'company_name', editable: false }
]

const ColumnContent = props => {

  const {
    open,
    is_update,
    selectedAcceptedVacancies,
    studentAcceptedVacanciesDocHeader,

    onClose,
  } = props

  const renderDetailContent = () => {
    switch ( studentAcceptedVacanciesDocHeader ){
      case 'Offer Letter':
        return <OfferLetter { ...props } />
      case 'Placement Form':
        return <PlacementForm { ...props } />
      case 'Duty Report':
        return <DutyReport { ...props } />
      case 'Insurance Form':
        return <InsuranceForm { ...props } />
      case 'Job Scope Form':
        return <JobScopeForm { ...props } />
      case 'Lecturer Visit Schedule':
        return <LecturerVisitSchedule { ...props } />
      case 'Weekly Log':
        return <WeeklyLog { ...props } />
      case 'External Evaluation Form':
        return <EvaluationForm { ...props } />
      case 'Internal Evaluation Form':
        return <EvaluationForm { ...props } />
    }
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={ () => onClose()}>
        { studentAcceptedVacanciesDocHeader }
      </ModalHeader>
      <ModalBody>
        {
          ![ 'External Evaluation Form', 'Internal Evaluation Form' ].includes( studentAcceptedVacanciesDocHeader ) && (
            <>
              <Row>
                {
                  studentDetail.map( data => (
                    <Col lg={ 6 }>
                      <FormGroup>
                        <Label>{ data.label }</Label>
                        <Input
                          type={ 'text' }
                          value={ selectedAcceptedVacancies[data.value] }
                          disabled={ true }
                        />
                      </FormGroup>
                    </Col>
                  ))
                }
              </Row>
              <hr/>
            </>
          )
        }
        { renderDetailContent() }
      </ModalBody>
    </Modal>
  )
}

export default ColumnContent;