import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Collapse, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import ImportFileForm from 'components/importFileForm'
import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import LecturerForm from './Form'

import LecturersHOC from './actions'
  
class Lecturers extends Component {

  componentDidUpdate = pp => {
    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getLecturers( btoa(`{"page":1}`) )
    }
  }

  render = () => {
    const { is_create, is_delete, is_update } = this.props.currentPermission
    return (
      <>
        <PageTitle
          heading="Lecturers"
          subheading="Listings of all the lecturers present in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeLecturersHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... (
              is_create ? [{
                color: 'primary',
                onClick: () => {
                  this.props.onChangeLecturersHOC( 'showCreateLecturersModal', true )
                },
                className: 'mr-2',
                content: 'Create'
              },
              {
                color: 'secondary',
                onClick: () => {
                  this.props.onChangeLecturersHOC( 'showImportLecturersModal', !this.props.showImportLecturersModal )
                },
                content: 'Import'
              }] : []
            )
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeLecturersHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeLecturersHOC }
              getListAPI={ this.props.getLecturers }
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.lecturers.data.length < 1? 3: 
                      this.props.lecturers.data.length < 10? this.props.lecturers.data.length:
                      10
                    }
                    data={ this.props.lecturers.data }
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'name'
                      },
                      {
                        Header: 'Email',
                        accessor: 'email'
                      },
                      {
                        Header: 'Phone',
                        accessor: 'phone'
                      },
                      {
                        Header: 'Actions',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id="UpdateLecturer"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary' }
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.getSelectedLecturer(row.original.id)
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateLecturer" placement="left">Update the information of this lecturer</UncontrolledTooltip>
                              <Button
                                id="DeleteLecturer"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_delete ? "danger" : 'secondary' }
                                disabled={ !is_delete }
                                onClick={ () => {
                                  this.props.onChangeLecturersHOC( 'showDeleteLecturerModal', true )
                                  this.props.onChangeLecturersHOC( 'selectedDeleteLecturerId', row.original.id )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteLecturer" placement="left">Delete this Lecturer</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.lecturers.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getLecturers( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImportFileForm
            open={ this.props.showImportLecturersModal }
            context={ 'Lecturer' }
            onLoad={ this.props.onLoadLecturer }
            fileURL={ ` https://ims-dev-api.cs.usm.my/static/lecturer_sample_csv.csv` }
            onClose={ () => this.props.onChangeLecturersHOC( 'showImportLecturersModal', false )}
            onSubmitFile={ this.props.importLecturers }/>
          <LecturerForm
            {...this.props}
            mode={ 'Create' }
            open={ this.props.showCreateLecturersModal }
            onClose={ () => this.props.onChangeLecturersHOC( 'showCreateLecturersModal', false )}
            onClickSubmit={ this.props.createLecturer }
          />
          <LecturerForm
            {...this.props}
            mode={ 'Edit' }
            selectedFormData={ this.props.selectedLecturer }
            open={ this.props.showUpdateLecturersModal }
            onClose={ () => this.props.onChangeLecturersHOC( 'showUpdateLecturersModal', false )}
            onClickSubmit={ this.props.updateLecturer }
          />
          <ConfirmationModal
            open={ this.props.showDeleteLecturerModal }
            title={ `Delete Lecturer` }
            loading={ this.props.onLoadLecturer }
            message={ `Are you sure you want to delete this lecturer? (This process can't be undone)` }
            onClose={ () => this.props.onChangeLecturersHOC( 'showDeleteLecturerModal', false )}
            onClickConfirm={ () => this.props.deleteLecturer( this.props.selectedDeleteLecturerId )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadLecturer && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  LecturersHOC
)(Lecturers)