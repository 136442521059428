import { useRef, useState } from 'react'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, 
  FormGroup, Label, Button
} from 'reactstrap'
import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const CreateOfferLetter = props => {
  const pond = useRef(null)
  const [ offerLetterFile, onChangeFile ] = useState( null )

  const onDropFile = ( fileItems ) => {
    if( fileItems.length > 0 && fileItems[ 0 ].file ) {
      onChangeFile( fileItems[ 0 ].file )
    }
  }
  return (
    <Modal isOpen={ props.showCreateOfferLetter }>
      <ModalHeader toggle={() => props.onChangeOfferLetterHOC( 'showCreateOfferLetter', false )}>Create offer letter</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Upload Document</Label>
          <FilePond
            ref={ pond }
            allowMultiple={ false }
            fullWidth
            acceptedFileTypes={['image/*','application/pdf']}
            maxFiles={ 1 }
            onremovefile={ () => onChangeFile( null )}
            onupdatefiles={ onDropFile }
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ !offerLetterFile ? 'secondary': 'primary' }
          className="mr-2"
          disabled={ !offerLetterFile }
          onClick={() => {
            props.createOfferLetter( offerLetterFile, () => {
              pond.current.removeFiles()
              onChangeFile( null )
            })
          }}>
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateOfferLetter