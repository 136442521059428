import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import Moment from 'moment'

import { Get, Post, Put, Delete } from 'utils/axios'

const searchParams = [
  { 
    label: 'Start Date', 
    value: 'start_date',
    type: 'date',
    param: '',
    searchMode: '$gt',
    col: 6
  }
]
const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      studentEducation: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedEducation: {},
      searchParams: searchParams,

      showCreateEducationModal: false,
      showDeleteEducationModal: false,
      showUpdateEducationModal: false,
      showSearchForms: false,

      educationSearchQuery: ''
    }

    onChangeEducationHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getEducation = search => {
      this.setState({ educationSearchQuery: search }, () => {
        Get(
          `/student_cv_educations?query=${ search }`,
          this.getEducationSuccess,
          this.getEducationError,
          this.load,
          this.props.data.ProfileReducer.current_role_id
        )
      })
    }
    getEducationSuccess = payload => {
      let tempData = []
      payload.data?.[0] && payload.data.map(({ student_cv_education }) => {
        tempData.push({
          ... student_cv_education,
          start_date: Moment.utc( student_cv_education.start_date ).format( 'DD-MM-yyyy' ),
          end_date: Moment.utc( student_cv_education.end_date ).format( 'DD-MM-yyyy' ),
        })
      })
      this.setState({ studentEducation: {
        data: tempData,
        meta: payload.meta
      }})
    }
    getEducationError = error => this.requestError( error )

    getSelectedEducation = id => {
      Get(
        `/student_cv_educations/${ id }`,
        this.getSelectedEducationSuccess,
        this.getSelectedEducationError,
        this.load
      )
    }
    getSelectedEducationSuccess = ({ student_cv_education }) => {
      let tempStartDate = new Date( student_cv_education.start_date )  
      let tempEndDate = new Date( student_cv_education.end_date )
      var userTimezoneOffset = tempStartDate.getTimezoneOffset() * 60000;

      this.setState({   
        selectedEducation: {
          ... student_cv_education,
          start_date: new Date(tempStartDate.getTime() + userTimezoneOffset ),
          end_date: new Date(tempEndDate.getTime() + userTimezoneOffset ),
        }, 
        showUpdateEducationModal: true
      })
    }
    getSelectedEducationError = error => this.requestError ( error )

    createEducation = dataToSubmit => Post(
      `/student_cv_educations`,
      dataToSubmit,
      this.createEducationSuccess,
      this.createEducationError,
      this.load
    )
    createEducationSuccess = () => {
      this.getEducation( this.state.educationSearchQuery )
      this.setState({ showCreateEducationModal: false })
      this.requestSuccess( 'Education has been created successfully.' )
    }
    createEducationError = error => this.requestError( error )

    deleteEducation = id => Delete( 
      `/student_cv_educations/${ id }`,
      this.deleteEducationSuccess,
      this.deleteError,
      this.load
    )
    deleteEducationSuccess = () => {
      this.getEducation( this.state.educationSearchQuery )
      this.setState({ showDeleteEducationModal: false })
      this.requestSuccess( 'Education has been deleted succesfully.' )
    }
    deleteError = error => this.requestError( error )

    updateEducation = dataToSubmit => Put(
      `/student_cv_educations/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateEducationSucces,
      this.updateEducationError,
      this.load
    )
    updateEducationSucces = ({ student_cv_education }) => {
      this.getEducation( this.state.educationSearchQuery )
      this.getSelectedEducation( student_cv_education.id )
      this.requestSuccess( 'Education was updated successfully.' )
    }
    updateEducationError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          educationSearchQuery={ this.state.educationSearchQuery }
          searchParams={ this.state.searchParams }
          studentEducation={ this.state.studentEducation }
          onLoadEducation={ this.state.loading }
          selectedEducation={ this.state.selectedEducation }
          showCreateEducationModal={ this.state.showCreateEducationModal }
          showDeleteEducationModal={ this.state.showDeleteEducationModal }
          showUpdateEducationModal={ this.state.showUpdateEducationModal }
          showSearchForms={ this.state.showSearchForms }
          
          getEducation={ this.getEducation }
          getSelectedEducation={ this.getSelectedEducation }
          createEducation={ this.createEducation }
          deleteEducation={ this.deleteEducation }
          updateEducation={ this.updateEducation }
          onChangeEducationHOC={ this.onChangeEducationHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC