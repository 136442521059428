export default () => {
  if( window.location.host.indexOf( 'localhost' ) !== -1 ) {
    return 'https://ims-dev-api.cs.usm.my';
  } else if( window.location.host.indexOf( 'ims-dev.cs.usm.my' ) !== -1 ) {
    return 'https://ims-dev-api.cs.usm.my';
  } else if( window.location.host.indexOf( 'ims-new.cs.usm.my' ) !== -1 ) {
    return 'https://ims-api.cs.usm.my';
  } else if( window.location.host.indexOf( 'ims-new.cs.usm.my' ) !== -1 ) {
    return 'https://ims-api.cs.usm.my';
  }
}