import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'
import { storeLastView } from 'actions/lastView'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      industryCat: [],
      countries: [],
      states: []
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getIndustryCat = () => Get(
      `/industry_categories?query=${ btoa(`{"page": 0}`)}`,
      this.getIndustryCatSuccess,
      this.getIndustryCatError,
      this.load
    )
    getIndustryCatSuccess = payload => this.setState({ industryCat: payload.data })
    getIndustryCatError = error => this.requestError( error )

    getCountry = () => Get(
      `/countries`,
      this.getCountrySuccess,
      this.getCountryError,
      this.load
    )
    getCountrySuccess = payload => this.setState({ countries: payload })
    getCountryError = error => this.requestError( error )

    getCountryState = id => Get(
      `/countries/${ id }/states`,
      this.getCountryStateSuccess,
      this.getCountryStateError,
      this.load
    )
    getCountryStateSuccess = payload => this.setState({ states: payload })
    getCountryStateError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          states={ this.state.states }
          countries={ this.state.countries }
          onLoadDictionary={ this.state.loading }
          industryCat={ this.state.industryCat }


          getCountryState={ this.getCountryState }
          getCountry={ this.getCountry }
          getIndustryCat={ this.getIndustryCat }
          getDesignations={ this.getDesignations }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps, {
    storeLastView
  } )( WithHOC )
}

export default HOC