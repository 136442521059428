import React, { useEffect, useState } from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import _ from 'lodash'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const defaultValue = {
  name: '',
}

const FormContent = ({
  mode,
  open,
  onClose,
  onLoadIndustryCat,
  selectedFormData,
  onClickSubmit
}) => {

  const [ IndustryCatData, onChangeIndustryCatData ] = useState( defaultValue )

  useEffect(() => {
    if ( open && mode === 'Edit' ){
      onChangeIndustryCatData( selectedFormData )
    } 
    if ( !open ){
      onChangeIndustryCatData( defaultValue )
    }
  }, [open])

  useEffect(() => {
    mode === 'Edit' && onChangeIndustryCatData( selectedFormData )
  }, [ selectedFormData ])

  const onChangeIndustryCatField = ( key, val ) => {
    let tmp = _.cloneDeep( IndustryCatData )
    tmp[ key ] = val
    return onChangeIndustryCatData( tmp )
  }

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `${ mode } Industry Category`}</ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>
              Category Name
              <span className={ 'text-danger' }> *</span>
            </Label>
            <Input
              type={ 'text' }
              value={ IndustryCatData.name }
              onChange={ e => {
                onChangeIndustryCatField( 'name', e.target.value )
              }} />
          </FormGroup>
        </Form>
        { onLoadIndustryCat && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button 
          className="mr-2"
          color={ 
            _.values( IndustryCatData ).some( item => !item )
              ? 'secondary' 
              : 'primary'
          }
          disabled={ _.values( IndustryCatData ).some( item => !item )}
          onClick={() => {
            onClickSubmit( IndustryCatData )
          }}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default FormContent