import React, { Component } from 'react'
import { compose } from 'redux'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, 
  Card, CardBody, CardHeader,
  Button, UncontrolledTooltip, Collapse,
  Modal, ModalHeader
} from 'reactstrap'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import EditInsurance from './Edit'
import UploadTemplate from './UploadTemplate'

import InsurancesHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

class Insurances extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getInsurances( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
      this.props.getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ current_selected_session_id } }}`))
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getInsurances( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, current_selected_session_id, 's.session_id' ))) )
      this.props.getSessions( btoa(`{ "page": 1, "filter":{ "sessions.id": ${ current_selected_session_id } }}`))
    }
  }

  renderNonStudentContent = () => {
    const { is_delete, is_update } = this.props.currentPermission

    return (
      <>
        <Collapse
          isOpen={ this.props.showSearchForms }
          onExiting={ () => this.props.onChangeInsurancesHOC( 'showSearchForms', false )}
        >
          <SearchForm
            searchParams={ this.props.searchParams }
            onChangeHOCState={ this.props.onChangeInsurancesHOC }
            getListAPI={ val => {
              let tempJSON = JSON.parse( atob( val ))
              tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 's.session_id' )
              
              this.props.getInsurances( btoa( JSON.stringify( tempJSON )) )
            }}
          />
        </Collapse>
        <Row>
          <Col md={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={ 
                    this.props.insurances.data.length < 1? 3: 
                    this.props.insurances.data.length < 10? this.props.insurances.data.length:
                    10
                  }
                  data={ this.props.insurances.data }
                  columns={[
                    {
                      Header: 'No.',
                      accessor: 'number'
                    },
                    {
                      Header: 'Student Name',
                      accessor: 'student_name'
                    },
                    {
                      Header: 'Company Name',
                      accessor: 'company_name'
                    },
                    {
                      Header: 'Approval Status',
                      Cell: cell =>(
                        <>
                          {
                            cell.original.is_clerk_approved 
                              ? <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                              : <CloseOutline cssClasses={ 'text-danger' } style={{ width: 18, height: 18 }}/>
                          }
                        </>
                      )
                    },
                    {
                      Header: 'Actions',
                      Cell: (row) => {
                        return (
                          <>
                            <Button
                              id="DownloadInsurance"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ 'warning' }
                              disabled={ !row.original.doc_url }
                              onClick={() => {
                                window.open( row.original.doc_url, '_blank' )
                              }}
                            >
                              <AiOutlineCloudDownload style={{ width: 17, height: 17 }}/>
                            </Button>
                            <UncontrolledTooltip target="DownloadInsurance" placement="left">
                              Download Insurance Form.
                            </UncontrolledTooltip>
                            <Button
                              id="UpdateLecturer"
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_update ? "primary" : 'secondary' }
                              disabled={ !is_update }
                              onClick={() => {
                                this.props.onChangeInsurancesHOC( 'selectedInsurance', row.original )
                                this.props.onChangeInsurancesHOC( 'showUpdateInsurancesModal', true )
                              }}>
                              <i className="pe-7s-pen btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="UpdateLecturer" placement="left">Update the information of this insurance</UncontrolledTooltip>
                            <Button
                              id="DeleteInsurance"
                              disabled={ !is_delete }
                              className="mr-2 btn-icon btn-icon-only"
                              color={ is_delete ? "danger" : 'secondary' } 
                              onClick={() => {
                                this.props.onChangeInsurancesHOC( 'showDeleteInsuranceModal', true )
                                this.props.onChangeInsurancesHOC( 'selectedInsurance', row.original )
                              }}>
                              <i className="pe-7s-close btn-icon-wrapper"> </i>
                            </Button>
                            <UncontrolledTooltip target="DeleteInsurance" placement="left">Delete this Insurance</UncontrolledTooltip>
                          </>
                        )
                      }
                    }
                  ]}
                  showPagination={false} />
                <Pagination 
                  metadata={ this.props.insurances.meta } 
                  onChangePage={ pg => {
                    const { search } = this.props.data.LastViewReducer.lastView
                    let tempJSON = JSON.parse( atob( search ))
                    tempJSON.page = pg
                    
                    this.props.getInsurances( btoa( JSON.stringify( tempJSON )))
                  }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={ this.props.showUpdateInsurancesModal }>
          <ModalHeader toggle={ () => this.props.onChangeInsurancesHOC( 'showUpdateInsurancesModal', false )}>
            Edit Insurance
          </ModalHeader>
          <EditInsurance
            data={ this.props.data }
            onLoadInsurance={ this.props.onLoadInsurance }
            selectedInsurance={ this.props.selectedInsurance }
            updateInsurance={ this.props.updateInsurance }
            uploadInsuranceDoc={ this.props.uploadInsuranceDoc }
          />
        </Modal>
        <Modal isOpen={ this.props.showUploadInsuranceTemplate }>
          <ModalHeader toggle={ () => this.props.onChangeInsurancesHOC( 'showUploadInsuranceTemplate', false )}>
            Edit Empty Insurance Form
          </ModalHeader>
          <UploadTemplate
            data={ this.props.data }
            session={ this.props.sessions }
            onLoadInsurance={ this.props.onLoadInsurance }
            uploadInsuranceTemplate={ this.props.uploadInsuranceTemplate }
          />
        </Modal>
        <ConfirmationModal
          loading={ this.props.onLoadInsurance }
          open={ this.props.showDeleteInsuranceModal }
          title={ `Delete Insurance` }
          message={ `Are you sure you want to delete this insurance? (This process can't be undone)` }
        
          onClose={ () => this.props.onChangeInsurancesHOC( 'showDeleteInsuranceModal', false )}
          onClickConfirm={ () => this.props.deleteInsurance( this.props.selectedDeleteInsuranceId )}/>
      </>
    )
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Insurances"
          subheading="Listings of all the insurance files present in the system."
          icon="pe-7s-speaker icon-gradient bg-happy-itmeo"
          buttons={ this.props.data.ProfileReducer.current_role_id !== 13 ? [
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeInsurancesHOC( 'showUploadInsuranceTemplate', true )
              },
              content:<>
                <i className="pe-7s-cloud-upload btn-icon-wrapper"> </i>
                <span>Upload Template</span>
              </>
            },
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeInsurancesHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            }
          ] : [
            {
              className: 'bg-transparent border-0 p-0',
              content:<>
                <Button 
                  id='DownloadInsuranceTemplate' color='warning'
                  className='mr-2 btn-icon btn-icon-only'
                  onClick={() => {
                    window.open(this.props.sessions?.student_insurance_form_template_url)
                  }}
                >
                  <i className="pe-7s-cloud-download btn-icon-wrapper">
                </i></Button>
                <UncontrolledTooltip target="DownloadInsuranceTemplate" placement="left">Download Empty Insurance Form</UncontrolledTooltip>
              </>
            }
          ] } />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          { this.props.data.ProfileReducer.current_role_id !== 13 && this.renderNonStudentContent() }
          { this.props.data.ProfileReducer.current_role_id === 13 && 
            this.props.insurances.data && ( 
            <>
              {
                (
                  <Card>
                    <EditInsurance
                      data={ this.props.data }
                      onLoadInsurance={ this.props.onLoadInsurance }
                      selectedInsurance={ this.props.insurances.data[0] }
                      updateInsurance={ this.props.updateInsurance }
                      uploadInsuranceDoc={ this.props.uploadInsuranceDoc }
                    />
                  </Card>
                )
              }
            </>
          )}
        </ReactCSSTransitionGroup>
        { this.props.onLoadInsurance && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  InsurancesHOC
)(Insurances)