import React, { useEffect } from 'react' 
import { 
   Button,
   FormGroup,
   Input,
   Card, CardHeader, CardBody,
   UncontrolledTooltip
 } from 'reactstrap'
 import _ from 'lodash'
 import ReactTable from 'react-table'

import { GradeOptions, MuetGrade } from '../../../asset'
import Pagination from 'components/Pagination'
import ConfirmationModal from 'components/Modal/confirmation'
import CreateCourse from './Create'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import PrerequisiteHOC from '../../../actions/prerequisite'

const PrerequisiteContent = ({
  data,
  courses,
  selectedFormData,
  onLoadCourses,
  selectedCourse,
  prerequesiteSearch,
  showCreateCourseModal,
  showDeleteCourseModal,

  getCourses,
  updateCourse,
  createCourse,
  deleteCourse,
  onChangeCoursesHOC
}) => {
  
  useEffect(() => {
    getCourses( btoa(`{"page":1, "filter": { "student_principal_courses.student_id": ${ selectedFormData.id } }}`) )
  }, [])

  const onChangPrerequisiteField = ( index, context, val ) => {
    let temp = _.cloneDeep( courses.data )
    temp[index][context] = val
    onChangeCoursesHOC( 'courses', { data: temp, meta: courses.meta } )
  }
  return (
    <>
      <Card className="main-card mt-3">
        <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
          Prerequisite Courses
          <Button
            className="ml-auto"
            color="primary"
            onClick={() => onChangeCoursesHOC( 'showCreateCourseModal', true )}
          >
            Add Course
          </Button>
        </CardHeader>
        <CardBody>
          <ReactTable
            pageSize={ 
              courses.data.length < 1? 3: 
              courses.data.length < 10? courses.data.length:
              10
            }
            data={ courses.data }
            columns={[
              {
                Header: 'Course Name',
                accessor: 'principal_course'
              },
              {
                Header: 'Grade',
                Cell: row => {
                  return (
                    <Input
                      type={ 'select' }
                      value={ row.original.grade }
                      onChange={ e => onChangPrerequisiteField( row.index ,'grade', e.target.value )}
                    >
                      <option value={ null }></option>
                      { 
                        (row.original.principal_course_id === 4 ? MuetGrade : GradeOptions ).map( grade => (
                          <option key={ grade } value={ grade }>{ grade }</option>
                        ))
                      }
                    </Input>
                  )
                }
              },
              {
                Header: 'If currently repeating or taking the course',
                Cell: row => {
                  return (
                    <FormGroup checked style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                      <Input 
                        checked={ row.original.is_current_repeat_course } 
                        type="checkbox" 
                        onChange={ e => onChangPrerequisiteField( row.index, 'is_current_repeat_course', e.target.checked )}/>
                    </FormGroup>
                  )
                }
              },
              {
                Header: 'Actions',
                Cell: row => {
                  return (
                    <>
                      <Button
                        id="UpdateCourses"
                        className="mr-2 btn-icon btn-icon-only"
                        color="primary"
                        onClick={() => updateCourse({
                          id: row.original.id,
                          grade: row.original.grade,
                          is_current_repeat_course: row.original.is_current_repeat_course,
                          principal_course_id: row.original.principal_course_id ,
                          student_id: row.original.student_id
                        })}
                      >
                        Update
                      </Button>
                      <UncontrolledTooltip target="UpdateCourses" placement="left">Update the information of this company</UncontrolledTooltip>
                      <Button
                        id="DeleteStudent"
                        className="mr-2 btn-icon btn-icon-only"
                        color="danger"
                        onClick={ () => {
                          onChangeCoursesHOC( 'showDeleteCourseModal', true )
                          onChangeCoursesHOC( 'selectedCourse', row.original )
                        }}>
                        <i className="pe-7s-close btn-icon-wrapper"> </i>
                      </Button>
                      <UncontrolledTooltip target="DeleteStudent" placement="left">Delete this Course</UncontrolledTooltip>
                    </>
                  )
                }
              }
            ]}
            showPagination={ false } />
          <Pagination 
            metadata={ courses.meta } 
            onChangePage={ pg => {
              let tempJSON = JSON.parse( atob( prerequesiteSearch ))
              tempJSON.page = pg
              
              getCourses( btoa( JSON.stringify( tempJSON )))
            }} />
          { onLoadCourses && <LoadingOverlay/> }
        </CardBody>
      </Card>
      <ConfirmationModal
        open={ showDeleteCourseModal }
        title={ `Delete Course` }
        loading={ onLoadCourses }
        message={ `Are you sure you want to delete this course? (This process can't be undone)` }
        onClose={ () => onChangeCoursesHOC( 'showDeleteCourseModal', false )}
        onClickConfirm={ () => deleteCourse( selectedCourse.id )}/>
      <CreateCourse
        open={ showCreateCourseModal }
        student_id={ selectedFormData.id }
        data={ data }
        onLoadCourse={ onLoadCourses }
        
        onClose={ () => onChangeCoursesHOC( 'showCreateCourseModal', false )}
        onClickSubmit={ createCourse }
      />
    </>
  )
}

export default PrerequisiteHOC( PrerequisiteContent );