import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Collapse, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import ReactTable from 'react-table'
import _ from 'lodash'

import SearchForm from 'components/searchForm'
import Pagination from 'components/Pagination'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import EditAdvisor from './Edit'
import CreateAdvisor from './Create'

import AdvisorHOC from './actions/index'
import StudentHOC from './actions/user'
import sessionQueryHandler from 'utils/sessionQueryHandler'
  
class Advisor extends Component {

  componentDidUpdate = pp => {
    const { current_selected_session_id } = this.props.data.SessionReducer

    if ( pp.currentPermission !== this.props.currentPermission && this.props.currentPermission.is_read ){
      this.props.getAdvisor( btoa( JSON.stringify( sessionQueryHandler({ "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }

    if ( pp.data.SessionReducer.current_selected_session_id !== current_selected_session_id 
      && current_selected_session_id 
    ) {
      this.props.getAdvisor( btoa( JSON.stringify( sessionQueryHandler({ "page": 1 }, current_selected_session_id, 's.session_id' ))) )
    }
  }

  render = () => {
    const { is_create, is_update } = this.props.currentPermission
    return (
      <>
        <PageTitle
          heading="Assign Academic Advisor"
          subheading="Listings of all the Academic Advisors present in the system."
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: 'warning',
              className: 'btn-icon mr-2',
              onClick: () => {
                this.props.onChangeAdvisorHOC( 'showSearchForms', !this.props.showSearchForms )
              },
              content:<>
                <i className="pe-7s-search btn-icon-wrapper"> </i>
                <span>Search</span>
              </>
            },
            ... ( is_create ? [{
              color: 'primary',
              onClick: () => {
                this.props.onChangeAdvisorHOC( 'showCreateModal', true )
              },
              className: 'mr-2',
              content: 'Create'
            }] : [])
          ]} />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Collapse
            isOpen={ this.props.showSearchForms }
            onExiting={ () => this.props.onChangeAdvisorHOC( 'showSearchForms', false )}
          >
            <SearchForm
              searchParams={ this.props.searchParams }
              onChangeHOCState={ this.props.onChangeAdvisorHOC }
              getListAPI={ val => {
                let tempJSON = JSON.parse( atob( val ))
                tempJSON = sessionQueryHandler( tempJSON, this.props.data.SessionReducer.current_selected_session_id, 's.session_id' )

                this.props.getAdvisor( btoa( JSON.stringify( tempJSON )) )
              }}
            />
          </Collapse>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    pageSize={ 
                      this.props.academicAdvisors.data.length < 1? 3: 
                      this.props.academicAdvisors.data.length < 10? this.props.academicAdvisors.data.length:
                      10
                    }
                    data={ this.props?.academicAdvisors?.data??[] }
                    columns={[
                      {
                        Header: 'Student',
                        accessor: 'studentName'
                      },
                      {
                        Header: 'Academic Advisor',
                        accessor: 'advisorName'
                      },
                      {
                        Header: 'Actions',
                        Cell: row => {
                          return (
                            <>
                              <Button
                                id="UpdateAdvisor"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "primary" : 'secondary'}
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.onChangeAdvisorHOC( 'selectedAdvisor', row.original )
                                  this.props.onChangeAdvisorHOC( 'showUpdateModal', true )
                                  this.props.getSelectedAdvisor(row.original.id)
                                }}>
                                <i className="pe-7s-pen btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="UpdateAdvisor" placement="left">Update the information of this adademic advisor</UncontrolledTooltip>
                              <Button
                                id="DeleteAdvisor"
                                className="mr-2 btn-icon btn-icon-only"
                                color={ is_update ? "danger" : 'secondary'}
                                disabled={ !is_update }
                                onClick={() => {
                                  this.props.onChangeAdvisorHOC( 'selectedAdvisor', row.original )
                                  this.props.onChangeAdvisorHOC( 'showDeleteModal', true )
                                }}>
                                <i className="pe-7s-close btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DeleteAdvisor" placement="left">Delete this adademic advisor</UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]}
                    showPagination={ false } />
                  <Pagination 
                    metadata={ this.props.academicAdvisors.meta } 
                    onChangePage={ pg => {
                      const { search } = this.props.data.LastViewReducer.lastView
                      let tempJSON = JSON.parse( atob( search ))
                      tempJSON.page = pg
                      
                      this.props.getAdvisor( btoa( JSON.stringify( tempJSON )))
                    }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CreateAdvisor
            { ... this.props }
            open={ this.props.showCreateModal }
            onClose={ () => this.props.onChangeAdvisorHOC( 'showCreateModal', false )}
            onClickSubmit={ this.props.createAdvisor }
          />
          <EditAdvisor
            { ... this.props }
            open={ this.props.showUpdateModal }
            selectedFormData={ this.props.selectedAdvisor }
            onClose={ () => this.props.onChangeAdvisorHOC( 'showUpdateModal', false )}
            onClickSubmit={ this.props.updateAdvisor }
          />
          <ConfirmationModal
            open={ this.props.showDeleteModal }
            title={ `Delete Advisor` }
            loading={ this.props.onLoadAdvisor }
            message={ `Are you sure you want to delete this academic advisor? (This process can't be undone)` }
            onClose={ () => this.props.onChangeAdvisorHOC( 'showDeleteModal', false )}
            onClickConfirm={ () => this.props.deleteAdvisor( this.props.selectedAdvisor.id )}/>
        </ReactCSSTransitionGroup>
        { this.props.onLoadAdvisor && <LoadingOverlay/> }
      </>
    )
  }
}

export default compose(
  AdvisorHOC,
  StudentHOC
)(Advisor)