import React, { useEffect, useState } from 'react'
import { 
  Row, Col,
  FormGroup, Label, Input,
  Card, CardHeader,CardBody,
  Button, UncontrolledTooltip
} from 'reactstrap'
import { CheckmarkOutline, CloseOutline } from 'react-ionicons'

const FieldData = [
  { label: 'HR Officer', value: 'hr_officer_name', type: 'text', editable: false },
  { label: 'HR Officer Email', value: 'hr_officer_email', type: 'text', editable: false },
  { label: 'HR Officer Phone', value: 'hr_officer_phone', type: 'text', editable: false },
  { label: 'HR Officer Mobile', value: 'hr_officer_mobile_phone', type: 'text', editable: false },
  { label: 'Duty Report Date', value: 'report_duty_date', type: 'text', editable: false },
  { label: 'Duty Report URL', value: 'url', type: 'url', editable: false },
  { label: 'Reviewed', value: 'is_reviewed', type: 'boolean', editable: false },
]

const DutyReport = props => {

  const { student_report_duty_form, student_report_duty_form_id, student_name } = props.selectedAcceptedVacancies
  const { is_update } = props
  const [ formContent, setFormContent ] = useState({
    allowance_amount: "",
    hr_officer_email: "",
    hr_officer_mobile_phone: "",
    hr_officer_name: "",
    hr_officer_phone: "",
    is_change_job_scope: false,
    is_have_allowance: "",
    url: "",
    report_duty_date: null,
    is_reviewed: null,
  })

  useEffect( () => {
    if( student_report_duty_form ){
      setFormContent({ 
        ...student_report_duty_form,
        url: `https://ims-dev-api.cs.usm.my/api/v1/student_report_duty_forms/pdf/${ student_report_duty_form_id }` 
      })
    }
  }, [ props.selectedAcceptedVacancies ])

  return(
    <Card className="mt-2 mb-3">
      <CardHeader style={{ fontSize: '1rem', display: 'flex' }}>
        Report Details
      </CardHeader>
      <CardBody>
        <Row>
          {
            FieldData.map( data => (
              <Col lg={ 6 }>
                <FormGroup>
                  <Label>{ data.label }</Label>
                  {
                    data.type === 'boolean' && (
                      formContent[data.value]
                        ? <CheckmarkOutline cssClasses={ 'text-success d-block' }/>
                        : <CloseOutline cssClasses={ 'text-danger d-block' }/>
                    )
                  }
                  {
                    data.type === 'url' && (
                      <div>
                        {
                          formContent[data.value]
                          ? <div>
                              <Button
                                id="DownloadDutyReport"
                                className="mr-2 btn-icon btn-icon-only"
                                color="warning"
                                onClick={() => props.getStudentReportDuty_pdf(student_report_duty_form_id, student_name) }
                              >
                                <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                              </Button>
                              <UncontrolledTooltip target="DownloadDutyReport" placement="right">Download this duty report</UncontrolledTooltip>
                            </div>
                          : <span className='student-vacancy__detail-subtitle'>N/A</span>
                        }
                      </div>
                    )
                  }
                  {
                    data.type !== 'boolean' && data.type !== 'url' && (
                      <Input
                        type={ data.type }
                        value={ formContent[data.value] || 'N/A' }
                        disabled={ true }
                      />
                    )
                  }
                </FormGroup>
              </Col>
            ))
          }
          <Col md={ 6 }>
            <Label>Allowance</Label>
              {
                formContent.is_have_allowance
                  ? <span className='student-vacancy__detail-subtitle'>{ formContent.allowance_amount || 'N/A' }</span>
                  : <CloseOutline cssClasses={ 'text-danger d-block' }/>
              }
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default DutyReport;