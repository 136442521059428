import React, { useEffect } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Modal, ModalBody, ModalHeader,
  Row, Col,
  Button, UncontrolledTooltip,
  Input
} from 'reactstrap'
import { CheckmarkOutline } from 'react-ionicons'
import ReactTable from 'react-table'
import Moment from 'moment'
import _ from 'lodash'

import Pagination from 'components/Pagination'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'

import StudentVacanciesHOC from './actions'
import sessionQueryHandler from 'utils/sessionQueryHandler'

const FormContent = ({
  open,
  data,
  onClose,
  studentVacancies,
  studentVacancyStatus,
  showDeleteStudentVacanciesModal,
  showUpdateStudentVacanciesModal,
  selectedDeleteStudentVacanciesId,
  selectedUpdateStudentVacancies,
  onLoadStudentVacancies,

  deleteStudentVacancies,
  updateStudentVacancy,
  onChangeAppliedStudentStatus,
  onChangeStudentConfirmation,
  onChangeStudentVacanciesHOC,
  getStudentVacancies
}) => {

  useEffect(() => {
    open && 
    getStudentVacancies( btoa( JSON.stringify( sessionQueryHandler( { "page": 1 }, data.SessionReducer.current_selected_session_id, 'students.session_id' ))) )
  }, [open] )

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `View Vacancies` }</ModalHeader>
      <ModalBody>
      <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col xs={ 12 }>
              <ReactTable
                pageSize={ 
                  studentVacancies.data.length < 1? 3: 
                  studentVacancies.data.length < 10? studentVacancies.data.length:
                  10
                }
                data={ studentVacancies.data }
                columns={[
                  {
                    Header: 'Student Name',
                    accessor: 'student_name'
                  },
                  {
                    Header: 'Company Name',
                    accessor: 'company_name'
                  },
                  {
                    Header: 'Job Title',
                    accessor: 'job_title'
                  },
                  // {
                  //   Header: 'Vacancy Status',
                  //   accessor: 'student_vacancy_status'
                  // },
                  {
                    Header: 'Vacancy Status',
                    accessor: 'status_id',
                    Cell: data => (
                      <Input
                        type={ 'select' }
                        disabled={ true }
                        value={ data.original.student_vacancy_status_id }>
                        <option value={ '' }></option>
                        { 
                          studentVacancyStatus.data.map( item => (
                            <option key={ item.id } value={ item.id }>{ item.name }</option>
                          ))
                        }
                      </Input>
                    )
                  },
                  {
                    Header: 'Student Confirmation',
                    accessor: 'student_confirmation',
                    Cell: data => {
                      return (
                        !data.original.disableStatusField ? (
                          <Input
                            type={ 'select' }
                            disabled={ data.original.allowChange }
                            value={ data.original.student_confirmation }
                            onChange={ e => onChangeStudentConfirmation( e.target.value, data.index) }>
                            <option value={ '' }></option>
                            <option value={ false }>False</option>
                            <option value={ true }>True</option>
                          </Input>
                        ): (
                          <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CheckmarkOutline cssClasses={ 'text-success' } style={{ width: 18, height: 18 }}/>
                          </div>
                        )
                      )
                    }
                  },
                  {
                    Header: 'Applied On',
                    Cell:(row) => {
                      return (
                        <span>
                          { Moment( row.original.created_at ).format( 'DD-MM-yyyy h:mm a' ) }
                        </span>
                      )
                    }
                  },
                  {
                    Header: 'Actions',
                    Cell: (row) => {
                      return (
                        <>
                          <Button
                            id="UpdateStudentVacancies"
                            className="mr-2"
                            color={ 
                              !row.original.allowChange 
                                ? "primary" 
                                : 'secondary'
                            }
                            disabled={ row.original.allowChange }
                            onClick={() => {
                              onChangeStudentVacanciesHOC( 'selectedUpdateStudentVacancies', row.original )
                              onChangeStudentVacanciesHOC( 'showUpdateStudentVacanciesModal', true )
                          }}>
                            Update
                          </Button>
                          <UncontrolledTooltip target="UpdateStudentVacancies" placement="left">Accept/Decline vacancy offer</UncontrolledTooltip>
                          <Button
                            id="DeleteStudentVacancies"
                            className="mr-2 btn-icon btn-icon-only"
                            color={ !row.original.disableStatusField ? "danger" : 'secondary' }
                            disabled={ row.original.disableStatusField }
                            onClick={() => {
                              onChangeStudentVacanciesHOC( 'selectedDeleteStudentVacanciesId', row.original.id )
                              onChangeStudentVacanciesHOC( 'showDeleteStudentVacanciesModal', true )
                            }}
                          >
                            <i className="pe-7s-close btn-icon-wrapper"> </i>
                          </Button>
                          <UncontrolledTooltip target="DeleteStudentVacancies" placement="left">Delete vacancy application</UncontrolledTooltip>
                        </>
                      )
                    }
                  }
                ]}
                showPagination={ false } />
              <Pagination 
                metadata={ studentVacancies.meta } 
                onChangePage={ pg => {
                  const { search } = data.LastViewReducer.lastView
                  let tempJSON = JSON.parse( atob( search ))
                  tempJSON.page = pg
                  
                  getStudentVacancies( btoa( JSON.stringify( tempJSON )))
                }} />
            </Col>
          </Row>
          <ConfirmationModal
            open={ showDeleteStudentVacanciesModal }
            title={ `Delete Vacancy Application` }
            loading={ onLoadStudentVacancies }
            message={ `Are you sure you want to delete this vacancy application? (This process can't be undone)` }
          
            onClose={ () => onChangeStudentVacanciesHOC( 'showDeleteStudentVacanciesModal', false )}
            onClickConfirm={ () => 
              deleteStudentVacancies( selectedDeleteStudentVacanciesId )
            }/>
          <ConfirmationModal
            loading={ onLoadStudentVacancies }
            open={ showUpdateStudentVacanciesModal }
            title={ `Accept Vacancy Offer` }
            message={ `Are you sure you want to accept this vacancy offer? (This process can't be undone)` }
            onClose={ () => onChangeStudentVacanciesHOC( 'showUpdateStudentVacanciesModal', false )}
            onClickConfirm={ () => 
              updateStudentVacancy({
                student_vacancy_id: selectedUpdateStudentVacancies.id,
                company_vacancy_id : selectedUpdateStudentVacancies.company_id,
                student_id : selectedUpdateStudentVacancies.student_id,
                student_vacancy_status_id: parseInt( selectedUpdateStudentVacancies.student_vacancy_status_id ),
                student_confirmation: (selectedUpdateStudentVacancies.student_confirmation.toLowerCase() === "true")
              })
            }/>
        </ReactCSSTransitionGroup>
        { onLoadStudentVacancies && <LoadingOverlay/> }
      </ModalBody>
    </Modal>
  )
}

export default StudentVacanciesHOC( FormContent );