export const FormDefaultValue = {
  date: null,
  start_time: null,
  end_time: null,

  // user_id: null,
  // company_id: null,
  // session_id: null,
  message: '',
  internal_sv_remark: '',
  // student_vacancy_accepted_list_id: null
}

export const FieldData = [
  { label: 'Date', value: 'date', col: 12, showTimeSelect:  false, required: true },
  { label: 'Start Time', value: 'start_time', col: 6, showTimeSelect: true, required: true },
  { label: 'End Time', value: 'end_time', col: 6, showTimeSelect: true, required: true , required: true },
  { label: 'Message', value: 'message', col: 6, showTimeSelect: true, type: 'textarea', required: false },
  { label: 'Remark', value: 'internal_sv_remark', col: 6, showTimeSelect: true, type: 'textarea', required: false  },
]