import React, { useState } from "react";
import CompanyForm from "./CompanyForm";
import CompanyUser from "./CompanyUser";
import VacanciesForm from "./VacanciesForm";
import VacancyStudentForm from "./VacancyStudentForm";
import { Button } from "reactstrap";

function AdminForm( props ) {
	if ( !props.currentPermission.is_read ){
		props.history.push( '/dashboard/statistics' )
	}
	const [currentForm, setCurrentForm] = useState("company");
	return (
		<>
			<div className="d-flex mb-3 flex-wrap" style={{ gap: 10 }}>
				<Button
					color={currentForm === "company" ? "primary" : "secondary"}
					onClick={() => setCurrentForm("company")}>
					Company Form
				</Button>
				<Button
					color={currentForm === "company-user" ? "primary" : "secondary"}
					onClick={() => setCurrentForm("company-user")}>
					Company User
				</Button>
				<Button
					color={currentForm === "vacancies" ? "primary" : "secondary"}
					onClick={() => setCurrentForm("vacancies")}>
					Vacancies Form
				</Button>
				<Button
					color={currentForm === "student-vacancies" ? "primary" : "secondary"}
					onClick={() => setCurrentForm("student-vacancies")}>
					Assign Student to Vacancies Form
				</Button>
			</div>
			{currentForm === "company" && <CompanyForm />}
			{currentForm === "company-user" && <CompanyUser />}
			{currentForm === "vacancies" && <VacanciesForm />}
			{currentForm === "student-vacancies" && <VacancyStudentForm />}
		</>
	);
}

export default AdminForm;
