import React, { useState } from 'react'
import { 
  Modal, ModalHeader, ModalBody,
} from 'reactstrap'
import ConfirmationModal from 'components/Modal/confirmation'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import _ from 'lodash'
import StudentForm from './StudentForm'

import UserRoleHOC from '../actions/userRole'

const FormContent = ({
  data,
  open,
  onClose,

  selectedFormData,
  onLoadStudent,
  onLoadUser,
  onLoadLecturer,
  onLoadUserRole,
  
  updateUser,
  updateStudent,

  createUserRole,
  deleteUserRole
}) => {

  const { roles } = data.DictionaryReducer
  const [ newRole, onChangeNewRole ] = useState( null )
  const [ removeRole, onChangeRemoveRole ] = useState( null)

  return (
    <Modal
      isOpen={ open }
      size={ 'xl' }>
      <ModalHeader toggle={() => onClose()}>{ `Student Approval` }</ModalHeader>
      <ModalBody>
        <StudentForm
          selectedFormData={ selectedFormData }
          updateStudent={ updateStudent }
        />
        {( onLoadUser || 
            onLoadLecturer ||
            onLoadStudent
          ) && <LoadingOverlay />}
        <ConfirmationModal
          open={ newRole && _.values( newRole ).length > 0 }
          title={ `Add ${ newRole?.name??'' } Role` }
          loading={ onLoadUserRole }
          message={ `Are you sure you want to add ${ newRole?.name??'' } role for this user?` }
          onClose={ () => onChangeNewRole( null )}
          onClickConfirm={ () => {
            Promise.all([
              createUserRole({
                role_id: newRole.id,
                user_id: selectedFormData.id
              })
            ]).then(() => {
              onChangeNewRole( null )
            })
          }}/>
        <ConfirmationModal
          open={ removeRole && _.values( removeRole ).length > 0 }
          title={ `Remove ${ removeRole?.name??'' } Role` }
          loading={ onLoadUserRole }
          message={ `Are you sure you want to remove ${ removeRole?.name??'' } role from this user?` }
          onClose={ () => onChangeRemoveRole( null )}
          onClickConfirm={ () => {
            Promise.all([
              deleteUserRole( selectedFormData.id, removeRole.id )
            ]).then(() => onChangeRemoveRole( null ))
          }}/>
      </ModalBody>
    </Modal>
  )
}

export default UserRoleHOC( FormContent );
