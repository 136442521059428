import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Get, Post } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      roles: {
        data: [],
        meta: {
          page: 1,
          page_count: 0,
          per_page: 1,
          total_count: 0,
        }
      },
      selectedRole: {},

      showCreateRolesModal: false,
      showUpdateRolesModal: false
    }

    onChangeRolesHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getRoles = search => Get(
      `/roles?query=${ search }`,
      this.getRolesSuccess,
      this.getRolesError,
      this.load,
      this.props.data.ProfileReducer.current_role_id
    )
    getRolesSuccess = payload => this.setState({ roles: payload })
    getRolesError = error => this.requestError( error )

    getSelectedRole = ( id, context ) => Get(
      `/roles/${ id }`,
      payload => this.getSelectedRoleSuccess( payload, context ),
      this.getSelectedRoleError,
      this.load
    )
    getSelectedRoleSuccess = ( payload, context ) => this.setState({ 
      selectedRole: payload,
      showUpdateRolesModal: context === 'edit',
    })
    getSelectedRoleError = error => this.requestError ( error )

    createRole = dataToSubmit => Post(
      `/roles`,
      dataToSubmit,
      this.createRoleSuccess,
      this.createRoleError,
      this.load
    )
    createRoleSuccess = payload => {
      this.getRoles( '', 1 )
      this.setState({ showCreateRolesModal: false })
      this.requestSuccess( 'New role has been created successfully.' )
    }
    createRoleError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          roles={ this.state.roles }
          selectedRole={ this.state.selectedRole }
          onLoadRoles={ this.state.loading }
          showCreateRolesModal={ this.state.showCreateRolesModal }
          showUpdateRolesModal={ this.state.showUpdateRolesModal }

          getRoles={ this.getRoles }
          getSelectedRole={ this.getSelectedRole }
          createRole={ this.createRole }
          onChangeRolesHOC={ this.onChangeRolesHOC }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC